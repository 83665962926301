import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerTransacciones(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/transacciones/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerRecargas(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/recargas/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerRecarga(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/recargas/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerProveedoresRecargas(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/recargas/proveedores/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerPagosServicios(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/pagos-servicios/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerPagoServicio(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/pagos-servicios/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerProveedoresServicios(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/servicios/proveedores/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function actualizarEstatusRecarga(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/recargas/${id}/cambiar-estatus/`, data);
  } catch (err) {
    callError(err);
  }
}

async function actualizarEstatusPagoServicio(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/pagos-servicios/${id}/cambiar-estatus/`, data);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerTransacciones,
  obtenerRecargas,
  obtenerRecarga,
  obtenerProveedoresRecargas,
  obtenerPagosServicios,
  obtenerPagoServicio,
  obtenerProveedoresServicios,
  actualizarEstatusRecarga,
  actualizarEstatusPagoServicio
};
