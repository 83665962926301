const quitarNulos = (obj, valorPorDefecto = '--') => {
  const isArray = Array.isArray(obj)
  if (isArray){
    const objetos = []
    obj.forEach(o => {
      const llaves = Object.keys(o)
      llaves.forEach(llave => {
        const val = o[llave]
          if (val === undefined || val === '' || val === null){
              o[llave] = valorPorDefecto
          }
      })
      objetos.push(o)

    })
    return objetos
  }
  else {
    const llaves = Object.keys(obj)
    llaves.forEach(llave => {
      const val = obj[llave]
        if (val === undefined || val === '' || val === null){
            obj[llave] = valorPorDefecto
        }
    })
    return obj
  }
}

const frecuenciaCobro = (obj) => {
  let frecuenciaLabel = '';
  switch (obj) {
    case 'semanal':
        frecuenciaLabel = 'semanas';
        break;
    case 'decenal':
        frecuenciaLabel = 'decenas';
        break;
    case 'catorcenal':
        frecuenciaLabel = 'catorcenas';
        break;
    case 'quincenal':
        frecuenciaLabel = 'quincenas';
        break;
    case 'mensual':
        frecuenciaLabel = 'meses';
        break;
    default:
        frecuenciaLabel = '';
  }
  return frecuenciaLabel;
}

const remplazarDaysToDias = (cadenaTexto) => {
  if (typeof cadenaTexto === 'string') {
    return cadenaTexto.replace('day', 'día');
  }
  return cadenaTexto;
}

export {
  quitarNulos,
  frecuenciaCobro,
  remplazarDaysToDias
}
