import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import {
    obtenerRecarga,
    actualizarEstatusRecarga
} from '../../api/EMIDA'
import { dateFormat, currencyFormat } from '../../utils/filters'
import { quitarNulos } from '../../utils/functions'
import SweetAlert from 'sweetalert-react';
import Swal from "sweetalert2";
import { verificarPermiso } from '../../utils/auth';

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        objeto: {},
        estatus: '',
        showConfirm: false,
        empleado: {},
        transaccion: {
        }
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Ver detalle de recarga'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            const res = await obtenerRecarga(id)
            console.log('res', res)
            let objeto = res.data
            let { empleado, transaccion } = objeto
            if (transaccion === null) {
                transaccion = {
                    'account_id': '--',
                    'amount_id': '--',
                    'carrier_control_no': '--',
                    'control_no': '--',
                    'customer_service_no': '--',
                    'date_time': '--',
                    'fecha_creacion': '--',
                    'h2h_result_code': '--',
                    'id': '--',
                    'invoice_id': '--',
                    'pin': '--',
                    'product_id': '--',
                    'response_code': '--',
                    'response_message': '--',
                    'response_transaction_id': '--',
                    'terminal_id': '--'
                }
            }
            if (empleado === null) {
                empleado = {
                    'curp': '--',
                    'email': '--',
                    'empresa': '--',
                    'nombre': '--'
                }
            }
            objeto = quitarNulos(objeto)
            transaccion = quitarNulos(transaccion)
            empleado = quitarNulos(empleado)
            let estatus = objeto.estatus
            this.setState({ objeto, empleado, transaccion, estatus })
        } catch (error) {
            console.log(error)
        }
    }

    handleChange = (event) => {
        console.log(event.target.value)
        this.setState({estatus: event.target.value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // alert('Se ha cambiado el estatus');
        this.setState({showConfirm: true});
    }

    async cambiarEstatus() {
        let { id, estatus } = this.state;

        this.setState({showConfirm: false});

        try {
            await actualizarEstatusRecarga(id, {estatus});
            this.mostrarToast('Se actualizó el estatus de la recarga con éxito');
            this.componentDidMount()
        } catch (error) {
            // Ocurrió un error
            console.log(error);
            if (error.status === 403) {
                this.mostrarToast(error.message, 'warning');
            } else {
                this.mostrarToast('Ocurrió un error al guardar el estatus', 'error');
            }
        }
    }

    mostrarToast = (mensaje, tipo = 'success') => {
        Swal.fire({
            type: tipo,
            text: mensaje,
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
        })
    }

    render () {
        const guia = [
            { nombre: 'Recargas', url: '/recargas', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        const {
            titulo,
            objeto,
            empleado,
            transaccion,
            estatus
        } = this.state

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active">
                                    <div className="panel-body">

                                        {/* Estatus de la recarga*/}
                                        { objeto.estatus === 'pendiente' && verificarPermiso('pago_servicios.change_recarga') &&

                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="panel panel-default">
                                                      <div className="panel-heading">
                                                          Estatus de la recarga
                                                      </div>
                                                      <div className="panel-body">
                                                          El estatus de la recarga actualmente esta en pendiente, se tiene que verificar manualmente el estatus para rechazarlo o marcarla como exitosa.

                                                          <div className="row">
                                                              <div className="col-sm-6">

                                                                  <div className="form-recarga">
                                                                      <b>Modfica el estatus de la recarga</b>
                                                                      <form onSubmit={(e) => this.handleSubmit(e)} action=''>
                                                                          <div className="form-group row">
                                                                              <label className="col-sm-2 col-form-label">Estatus</label>
                                                                              <div className="col-sm-10">
                                                                                  <select className="form-control m-b" name="account" onChange={(e) => this.handleChange(e)}>
                                                                                      <option value="">Selecciona un estatus</option>
                                                                                      <option value="exito">Exito</option>
                                                                                      <option value="error">Error</option>
                                                                                  </select>
                                                                              </div>
                                                                          </div>
                                                                          <div className="form-group row">
                                                                              <div className="col-sm-12">
                                                                                  <button disabled={estatus === 'pendiente'} type='submit' className='btn btn-primary btn-sm pull-right'>Guardar</button>
                                                                              </div>
                                                                          </div>
                                                                      </form>
                                                                      <SweetAlert
                                                                        show={this.state.showConfirm}
                                                                        title="¿Estás seguro?"
                                                                        text="Al cambiar el estatus de una Recarga no se podrá volver a cambiar. Ten en cuenta que si se marca como éxitosa se generará un préstamo emergente."
                                                                        showCancelButton={true}
                                                                        cancelButtonText='Cancelar'
                                                                        confirmButtonText='Confirmar'
                                                                        onConfirm={() => this.cambiarEstatus()}
                                                                        onCancel={() => this.setState({showConfirm: false})}
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>

                                        }

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Detalle de recarga
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Producto</label>
                                                                <label>{ objeto.producto }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Proveedor</label>
                                                                <label>{ objeto.proveedor }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Folio</label>
                                                                <label>{ objeto.folio }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Número de teléfono</label>
                                                                <label>{ objeto.numero_telefono }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Estatus</label>
                                                                <label>{ objeto.estatus }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha</label>
                                                                <label>{ dateFormat(objeto.fecha_creacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto</label>
                                                                <label>{ currencyFormat(objeto.monto) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Comision</label>
                                                                <label>{ currencyFormat(objeto.comision) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Total</label>
                                                                <label>{ currencyFormat(objeto.total) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Usuario que creó</label>
                                                                <label>{ objeto.usuario_creo }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Detalle de empleado
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Nombre</label>
                                                                <label>{ empleado.nombre }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Correo electrónico</label>
                                                                <label>{ empleado.email }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>CURP</label>
                                                                <label>{ empleado.curp }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>empresa</label>
                                                                <label>{ empleado.empresa }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Detalle de transacción
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Terminal</label>
                                                                <label>{ transaccion.terminal_id }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Transacción</label>
                                                                <label>{ transaccion.response_transaction_id }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>invoice_id</label>
                                                                <label>{ transaccion.invoice_id }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>PIN</label>
                                                                <label>{ transaccion.pin }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Número de control</label>
                                                                <label>{ transaccion.control_no }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Código h2h</label>
                                                                <label>{ transaccion.h2h_result_code }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Mensaje de respuesta</label>
                                                                <label>{ transaccion.response_message }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label># Control de proveedor</label>
                                                                <label>{ transaccion.carrier_control_no }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label># Servicio al cliente</label>
                                                                <label>{ transaccion.customer_service_no }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Producto ID</label>
                                                                <label>{ transaccion.product_id }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto</label>
                                                                <label>{ currencyFormat(transaccion.amount_id) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>account_id</label>
                                                                <label>{ transaccion.account_id }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Código de respuesta</label>
                                                                <label>{ transaccion.response_code }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha</label>
                                                                <label>{ dateFormat(transaccion.fecha_creacion) }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                    .form-recarga {
                        margin-top: 15px;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
