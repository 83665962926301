import React from 'react';

const Spinner = () => {
    return (
        <div className="ibox">
            <div className="ibox-content">
                <div className="spiner-example">
                    <div className="sk-spinner sk-spinner-wave">
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                    </div>
                    <p className="text-center">Cargando...</p>
                </div>
            </div>
        </div>
    );
}
 
export default Spinner;