import React, { Component } from 'react';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import 'sweetalert/dist/sweetalert.css';
import 'flatpickr/dist/themes/material_green.css';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { currencyFormat, dateFormat } from '../../utils/filters';
import '../../css/plugins/steps/jquery.steps.css';
import {
  cotizarProductoPromotor,
  crearSolicitudPromotor,
  obtenerEmpleadosDeEmpresa,
  obtenerEmpresasPromotores,
  obtenerProductosEmpleado
} from '../../api/Promotores';
import { validateDecimal } from '../../utils/regex';
import { obtenerEmpleado } from '../../api/Empleados';
import { mostrarToast } from '../../utils/alerts';
import { quitarNulos } from '../../utils/functions';
import { Link } from 'react-router-dom';

class Form extends Component {
    state = {
        fechaInicio: moment().toDate(),
        idEmpleado: null,
        idEmpresa: null,
        idProducto: null,
        indiceEmpleado: null,
        indiceProducto: null,
        empleado: null,
        titulo: null,
        pasoSeleccionado: 1,
        datos: {
            monto: '',
            plazo: '',
            num_amortizaciones: ''
        },
        errores: {
            monto: [],
            plazo: [],
            fecha_inicio: []
        },
        // empleado: {},
        empresas: [],
        empleados: [],
        productos: [],
        montoMinimo: null,
        montoMaximo: null,
        frecuenciaCobro: null,
        plazos: null,
        pagos: [],
        totales: {},
        cotizado: false,
        totalRecords: 0,
        pageLimit: 10,
        params: {},
        datosBus: {
            search: ''
        }
    }

    componentWillMount() {
        const titulo = 'Crear solicitud'
        this.setState({ titulo })
    }

    async componentDidMount() {
        try {
            const resp = await obtenerEmpresasPromotores();
            const empresas = resp.data.map(obj => {
              return { label: obj.nombre, value: obj.id };
            });
            this.setState({ empresas });
        } catch (error) {
            console.log(error)
        }
    }

    changeCompany = (name) => {
        const idEmpresa = name.value
        this.setState({
            idEmpresa,
            idEmpleado: null,
            indiceEmpleado: null,
            empleado: null,
            idProducto: null,
            indiceProducto: null
        })
    }

    seleccionarEmpleado = async(idEmpleado, indiceEmpleado) => {
        try {
            const req = await obtenerEmpleado(idEmpleado);
            const empleado = quitarNulos(req.data);
            this.setState({ idEmpleado, indiceEmpleado, empleado })
        } catch (error) {
            console.log(error);
        }
    }

    seleccionarProducto = async(idProducto, indiceProducto) => {
        const { productos } = this.state
        if (productos.length > 1){
            const producto = productos[indiceProducto]
            const { plazos } = producto
            const frecuenciaCobro = producto.frecuencia_cobro
            const montoMaximo = producto.monto_maximo
            const montoMinimo = producto.monto_minimo
            const nombreProducto = producto.nombre
            const tasaInteresAnual = producto.tasa_interes_anual

            this.setState({
                idProducto,
                indiceProducto,
                frecuenciaCobro,
                montoMaximo,
                montoMinimo,
                nombreProducto,
                tasaInteresAnual,
                plazos
            })
        }
    }

    siguiente = async () => {

        const { idEmpresa, idEmpleado, idProducto } = this.state
        let { pasoSeleccionado } = this.state
        switch(pasoSeleccionado) {
            case 1:
                if (pasoSeleccionado === 1 && idEmpresa !== null){
                    const params = { empresa: idEmpresa };
                    await this.obtenerObjetos(params)
                } else return
                break
            case 2:
                if (pasoSeleccionado === 2 && idEmpleado !== null){
                    const resp = await obtenerProductosEmpleado(idEmpleado)
                    const productos = resp.data
                    if (productos.length === 1) {
                        pasoSeleccionado += 1
                        const { plazos } = productos[0]
                        const tasaInteresAnual = productos[0].tasa_interes_anual
                        const frecuenciaCobro = productos[0].frecuencia_cobro
                        const montoMaximo = productos[0].monto_maximo
                        const montoMinimo = productos[0].monto_minimo
                        const nombreProducto = productos[0].nombre
                        const idProducto = productos[0].id
                        this.setState({
                            productos,
                            nombreProducto,
                            tasaInteresAnual,
                            montoMinimo,
                            montoMaximo,
                            frecuenciaCobro,
                            plazos,
                            idProducto
                        })
                    } else {
                        console.log()
                        this.setState({ productos })
                    }
                } else return
                break
            case 3:
                if (pasoSeleccionado === 3 && idProducto === null) return
                break
            default:
                return
        }

        pasoSeleccionado += 1
        this.setState({ pasoSeleccionado })
    }

    onChange = (e, nombre) => {
        const { datos } =  this.state
        const valor = e.target.value
        if (nombre === 'monto') {
            if (!validateDecimal(valor) && valor.length > 0) { return }
        }
        datos[nombre] = valor
        this.setState({ datos, cotizado: false }, () => { console.log('state', this.state.datos)})
    }

    handleChangeDate = date => {
        const fechaInicio = date[0];
        this.setState({ fechaInicio, cotizado: false });
    }

    cotizar =async () => {
        const { datos, fechaInicio, frecuenciaCobro, idProducto, montoMinimo, montoMaximo } = this.state
        const errores = {
            monto: [],
            plazo: [],
            fecha_inicio: []
        };
        if (datos.monto.trim().length === 0) {
            errores.monto = ['Este campo es requerido.']
        } else if (parseFloat(datos.monto) < parseFloat(montoMinimo) || parseFloat(datos.monto) > parseFloat(montoMaximo)) {
            errores.monto = [`El valor del monto debe estar entre ${currencyFormat(montoMinimo)} y ${currencyFormat(montoMaximo)}.`]
        }
        if (datos.num_amortizaciones.trim().length === 0) {
            errores.plazo = ['Este campo es requerido.']
        }
        if (errores.monto.length > 0 || errores.plazo.length > 0) {
            this.setState({ errores });
            return;
        }
        datos.fecha_inicio = moment(fechaInicio).format('YYYY-MM-DD')
        datos.frecuencia = frecuenciaCobro
        const resp = await cotizarProductoPromotor(datos, idProducto)
        const { pagos, totales } = resp.data
        this.setState({ datos, pagos, totales, cotizado: true })
    }

    async crear() {
        try {
            const { datos, idEmpleado, idProducto } = this.state
            const obj = {
                empleado: idEmpleado,
                producto: idProducto,
                monto: datos.monto,
                num_amortizaciones: datos.num_amortizaciones
            }
            const resp = await crearSolicitudPromotor(obj);
            mostrarToast('Se creó la solicitud como incompleta', 'success');
            this.props.history.push(`/solicitudes/${resp.data.id}/referencias`)
        } catch (error) {
            const errors = error.data
            this.setState({ errors })
        }

    }

    anterior(){
        let { pasoSeleccionado } = this.state
        pasoSeleccionado -= 1
        this.setState({ pasoSeleccionado })
    }

    onPageChanged = async data => {
        const page = data.currentPage;
        const { pageLimit, params } = this.state;
        params.page = page;
        params.page_size = pageLimit;
        try {
            const req = await obtenerEmpleadosDeEmpresa(params);
            const empleados = req.data.results;
            const totalRecords = req.data.count;
            this.setState({ empleados, totalRecords, params });
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    obtenerObjetos = async params => {
        try {
            const req = await obtenerEmpleadosDeEmpresa(params);
            const empleados = req.data.results;
            const totalRecords = req.data.count;
            this.setState({ empleados, totalRecords, params });
        } catch (error) {
            console.log(error);
        }
    };

    buscarEmpleado = async () => {
        let { params } = this.state;
        const {
            datosBus: { search }
        } = this.state;
        delete params.search;
        delete params.page;
        params = Object.assign({ search }, params);
        this.obtenerObjetos(params);
    };

    onChangeEmpleado = async(campo, e) => {
        const { datosBus } = this.state;
        const valor = e.target.value;
        datosBus[campo] = valor;
        this.setState({ datosBus });
        if (valor === '') {
            await this.limpiarEmpleado()
        }
    };

    limpiarEmpleado = async () => {
        const { idEmpresa } = this.state
        this.setState({
            params: {},
            datosBus: {
                search: '',
                // empresa: idEmpresa
            }
        });
        const { pageLimit } = this.state;
        const params = { page_size: pageLimit, empresa: idEmpresa };
        await this.obtenerObjetos(params);
    };

    handleKeyDown = async(e) => {
        if (e.key === 'Enter') {
            await this.buscar()
        }
    }

    render () {
        const guia = [
            { nombre: 'Solicitudes', url: '/solicitudes', clase: '' },
            { nombre: 'Crear', url: '', clase: 'active' }
        ]
        const {
            titulo,
            pasoSeleccionado,
            indiceEmpleado,
            indiceProducto,
            idEmpleado,
            idEmpresa,
            idProducto,
            empleado,
            nombreProducto,
            // tasaInteresAnual,
            montoMinimo,
            montoMaximo,
            frecuenciaCobro,
            plazos,
            // empleado,
            empresas,
            totalRecords,
            pageLimit,
            datosBus: { search },
            // datos: { empresa, estatus, tipo },
            empleados,
            productos,
            datos,
            errores,
            fechaInicio,
            pagos,
            totales,
            // cols,
            cotizado
        } = this.state;

        let frecuenciaLabel = '';
        switch (frecuenciaCobro) {
            case 'semanal':
                frecuenciaLabel = 'semanas';
                break;
            case 'decenal':
                frecuenciaLabel = 'decenas';
                break;
            case 'catorcenal':
                frecuenciaLabel = 'catorcenas';
                break;
            case 'quincenal':
                frecuenciaLabel = 'quincenas';
                break;
            case 'mensual':
                frecuenciaLabel = 'meses';
                break;
            default:
                frecuenciaLabel = '';

        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-content">
                                <form id="form" action="#" className="wizard-big wizard clearfix frm">
                                    <div className="steps clearfix">
                                        <ul role="tablist">
                                            <li role="tab" className={`first ${pasoSeleccionado === 1 ? 'current' : 'done'}`} aria-disabled="false" aria-selected="true">
                                                <a id="form-t-0" aria-controls="form-p-0">
                                                    <span className="number">1.</span> Seleccionar empresa
                                                </a>
                                            </li>
                                            <li role="tab" className={`second ${pasoSeleccionado === 2 ? 'current' : 'done'}`} aria-disabled="false" aria-selected="true">
                                                <a id="form-t-0" aria-controls="form-p-0">
                                                    <span className="number">2.</span> Seleccionar empleado
                                                </a>
                                            </li>
                                            <li role="tab" className={`third ${pasoSeleccionado === 3 ? 'current' : 'done'}`} aria-disabled="false" aria-selected="true">
                                                <a id="form-t-0" aria-controls="form-p-0">
                                                    <span className="number">3.</span> Seleccionar producto
                                                </a>
                                            </li>
                                            <li role="tab" className={`last ${pasoSeleccionado === 4 ? 'current' : 'done'}`} aria-disabled="false" aria-selected="false">
                                                <a id="form-t-1" aria-controls="form-p-1">
                                                    <span className="number">4.</span> Finalizar
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    { pasoSeleccionado === 1 &&
                                        <fieldset>
                                            <h2>Seleccionar empresa</h2>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="ibox ">
                                                    <div className="ibox-content">
                                                        <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="input-group">
                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="empresa"
                                                                    value={empresas.filter(({value}) => value === idEmpresa)}
                                                                    onChange={this.changeCompany}
                                                                    options={empresas}
                                                                />
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <button
                                                                type="button"
                                                                className={`btn btn-primary btn-sm right ${idEmpresa === null ? 'disabled' : ''}`}
                                                                onClick={ () => this.siguiente() }
                                                            >
                                                                Siguiente
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }
                                    { pasoSeleccionado === 2 &&
                                        <fieldset>
                                            <h2>Seleccionar empleado</h2>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="ibox ">
                                                    <div className="ibox-content">
                                                        <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="input-group">
                                                            <input
                                                                value={search}
                                                                type="text"
                                                                autoFocus
                                                                className="form-control form-control-sm"
                                                                onChange={e => this.onChangeEmpleado('search', e)}
                                                                placeholder="Buscar"
                                                            />
                                                            <span className="input-group-append">
                                                                <button
                                                                type="button"
                                                                className="btn btn-sm btn-info"
                                                                onClick={() => this.limpiarEmpleado()}
                                                                >
                                                                <i className="fa fa-close fa-margin" />
                                                                </button>
                                                            </span>
                                                            <span className="input-group-append">
                                                                <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary"
                                                                onClick={() => this.buscarEmpleado()}
                                                                onKeyDown={e => this.handleKeyDown(e)}
                                                                >
                                                                <i className="fa fa-search fa-margin" />
                                                                Buscar
                                                                </button>
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {empleados.map((obj, indice) => {
                                                                return (
                                                                    <tr key={ obj.id } className={`${indiceEmpleado === indice && 'prestamo-sel'}`}>
                                                                        <td>{ obj.nombre }</td>
                                                                        <td>
                                                                            { obj.solicitud_en_proceso ?
                                                                                <React.Fragment>
                                                                                    <Link
                                                                                        className="btn btn-warning btn-sm btns-space"
                                                                                        to={"/solicitudes/ver/"+obj.solicitud_en_proceso}>
                                                                                        Ir Solicitud
                                                                                    </Link>
                                                                                    <small className="font-italic">
                                                                                        <br />
                                                                                        Ya cuenta con una solicitud en proceso
                                                                                    </small>
                                                                                </React.Fragment>
                                                                                :
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                    onClick={() => this.seleccionarEmpleado(obj.id, indice)}
                                                                                >
                                                                                    Seleccionar
                                                                                </button>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <Paginacion
                                                        totalRecords={totalRecords}
                                                        pageLimit={pageLimit}
                                                        pageNeighbours={1}
                                                        onPageChanged={this.onPageChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button
                                                        type="button"
                                                        className={`btn btn-primary btn-sm right ${idEmpleado === null ? 'disabled' : ''}`}
                                                        onClick={ () => this.siguiente() }
                                                    >
                                                        Siguiente
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm fa-margin right"
                                                        onClick={ () => this.anterior() }
                                                    >
                                                        Anterior
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }
                                    { pasoSeleccionado === 3 &&
                                        <fieldset>
                                            <h2>Seleccionar producto</h2>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="ibox ">
                                                    <div className="ibox-content">
                                                        <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {productos.map((prod, indice) => {
                                                                return (
                                                                    <tr key={ prod.id } className={`${indiceProducto === indice && 'prestamo-sel'}`}>
                                                                    <td>{prod.nombre}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm btns-space"
                                                                            onClick={() => this.seleccionarProducto(prod.id, indice)}
                                                                        >
                                                                            Seleccionar
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button
                                                        type="button"
                                                        className={`btn btn-primary btn-sm right ${idProducto === null ? 'disabled' : ''}`}
                                                        onClick={ () => this.siguiente() }
                                                    >
                                                        Siguiente
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm fa-margin right"
                                                        onClick={ () => this.anterior() }
                                                    >
                                                        Anterior
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }
                                    { pasoSeleccionado === 4 &&
                                        <fieldset>
                                            <br/>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Datos del Empleado
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Nombre</label>
                                                                    <label>{ empleado.nombre }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Apellido Paterno</label>
                                                                    <label>{ empleado.apellido_paterno }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Apellido Materno</label>
                                                                    <label>{ empleado.apellido_materno }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>CURP</label>
                                                                    <label>{ empleado.curp }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>RFC</label>
                                                                    <label>{ empleado.rfc }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Empresa</label>
                                                                    <label>{ empleado.empresa_str }</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Datos del producto
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Producto</label>
                                                                    <label>{ nombreProducto }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Frecuencia de cobro</label>
                                                                    <label>{ frecuenciaCobro }</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className={`form-group row ${errores.monto.length > 0 && 'has-error'}`}>
                                                            <label className="col-sm-4 col-form-label">Monto *</label>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    autoFocus
                                                                    type="text"
                                                                    value={datos.monto}
                                                                    name="monto"
                                                                    onChange={e => this.onChange(e, 'monto')}
                                                                    className="form-control"
                                                                />
                                                                <span className="form-text m-b-none">
                                                                    El monte debe de ser entre { montoMinimo} y { montoMaximo }
                                                                </span>
                                                                {errores.monto.map((obj, index) => (
                                                                    <span className="form-text m-b-none text-error" key={index}>
                                                                        {obj}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className={`form-group row ${errores.plazo.length > 0 && 'has-error'}`}>
                                                            <label className="col-sm-4 col-form-label">Plazo *</label>
                                                            <div className="col-sm-8">
                                                                <select
                                                                    value={datos.num_amortizaciones}
                                                                    name="plazo"
                                                                    onChange={e => this.onChange(e, 'num_amortizaciones')}
                                                                    className="form-control m-b"
                                                                >
                                                                    <option value="">
                                                                        Seleccionar...
                                                                    </option>
                                                                    {plazos.map(plazo => (
                                                                        <option key={plazo} value={plazo}>
                                                                            { `${plazo} ${frecuenciaLabel}`}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errores.plazo.map((obj, index) => (
                                                                    <span className="form-text m-b-none text-error" key={index}>
                                                                        {obj}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="fecha">Fecha inicial *</label>
                                                            <Flatpickr
                                                                className="form-control date"
                                                                value={fechaInicio}
                                                                name="fecha_inicio"
                                                                options={{
                                                                    dateFormat: 'd-m-Y'
                                                                }}
                                                                onChange={this.handleChangeDate}
                                                            />
                                                            {errores.fecha_inicio.map((obj, index) => (
                                                                <span
                                                                    className="form-text m-b-none text-error"
                                                                    key={`efi${index}`}
                                                                >
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-4 col-sm-offset-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={ this.cotizar }
                                                        >
                                                            Cotizar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Plazo</th>
                                                                    <th>Fecha</th>
                                                                    <th>Capital</th>
                                                                    <th>Interes</th>
                                                                    <th>IVA</th>
                                                                    <th>Total</th>
                                                                    <th>Saldo final</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pagos.map(pago => {
                                                                    return (
                                                                        <tr key={`amortizacion-${pago.num_pago}`}>
                                                                            <td>{ pago.num_pago }</td>
                                                                            <td>{ dateFormat(pago.fecha) }</td>
                                                                            <td>{ currencyFormat(pago.capital) }</td>
                                                                            <td>{ currencyFormat(pago.interes_sin_iva) }</td>
                                                                            <td>{ currencyFormat(pago.iva) }</td>
                                                                            <td>{ currencyFormat(pago.total_pagar) }</td>
                                                                            <td>{ currencyFormat(pago.saldo_final) }</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                {totales &&
                                                                    <tr className="font-bold">
                                                                        <td></td>
                                                                        <td>TOTAL</td>
                                                                        <td>{ currencyFormat(totales.capital) }</td>
                                                                        <td>{ currencyFormat(totales.interes) }</td>
                                                                        <td>{ currencyFormat(totales.iva) }</td>
                                                                        <td>{ currencyFormat(totales.total) }</td>
                                                                        <td></td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm right"
                                                        disabled={!cotizado}
                                                        onClick={ () => this.crear() }
                                                    >
                                                        Crear
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm fa-margin right"
                                                        onClick={ () => this.anterior() }
                                                    >
                                                        Anterior
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                    margin{
                        margin-top: 120px
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .prestamo-sel {
                        border: solid #398058 2px;
                        background-color: #94DAD0 !important;
                    }

                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .centrado{
                        text-align: center;
                    }
                    .rojo{
                        color: red;
                    }
                    .verde{
                        color: green;
                    }
                    .frm {
                        min-height: 500px;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
