import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias'
import { verificarPermiso } from '../../utils/auth'

class Lista extends Component {
    state = {
        objetos: []
    }

    async componentDidMount() {
        try {
            const res = await obtenerCuentasBancarias()
            const objetos = res.data
            this.setState({ objetos })
        } catch (error) {
            console.log(error)
        }   
    }

    render () {
        const { objetos } = this.state
        const guia = [
            { nombre: 'Cuentas bancarias', url: '', clase: 'active' }
        ]

        const urlBotonAgregar = verificarPermiso('cuentas_bancarias.add_cuenta') ? '/cuentas-bancarias/agregar' : ''
        const urlBotonEditar = verificarPermiso('cuentas_bancarias.change_cuenta') ? '/cuentas-bancarias/editar' : ''

        const botones = [
            {
                etiqueta: 'Editar',
                url: urlBotonEditar
            },{
                etiqueta: 'Ver',
                url: '/cuentas-bancarias/ver/'
            }
        ]
        const columnas = [
            {
                llave: 'no_cuenta',
                etiqueta: 'Cuenta'
            },{
                llave: 'banco',
                etiqueta: 'Banco'
            },{
                llave: 'fecha_creacion',
                etiqueta: 'Fecha de creación',
                tipo: 'fecha'
            },{
                llave: 'usuario_creo',
                etiqueta: 'Usuario que creó'
            }
        ]

        return (
            <Autenticado titulo="Cuentas bancarias" guia={ guia }>
                <Tabla
                    objetos={ objetos }
                    columnas={ columnas }
                    botones={ botones }
                    urlBotonAgregar={urlBotonAgregar}
                />
            </Autenticado>
        )
    }
}

export default Lista
