import React from 'react';

const Input = ({
  error,
  label,
  type,
  value,
  checked,
  name,
  helpText,
  className = 'col-sm-6',
  labelClassName = 'col-sm-4',
  controlClassName = 'col-sm-8',
  onChange,
  validate,
  onKeyPress,
  disabled
}) => (
  <div className={className}>
    <div className={`form-group row ${error.length > 0 && 'has-error'}`}>
      <div className={`${controlClassName} center-vertical`}>
        <input
          type={type}
          value={value}
          checked={checked}
          name={name}
          onChange={onChange}
          className="form-control"
          onBlur={validate}
          onKeyPress={onKeyPress}
          disabled={disabled}
        />
        <small className="form-text m-b-none">{helpText}</small>
        {error.map((obj, index) => (
          <span className="form-text m-b-none text-error" key={index}>
            {obj}
          </span>
        ))}
      </div>
      <label className={`${labelClassName} col-form-label`}>{label}</label>
    </div>
    <style jsx="true">{`
      .center-vertical {
        align-items: center;
        display: flex;
      }
    `}</style>
  </div>
);

export default Input;
