import React, { Component } from 'react';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import Autenticado from '../../components/Autenticado';
import { obtenerProducto, cotizarProducto } from '../../api/Productos';
import { currencyFormat } from '../../utils/filters';
import { validateDecimal } from '../../utils/regex';

class Form extends Component {
  state = {
    id: null,
    monto: '',
    tasa_interes_anual: 0,
    amortizaciones: '',
    frecuencia: '',
    fechaInicio: moment().toDate(),
    objeto: {},
    pagos: [],
    totales: {},
    errores: {
      monto: [],
      num_amortizaciones: [],
      frecuencia: [],
      fecha_inicio: [],
      tasa_interes_anual: [],
    }
  };

  componentWillMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({ id });
  }

  async componentDidMount() {
    const { id } = this.state;
    if (id) {
      try {
        let frecuencia = '';
        const resp = await obtenerProducto(id);
        const objeto = resp.data;
        console.log('objeto', objeto)
        if (!objeto.frecuencia_cobro_dinamico) {
          frecuencia = objeto.frecuencia_cobro;
        }
        this.setState({ objeto, frecuencia });
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState({ [name]: value });
  };

  handleChangeDate = date => {
    const fechaInicio = date[0];
    this.setState({ fechaInicio });
  };

  handleKeyPress = (validation, e) => {
    const charCode = e.which ? e.which : e.keyCode;
    switch (validation) {
      case undefined:
        return true;
      case 'only_numbers':
        if (charCode >= 48 && charCode <= 57) return true;
        e.preventDefault();
        break;
      case 'read_only': {
        const _e = e || window.event;
        _e.preventDefault();
        break;
      }
      case 'decimal': {
        const value = `${e.target.value}${e.key}`;
        if (!validateDecimal(value)) e.preventDefault();
        break;
      }
      default:
        console.log('Validacion KeyPress no valida');
        return true;
    }
    return true;
  };

  validar = () => {
    const errores = {
      monto: [],
      num_amortizaciones: [],
      frecuencia: [],
      fecha_inicio: []
    };
    let valido = true;
    const {
      monto,
      amortizaciones,
      frecuencia,
      fechaInicio,
      objeto
    } = this.state;

    if (monto.length === 0) {
      errores.monto = ['El monto es requerido.'];
      valido = false;
    } else if (parseFloat(monto) < parseFloat(objeto.monto_minimo) && false) {
      errores.monto = [
        `El monto minimo es ${currencyFormat(objeto.monto_minimo)}.`
      ];
      valido = false;
    } else if (parseFloat(monto) > parseFloat(objeto.monto_maximo) && false) {
      errores.monto = [
        `El monto maximo es ${currencyFormat(objeto.monto_maximo)}.`
      ];
      valido = false;
    }

    if (amortizaciones.length === 0) {
      errores.num_amortizaciones = ['Las amortizaciones son requeridas.'];
      valido = false;
    } else if (parseFloat(amortizaciones) < objeto.plazo_minimo && false) {
      errores.num_amortizaciones = [
        `El plazo minimo es ${objeto.plazo_minimo}.`
      ];
      valido = false;
    } else if (parseFloat(amortizaciones) > objeto.plazo_maximo) {
      errores.num_amortizaciones = [
        `El plazo maximo es ${objeto.plazo_maximo}.`
      ];
      valido = false;
    }

    if (frecuencia.length === 0) {
      errores.frecuencia = ['Selecciona una frecuencia.'];
      valido = false;
    }

    if (fechaInicio.length === 0) {
      errores.fecha_inicio = ['Selecciona una fecha inicial.'];
      valido = false;
    }

    this.setState({ errores });
    return valido;
  };

  submit = async () => {
    if (!this.validar()) {
      return;
    }
    try {
      const { id, monto, tasa_interes_anual, amortizaciones, frecuencia, fechaInicio } = this.state;
      const datos = {
        monto,
        tasa_interes_anual,
        num_amortizaciones: amortizaciones,
        frecuencia,
        fecha_inicio: moment(fechaInicio).format('YYYY-MM-DD')
      };
      console.log('Datos:', datos);
      const resp = await cotizarProducto(id, datos);
      this.setState({
        pagos: resp.data.pagos,
        totales: resp.data.totales
      });
      console.log('Resp:', resp);
    } catch (error) {
      const oErrores = {
        monto: [],
        num_amortizaciones: [],
        frecuencia: [],
        fecha_inicio: []
      };
      const errores = { ...oErrores, ...error.response.data };
      this.setState({ errores });
    }
  };

  render() {
    const guia = [
      { nombre: 'Productos', url: '/productos', clase: '' },
      { nombre: 'Cotizar', url: '', clase: 'active' }
    ];
    const {
      monto,
      tasa_interes_anual,
      amortizaciones,
      frecuencia,
      fechaInicio,
      pagos,
      totales,
      objeto,
      errores
    } = this.state;
    return (
      <Autenticado titulo="Ver producto" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>Producto: { objeto.nombre }</h5>
                  </div>
                  <div className="ibox-content">
                    <form>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="monto">Monto *</label>
                            <input
                              id="monto"
                              name="monto"
                              type="text"
                              placeholder="Ej: 1000"
                              className="form-control"
                              value={monto}
                              onChange={this.handleChange}
                              onKeyPress={e =>
                                this.handleKeyPress('decimal', e)
                              }
                            />
                            {errores.monto.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`em${index}`}
                              >
                                {obj}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="tasa_interes_anual">Tasa Interés *</label>
                            <input
                              id="tasa_interes_anual"
                              name="tasa_interes_anual"
                              type="text"
                              placeholder="Ej: 45.25"
                              className="form-control"
                              value={tasa_interes_anual}
                              onChange={this.handleChange}
                              onKeyPress={e =>
                                this.handleKeyPress('decimal', e)
                              }
                            />
                            {errores.tasa_interes_anual && errores.tasa_interes_anual.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`em${index}`}
                              >
                                {obj}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="amortizaciones">
                              Amortizaciónes *
                            </label>
                            <input
                              id="amortizaciones"
                              name="amortizaciones"
                              type="text"
                              placeholder="Ej: 12"
                              value={amortizaciones}
                              className="form-control"
                              onChange={this.handleChange}
                              onKeyPress={e =>
                                this.handleKeyPress('only_numbers', e)
                              }
                            />
                            {errores.num_amortizaciones.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`ea${index}`}
                              >
                                {obj}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="frecuencia">Frecuencia *</label>
                            <select
                              className="form-control"
                              id="frecuencia"
                              name="frecuencia"
                              value={frecuencia}
                              // disabled={!objeto.frecuencia_cobro_dinamico}
                              onChange={this.handleChange}
                            >
                              <option value="">Selecciona...</option>
                              <option value="semanal">Semanal</option>
                              <option value="decenal">Decenal</option>
                              <option value="catorcenal">Catorcenal</option>
                              <option value="quincenal">Quincenal</option>
                              <option value="mensual">Mensual</option>
                            </select>
                            {errores.frecuencia.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`ef${index}`}
                              >
                                {obj}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="fecha">Fecha inicial *</label>
                            <Flatpickr
                              className="form-control date"
                              value={fechaInicio}
                              options={{
                                minDate: moment().format('YYYY-MM-DD'),
                                dateFormat: 'd-m-Y'
                              }}
                              onChange={this.handleChangeDate}
                            />
                            {errores.fecha_inicio.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`efi${index}`}
                              >
                                {obj}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-primary btn-w-m"
                            onClick={this.submit}
                          >
                            <i className="fa fa-usd" aria-hidden="true" />
                            &nbsp;Cotizar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {pagos.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Pagos</h5>
                    </div>
                    <div className="ibox-content">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Fecha</th>
                              <th className="text-right">Capital</th>
                              <th className="text-right">Interes</th>
                              <th className="text-right">Interes sin IVA</th>
                              <th className="text-right">IVA</th>
                              <th className="text-right">Total a pagar</th>
                              <th className="text-right">Saldo final</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pagos.map(p => (
                              <tr key={`pago${p.num_pago}`}>
                                <td>{p.num_pago}</td>
                                <td>{p.fecha}</td>
                                <td className="text-right">
                                  {currencyFormat(p.capital)}
                                </td>
                                <td className="text-right">
                                  {currencyFormat(p.interes)}
                                </td>
                                <td className="text-right">
                                  {currencyFormat(p.interes_sin_iva)}
                                </td>
                                <td className="text-right">
                                  {currencyFormat(p.iva)}
                                </td>
                                <td className="text-right">
                                  {currencyFormat(p.total_pagar)}
                                </td>
                                <td className="text-right">
                                  {currencyFormat(p.saldo_final)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th />
                              <th />
                              <th className="text-right">
                                {currencyFormat(totales.capital)}
                              </th>
                              <th className="text-right">
                                {currencyFormat(totales.interes)}
                              </th>
                              <th />
                              <th className="text-right">
                                {currencyFormat(totales.iva)}
                              </th>
                              <th className="text-right">
                                {currencyFormat(totales.total)}
                              </th>
                              <th />
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .pb25 {
            padding-bottom: 25px;
          }
          .pb5 {
            padding-bottom: 5px;
          }
          .pl10 {
            padding-left: 10px;
          }
          .date {
            background-color: #fff !important;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
