import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado';
import { obtenerPrestamosPendientes/* , obtenerBancosList  */} from '../../api/Domiciliacion'
import { validarArchivoPromesas, /* eliminar_prestamo_domiciliacion, */ generar_archivo_banorte, generar_archivo_hsbc, generar_archivo_vepormas  } from '../../api/Domiciliacion'
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
// import EmpresasSelect from '../../components/EmpresasSelect';
// import BancosSelect from '../../components/BancosSelect';
import Select from 'react-select';
import { currencyFormat } from '../../utils/filters'
import Modal from 'react-awesome-modal';

class GenerarPromesas extends Component {
  state = {
    loading: false,
    titulo: 'Generar archivo de promesas de pago',
    pestanaSeleccionada: 1,
    objetos: [],
    mensaje_busqueda: 'El archivo no arroja resultados',
    mensaje: 'No hay ningún préstamo seleccionado',
    fecha_transferencia: moment().format("DD-MM-YYYY"),
    modal_generacion: false,
    modal_eliminar: false,
    banco_seleccionado: '1',
    archivo: null,
    motivo_eliminacion: '',
    id_eliminar_domiciliacion: 0,
    prestamos_sin_alta: [],
    // banco_seleccionado: '1',
    fechaHoy: moment().format("DD-MM-YYYY"),
    datos_generacion_hsbc:{
      fecha_transferencia:moment().format("YYYY-MM-DD"),
      tipo_operacion:'1'
    },
  };

  cerrarAlertas = () => {
    Swal.close();
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };

  handleFileChange = async e => {
    e.preventDefault();
    const {
      target: { name, files }
    } = e;
    const file = files[0];
    if (file) {
      this.setState({ [name]: file });
    } else {
      this.setState({ [name]: null });
    }
  };

  procesarArchivo = async () => {
    const {archivo} = this.state
    if(!archivo){
      this.mostrarToast('Por favor, adjunta un archivo.', 'error');
    }else{
      this.setState({loading:true})
      try {
        const formData = new FormData();
        formData.append('archivo', archivo);
        const resp = await validarArchivoPromesas(formData);
        const objetos = resp.data;
        this.setState({ objetos, loading:false });
      } catch (error) {
        this.setState({loading:false})
        this.mostrarToast(error, 'error');
      }
    }
  
  };

  
  anadir_nuevos(arreglo_nuevos){
    const {objetos_totales} = this.state
    for (let index = 0; index < arreglo_nuevos.length; index++) {
      const element = arreglo_nuevos[index];
      if(! objetos_totales.some(objeto => objeto.id === element.id)){
        objetos_totales.push(element)
      }
    }
    this.setState({objetos_totales})
  }

  seleccionarPestana(pestanaSeleccionada) {
      this.setState({ pestanaSeleccionada })
  }

  onCleanClicked = async () => {
    const search = '';
    const { datos } = this.state;

    datos.search = search;

    this.setState({search, datos});
  }

  searchHandleChage = (e) => {
    const search = e.target.value;
    const { datos } = this.state;

    datos.search = search;
    this.setState({search, datos});
  }

  eliminar_prestamo_change = (e) => {
    const motivo_eliminacion = e.target.value;
    this.setState({motivo_eliminacion});
  }

  empresaChange = async (e) => {
    const empresas = Array.isArray(e) ? e.map(x => x.value) : [];
    const {datos} = this.state;
    datos.empresas = empresas;
    this.setState({ empresas, datos });
  }

  bancoChange = async (e) => {
    const bancos = Array.isArray(e) ? e.map(x => x.value) : [];
    const {datos} = this.state;
    datos.bancos = bancos;
    this.setState({ bancos, datos });
  }

  calcular_totales = async () =>{
    const {totales} = this.state
    const {prestamos_elegidos} = this.state
    let cantidad = prestamos_elegidos.length
    let monto = 0
    for (let index = 0; index < prestamos_elegidos.length; index++) {
      const element = prestamos_elegidos[index];
      var obj = this.obtener_objeto(element)
      monto = monto + parseFloat(obj.monto)
    }
    totales.cantidad_prestamos = cantidad
    totales.total_monto = monto
    this.setState({totales})
  }

  tipoDescuentoChange = async (e) => {
    const tipo_descuento = e.value;
    const { datos } = this.state;
    datos.tipo_descuento = e.value;
    this.setState({ tipo_descuento, datos });
  }

  fechasChange = date => {
    const fecha_inicio = date[0];
    const fecha_fin = date[1];
    const { datos } = this.state;
    const fechaInicio= moment(fecha_inicio).format('YYYY-MM-DD');
    const fechaFin = moment(fecha_fin).format('YYYY-MM-DD');
    datos.fecha_inicio = fechaInicio
    datos.fecha_fin = fechaFin
    this.setState({datos});
  }

  fechaRecibirChange = date => {
    const fecha_transferencia = date[0];
    const { datos_generacion_hsbc } = this.state;
    const fechaTransferencia= moment(fecha_transferencia).format('YYYY-MM-DD');
    datos_generacion_hsbc.fecha_transferencia = fechaTransferencia
    this.setState({datos_generacion_hsbc, fecha_transferencia});
  }

  tipoOperacionChange = async (e) => {
    const tipo_operacion = e.value
    const { datos_generacion_hsbc } = this.state;
    datos_generacion_hsbc.tipo_operacion = e.value;
    console.log(tipo_operacion)
    this.setState({ datos_generacion_hsbc, tipo_operacion });
  }

  bancoSeleccionadoChange = async(e) => {
    let { banco_seleccionado } = this.state
    const {datos_generacion_hsbc} = this.state
    banco_seleccionado = e.value
    if(e.value === '1'){
      //Colocamos las variables de Banorte como vacías
    }else{
      //Colocamos las variables de HSBC como vacías
      datos_generacion_hsbc.fecha_transferencia = ''
      datos_generacion_hsbc.tipo_operacion = ''

    }
    this.setState({banco_seleccionado});
  }
 
  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  obtenerObjetos = async params => {
    try {
      this.setState({loading: true, disabledSearch: true});
      const req = await obtenerPrestamosPendientes(params);
      const objetos = req.data;
      this.obtener_listado_bancos(objetos)
      this.anadir_nuevos(objetos)
      this.setState({ objetos, params, loading: false, disabledSearch: false }, () => {this.calcular_totales()});
    } catch (error) {
      this.setState({loading: false, disabledSearch: false})
      console.log(error);
      this.mostrarToast(error, 'error');
    }
  };

  obtener_listado_bancos = objetos => {
    const {listado_bancos_api} = this.state
    var listado_bancos = [{'label':'Todos','value':''}]

    for (let index = 0; index < objetos.length; index++) {
      const objeto_nuevo = objetos[index];
      if(listado_bancos_api.some(objeto => objeto.nombre === objeto_nuevo.banco)){
        if(! listado_bancos.some(objeto => objeto.label === objeto_nuevo.banco)){
          let banco = listado_bancos_api.find(item => item.nombre === objeto_nuevo.banco)
          listado_bancos.push({'label':banco.nombre,'value':banco.id})
        }
      }
    }
    this.setState({listado_bancos})

  }

  buscar = async () => {
    let { datos } = this.state;
    this.obtenerObjetos(datos);
  };

  obtener_objeto = id => {
    const { objetos_totales } = this.state
    let objeto = objetos_totales.filter((o) => o.id === id);
    return objeto[0]
  }

  eliminar_todos = () =>{
    const {objetos} = this.state
    var {prestamos_elegidos} = this.state
    var ids = objetos.map(o => o.id);
    prestamos_elegidos = prestamos_elegidos.filter((item) => !ids.includes(item));
    this.setState({prestamos_elegidos},() => {this.calcular_totales()})
    
  }

  seleccionar_todos = () =>{
    const {objetos} = this.state
    var {prestamos_elegidos} = this.state
    var ids = objetos.map(o => o.id);
    ids.forEach(id => {
      if(prestamos_elegidos.indexOf(id) === -1) prestamos_elegidos.push(id)
    });
    this.setState({prestamos_elegidos},() => {this.calcular_totales()})
  }

  btn_generar_archivo = async () => {
    this.setState({modal_generacion: false})
    const { banco_seleccionado, objetos } = this.state

    if(banco_seleccionado === '1'){
      const {datos_generacion_hsbc} = this.state
      try {
        this.descargar_archivo(banco_seleccionado, objetos, datos_generacion_hsbc)
        this.mostrarToast('Archivo generado correctamente. Descargando...');
      } catch (error) {
        console.log(error);
        this.mostrarToast(error, 'error');
      }
    }else if(banco_seleccionado === '2'){
      const prestamos_sin_alta = [], prestamos_con_alta = []
      objetos.forEach(element => {
        if(! element.alta_domiciliacion){
          prestamos_sin_alta.push(element)
        }else{
          prestamos_con_alta.push(element)
        }
      });
      if(prestamos_sin_alta.length >= 1){
        if(prestamos_con_alta.length >= 1){
          this.descargar_archivo(banco_seleccionado, prestamos_con_alta, '')
          this.mostrarToast('Archivo de préstamos con alta generados correctamente. Descargando...');
        }
        this.setState({modal_confirmacion_banorte: true, prestamos_sin_alta})
      }else{
        this.descargar_archivo(banco_seleccionado, objetos, '')
        this.mostrarToast('Archivo generado correctamente. Descargando...');
      }
    } else if (banco_seleccionado === '3'){
      try {
        this.descargar_archivo(banco_seleccionado, objetos)
        this.mostrarToast('Archivo generado correctamente. Descargando...');
      } catch (error) {
        console.log(error);
        this.mostrarToast(error, 'error');
      }
    }
  };

  btn_generar_archivo_sin_altas = async() => {
    this.setState({modal_confirmacion_banorte: false})
    const { prestamos_sin_alta, datos_generacion_hsbc } = this.state
    this.descargar_archivo('1', prestamos_sin_alta, datos_generacion_hsbc)
    this.mostrarToast('Archivo de préstamos sin alta generado correctamente. Descargando...');
  }

  descargar_archivo = async (tipo, prestamos_elegidos, datos_generacion) => {
    var resp = '';
    var nombre = '';
    if(tipo === '2'){
      resp = await generar_archivo_banorte( prestamos_elegidos, true );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(resp.data);
      link.download = 'domiciliaciones.zip'
      link.click();
    }else if (tipo === '1'){
      resp = await generar_archivo_hsbc( prestamos_elegidos, datos_generacion.fecha_transferencia, datos_generacion.tipo_operacion, true );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(resp.data);
      link.download = 'domiciliaciones.zip'
      link.click();
    } else if (tipo === '3'){
        resp = await generar_archivo_vepormas( prestamos_elegidos, true );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(resp.data);
        link.download = 'domiciliaciones.zip'
        link.click();
    }

  }

  obtener_objeto = id => {
    const { objetos } = this.state
    let objeto = objetos.filter((o) => o.id === id)
    return objeto[0]
  }

  render() {
    const {
      titulo,
      objetos,
      pestanaSeleccionada,
      // prestamos_elegidos,
      fecha_transferencia,
      // tipo_operacion,
      // mensaje,
      mensaje_busqueda,
      // totales,
      modal_generacion,
      banco_seleccionado,
      // fechaMinima,
      fechaHoy,
      loading,
      modal_confirmacion_banorte,
      prestamos_sin_alta,
      // modal_eliminar,
      // motivo_eliminacion,
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="wrapper wrapper-content">
          <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="tabs-container">
                        <ul className="nav nav-tabs" role="tablist">
                        <li>
                            <a className={`nav-link ${pestanaSeleccionada === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1)} >Adjuntar Archivo</a>
                        </li>
                    </ul>
                        <div className="tab-content">
                            <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                              <br/>
                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <label>&nbsp;</label>
                                                  <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary float-right mr-1"
                                                    onClick={ () => this.setState({modal_generacion: true}) }
                                                    disabled={objetos.length === 0}
                                                  >
                                                    <i className="fa fa-file-text fa-margin" />
                                                    Generar TXT
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 ml-1">
                                                  <div className="form-group">
                                                    <label htmlFor="archivo">Archivo</label>
                                                    <input
                                                      type="file"
                                                      className="form-control p36"
                                                      id="archivo"
                                                      name="archivo"
                                                      onChange={this.handleFileChange}
                                                      disabled={loading === true}
                                                    />
                                                    {/*errores.archivo.map((obj, index) => (
                                                      <span
                                                        className="form-text m-b-none text-error"
                                                        key={`ea${index}`}
                                                      >
                                                        {obj}
                                                      </span>
                                                    ))*/}
                                                  </div>
                                                </div>
                                                <div className="col-sm-2">
                                                  <label>&nbsp;</label>
                                                  <button
                                                    type="button"
                                                    className="btn btn-success btn-sm btn-block"
                                                    onClick={this.procesarArchivo}
                                                    disabled={loading === true}
                                                  >
                                                    <i className="fa fa-cloud-upload" /> Procesar archivo
                                                  </button>
                                                </div>
                                              </div>
                                              
                                              <br/>
                                              <br/>
                                              {
                                                loading ?
                                                  <div className="spiner-example">
                                                    <div className="sk-spinner sk-spinner-wandering-cubes">
                                                      <div className="sk-cube1"></div>
                                                      <div className="sk-cube2"></div>
                                                    </div>
                                                  </div>
                                                :
                                                objetos.length > 0 ?
                                                  <div className="table-responsive" style={{'height':'500px'}}>
                                                    <table className="table table-striped" style={{'overflowY':'scroll'}}>
                                                      <thead>
                                                        <tr>
                                                          {/* <th>Folio</th>
                                                          <th>Tipo de Cobro</th>
                                                          <th>Tipo de Descuento</th>
                                                          <th>Empresa</th>
                                                          <th>Empleado</th>
                                                          <th>Banco</th>
                                                          <th>Frecuencia de Pago</th>
                                                          <th>Fecha de domiciliación</th>
                                                          <th>Monto</th>
                                                          <th></th> */}
                                                          <th>Folio</th>
                                                          <th>Monto de Amortizacion</th>
                                                          <th>Gasto de Cobranza Total</th>
                                                          <th>Descuento</th>
                                                          <th>Monto a Descontar</th>
                                                          <th>Gasto de Cobranza Final</th>
                                                          <th>Fecha de Promesa</th>
                                                          <th>Fecha de Creacion</th>
                                                          <th></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {objetos.map(obj => {
                                                          return (
                                                            <tr key={obj.folio}>
                                                              {/* <td>{obj.folio}</td>
                                                              <td>{obj.tipo_cobro}</td>
                                                              <td>{obj.tipo_descuento}</td>
                                                              <td>{obj.empresa}</td>
                                                              <td>{obj.empleado}</td>
                                                              <td>{obj.banco}</td>
                                                              <td>{obj.frecuencia_pago}</td>
                                                              <td>{obj.fecha}</td>
                                                              <td>{currencyFormat(obj.monto)}</td> */}
                                                              <td>{obj.folio}</td>
                                                              <td>{currencyFormat(obj.monto)}</td>
                                                              <td>{currencyFormat(obj.gasto_cobranza_total)}</td>
                                                              <td>{obj.descuento != '' ? obj.descuento : '0%'}</td>
                                                              <td>{currencyFormat(obj.monto_descontar)}</td>
                                                              <td>{currencyFormat(obj.gasto_cobranza_final)}</td>
                                                              <td>{obj.fecha}</td>
                                                              <td>{obj.fecha_creacion}</td>
                                                            </tr>
                                                          );
                                                        })}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                :
                                                  <div className="row">
                                                    <div className="col-lg-12">
                                                      <div className="text-center p-lg">
                                                          <h2>{ mensaje_busqueda }</h2>
                                                      </div>
                                                    </div>
                                                  </div>
                                              }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal visible={ modal_generacion } width="30%" height="55%" effect="fadeInUp">
          <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
              <div className="modal-header">
                  <h4 className="modal-title">Banco para domiciliar</h4>
              </div>
              <div className="modal-body" style={{'overflowY':'scroll'}}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <Select
                          name="banco"
                          placeholder="Banco"
                          className="select"
                          onChange={this.bancoSeleccionadoChange}
                          options={[{label: 'HSBC', value: '1'},{label: 'Banorte', value: '2' },{label: 'BX+', value: '3' }]}
                          defaultValue={{label: 'HSBC', value: '1'}}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    banco_seleccionado === '1' ? 
                      <div className="row">
                        <div className="col-md-8 offset-md-2">
                          <div className="form-group">
                            <Flatpickr
                              name='fecha_transferencia'
                              className="form-control date"
                              value={fecha_transferencia}
                              placeholder="Fecha de transferencia"
                              options={{ dateFormat: 'd-m-Y', minDate: fechaHoy }}
                              onChange={this.fechaRecibirChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-8 offset-md-2">
                          <div className="form-group">
                            <Select
                              name="tipo_operacion"
                              placeholder="Tipo de Operación"
                              className="select"
                              defaultValue={{label: 'Mismo día', value: "1"}}
                              onChange={this.tipoOperacionChange}
                              options={[{label: 'Mismo día', value: "1"},{label: 'Programado', value: "2" }]}
                            />
                          </div>
                        </div>
                      </div>
                    :
                      <div className="row">
                        
                      </div>
                  }
                  
              </div>
              <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() => this.setState({modal_generacion: false}) }
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.btn_generar_archivo()}
                  >
                      Generar
                  </button>
              </div>
          </div>
        </Modal>
        <Modal visible={ modal_confirmacion_banorte } width="70%" height="55%" effect="fadeInUp">
          <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
              <div className="modal-header">
                  <h4 className="modal-title">¿Enviar los siguientes préstamos por domiciliación HSBC?</h4>
              </div>
              <div className="modal-body" style={{'overflowY':'scroll'}}>
                  <div className="row">
                    <div className="col-md-4 offset-md-4">
                      <div className="form-group">
                        <p> Fecha de transferencia:</p>
                        <Flatpickr
                          name='fecha_transferencia'
                          className="form-control date"
                          value={fecha_transferencia}
                          placeholder="Fecha de transferencia"
                          options={{ dateFormat: 'd-m-Y', minDate: fechaHoy }}
                          onChange={this.fechaRecibirChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 offset-md-4">
                      <div className="form-group">
                        <p> Tipo de Operación:</p>
                        <Select
                          name="tipo_operacion"
                          placeholder="Tipo de Operación"
                          className="select"
                          defaultValue={{label: 'Mismo día', value: "1"}}
                          onChange={this.tipoOperacionChange}
                          options={[{label: 'Mismo día', value: "1"},{label: 'Programado', value: "2" }]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table id="tabla_prestamos_sin_alta" className="table table-striped"  style={{'overflowY':'scroll'}}>
                          <thead>
                            <tr>
                              <th>Folio</th>
                              <th>Empresa</th>
                              <th>Empleado</th>
                              <th>Banco</th>
                              <th>Frecuencia de Pago</th>
                              <th>Monto Amortizacion</th>
                              <th>Gasto Cobranza Final</th>
                              <th>Estatus</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            prestamos_sin_alta.map(obj => {
                              return (
                                <tr key={obj.folio+"_sin_alta"}>
                                  <td>{obj.folio}</td>
                                  <td>{obj.empresa}</td>
                                  <td>{obj.empleado}</td>
                                  <td>{obj.banco}</td>
                                  <td>{obj.frecuencia_pago}</td>
                                  <td>{currencyFormat(obj.monto)}</td>
                                  <td>{currencyFormat(obj.gasto_cobranza_final)}</td>
                                  <td>
                                    ERROR DE ALTA
                                  </td>
                                </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() => this.setState({modal_confirmacion_banorte: false}) }
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.btn_generar_archivo_sin_altas()}
                  >
                      Generar domiciliación por HSBC
                  </button>
              </div>
          </div>
        </Modal>
    </div>
    <style jsx="true">{`
      .fa-margin {
        margin-right: 5px;
      }
      .centrado {
        text-align: center;
      }
      .rojo {
        color: red;
      }
      .verde {
        color: green;
      }
      .negritas {
        font-weight: bold;
      }
      .clean-search {
        position: absolute;
      }
    `}</style>
  </Autenticado>
    );
  }
}

export default GenerarPromesas;
