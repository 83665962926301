import React from 'react';
import Select from 'react-select';

const SelectMultiple = ({
  options,
  value = [],
  error,
  label,
  name,
  className,
  validate,
  helpText,
  onChange
}) => {
  const getValues = () => {
    const values = [];
    value.forEach(element => {
      const r = options.filter(o => o.value === element);
      values.push(r[0]);
    });
    return values;
  };

  return (
    <div className="col-sm-6">
      <div className={`form-group row ${error.length > 0 && 'has-error'}`}>
        <label className="col-sm-4 col-form-label">{label}</label>
        <div className="col-sm-8">
          <Select
            value={getValues()}
            isMulti
            name={name}
            options={options}
            onChange={onChange}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          <span className="form-text m-b-none">{helpText}</span>
          {error.map((obj, index) => (
            <span className="form-text m-b-none text-error" key={index}>
              {obj}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectMultiple;
