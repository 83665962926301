import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { obtenerProducto } from '../../api/Productos';
import { currencyFormat } from '../../utils/filters';
import { verificarPermiso } from '../../utils/auth'

class Form extends Component {
  state = {
    id: null,
    objeto: {
      documentos_str: [],
      unidades_operativas_str: [],
    }
  };

  componentWillMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({ id });
  }

  async componentDidMount() {
    const { id } = this.state;
    if (id) {
      try {
        const resp = await obtenerProducto(id);
        const objeto = resp.data;
        this.setState({ objeto });
      } catch (error) {
        console.log(error);
      }
    }
  }

  renderRow = (label, value) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        <strong>{value}</strong>
      </div>
    </div>
  );

  renderRowBool = (label, value) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        {value ? (
          <i className="fa fa-check fa-fw" />
        ) : (
          <i className="fa fa-times fa-fw" />
        )}
      </div>
    </div>
  );

  render() {
    const guia = [
      { nombre: 'Productos', url: '/productos', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
    const { objeto } = this.state;
    return (
      <Autenticado titulo="Ver producto" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-content">
                        <h3>
                          <strong>{objeto.nombre}</strong>
                        </h3>
                        {this.renderRow(
                          'Monto minimo',
                          currencyFormat(objeto.monto_minimo)
                        )}
                        {this.renderRow(
                          'Monto maximo',
                          currencyFormat(objeto.monto_maximo)
                        )}
                        {this.renderRow(
                          'Tasa de interes anual',
                          `${objeto.tasa_interes_anual} %`
                        )}
                        {this.renderRowBool(
                          'Frecuencia cobro dinamico',
                          objeto.frecuencia_cobro_dinamico
                        )}
                        {this.renderRow(
                          'Frecuencia de cobro',
                          objeto.frecuencia_cobro
                        )}
                        {this.renderRowBool(
                          'Plazos fijos',
                          objeto.plazos_fijos
                        )}
                        {this.renderRow('Plazo minimo', objeto.plazo_minimo)}
                        {this.renderRow('Plazo maximo', objeto.plazo_maximo)}
                        {this.renderRow('Leyenda CAT', objeto.leyenda_cat ? objeto.leyenda_cat : 'N/A')}
                        {this.renderRowBool('Activo', objeto.activo)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Unidades operativas</h5>
                      </div>
                      <div className="ibox-content">
                        {objeto.unidades_operativas_str.map(uo => (
                          <div>
                            <i className="fa fa-caret-right fa-fw" />
                            <span className="pl10">{uo}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Documentos</h5>
                      </div>
                      <div className="ibox-content">
                        {objeto.documentos_str.map(doc => (
                          <div>
                            <i className="fa fa-check fa-fw" />
                            <span className="pl10">{doc}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pb25">
                { verificarPermiso('productos.change_producto,') &&
                  <a
                    href={`/productos/editar/${objeto.id}`}
                    className="btn btn-primary btn-sm btn-block"
                  >
                    <i className="fa fa-pencil" /> Editar
                  </a>
                }
                <a
                  href={`/productos/cotizar/${objeto.id}`}
                  className="btn btn-info btn-sm btn-block"
                >
                  <i className="fa fa-usd" /> Cotizar
                </a>
                { verificarPermiso('productos.delete_producto,') &&
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-block"
                  >
                    <i className="fa fa-trash" /> Eliminar
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .pb25 {
            padding-bottom: 25px;
          }
          .pb5 {
            padding-bottom: 5px;
          }
          .pl10 {
            padding-left: 10px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
