import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerPermisos() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/permisos/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerPermisosUsuario(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/usuarios/${id}/permisos/`);
  } catch (err) {
    callError(err);
  }
}

async function actualizarPermisosUsuario(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/usuarios/${id}/permisos/`, datos);
  } catch (err) {
    callError(err);
  }
}

export { obtenerPermisos, obtenerPermisosUsuario, actualizarPermisosUsuario };
