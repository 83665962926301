import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import Paginacion from '../../components/Paginacion'
import { obtenerTransacciones } from '../../api/EMIDA';

class Lista extends Component {
    state = {
        titulo: 'Transacciones',
        objetos: [],
        totalRecords: 0,
        pageLimit: 50,
        params: {}
    }

    async componentDidMount() {
        const { pageLimit } = this.state
        const params = { page_size: pageLimit }
        this.obtenerObjetos(params)
    }

    obtenerObjetos = async params => {
        try {
            const req = await obtenerTransacciones(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
    }
    
    onPageChanged = async data => {
        const page = data.currentPage
        const { pageLimit, params } = this.state
        params.page = page
        params.page_size = pageLimit
        this.obtenerObjetos(params)
    }

    limpiar = async () => {
        this.setState({ params: {} })
        const { pageLimit } = this.state
        const params = { page_size: pageLimit }
        this.obtenerObjetos(params)
    }

    buscar = async texto => {
        let { params } = this.state
        delete params.search
        params = Object.assign({ search: texto }, params)
        this.obtenerObjetos(params)
    }

    filtrar = async codigo => {
        let { params } = this.state
        delete params.response_code
        params = Object.assign({ response_code: codigo }, params)
        this.obtenerObjetos(params)
    }

    render () {
        const { titulo, objetos, totalRecords, pageLimit } = this.state
        const guia = [
            { nombre: titulo, url: '', clase: 'active' }
        ]

        const columnas = [
            {
                llave: 'fecha_creacion',
                etiqueta: 'Fecha',
                tipo: 'fechaTiempo'
            },{
                llave: 'response_code',
                etiqueta: 'Respuesta'
            },{
                llave: 'amount_id',
                etiqueta: 'Monto',
                tipo: 'dinero'
            },{
                llave: 'response_transaction_id',
                etiqueta: 'Transacción'
            },{
                llave: 'product_id',
                etiqueta: 'Producto'
            },{
                llave: 'account_id',
                etiqueta: 'Cuenta'
            },{
                llave: 'invoice_id',
                etiqueta: 'Referencia'
            },{
                llave: 'terminal_id',
                etiqueta: 'Terminal'
            }
        ]

        const opcionesFiltro = [
            {
                llave: '0',
                valor: '0'
            },{
                llave: '51',
                valor: '51'
            }
        ]

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <Tabla
                    objetos={ objetos }
                    columnas={ columnas }
                    urlBotonAgregar='|'
                    buscar
                    filtrar
                    onFindClicked={this.buscar}
                    onCleanClicked={this.limpiar}
                    onFilterChanged={this.filtrar}
                    opcionesFiltro={opcionesFiltro}
                />
                <Paginacion
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                />
            </Autenticado>
        )
    }
}

export default Lista
