import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function Auth(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/auth/admin/login/', datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerPermisosUsuario() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/permisos-usuario/');
  } catch (err) {
    callError(err);
  }
}

async function resetearContrasena(email) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/auth/password-reset/', { email });
  } catch (err) {
    callError(err);
  }
}

async function confirmarResetearContrasena(idBase64, uuid, password) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/reset/${idBase64}/${uuid}/`, { password });
  } catch (err) {
    callError(err);
  }
}

async function cambiarImagenPerfil(datos) {
  const API = await ConstructorAPIAsync(20000);
  try {
    return await API.put('usuarios/cambiar-imagen-perfil/', datos);
  } catch (err) {
    callError(err);
  }
}

export {
  Auth,
  obtenerPermisosUsuario,
  resetearContrasena,
  confirmarResetearContrasena,
  cambiarImagenPerfil
};
