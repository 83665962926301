import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import { obtenerReportesBuroAdmin } from '../../api/BuroCredito';
import { dateTimeFormat } from '../../utils/filters';

class ReportesBuroCreditoList extends Component {
  state = {
    objetos: [],
    selectedYear: 2022,
    selectedProveedor: 'buro credito'
  }

  async componentDidMount() {
    const year = new Date().getFullYear();
    await this.getReportesBuro(year);
  }

  getReportesBuro = async (year) => {
    this.setState({selectedYear: year});
    try {
      const res = await obtenerReportesBuroAdmin({year})
      const objetos = res.data
      this.setState({ objetos })
    } catch (error) {
      console.log(error)
    }
  }

  handleChangeYear = async (e) => {
    this.getReportesBuro(e.target.value);
  }
  
  render () {
    const { objetos, selectedYear, selectedProveedor } = this.state;

    const actualYear = new Date().getFullYear();

    const initialYear = 2021;

    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

    const yearsWithReportesBuro = range(initialYear, actualYear, 1);

    const guia = [
      { nombre: 'Reporte de buró de crédito', url: '', clase: 'active' }
    ]

    return (
      <Autenticado titulo="Reportes de buró de crédito" guia={ guia }>

        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-2 offset-10 text-right">
                    <select name="" id="" className="form-control" value={selectedYear} onChange={this.handleChangeYear}>
                      { yearsWithReportesBuro.map(year => {
                        return <option key={year} value={ year }>{ year }</option>
                      }) }
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table-striped table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Fecha de reporte</th>
                          <th>Usuario</th>
                          <th>Proveedor</th>
                          <th>Fecha creación</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                       { objetos.length > 0 ?
                         objetos.map((reporte, index) => (
                           <tr key={reporte.id}>
                             <td>{ index + 1 }</td>
                             <td>{reporte.fecha_reporte}</td>
                             <td>{reporte.usuario_creo || 'Creado automáticamente por sistema'}</td>
                             <td>{reporte.proveedor}</td>
                             <td>{ dateTimeFormat(reporte.fecha_creacion) }</td>
                             <td><a className="btn btn-primary" href={reporte.reporte} target="_blank" rel="noreferrer noopener"><i className="fa fa-download"></i> Descargar</a></td>
                           </tr>
                         ))
                         : <tr><td colSpan={5} className="text-center">No se encontraron registros</td></tr>
                       }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Autenticado>
    )
  }
}

export default ReportesBuroCreditoList
