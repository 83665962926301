import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    actualizarGrupoEmpresa,
    agregarGrupoEmpresa,
    obtenerGrupoEmpresa
} from '../../api/GrupoEmpresas'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        data: {},
        errors: {}
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = id ? 'Editar grupo de empresas' : 'Agregar grupo de empresas'
        const accion = id ? 'Editar' : 'Agregar'
        this.setState({ id, titulo, accion })
    }

    async componentDidMount() {
        const { id } = this.state
        if (id){
            try {
                const resp = await obtenerGrupoEmpresa(id)
                const { data } = resp
                this.setState({ data })
            } catch (error) {
                console.log(error)
            }
        }
    }

    render () {
        const { accion } = this.state

        const guia = [
            { nombre: 'Grupos de empresas', url: '/grupos-de-empresas', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]
        const { id, titulo } = this.state

        let form = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    if (id){
                        try {
                            await actualizarGrupoEmpresa(values, id)
                            this.props.history.push('/grupos-de-empresas')
                        } catch (error) {
                            const errors = error.data
                            console.log(errors)
                            this.setState({ errors })
                        }
                    } else {
                        try {
                            await agregarGrupoEmpresa(values)
                            this.props.history.push('/grupos-de-empresas')
                        } catch (error) {
                            const errors = error.data
                            console.log(errors)
                            this.setState({ errors })
                        }
                    }
                }
            },
            fields:[{
                name:'nombre',
                label:'Nombre *',
                type:'text',
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <Formulario
                                    buttonLabel={ form.button.label }
                                    onSubmit={ form.button.onSubmit }
                                    fields={ form.fields }
                                    data={ form.data }
                                    errors={ form.errors }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Autenticado>
        )
    }
}

export default Form
