import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { obtenerPrestamosPendientes, obtenerBancosList } from '../../api/Domiciliacion'
import { generar_archivo_hsbc, generar_archivo_excel, generar_archivo_banorte,generar_archivo_vepormas, eliminar_prestamo_domiciliacion } from '../../api/Domiciliacion'
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import EmpresasSelect from '../../components/EmpresasSelect';
import BancosSelect from '../../components/BancosSelect';
import Select from 'react-select';
import { currencyFormat } from '../../utils/filters'
import Modal from 'react-awesome-modal';
import Swal from 'sweetalert2';



class DomiciliacionGenerar extends Component {
  state = {
    loading: false,
    listado_bancos_api:[],
    listado_bancos:[],
    disabledExport: false,
    disabledSearch: false,
    titulo: 'Generar archivo domiciliación',
    pestanaSeleccionada: 1,
    objetos: [],
    objetos_totales: [],
    params: {},
    datos: {
      fecha_inicio: moment().format("YYYY-MM-DD"),
      fecha_fin: moment().format("YYYY-MM-DD"),
    },
    datos_generacion_hsbc:{
      fecha_transferencia:moment().format("YYYY-MM-DD"),
      tipo_operacion:'1'
    },
    totales: {
      cantidad_prestamos: 0,
      total_monto: 0
    },
    fecha_transferencia: moment().format("DD-MM-YYYY"),
    fecha_recibir: '',
    tipo_operacion: '1',
    fechaInicio: moment().format("DD-MM-YYYY"),
    fechaFin: moment().format("DD-MM-YYYY"),
    fechaHoy: moment().format("DD-MM-YYYY"),
    fechaMinima: moment().subtract(5, "days").format("DD-MM-YYYY"),
    prestamos_elegidos: [],
    mensaje_busqueda: 'La búsqueda no arroja resultados',
    mensaje: 'No hay ningún préstamo seleccionado',
    modal_generacion: false,
    modal_confirmacion_banorte: false,
    modal_eliminar: false,
    motivo_eliminacion: '',
    motivo_eliminacion_comentario: '',
    id_eliminar_domiciliacion: 0,
    prestamos_sin_alta: [],
    banco_seleccionado: '1',
    erroresEliminarDomiciliacion: {
      motivo: [],
      comentario: []
    }
  };

  
  async componentDidMount() {
    try {
      const req = await obtenerBancosList();
      const listado_bancos_api = req.data;
      listado_bancos_api.unshift({'id':0,'value':'Todos'})
      this.setState({listado_bancos_api})
    } catch (error) {
      console.log(error);
    }
  }

  cerrarAlertas = () => {
    Swal.close();
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };
  

  anadir_nuevos(arreglo_nuevos){
    const {objetos_totales} = this.state
    for (let index = 0; index < arreglo_nuevos.length; index++) {
      const element = arreglo_nuevos[index];
      if(! objetos_totales.some(objeto => objeto.id === element.id)){
        objetos_totales.push(element)
      }
    }
    this.setState({objetos_totales})
  }

  seleccionarPestana(pestanaSeleccionada) {
      this.setState({ pestanaSeleccionada })
  }

  onCleanClicked = async () => {
    const search = '';
    const { datos } = this.state;

    datos.search = search;

    this.setState({search, datos});
  }

  searchHandleChage = (e) => {
    const search = e.target.value;
    const { datos } = this.state;

    datos.search = search;
    this.setState({search, datos});
  }

  eliminar_prestamo_change = (e) => {
    const motivo_eliminacion = e.target.value;
    // console.log(motivo_eliminacion);
    this.setState({motivo_eliminacion});
  }

  handle_eliminar_prestamo_change = (e) => {
    const motivo_eliminacion_comentario = e.target.value;
    // console.log( e.target.name, e.target.value );
    this.setState({ motivo_eliminacion_comentario });
  }

  empresaChange = async (e) => {
    const empresas = Array.isArray(e) ? e.map(x => x.value) : [];
    const {datos} = this.state;
    datos.empresas = empresas;
    this.setState({ empresas, datos });
  }

  bancoChange = async (e) => {
    const bancos = Array.isArray(e) ? e.map(x => x.value) : [];
    const {datos} = this.state;
    datos.bancos = bancos;
    this.setState({ bancos, datos });
  }

  checkboxChange = async (e) => {
    const {prestamos_elegidos} = this.state
    const target = e.target;
    var value = target.type === 'checkbox' ? target.value : target.checked;
    value = parseInt(value)
    if(prestamos_elegidos.includes(value)){
      let indice = prestamos_elegidos.indexOf(value);
      if (indice !== -1) {
        prestamos_elegidos.splice(indice, 1);
      }
    }else{
      prestamos_elegidos.push(value)
    }
    this.calcular_totales()
    this.setState({prestamos_elegidos});
  }

  calcular_totales = async () =>{
    const {totales} = this.state
    const {prestamos_elegidos} = this.state
    let cantidad = prestamos_elegidos.length
    let monto = 0
    for (let index = 0; index < prestamos_elegidos.length; index++) {
      const element = prestamos_elegidos[index];
      var obj = this.obtener_objeto(element)
      monto = monto + parseFloat(obj.monto)
    }
    totales.cantidad_prestamos = cantidad
    totales.total_monto = monto
    this.setState({totales})
  }

  eliminar_seleccion(id){
    this.setState({id_eliminar_domiciliacion: id, modal_eliminar: true, motivo_eliminacion:''})
  }

  eliminar_seleccion_confirmacion(id){
    const {prestamos_elegidos} = this.state
    if(prestamos_elegidos.includes(id)){
        let indice = prestamos_elegidos.indexOf(id);
        if (indice !== -1) {
          prestamos_elegidos.splice(indice, 1);
        }
    }
    this.calcular_totales()
    this.setState({prestamos_elegidos});
  }

  validar_eliminar_domiciliacion = () => {
    let validado = true;
    const erroresEliminarDomiciliacion = {
      motivo: [],
      comentario: []
    };
    const { motivo_eliminacion, motivo_eliminacion_comentario } = this.state;

    if (motivo_eliminacion === "Otro" && motivo_eliminacion_comentario.length === 0){
      erroresEliminarDomiciliacion.comentario = ['Este campo es requerido.']
        validado = false
    }

    if(motivo_eliminacion.length === 0){
      erroresEliminarDomiciliacion.motivo = ['Selecciona un motivo.']
        validado = false
    }

    this.setState({ erroresEliminarDomiciliacion });
    return validado;
  }


  btn_eliminar_prestamo_domiciliacion = async () => {
    if (!this.validar_eliminar_domiciliacion()) {
      return;
    }
    try{
      const {id_eliminar_domiciliacion, motivo_eliminacion, motivo_eliminacion_comentario, objetos, prestamos_elegidos} = this.state
      /* const eliminacion = */ await eliminar_prestamo_domiciliacion(id_eliminar_domiciliacion, motivo_eliminacion, motivo_eliminacion_comentario);
      //
      var value = parseInt(id_eliminar_domiciliacion)
      if(prestamos_elegidos.includes(value)){
          let indice = prestamos_elegidos.indexOf(value);
          if (indice !== -1) {
            prestamos_elegidos.splice(indice, 1);
          }
      }
      var indice_objeto = objetos.indexOf(this.obtener_objeto(id_eliminar_domiciliacion))
      objetos.splice(indice_objeto, 1);
      this.calcular_totales()
      this.mostrarToast('Préstamo bloqueado de la domiciliación correctamente.');
      this.setState({objetos, prestamos_elegidos, modal_eliminar:false});
    }catch(error){
      console.log(error)
    }
  }

  tipoDescuentoChange = async (e) => {
    const tipo_descuento = e.value;
    const { datos } = this.state;
    datos.tipo_descuento = e.value;
    this.setState({ tipo_descuento, datos });
  }

  fechasChange = date => {
    const fecha_inicio = date[0];
    const fecha_fin = date[1];
    const { datos } = this.state;
    const fechaInicio= moment(fecha_inicio).format('YYYY-MM-DD');
    const fechaFin = moment(fecha_fin).format('YYYY-MM-DD');
    datos.fecha_inicio = fechaInicio
    datos.fecha_fin = fechaFin
    this.setState({datos});
  }

  fechaRecibirChange = date => {
    const fecha_transferencia = date[0];
    const { datos_generacion_hsbc } = this.state;
    const fechaTransferencia= moment(fecha_transferencia).format('YYYY-MM-DD');
    datos_generacion_hsbc.fecha_transferencia = fechaTransferencia
    this.setState({datos_generacion_hsbc, fecha_transferencia});
  }

  tipoOperacionChange = async (e) => {
    const tipo_operacion = e.value
    const { datos_generacion_hsbc } = this.state;
    datos_generacion_hsbc.tipo_operacion = e.value;
    this.setState({ datos_generacion_hsbc, tipo_operacion });
  }

  bancoSeleccionadoChange = async(e) => {
    let { banco_seleccionado } = this.state
    const {datos_generacion_hsbc} = this.state
    banco_seleccionado = e.value
    if(e.value === '1'){
      //Colocamos las variables de Banorte como vacías
    }else{
      //Colocamos las variables de HSBC como vacías
      datos_generacion_hsbc.fecha_transferencia = ''
      datos_generacion_hsbc.tipo_operacion = ''

    }
    this.setState({banco_seleccionado});
  }
 
  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  obtenerObjetos = async params => {
    try {
      this.setState({loading: true, disabledSearch: true});
      const req = await obtenerPrestamosPendientes(params);
      const objetos = req.data;
      this.obtener_listado_bancos(objetos)
      this.anadir_nuevos(objetos)
      this.setState({ objetos, params, loading: false, disabledSearch: false }, () => {this.calcular_totales()});
    } catch (error) {
      this.setState({loading: false, disabledSearch: false})
      console.log(error);
    }
  };

  obtener_listado_bancos = objetos => {
    const {listado_bancos_api} = this.state
    var listado_bancos = [{'label':'Todos','value':''}]

    for (let index = 0; index < objetos.length; index++) {
      const objeto_nuevo = objetos[index];
      if(listado_bancos_api.some(objeto => objeto.nombre === objeto_nuevo.banco)){
        if(! listado_bancos.some(objeto => objeto.label === objeto_nuevo.banco)){
          let banco = listado_bancos_api.find(item => item.nombre === objeto_nuevo.banco)
          listado_bancos.push({'label':banco.nombre,'value':banco.id})
        }
      }
    }
    this.setState({listado_bancos})

  }

  buscar = async () => {
    let { datos } = this.state;
    this.obtenerObjetos(datos);
  };

  obtener_objeto = id => {
    const { objetos_totales } = this.state
    let objeto = objetos_totales.filter((o) => o.id === id)
    return objeto[0]
  }

  eliminar_todos = () =>{
    const {objetos} = this.state
    var {prestamos_elegidos} = this.state
    var ids = objetos.map(o => o.id);
    prestamos_elegidos = prestamos_elegidos.filter((item) => !ids.includes(item));
    this.setState({prestamos_elegidos},() => {this.calcular_totales()})
    
  }

  seleccionar_todos = () =>{
    const {objetos} = this.state
    var {prestamos_elegidos} = this.state
    var ids = objetos.map(o => o.id);
    ids.forEach(id => {
      if(prestamos_elegidos.indexOf(id) === -1) prestamos_elegidos.push(id)
    });
    this.setState({prestamos_elegidos},() => {this.calcular_totales()})
  }

  btn_generar_archivo = async () => {
    this.setState({modal_generacion: false})
    const { banco_seleccionado, prestamos_elegidos } = this.state

    if(banco_seleccionado === '1' || banco_seleccionado == '3'){
      const {datos_generacion_hsbc} = this.state
      try {
        this.descargar_archivo(banco_seleccionado, prestamos_elegidos, datos_generacion_hsbc)
        this.mostrarToast('Archivo generado correctamente. Descargando...');
      } catch (error) {
        console.log(error);
      }
    }else if(banco_seleccionado === '2'){
      const { objetos_totales } = this.state
      const prestamos_sin_alta = [], prestamos_con_alta = []
      prestamos_elegidos.forEach(element => {
        let prestamo = objetos_totales.filter(item => item.id === element)[0]
        if(! prestamo.alta_domiciliacion){
          prestamos_sin_alta.push(prestamo.id)
        }else{
          prestamos_con_alta.push(prestamo.id)
        }
      });
      if(prestamos_sin_alta.length >= 1){
        if(prestamos_con_alta.length >= 1){
          this.descargar_archivo(banco_seleccionado, prestamos_con_alta, '')
          this.mostrarToast('Archivo de préstamos con alta generados correctamente. Descargando...');
        }
        this.setState({modal_confirmacion_banorte: true, prestamos_sin_alta})
      }else{
        this.descargar_archivo(banco_seleccionado, prestamos_elegidos, '')
        this.mostrarToast('Archivo generado correctamente. Descargando...');
      }
      

    }
  };

  btn_generar_archivo_sin_altas = async() => {
    this.setState({modal_confirmacion_banorte: false})
    const { prestamos_sin_alta, datos_generacion_hsbc } = this.state
    this.descargar_archivo('1', prestamos_sin_alta, datos_generacion_hsbc)
    this.mostrarToast('Archivo de préstamos sin alta generado correctamente. Descargando...');
  }

  descargar_archivo = async (tipo, prestamos_elegidos, datos_generacion) => {
    var resp = '';
    var nombre = '';
    if(tipo === '2'){
      resp = await generar_archivo_banorte( prestamos_elegidos );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(resp.data);
      link.download = 'domiciliaciones.zip'
      link.click();
    }else if (tipo === '1'){
      resp = await generar_archivo_hsbc( prestamos_elegidos, datos_generacion.fecha_transferencia, datos_generacion.tipo_operacion );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(resp.data);
      link.download = 'domiciliaciones.zip'
      link.click();
    }else if (tipo === '3'){
      resp = await generar_archivo_vepormas( prestamos_elegidos);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(resp.data);
      link.download = 'domiciliaciones.zip'
      link.click();
      
    }
  }

  btn_generar_excel = async() => {
    const { prestamos_elegidos } = this.state;
    this.setState({disabledExport: true })
    
    const datos_enviar = {
      prestamos_elegidos,
      export: true
    }
    try {
      const resp = await generar_archivo_excel( datos_enviar );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cobranzadomiciliada_${moment().format('YYYYMMDD')}_hsbc.csv`
      );
      document.body.appendChild(link);
      link.click();
      this.setState({disabledExport: false })
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const {
      titulo,
      objetos,
      fechaInicio,
      pestanaSeleccionada,
      prestamos_elegidos,
      fecha_transferencia,
      // tipo_operacion,
      mensaje,
      mensaje_busqueda,
      totales,
      modal_generacion,
      banco_seleccionado,
      disabledSearch,
      fechaMinima,
      fechaHoy,
      loading,
      listado_bancos,
      modal_confirmacion_banorte,
      prestamos_sin_alta,
      modal_eliminar,
      motivo_eliminacion,
      motivo_eliminacion_comentario,
      datos: { search },
      erroresEliminarDomiciliacion
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="wrapper wrapper-content">
          <div>
            <div className="row">

                <div className="col-lg-12">
                    <div className="tabs-container">
                        <ul className="nav nav-tabs" role="tablist">
                        <li>
                            <a className={`nav-link ${pestanaSeleccionada === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1)} >Búsqueda de Créditos</a>
                        </li>
                        <li>
                            <a className={`nav-link ${pestanaSeleccionada === 2 && 'active'}`} onClick={() => this.seleccionarPestana(2)} >Confirmación</a>
                        </li>
                    </ul>
                        <div className="tab-content">
                            <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                              <br/>
                                              <div className="row">
                                                <div className="col-sm-2">
                                                  <div className="form-group">
                                                    <Select
                                                      name="tipo_cobro"
                                                      placeholder="Tipo de Descuento"
                                                      className="select"
                                                      onChange={this.tipoDescuentoChange}
                                                      options={[{label: 'Todos', value: ''},{label: 'Domiciliación', value: 'domiciliacion'},{label: 'Retención', value: 'retencion' }]}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-sm-2">
                                                  <div className="form-group">
                                                    <EmpresasSelect
                                                      isMulti
                                                      onChange={this.empresaChange}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-sm-2">
                                                  <div className="form-group">
                                                    <BancosSelect
                                                      isMulti                                                    
                                                      options={listado_bancos}
                                                      onChange={this.bancoChange}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-sm-3">
                                                  <Flatpickr
                                                    name='rango-fechas'
                                                    className="form-control date"
                                                    value={fechaInicio}
                                                    placeholder="Rango de Fechas"
                                                    options={{ dateFormat: 'd-m-Y', mode: 'range'}}
                                                    onChange={this.fechasChange}
                                                  />
                                                </div>
                                                <div className="col-sm-3">
                                                  <div className="input-group">
                                                    <input
                                                      name="search"
                                                      value={search}
                                                      type="text"
                                                      className="form-control form-control-sm"
                                                      onChange={e => this.searchHandleChage(e)}
                                                    />
                                                    <span className="input-group-append">
                                                        <button
                                                          type="button"
                                                          className="btn btn-sm btn-info"
                                                          onClick={() => this.onCleanClicked()}
                                                        >
                                                          <i className="fa fa-close fa-margin" />
                                                        </button>
                                                      </span>
                                                    <span className="input-group-append">
                                                        <button
                                                          type="button"
                                                          className="btn btn-sm btn-primary"
                                                          disabled={disabledSearch}
                                                          onClick={() => this.buscar()}
                                                        >
                                                          <i className="fa fa-search fa-margin" />
                                                          Buscar
                                                        </button>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <br/>
                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary float-right mr-1"
                                                    disabled={disabledSearch}
                                                    onClick={() => this.eliminar_todos()}
                                                  >
                                                    <i className="fa fa-close fa-margin" />
                                                    Quitar Todos
                                                  </button>
                                            
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary float-right mr-1"
                                                    disabled={disabledSearch}
                                                    onClick={() => this.seleccionar_todos()}
                                                  >
                                                    <i className="fa fa-th fa-margin" />
                                                    Seleccionar Todos
                                                  </button>
                                                </div>
                                              </div>
                                              <br/>
                                              {
                                                loading ?
                                                  <div className="spiner-example">
                                                    <div className="sk-spinner sk-spinner-wandering-cubes">
                                                      <div className="sk-cube1"></div>
                                                      <div className="sk-cube2"></div>
                                                    </div>
                                                  </div>
                                                :
                                                objetos.length > 0 ?
                                                  <div className="table-responsive" style={{'height':'500px'}}>
                                                    <table className="table table-striped" style={{'overflowY':'scroll'}}>
                                                      <thead>
                                                        <tr>
                                                          <th></th>
                                                          <th>Folio</th>
                                                          <th>Tipo de Descuento</th>
                                                          <th>Empresa</th>
                                                          <th>Empleado</th>
                                                          <th>Banco</th>
                                                          <th>Frecuencia de Pago</th>
                                                          <th>Fecha de Pago</th>
                                                          <th>Monto</th>
                                                          <th>Acciones</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {objetos.map(obj => {
                                                          return (
                                                            <tr key={obj.folio}>
                                                              <td>
                                                                <input
                                                                  value={obj.id}
                                                                  type="checkbox"
                                                                  checked={
                                                                    prestamos_elegidos.includes(obj.id)
                                                                  }
                                                                  onChange={this.checkboxChange} />
                                                              </td>
                                                              <td>{obj.folio}</td>
                                                              <td>{obj.tipo_descuento}</td>
                                                              <td>{obj.empresa}</td>
                                                              <td>{obj.empleado}</td>
                                                              <td>{obj.banco}</td>
                                                              <td>{obj.frecuencia_pago}</td>
                                                              <td>{obj.fecha_pago}</td>
                                                              <td>{currencyFormat(obj.monto)}</td>
                                                              <td>
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-sm btn-secondary"
                                                                  onClick={() => this.eliminar_seleccion(obj.id)}
                                                                >
                                                                  Quitar de domiciliación
                                                                </button>
                                                              </td>
                                                            </tr>
                                                          );
                                                        })}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                :
                                                  <div className="row">
                                                    <div className="col-lg-12">
                                                      <div className="text-center p-lg">
                                                          <h2>{ mensaje_busqueda }</h2>
                                                      </div>
                                                    </div>
                                                  </div>
                                              }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal visible={ modal_eliminar } width="40%" height="40%" effect="fadeInUp">
                                  <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                      <div className="modal-header">
                                          <h4 className="modal-title">Eliminar préstamo de domiciliación</h4>
                                      </div>
                                      <div className="modal-body" style={{'overflowY':'scroll'}}>
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <p>Motivo:</p>
                                              
                                              <select
                                                name="motivo_eliminacion"
                                                value={motivo_eliminacion}
                                                type="text"
                                                className="form-control form-control-md"
                                                onChange={e => this.eliminar_prestamo_change(e)}
                                              >
                                                <option value="">Seleccionar...</option>
                                                <option value="Pagó 3 veces por medios externos">Pagó 3 veces por medios externos</option>
                                                <option value="Crédito con atraso">Crédito con atraso</option>
                                                <option value="Cliente lo solicitó">Cliente lo solicitó</option>
                                                <option value="Cuenta cancelada o bloqueada">Cuenta cancelada o bloqueada</option>
                                                <option value="Otro">Otro</option>
                                              </select>
                                              {erroresEliminarDomiciliacion.motivo.map((obj, index) => (
                                                <span
                                                className="form-text m-b-none text-error"
                                                key={`ee${index}`}
                                                >
                                                {obj}
                                                </span>
                                            ))}
                                            </div>
                                          </div>

                                          {
                                            motivo_eliminacion === "Otro" &&  

                                            <div className="row mt-4">
                                              <div className="col-sm-12">
                                                <p>Comentario:</p>
                                                
                                                <textarea
                                                  name="motivo_eliminacion_comentario"
                                                  value={motivo_eliminacion_comentario}
                                                  // type="text"
                                                  className="form-control form-control-md"
                                                  onChange={e => this.handle_eliminar_prestamo_change(e)}
                                                >
                                                </textarea>
                                                {erroresEliminarDomiciliacion.comentario.map((obj, index) => (
                                                    <span
                                                        className="form-text m-b-none text-error"
                                                        key={`ee${index}`}
                                                    >
                                                        {obj}
                                                    </span>
                                                ))}
                                              </div>
                                            </div>
                                          }
                                      </div>

                                      <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-white"
                                            onClick={() => this.setState({modal_eliminar: false}) }
                                          >
                                            Cancelar
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            // disabled={motivo_eliminacion.length === 0}
                                            onClick={() => this.btn_eliminar_prestamo_domiciliacion()}
                                          >
                                              Eliminar
                                          </button>
                                      </div>
                                  </div>
                                </Modal>
                            </div>
                            <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 2 && 'active'}`}>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                              <br/>
                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary float-right mr-1"
                                                    onClick={ () => this.setState({modal_generacion: true}) }
                                                    disabled={prestamos_elegidos.length === 0}
                                                  >
                                                    <i className="fa fa-file-text fa-margin" />
                                                    Generar TXT
                                                  </button>
                                                  {
                                                    /* <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary float-right mr-1"
                                                      onClick={() => this.btn_generar_excel()}
                                                      disabled={prestamos_elegidos.length === 0 || disabledExport}
                                                    >
                                                      <i className="fa fa-file-text fa-margin" />
                                                      {!disabledExport ? 
                                                        'Generar Excel' : 
                                                        'Descargando...'
                                                      }
                                                    </button> */
                                                  }
                                                </div>
                                                <Modal visible={ modal_generacion } width="30%" height="55%" effect="fadeInUp">
                                                  <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                                      <div className="modal-header">
                                                          <h4 className="modal-title">Banco para domiciliar</h4>
                                                      </div>
                                                      <div className="modal-body" style={{'overflowY':'scroll'}}>
                                                          <div className="row">
                                                            <div className="col-sm-12">
                                                              <div className="form-group">
                                                                <Select
                                                                  name="banco"
                                                                  placeholder="Banco"
                                                                  className="select"
                                                                  onChange={this.bancoSeleccionadoChange}
                                                                  options={[{label: 'HSBC', value: '1'},{label: 'Banorte', value: '2' },{label : 'BX+',value : '3'}]}
                                                                  defaultValue={{label: 'HSBC', value: '1'}}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {
                                                            banco_seleccionado === '1' ? 
                                                              <div className="row">
                                                                <div className="col-md-8 offset-md-2">
                                                                  <div className="form-group">
                                                                    <Flatpickr
                                                                      name='fecha_transferencia'
                                                                      className="form-control date"
                                                                      value={fecha_transferencia}
                                                                      placeholder="Fecha de transferencia"
                                                                      options={{ dateFormat: 'd-m-Y', minDate: fechaHoy }}
                                                                      onChange={this.fechaRecibirChange}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                <div className="col-md-8 offset-md-2">
                                                                  <div className="form-group">
                                                                    <Select
                                                                      name="tipo_operacion"
                                                                      placeholder="Tipo de Operación"
                                                                      className="select"
                                                                      defaultValue={{label: 'Mismo día', value: "1"}}
                                                                      onChange={this.tipoOperacionChange}
                                                                      options={[{label: 'Mismo día', value: "1"},{label: 'Programado', value: "2" }]}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            :
                                                              <div className="row">
                                                                
                                                              </div>
                                                          }
                                                          
                                                      </div>
                                                      <div className="modal-footer">
                                                          <button
                                                            type="button"
                                                            className="btn btn-white"
                                                            onClick={() => this.setState({modal_generacion: false}) }
                                                          >
                                                            Cancelar
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => this.btn_generar_archivo()}
                                                          >
                                                              Generar
                                                          </button>
                                                      </div>
                                                  </div>
                                                </Modal>

                                                <Modal visible={ modal_confirmacion_banorte } width="70%" height="55%" effect="fadeInUp">
                                                  <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                                      <div className="modal-header">
                                                          <h4 className="modal-title">¿Enviar los siguientes préstamos por domiciliación HSBC?</h4>
                                                      </div>
                                                      <div className="modal-body" style={{'overflowY':'scroll'}}>
                                                          <div className="row">
                                                            <div className="col-md-4 offset-md-4">
                                                              <div className="form-group">
                                                                <p> Fecha de transferencia:</p>
                                                                <Flatpickr
                                                                  name='fecha_transferencia'
                                                                  className="form-control date"
                                                                  value={fecha_transferencia}
                                                                  placeholder="Fecha de transferencia"
                                                                  options={{ dateFormat: 'd-m-Y', minDate: fechaHoy }}
                                                                  onChange={this.fechaRecibirChange}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="row">
                                                            <div className="col-md-4 offset-md-4">
                                                              <div className="form-group">
                                                                <p> Tipo de Operación:</p>
                                                                <Select
                                                                  name="tipo_operacion"
                                                                  placeholder="Tipo de Operación"
                                                                  className="select"
                                                                  defaultValue={{label: 'Mismo día', value: "1"}}
                                                                  onChange={this.tipoOperacionChange}
                                                                  options={[{label: 'Mismo día', value: "1"},{label: 'Programado', value: "2" }]}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="row">
                                                            <div className="col-md-12">
                                                              <div className="table-responsive">
                                                                <table id="tabla_prestamos_sin_alta" className="table table-striped"  style={{'overflowY':'scroll'}}>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>Folio</th>
                                                                      <th>Empresa</th>
                                                                      <th>Empleado</th>
                                                                      <th>Banco</th>
                                                                      <th>Frecuencia de Pago</th>
                                                                      <th>Fecha de Pago</th>
                                                                      <th>Monto</th>
                                                                      <th>Estatus</th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                  {
                                                                    prestamos_sin_alta.map(id => {
                                                                      var obj = this.obtener_objeto(id)
                                                                      return (
                                                                        <tr key={obj.folio+"_sin_alta"}>
                                                                          <td>{obj.folio}</td>
                                                                          <td>{obj.empresa}</td>
                                                                          <td>{obj.empleado}</td>
                                                                          <td>{obj.banco}</td>
                                                                          <td>{obj.frecuencia_pago}</td>
                                                                          <td>{obj.fecha_pago}</td>
                                                                          <td>{currencyFormat(obj.monto)}</td>
                                                                          <td>
                                                                            ERROR DE ALTA
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    })
                                                                  }
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </div>
                                                          </div>
                                                      </div>
                                                      <div className="modal-footer">
                                                          <button
                                                            type="button"
                                                            className="btn btn-white"
                                                            onClick={() => this.setState({modal_confirmacion_banorte: false}) }
                                                          >
                                                            Cancelar
                                                          </button>
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => this.btn_generar_archivo_sin_altas()}
                                                          >
                                                              Generar domiciliación por HSBC
                                                          </button>
                                                      </div>
                                                  </div>
                                                </Modal>
                                                
                                              </div>
                                              <br/>
                                              {
                                                totales !== {} &&
                                                  <div className="row">
                                                    <div className="col-sm-3 ml-1">
                                                      <p>Cantidad de préstamos a domiciliar: { totales.cantidad_prestamos }</p>
                                                    </div>
                                                    <div className="col-sm-2">
                                                      <p>Monto total: { currencyFormat(totales.total_monto) }</p>
                                                    </div>

                                                  </div>
                                              }
                                              <br/>
                                              {
                                                prestamos_elegidos.length > 0 ?
                                                <div className="table-responsive" style={{'height':'500px'}}>
                                                  <table id="tabla_elegidos" className="table table-striped"  style={{'overflowY':'scroll'}}>
                                                    <thead>
                                                      <tr>
                                                        <th>Folio</th>
                                                        <th>Tipo de Descuento</th>
                                                        <th>Empresa</th>
                                                        <th>Empleado</th>
                                                        <th>Banco</th>
                                                        <th>Frecuencia de Pago</th>
                                                        <th>Fecha de Pago</th>
                                                        <th>Monto</th>
                                                        <th></th>
                                                        
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                    {prestamos_elegidos.map(id => {
                                                      var obj = this.obtener_objeto(id)
                                                      return (
                                                        <tr key={parseInt(id)}>
                                                          <td>{obj.folio}</td>
                                                          <td>{obj.tipo_descuento}</td>
                                                          <td>{obj.empresa}</td>
                                                          <td>{obj.empleado}</td>
                                                          <td>{obj.banco}</td>
                                                          <td>{obj.frecuencia_pago}</td>
                                                          <td>{obj.fecha_pago}</td>
                                                          <td>{currencyFormat(obj.monto)}</td>
                                                          <td>
                                                            <button
                                                              type="button"
                                                              className="btn btn-lg btn-secondary"
                                                              onClick={() => this.eliminar_seleccion_confirmacion(id)}
                                                            >
                                                              Deseleccionar
                                                            </button>
                                                          </td>
                                                        </tr>
                                                      );
                                                    })
                                                    }
                                                    </tbody>
                                                  </table>
                                                </div>

                                              :
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="text-center p-lg">
                                                        <h2>{ mensaje }</h2>
                                                    </div>
                                                  </div>
                                                </div>
                                              }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <style jsx="true">{`
      .fa-margin {
        margin-right: 5px;
      }
      .centrado {
        text-align: center;
      }
      .rojo {
        color: red;
      }
      .verde {
        color: green;
      }
      .negritas {
        font-weight: bold;
      }
      .clean-search {
        position: absolute;
      }
    `}</style>
  </Autenticado>
    );
  }
}

export default DomiciliacionGenerar;
