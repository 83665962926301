import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import Paginacion from '../../components/Paginacion'
import { obtenerUsuarios } from '../../api/Usuarios'
import { verificarPermiso } from '../../utils/auth'

class Lista extends Component {
    state = {
        titulo: 'Usuarios',
        objetos: [],
        totalRecords: 0,
        pageLimit: 50,
        params: {}
    }

    async componentDidMount() {
        try {
            const { pageLimit } = this.state
            const params = { page_size: pageLimit }
            const req = await obtenerUsuarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
    }

    onPageChanged = async data => {
        const page = data.currentPage
        const { pageLimit, params } = this.state
        params.page = page
        params.page_size = pageLimit
        try {
            const req = await obtenerUsuarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    buscar = async texto => {
        try {
            let { params } = this.state
            params = Object.assign({ search: texto }, params)
            const req = await obtenerUsuarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
        console.log(texto)
    }

    limpiar = async () => {
        try {
            const { pageLimit } = this.state
            const params = { page_size: pageLimit }
            const req = await obtenerUsuarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
    };

    render () {
        const { titulo, objetos, totalRecords, pageLimit } = this.state
        const guia = [
            { nombre: titulo, url: '', clase: 'active' }
        ]

        const urlBotonAgregar = verificarPermiso('usuarios.add_user,') ? '/usuarios/agregar/' : ''
        const urlBotonEditar = verificarPermiso('usuarios.change_user,') ? '/usuarios/editar/' : ''

        const botones = [
            {
                etiqueta: 'Editar',
                url: urlBotonEditar
            },{
                etiqueta: 'Ver',
                url: '/usuarios/ver/'
            }
        ]
        const columnas = [
            {
                llave: 'nombre',
                etiqueta: 'Nombre'
            },{
                llave: 'apellido_paterno',
                etiqueta: 'Apellido paterno'
            },{
                llave: 'apellido_materno',
                etiqueta: 'Apellido Materno'
            },{
                llave: 'email',
                etiqueta: 'Correo elctrónico'
            }
        ]
        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <Tabla
                    objetos={ objetos }
                    columnas={ columnas }
                    botones={ botones }
                    urlBotonAgregar={urlBotonAgregar}
                    buscar
                    onFindClicked={this.buscar}
                    onCleanClicked={this.limpiar}
                />
                <Paginacion
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                />
            </Autenticado>
        )
    }
}

export default Lista
