import React, {useState, useEffect} from 'react';
import { tiemposPromediosPorEtapa } from '../../api/TrackingSolicitudes';
import Autenticado from '../../components/Autenticado';
// import ChartistGraph from 'react-chartist'
import NavPeriodos from '../../components/tracking_solicitudes/NavPeriodos';
import { currencyFormat } from '../../utils/filters';
import Card from '../../components/tracking_solicitudes/Card';
import Spinner from '../../components/tracking_solicitudes/Spinner';
import TrackingMeses from '../../components/tracking_solicitudes/TrackingMeses';
// import TrackingMesesAnalisisPromedio from '../../components/tracking_solicitudes/TrackingMesesAnalisisPromedio';
// import TablaTiempoEtapas from '../../components/tracking_solicitudes/TablaTiempoEtapas';
import TrackingEtapas from '../../components/tracking_solicitudes/TrackingEtapas';
import PorcentajesEtapas from '../../components/tracking_solicitudes/PorcentajesEtapas';
import { remplazarDaysToDias } from '../../utils/functions';

const DasboardTracking = () => {
    const [cargando, setCargando] = useState(true);
    const [periodo, setPeriodo] = useState('hoy');
    const [solicitud, setSolicitud] = useState('');
    // const [numeroSolicitudesTerminadas, setNumeroSolicitudesTerminadas] = useState('');
    const [tiemposTotalPromedio, setTiemposTotalPromedio] = useState([]);
    // const [promediosEtapa, setPromediosEtapa] = useState([]);
    const [trackingEtapas, setTrackingEtapas] = useState([]);
    const [canal, setCanal] = useState('');
    const [canales, setCanales] = useState([]);
    const [pipelines, setPipelines] = useState({});
    // const [promediosEtapa, setPromediosEtapa] = useState({etapas: [], duraciones: []});
    // const [promediosEtapa2, setPromediosEtapa2] = useState([]);
    const [montoSolicitudesPromedio, setMontoSolicitudesPromedio] = useState(0);
    const [montoSolicitudesTotal, setMontoSolicitudesTotal] = useState(0);
    // const [tiempoAutomatizacionPromedio, setTiempoAutomatizacionPromedio] = useState('');
    // const [tiempoTotalSolicitudesPromedio, setTiempoTotalSolicitudesPromedio] = useState('');
    const [tiempoAutomatizacionMeses, setTiempoAutomatizacionMeses] = useState([]);
    const [solicitudesTotales, setSolicitudesTotales] = useState('');
    // const [solicitudesYuhudils, setSolicitudesYuhudils] = useState('');


    // const [tiempoAnalisisPromedio, setTiempoAnalisisPromedio] = useState('');
    // const [tiempoOtorgamientoPromedio, setTiempoOtorgamientoPromedio] = useState('');
    // const [tiempoPreanalisisPromedio, setTiempoPreanalisisPromedio] = useState('');
    // const [tiempoDocumentacionPromedio, setTiempoDocumentacionPromedio] = useState('');
    // const [tiempoFormalizacionPromedio, setTiempoFormalizacionPromedio] = useState('');

    const PERIODOS = {
        hoy: 'Hoy',
        ayer: 'Ayer',
        '7-dias': '7 días',
        'este-mes': 'Este mes',
        'mes-anterior': 'Mes anterior',
        '3-meses': '3 meses'
    }
    
    const cargarTiempoPromediosPorEtapas = async (parametros) => {
        try {
            setCargando(true);
            // let params = {};
            // if (parametros.hasOwnProperty(periodo)) {
            //     console.log('entro a periodo');
            //     params = {
            //         // ...params,
            //         tiempo: parametros.periodo
            //     }
            // }
            // if (parametros.hasOwnProperty(solicitud)) {
            //     console.log('entro a solicitud');
            //     params = {
            //         // ...params,
            //         folio: parametros.solicitud
            //     }
            // }
            // console.log('params ', params);
            let {data} = await tiemposPromediosPorEtapa(parametros);
            let {
                // tiempo_total_solicitudes_promedio: tiempoTotalSolicitudesPromedio,
                // numero_solicitudes_terminadas: numeroSolicitudesTerminadas,
                monto_solicitudes_promedio: montoSolicitudesPromedio,
                monto_solicitudes_total: montoSolicitudesTotal,
                numero_solicitudes_totales: solicitudesTotales,
                // solicitudes_yuhudils: solicitudesYuhudils,

                tiempos_total_promedio: tiemposTotalPromedio,

                tracking_etapas: trackingEtapas,
                // tiempo_automatizacion_promedio: tiempoAutomatizacionPromedio,
                tiempo_automatizacion_meses: tiempoAutomatizacionMeses,
                canales,
                // tiempo_formalizacion_promedio: tiempoFormalizacionPromedio,
                
                // tiempo_preanalisis_promedio: tiempoPreanalisisPromedio,
                // tiempo_documentacion_promedio: tiempoDocumentacionPromedio,
                // tiempo_analisis_promedio: tiempoAnalisisPromedio,
                // tiempo_formalizacion_promedio: tiempoFormalizacionPromedio,
                // tiempo_otorgamiento_promedio: tiempoOtorgamientoPromedio,

                pipeline_tiempo_promedio: pipelineTiempoPromedio
            } = data;

           

            // let etapas = [];
            // let duraciones = [];
            // let porcentajes = [];
            
            // trackingEtapas.forEach(e => {
            //     etapas.push(e.etapa);
            //     duraciones.push(e.duracion);
            //     porcentajes.push(e.porcentaje);
            // });

            // setPromediosEtapa({etapas, duraciones});
            // setNumeroSolicitudesTerminadas(numeroSolicitudesTerminadas);
            setTrackingEtapas(trackingEtapas);
            // setPromediosEtapa(trackingEtapas);
            // setEtapasPorcentaje({etapas, porcentajes});
            // setPromediosEtapa2(trackingEtapas);
            setTiemposTotalPromedio(tiemposTotalPromedio);
            setMontoSolicitudesPromedio(montoSolicitudesPromedio);
            setMontoSolicitudesTotal(montoSolicitudesTotal);
            // setTiempoAutomatizacionPromedio(tiempoAutomatizacionPromedio || 'Sin datos registrados');
            // setTiempoTotalSolicitudesPromedio(tiempoTotalSolicitudesPromedio || 'Sin datos registrados');
            setTiempoAutomatizacionMeses(tiempoAutomatizacionMeses);
            setSolicitudesTotales(solicitudesTotales || 0);
            // setSolicitudesYuhudils(solicitudesYuhudils);
            
            setCanales(canales);

            // setTiempoAnalisisPromedio(tiempoAnalisisPromedio || 0);
            // setTiempoPreanalisisPromedio(tiempoAnalisisPromedio || 0);
            // setTiempoDocumentacionPromedio(tiempoDocumentacionPromedio || 0);
            // setTiempoFormalizacionPromedio(tiempoFormalizacionPromedio || 0);
            // setTiempoOtorgamientoPromedio(tiempoOtorgamientoPromedio || 0);
            setPipelines(pipelineTiempoPromedio);
            setCargando(false);
        } catch(err) {
            console.log(err);
        }
    }

    const onClickBusquedaPorSolicitud = () => {
        cargarTiempoPromediosPorEtapas({folio: solicitud});
    }

    useEffect(() => {
       
        // if (canal === '') {
        //     cargarTiempoPromediosPorEtapas({tiempo: periodo});
        // } else {
        //     cargarTiempoPromediosPorEtapas({tiempo: periodo, canal});
        // }
        cargarTiempoPromediosPorEtapas({tiempo: periodo, canal});
        
        // if (canal !== '') {
        //     cargarTiempoPromediosPorEtapas({tiempo: periodo, canal});
        // } else {
        //     cargarTiempoPromediosPorEtapas();
        // }
    }, [periodo, canal]);



    return (
        <Autenticado titulo="Tracking solicitudes" guia={[]}>
            <NavPeriodos
                periodo={periodo}
                setPeriodo={setPeriodo}
                periodos={PERIODOS}
                solicitud={solicitud}
                setSolicitud={setSolicitud}
                onClickBusquedaPorSolicitud={onClickBusquedaPorSolicitud}
                canales={canales}
                setCanal={setCanal}
            />

            {cargando ? 
                <Spinner />
                    :
                <div className="row">
                    
                    <div className="col-sm-12 col-lg-4">
                        <Card 
                            periodo={PERIODOS[periodo]}
                            titulo="Monto total de solicitudes "
                            cantidad={currencyFormat(montoSolicitudesTotal)}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Solicitudes totales'}}
                        /> 
                    </div>

                    <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Monto promedio de solicitudes"
                            cantidad={currencyFormat(montoSolicitudesPromedio)}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Solicitudes totales'}}
                        />
                    </div>

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Tiempo promedio de automatización"
                            cantidad={tiempoAutomatizacionPromedio}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Total de solicitudes'}}
                        />
                    </div> */}

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Tiempo promedio de análisis"
                            cantidad={tiempoAnalisisPromedio}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Total de solicitudes'}}
                        />
                    </div> */}

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Tiempo promedio de solicitudes por dispersar"
                            cantidad={remplazarDaysToDias(tiempoTotalSolicitudesPromedio)}
                            tipo="info"
                            extra={{cantidad: numeroSolicitudesTerminadas, titulo: 'Número de solicitudes terminadas'}}
                        /> 
                    </div> */}

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Solicitudes de yuhudils"
                            cantidad={solicitudesYuhudils}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Total de solicitudes'}}
                        /> 
                    </div> */}

                    { 
                        tiemposTotalPromedio.map( ({nombre, duracion, numero_solicitudes: numeroSolicitudes}, index) => (
                            <div className="col-sm-12 col-lg-4" key={index}>
                                <Card
                                    periodo={PERIODOS[periodo]}
                                    titulo={nombre}
                                    cantidad={remplazarDaysToDias(duracion) || 'Sin datos registrados'}
                                    tipo="info"
                                    extra={{ cantidad: numeroSolicitudes, titulo: "Número de solicitudes" }}
                                />
                            </div>
                        )) 
                    }

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Tiempo promedio de preanálisis"
                            cantidad={tiempoPreanalisisPromedio}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Total de solicitudes'}}
                        />
                    </div> */}

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Tiempo promedio de documentación"
                            cantidad={tiempoDocumentacionPromedio}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Total de solicitudes'}}
                        />
                    </div> */}

                    {/* <div className="col-sm-12 col-lg-4">
                        <Card
                            periodo={PERIODOS[periodo]}
                            titulo="Tiempo promedio formalización"
                            cantidad={tiempoFormalizacionPromedio}
                            tipo="info"
                            extra={{cantidad: solicitudesTotales, titulo: 'Total de solicitudes'}}
                        />
                    </div> */}

                    <div className="col-sm-12 col-lg-7">
                        <TrackingEtapas 
                            duraciones={trackingEtapas}
                            pipelines={pipelines}
                            periodo={PERIODOS[periodo]}
                        />

                        <TrackingMeses
                                data={tiempoAutomatizacionMeses}
                                periodo={PERIODOS[periodo]}
                        />
                    </div>

                    <div className="col-sm-12 col-lg-5">
                        <PorcentajesEtapas
                            data={trackingEtapas}
                            periodo={PERIODOS[periodo]}    
                        />
                        {/* <TablaTiempoEtapas
                            data={promediosEtapa2}
                            periodo={PERIODOS[periodo]}
                        /> */}
                    </div>
                    
                    

                    {/* <div className="col-sm-12 col-lg-6">
                        <TrackingMesesAnalisisPromedio
                            data={tiempoAutomatizacionMeses}
                            periodo={PERIODOS[periodo]}
                        /> 
                    </div> */}
                </div>

            }
        </Autenticado>
    );
}
 
export default DasboardTracking;