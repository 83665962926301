import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NoAutenticado from '../components/NoAutenticado';
import { Auth, obtenerPermisosUsuario, resetearContrasena } from '../api/Auth';
import { setToken, setPermissions } from '../utils/auth';
import { validateEmail } from '../utils/regex';

const MySwal = withReactContent(Swal);

export class IniciarSesion extends Component {
  state = {
    datos: {},
    correo: '',
    errores: {}
  };

  handleChange = e => {
    const { datos } = this.state;
    datos[e.target.name] = e.target.value;
    this.setState({ datos, errores: {} });
  };

  handleChangeEmail = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validar = () => {
    let validado = true;
    const errores = {};
    const {
      datos: { email, password }
    } = this.state;

    if (email.length === 0) {
      validado = false;
      errores.email = ['Este campo es requerido.'];
    }
    if (password.length === 0) {
      validado = false;
      errores.password = ['Este campo es requerido.'];
    }
    this.setState({ errores });
    return validado;
  };

  iniciarSesion = async () => {
    if (!this.validar()) {
      return;
    }
    try {
      const { datos } = this.state;
      const resp = await Auth(datos);
      if (resp.status === 200) {
        await setToken(resp.data);
        const permisos = await obtenerPermisosUsuario();
        await setPermissions(permisos.data);
        this.props.history.push('/');
      }
    } catch (error) {
      console.log('Error:', error);
      if (error.status === 400) {
        this.setState({ errores: error.data });
      }
    }
  };

  enviarEmailRecuperar = async () => {
    const { correo } = this.state;
    if (correo.length === 0) {
      Swal.fire(
        'Correo no enviado',
        'El correo electrónico es obligatorio.',
        'error'
      );
    } else if (correo.length === 0 || !validateEmail(correo)) {
      Swal.fire(
        'Correo no enviado',
        'El correo electrónico no esta en el formato correcto.',
        'error'
      );
    } else {
      try {
        await resetearContrasena(correo);
        Swal.fire(
          'Recuperar contraseña',
          'Si el correo electrónico es válido, por favor revise su bandeja de entrada',
          'success'
        );
      } catch (error) {
        if (error.status === 400) {
          Swal.fire(
            'Correo no enviado',
            'Dirección de correo electrónico no existe.',
            'error'
          );
        } else {
          Swal.fire(
            'Correo no enviado',
            'Ocurrio un error al enviar el correo electrónico.',
            'error'
          );
        }
      }
    }
  };

  modalRecuperarContrasena = e => {
    e.preventDefault();
    MySwal.fire({
      title: 'Recuperar contraseña',
      html: (
        <div className="form-group">
          <label htmlFor="monto">Correo electrónico</label>
          <input
            id="monto"
            name="correo"
            type="email"
            placeholder="ejemplo@mail.com"
            className="form-control"
            onChange={this.handleChangeEmail}
          />
        </div>
      ),
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Recuperar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ml1'
      }
    }).then(result => {
      if (result.value) {
        this.enviarEmailRecuperar();
      }
    });
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      this.iniciarSesion()
    }
  }

  render() {
    const { errores } = this.state;
    return (
      <NoAutenticado>
        <div>
          <div>
            <h3 className="logo-name">yuhu</h3>
          </div>
          <h3>Bienvenido a Yuhu Admin</h3>
          <p>Ingresa tus accesos para iniciar sesión</p>
          <div className="m-t">
            <div className="form-group">
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Correo electrónico"
                onChange={this.handleChange}
              />
              {errores.email &&
                errores.email.map((obj, index) => (
                  <div key={`ee${index}`} className="invalid-feedback">
                    {obj}
                  </div>
                ))}
            </div>
            <div className="form-group">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Contraseña"
                onChange={this.handleChange}
                onKeyDown={e => this.handleKeyDown(e)}
              />
              {errores.password &&
                errores.password.map((obj, index) => (
                  <div key={`pe${index}`} className="invalid-feedback">
                    {obj}
                  </div>
                ))}
            </div>
            {errores.non_field_errors &&
              errores.non_field_errors.map((obj, index) => (
                <div key={`pe${index}`} className="invalid-feedback mb1">
                  {obj}
                </div>
              ))}
            <button
              type="button"
              className="btn btn-primary block full-width m-b"
              onClick={this.iniciarSesion}
            >
              Iniciar sesión
            </button>
            <a href="#" onClick={this.modalRecuperarContrasena}>
              <small>¿Olvidaste tu contraseña?</small>
            </a>
          </div>
        </div>
        <style jsx="true">{`
          .ml1 {
            margin-left: 1rem;
          }
          .mb1 {
            margin-bottom: 1rem;
          }
          .invalid-feedback {
            display: block;
            text-align: left;
          }
        `}</style>
      </NoAutenticado>
    );
  }
}

export default IniciarSesion;
