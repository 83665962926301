import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { exportarEmpleados, obtenerEmpleados } from '../../api/Empleados';
import { verificarPermiso } from '../../utils/auth';
import moment from 'moment';
import EmpresasSelect from '../../components/EmpresasSelect';
import UnidadOperativaSelect from '../../components/UnidadOperativaSelect2';

class Lista extends Component {
  state = {
    titulo: 'Empleados',
    objetos: [],
    totalRecords: 0,
    pageLimit: 50,
    params: {},
    unidadOperativaValue: '',
    datos: {
      empresa: '',
      unidad_operativa: '',
      search: ''
    }
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const promesas = [obtenerEmpleados(params)];
      const [req/* , resp */] = await Promise.all(promesas);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerEmpleados(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerObjetos = async params => {
    try {
      const req = await obtenerEmpleados(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async(campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (campo === 'empresa') {
      const {
        datos: { empresa }
      } = this.state;
      delete params.empresa;
      delete params.page;
      delete params.unidad_operativa;
      params = Object.assign({ empresa }, params);
      this.obtenerObjetos(params);
    }

    if (campo === 'unidad_operativa') {
      const {
        datos: { unidad_operativa }
      } = this.state;
      delete params.unidad_operativa;
      delete params.page;
      params = Object.assign({ unidad_operativa }, params);
      this.obtenerObjetos(params);
    }

    if (valor === '') {
      await this.limpiar()
    }
  };

  empresaChange = async (e) => {
    this.setState({unidadOperativaValue: null});
    await this.onChange('empresa', {target: e});
  }

  unidadOperativaChange = async (e) => {
    this.setState({unidadOperativaValue: e});
    await this.onChange('unidad_operativa', {target: e});
  }

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: '',
        empresa: ''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(params);
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  exportar = async () => {
    const { params } = this.state;

    try {
      const resp = await exportarEmpleados( params );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_empleados_${moment().format('YYYYMMDD')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }

    delete params.export;

  };

  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      datos: { search }
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <div className="row actions-buttons-row m-b-sm">
                  <div className="col-sm-12">
                    <button onClick={this.exportar} className="btn btn-sm btn-primary fa-margin">
                      <i className="fa fa-download fa-margin" />
                      Exportar
                    </button>
                    { verificarPermiso('empleados.add_empleado,') &&
                      <Link
                        to="/empleados/agregar"
                        className="btn btn-sm btn-primary fa-margin"
                      >
                        <i className="fa fa-plus fa-margin" />
                        Agregar
                      </Link>
                    }
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <EmpresasSelect
                        onChange={this.empresaChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <UnidadOperativaSelect
                        onChange={this.unidadOperativaChange}
                        empresa={this.state.datos.empresa}
                        value={this.state.unidadOperativaValue}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control form-control-lg"
                        onChange={e => this.onChange('search', e)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Buscar"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Empresa</th>
                        <th>Unidad Operativa</th>
                        <th>Puesto</th>
                        <th>Prestamos activos</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {objetos.map(obj => {
                        return (
                          <tr key={obj.id}>
                            <td>{obj.nombre}</td>
                            <td>{obj.empresa}</td>
                            <td>{obj.unidad_operativa}</td>
                            <td>{obj.puesto}</td>
                            <td>{obj.prestamos_activos}</td>
                            <td>
                              <Link
                                key={`ver${obj.id}`}
                                to={`/empleados/ver/${obj.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-eye fa-margin" />
                                Ver
                              </Link>
                              { verificarPermiso('empleados.change_empleado,') &&
                                <Link
                                key={`editar${obj.id}`}
                                  to={`/empleados/editar/${obj.id}/`}
                                  className="btn btn-primary btn-sm btns-space"
                                >
                                  <i className="fa fa-pencil fa-margin" />
                                  Editar
                                </Link>
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
