import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { currencyFormat, dateFormat, dateTimeFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import { obtenerTransaccionBancaria } from '../../api/TransaccionesBancarias';

class TransaccionBancaria extends Component {
  state = {
    id: null,
    objeto: {
        tipo: null,
        monto: null,
        divisa: null,
        codigo_transaccion: null,
        referencia_cliente: null,
        referencia_bancaria: null,
        detalles_complementarios: null,
        fecha_valor: null,
        fecha_operacion: null,
        informacion_adicional: null,
        fecha_creacion: null,
        fecha_edicion: null,
    },
  };

  componentWillMount() {
    const id = this.props.match.params.id;
    this.setState({ id });
  }


  async componentDidMount() {
    const { id } = this.state;
    try {
    let resp = await obtenerTransaccionBancaria(id);
    console.log(resp)
    let objeto = resp.data;
    this.setState({ objeto });
    } catch (error) {
    console.log(error);
    }
  }

  renderRow = (label, value, valid=null) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8 capitalize">
        <strong>{value}</strong>
      </div>
    </div>
  );


  render() {
    const guia = [
      { nombre: 'Transacciones Bancarias', url: '/conciliaciones/transacciones', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
    const { objeto } = this.state;
    return (
      <Autenticado titulo="Ver Transaccion Bancaria" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-content">
                        <h3>
                          <strong>{objeto.referencia_transaccion}</strong>
                        </h3>
                        {this.renderRow('Tipo',objeto.tipo)}
                        {this.renderRow('Monto',currencyFormat(objeto.monto))}
                        {this.renderRow('Divisa',objeto.divisa)}
                        {this.renderRow('Código de Transacción',objeto.codigo_transaccion)}
                        {this.renderRow('Referencia del Cliente',objeto.referencia_cliente)}
                        {this.renderRow('Referencia Bancaria',objeto.referencia_bancaria)}
                        {this.renderRow('Detalles Complementarios',objeto.detalles_complementarios)}
                        {this.renderRow('Fecha Valor',dateFormat(objeto.fecha_valor))}
                        {this.renderRow('Fecha Operación',dateFormat(objeto.fecha_operacion))}
                        {this.renderRow('Información Adicional',objeto.informacion_adicional)}
                        {this.renderRow('Fecha de Creación',dateTimeFormat(objeto.fecha_creacion))}
                        {this.renderRow('Fecha de Edición',dateTimeFormat(objeto.fecha_edicion))}
                        <div className="flex-between">
                          <a
                            className="btn btn-secondary mt2"
                            href={`/conciliaciones/archivos-bancarios/ver/${objeto.archivo_bancario}`}
                          >
                            Regresar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .pb25 {
            padding-bottom: 25px;
          }
          .pb5 {
            padding-bottom: 5px;
          }
          .pl10 {
            padding-left: 10px;
          }
          .warning-rfc-curp {
            color: #e2943a !important;
          }
          .success-rfc-curp {
            color: green !important;
          }
          .capitalize{
            text-transform: capitalize;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default TransaccionBancaria;
