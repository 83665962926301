import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { exportarSolicitudes, getAnalistas, obtenerSolicitudes, obtenerSolicitudesTotales } from '../../api/Solicitudes';
import { verificarPermiso } from '../../utils/auth';
import { currencyFormat, dateTimeFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import moment from 'moment';
import EmpresasSelect from '../../components/EmpresasSelect';
import UnidadOperativaSelect from '../../components/UnidadOperativaSelect2';
import SelectAnalista from '../../components/solicitudes/SelectAnalista';

class Lista extends Component {
  state = {
    titulo: 'Solicitudes',
    objetos: [],
    totalRecords: 0,
    pageLimit: 50,
    totales: {
      total_solicitudes: 0,
      total_monto: 0
    },
    params: {},
    unidadOperativaValue: null,
    datos: {
      empresa: '',
      unidad_operativa: '',
      estatus: '',
      search: ''
    },
    mensaje: 'No hay ningun filtro seleccionado',
    analistas: [],
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      this.setState({ params });
    } catch (error) {
      console.log(error);
    }
    // obtenemos los analistas
    this.obtenerAnalistas();
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerSolicitudes(params);
      let objetos = req.data.results;
      objetos = quitarNulos(objetos, 'N/A')
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerAnalistas = async () => {
    try {
      const response = await getAnalistas();
      this.setState({analistas: response.data});
    } catch (error) {
      console.log(error);
    }
  }

  obtenerObjetos = async params => {
    try {
      const req = await obtenerSolicitudes(params);
      let objetos = req.data.results;
      objetos = quitarNulos(objetos, 'N/A')
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
      await this.obtenerTotales();
    } catch (error) {
      console.log(error);
    }
  };

  obtenerTotales = async () => {
    const { params } = this.state;
    try {
      const req = await obtenerSolicitudesTotales(params);
      this.setState({totales: req.data});
    }catch (error) {
      console.log(error);
    }
  }

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async(campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    if (campo === 'empresa'){
      datos['unidad_operativa'] = '';
    }
    this.setState({ datos });
    if (campo === 'empresa' || campo === 'estatus' || campo === 'unidad_operativa') {
      const {
        datos: { empresa, estatus, unidad_operativa }
      } = this.state;
      delete params.empleado__empresa;
      delete params.estatus;
      delete params.page;
      delete params.empleado__unidad_operativa;
      params = Object.assign({ empleado__empresa: empresa, empleado__unidad_operativa: unidad_operativa, estatus }, params);
      await this.obtenerObjetos(params);
    }

    const { empresa, estatus, search } = datos
    if (empresa === '' && estatus === '' && search === '') {
      // const mensaje = 'No hay ningun filtro seleccionado';
      await this.limpiar()
    } else {
      const { objetos } = this.state
      if (objetos.length === 0) {
        const mensaje = 'No hay resultados para mostrar'
        this.setState({ mensaje })
      }
    }

    if (valor === '') {
      // await this.limpiar()
    }
  };

  empresaChange = async (e) => {
    this.setState({unidadOperativaValue: null});
    await this.onChange('empresa', {target: e});
  }

  unidadOperativaChange = async (e) => {
    this.setState({unidadOperativaValue: e});
    await this.onChange('unidad_operativa', {target: e});
  }

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: '',
        empresa: '',
        estatus: ''
      },
      objetos: [],
      totales: {
        total_solicitudes: 0,
        total_monto: 0
      },
      mensaje: 'No hay ningun filtro seleccionado'
    });
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  exportar = async () => {
    const { params } = this.state;
    try {
      const resp = await exportarSolicitudes( params );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_solicitudes_${moment().format('YYYYMMDD')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      datos: {/*  empresa,  */estatus, search },
      mensaje,
      totales
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <div className="row actions-buttons-row m-b-sm">
                  <div className="col-sm-12">
                    <button type="button" onClick={this.exportar} className="btn btn-sm btn-primary fa-margin">
                      <i className="fa fa-download fa-margin" />
                      Exportar
                    </button>
                    { verificarPermiso('solicitudes.add_solicitudcredito,') &&
                    <Link
                      to="/solicitudes/agregar"
                      className="btn btn-sm btn-primary fa-margin"
                    >
                      <i className="fa fa-plus fa-margin" />
                      Agregar
                    </Link>
                    }
                  </div>
                </div>

                <div className="row">

                  <div className="col-sm-3">
                    <div className="form-group">
                      <EmpresasSelect
                        onChange={this.empresaChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <UnidadOperativaSelect
                        onChange={this.unidadOperativaChange}
                        empresa={this.state.datos.empresa}
                        value={this.state.unidadOperativaValue}
                      />
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="input-group">
                      <select
                        value={estatus}
                        onChange={e => this.onChange('estatus', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona estatus</option>
                        <option value="incompleta">Incompleta</option>
                        <option value="preanalisis">Preanálisis</option>
                        <option value="stand by">Stand By</option>
                        <option value="pendiente analizar">Pendiente de analizar</option>
                        <option value="validar rescate">Validar rescate</option>
                        <option value="comite">Comité</option>
                        <option value="autorizada">Autorizada</option>
                        <option value="condicionado">Condicionado</option>
                        <option value="pendiente firmar">Pendiente de firmar</option>
                        <option value="por dispersar">Por dispersar</option>
                        <option value="revolvente autorizado">Revolvente autorizado</option>
                        <option value="por ejercer">Por ejercer</option>
                        <option value="revision pld">Revisión PLD</option>
                        <option value="credito generado">Crédito generado</option>
                        <option value="rechazada">Rechazada</option>
                        <option value="cancelada">Cancelada por el cliente</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control form-control-lg"
                        onChange={e => this.onChange('search', e)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Busca por empleado"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                { objetos.length > 0 ?
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Folio</th>
                          <th>Empleado</th>
                          <th>Empresa</th>
                          <th>Monto</th>
                          <th>Plazo</th>
                          <th>Estatus</th>
                          <th>Fecha creación</th>
                          <th>SF</th>
                          <th>Analista</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {objetos.map(obj => {
                          return (
                            <tr key={obj.id}>
                              <td>{obj.folio}</td>
                              <td>{obj.empleado}</td>
                              <td>{obj.empresa_str}</td>
                              <td>{currencyFormat(obj.monto)}</td>
                              <td>{obj.plazo}</td>
                              <td>{obj.estatus}</td>
                              <td>{dateTimeFormat(obj.fecha_creacion)}</td>
                              <td>
                                { obj.actualizacion_salesforce === 'Sin registro' 
                                  ? <i className="fa fa-times text-danger" />
                                  : <i className="fa fa-cloud-upload text-success" />
                                }
                              </td>
                              <td>
                              {obj.estatus !== 'credito generado' &&
                                obj.estatus !== 'rechazada' &&
                                obj.estatus !== 'cancelada' &&
                                verificarPermiso('solicitudes.change_solicitudcredito,')
                                ? <SelectAnalista solicitud={obj} analistas={this.state.analistas}/>
                                : obj.analista_str
                              }
                              </td>
                              <td>
                                <Link
                                  key={`ver${obj.id}`}
                                  to={`/solicitudes/ver/${obj.id}/`}
                                  className="btn btn-primary btn-sm btns-space"
                                >
                                  <i className="fa fa-eye fa-margin" />
                                  Ver
                                </Link>
                                {obj.estatus !== 'credito generado' &&
                                  obj.estatus !== 'rechazada' && verificarPermiso('solicitudes.change_solicitudcredito,') && (
                                    <Link
                                    key={`editar${obj.id}`}
                                      to={`/solicitudes/editar/${obj.id}/`}
                                      className="btn btn-primary btn-sm btns-space"
                                    >
                                      <i className="fa fa-eye fa-margin" />
                                      Editar
                                    </Link>
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                :
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center p-lg">
                          <h2>{ mensaje }</h2>
                      </div>
                    </div>
                  </div>
                }

                {
                  totales !== {} &&
                    <div className="row">
                      <div className="col-sm-2">
                        <p>Total de solicitudes: { totales.total_solicitudes }</p>
                      </div>
                      <div className="col-sm-2">
                        <p>Monto total: { currencyFormat(totales.total_monto) }</p>
                      </div>

                    </div>
                }

              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />

          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
