import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { exportarEmpresas, obtenerEmpresas } from '../../api/Empresas';
import { obtenerGruposEmpresas } from '../../api/GrupoEmpresas';
import CuentaMaestraSelect from  '../../components/CuentasMaestraSelect';
import { dateTimeFormat } from '../../utils/filters';
import moment from 'moment';

class Lista extends Component {
  state = {
    titulo: 'Empresas',
    objetos: [],
    totalRecords: 0,
    pageLimit: 20,
    params: {},
    datos: {
      search: '',
      grupo: '',
      cuenta_maestra:''
    },
    ordenarPor: 'nombre',
    tipoDeOrden: '',
    grupos: []
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const req = await obtenerEmpresas();
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      const gruposResp = await obtenerGruposEmpresas();
      this.setState({
        objetos,
        totalRecords,
        params,
        grupos: gruposResp.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerEmpresas(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerObjetos = async params => {
    try {
      const req = await obtenerEmpresas(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async (campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (campo === 'grupo') {
      const {
        datos: { grupo }
      } = this.state;
      delete params.grupo;
      delete params.page;
      params = Object.assign({ grupo }, params);
      this.obtenerObjetos(params);
    }
    if (campo === 'cuenta_maestra') {
      const {
        datos: { cuenta_maestra }
      } = this.state;
      delete params.cuenta_maestra;
      delete params.page;
      params = Object.assign({ cuenta_maestra }, params);
      this.obtenerObjetos(params);
    }
    if (valor === '') {
      await this.limpiar();
    }
  };

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: '',
        grupo: '',
        cuenta_maestra:''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(params);
  };

  handleKeyDown = async e => {
    if (e.key === 'Enter') {
      await this.buscar();
    }
  };

  ordenar = async campo => {
    let { ordenarPor, tipoDeOrden } = this.state;
    if (campo === ordenarPor) {
      if (tipoDeOrden === '') {
        tipoDeOrden = '-';
      } else {
        tipoDeOrden = '';
      }
    } else {
      ordenarPor = campo;
      tipoDeOrden = '';
    }

    const { params } = this.state;
    params.ordering = tipoDeOrden + ordenarPor;
    try {
      const req = await obtenerEmpresas(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params, ordenarPor, tipoDeOrden });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  exportar = async () => {
    const { params } = this.state;

    try {
      const resp = await exportarEmpresas( params );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_empresas_${moment().format('YYYYMMDD')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }

    delete params.export;

  };

  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      datos: { search/* , grupo, cuenta_maestra  */},
      tipoDeOrden,
      ordenarPor,
      // grupos
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                {/*{verificarPermiso('empresas.add_empresa,') && (*/}
                {/*  <div className="row mb-2">*/}
                {/*    <div className="col-12">*/}
                {/*      <Link*/}
                {/*        to="/empresas/agregar"*/}
                {/*        className="btn btn-sm btn-primary fa-margin"*/}
                {/*      >*/}
                {/*        <i className="fa fa-plus fa-margin" />*/}
                {/*        Agregar*/}
                {/*      </Link>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
                <div className="row">
                  <div className="col-sm-3">
                    <button onClick={this.exportar} className="btn btn-sm btn-primary fa-margin">
                      <i className="fa fa-download fa-margin" />
                      Exportar
                    </button>
                  </div>
                  <div className="col-sm-3">
                    <CuentaMaestraSelect onChange={e => this.onChange('cuenta_maestra', e)} />
                  </div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control"
                        onChange={e => this.onChange('search', e.target)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Buscar"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          className="manita"
                          onClick={() => this.ordenar('nombre')}
                        >
                          Nombre
                          {tipoDeOrden === '' && ordenarPor === 'nombre' && (
                            <i className="fa fa-caret-up" />
                          )}
                          {tipoDeOrden === '-' && ordenarPor === 'nombre' && (
                            <i className="fa fa-caret-down" />
                          )}
                        </th>
                        <th
                          className="manita"
                          onClick={() => this.ordenar('razon_social')}
                        >
                          Razón social
                          {tipoDeOrden === '' &&
                            ordenarPor === 'razon_social' && (
                              <i className="fa fa-caret-up" />
                            )}
                          {tipoDeOrden === '-' &&
                            ordenarPor === 'razon_social' && (
                              <i className="fa fa-caret-down" />
                            )}
                        </th>
                        <th
                          className="manita"
                          onClick={() => this.ordenar('rfc')}
                        >
                          RFC
                          {tipoDeOrden === '' && ordenarPor === 'rfc' && (
                            <i className="fa fa-caret-up" />
                          )}
                          {tipoDeOrden === '-' && ordenarPor === 'rfc' && (
                            <i className="fa fa-caret-down" />
                          )}
                        </th>
                        <th
                          className="manita"
                          onClick={() => this.ordenar('grupo')}
                        >
                          Cuenta Maestra
                          {tipoDeOrden === '' && ordenarPor === 'grupo' && (
                            <i className="fa fa-caret-up" />
                          )}
                          {tipoDeOrden === '-' && ordenarPor === 'grupo' && (
                            <i className="fa fa-caret-down" />
                          )}
                        </th>
                        <th
                          className="manita"
                          onClick={() => this.ordenar('fecha_creacion')}
                        >
                          Fecha de creación
                          {tipoDeOrden === '' &&
                            ordenarPor === 'fecha_creacion' && (
                              <i className="fa fa-caret-up" />
                            )}
                          {tipoDeOrden === '-' &&
                            ordenarPor === 'fecha_creacion' && (
                              <i className="fa fa-caret-down" />
                            )}
                        </th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {objetos.map(obj => {
                        return (
                          <tr key={obj.id}>
                            <td>{obj.nombre}</td>
                            <td>{obj.razon_social}</td>
                            <td>{obj.rfc}</td>
                            <td>{obj.cuenta_maestra_str}</td>
                            <td>{dateTimeFormat(obj.fecha_creacion)}</td>
                            <td>
                              <Link
                                key={`ver${obj.id}`}
                                to={`/empresas/ver/${obj.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-eye fa-margin" />
                                Ver
                              </Link>
                              {/*{verificarPermiso('empresas.change_empresa,') && (*/}
                              {/*  <Link*/}
                              {/*    key={`editar${obj.id}`}*/}
                              {/*    to={`/empresas/editar/${obj.id}/`}*/}
                              {/*    className="btn btn-primary btn-sm btns-space"*/}
                              {/*  >*/}
                              {/*    <i className="fa fa-pencil fa-margin" />*/}
                              {/*    Editar*/}
                              {/*  </Link>*/}
                              {/*)}*/}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
          .fa {
            margin-left: 10px;
          }
          .manita:hover {
            cursor: pointer;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
