import React from 'react';

const Select = ({
  options,
  error,
  label,
  value,
  name,
  field,
  validate,
  helpText,
  className = 'col-sm-6',
  labelClassName = 'col-sm-4',
  controlClassName = 'col-sm-8',
  disabled = false,
  onChange
}) => {
  const optionsMap = options.map(option => {
    return (
      <option key={option.value} value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    );
  });

  return (
    <div className={className}>
      <div className={`form-group row ${error.length > 0 && 'has-error'}`}>
        <label className={`${labelClassName} col-form-label`}>{label}</label>
        <div className={controlClassName}>
          <select
            value={value}
            name={name}
            onChange={e => onChange(field, e)}
            className="form-control m-b"
            onBlur={validate}
            disabled={disabled}
          >
            {optionsMap}
          </select>
          <span className="form-text m-b-none">{helpText}</span>
          {error.map((obj, index) => (
            <span className="form-text m-b-none text-error" key={index}>
              {obj}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Select;
