import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { eliminarEmpresa, obtenerEmpresa } from '../../api/Empresas';
import { verificarPermiso } from '../../utils/auth';
import { Link } from 'react-router-dom';

class Form extends Component {
  state = {
    id: this.props.match.params.id,
    objeto: {
      domicilio: {}
    }
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      try {
        const resp = await obtenerEmpresa(id);
        const objeto = resp.data;
        this.setState({ objeto });
      } catch (error) {
        console.log(error);
      }
    }
  }

  formarDireccion = d => {
    if (!d) {
      return '';
    }
    return `${d.calle} #${d.numero_exterior}${
      d.numero_interior ? ` int. ${d.numero_interior} ` : ''
    } ${d.colonia} C.P. ${d.codigo_postal}, ${d.municipio_str}, ${
      d.estado_str
    }`;
  };

  eliminar = async() => {
    try {
      const { id } = this.state
        await eliminarEmpresa(id)
        this.props.history.push('/empresas')
    } catch (error) {
      console.log(error)
    }
  }

  renderRow = (label, value) => (
    <div className="row m-t-xs">
      <div className="col-sm-6">
        <span>{label}</span>
      </div>
      <div className="col-sm-6">
        <strong>{value || '--'}</strong>
      </div>
    </div>  
  );

  render() {
    const guia = [
      { nombre: 'Empresas', url: '/empresas', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
    const { objeto/* , id */ } = this.state;

    return (
      <Autenticado titulo="Ver empresa" guia={guia}>
        <div className="row">
          <div className="col-md-4">
            <div className="ibox">
              <div className="ibox-content no-padding border-left-right">
                {objeto.logotipo ? (
                  <img
                    alt="Logo empresa"
                    className="img-fluid"
                    src={objeto.logotipo}
                  />
                ) : (
                  <div className="sinLogo" />
                )}
              </div>
            </div>
            {/*<div className="row">*/}
            {/*  { verificarPermiso('empresas.change_empresa,') &&*/}
            {/*    <div className="col-md-6">*/}
            {/*      <a*/}
            {/*        href={`/empresas/editar/${id}`}*/}
            {/*        className="btn btn-primary btn-sm btn-block"*/}
            {/*      >*/}
            {/*        <i className="fa fa-pencil" /> Editar*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*  { verificarPermiso('empresas.delete_empresa,') &&*/}
            {/*    <div className="col-md-6">*/}
            {/*      <button*/}
            {/*        type="button"*/}
            {/*        className="btn btn-danger btn-sm btn-block"*/}
            {/*        onClick={this.eliminar}*/}
            {/*      >*/}
            {/*        <i className="fa fa-trash" /> Eliminar*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>Información general</h5>
                  </div>
                  <div className="ibox-content">
                    <h4>
                      <strong>{objeto.nombre}</strong>
                    </h4>
                    {this.renderRow(
                      'Razón social',
                      objeto.razon_social
                    )}
                    {this.renderRow(
                      'RFC',
                      objeto.rfc
                    )}
                    {this.renderRow(
                      'E-mail',
                      objeto.email
                    )}
                    {this.renderRow(
                      'Teléfono',
                      objeto.telefono
                    )}
                    {this.renderRow(
                      'Frecuecia de pago',
                      objeto.frecuencia_pago
                    )}
                    {this.renderRow(
                      'Días de pago',
                      objeto.dias_cobro
                    )}
                    {this.renderRow(
                      'Tipo de descuento',
                      objeto.tipo_descuento
                    )}
                    {this.renderRow(
                      'Clabe STP',
                      objeto.cuenta_clabe
                    )}
                    { verificarPermiso('empresas.view_empresadomicilio,') &&
                    <div className="row m-t-xs">
                      <div className="col-sm-6">
                      <span>Dirección</span>
                      </div>
                      <div className="col-sm-6">
                      <strong>{this.formarDireccion(objeto.domicilio) || '--'}</strong>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {objeto.contactos && objeto.contactos.length > 0 && verificarPermiso('empresas.view_empresacontacto,') && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Contactos</h5>
                    </div>
                    {objeto.contactos.map(c => (
                      <div className="ibox-content" key={`contacto${c.id}`}>
                        {this.renderRow(
                        'Nombre',
                        c.nombre
                        )}
                        {this.renderRow(
                        'E-mail',
                        c.email
                        )}
                        {this.renderRow(
                        'Teléfono',
                        c.telefono
                        )}
                        {this.renderRow(
                        'Teléfono alterno',
                        c.telefono2
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {objeto.unidades_operativas && objeto.unidades_operativas.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Unidades Operativas</h5>
                    </div>
                    {objeto.unidades_operativas.map(u => (
                      <div className="ibox-content" key={`contacto${u.id}`}>
                        <div className="info">
                          <div className="col-md-6">
                            <span>{u.nombre}</span>
                          </div>
                          <div className="col-md-6">
                            <Link
                                  key={`ver${u.id}`}
                                  to={`/unidades-operativas/ver/${u.id}/`}
                                  className="btn btn-primary btn-sm btns-space"
                                >
                                  <i className="fa fa-eye fa-margin" />
                                  Ver
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {objeto.unidades_operativas && objeto.unidades_operativas.length === 0 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Unidades Operativas</h5>
                    </div>
                      <div className="ibox-content" key={`unidadesoperativas`}>
                        <div className="info">
                          <span>No hay unidades operativas registradas en esta empresa</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .sinLogo {
            width: 100%;
            background-color: #ccc;
          }
          .sinLogo:after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
