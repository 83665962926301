import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerGrupos() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/usuarios/grupos/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerGrupo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/usuarios/grupos/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarGrupo(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/usuarios/grupos/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarGrupo(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/usuarios/grupos/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarGrupo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(`/usuarios/grupos/${id}/`);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerGrupos,
  obtenerGrupo,
  agregarGrupo,
  actualizarGrupo,
  eliminarGrupo
};
