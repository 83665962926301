import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { asignarAnalista } from '../../api/Solicitudes';


const SelectAnalista = ({solicitud,  analistas}) => {

    const onChangeAnalista = async (e) => {
        let analista = e.target.value;

        if (e.target.value === 'sin analista') {
            analista = null;
        }

        try {
            await asignarAnalista(solicitud.id, {analista})
            Swal.fire({
                type: 'success',
                text: `Analista asignado a la solicitud ${solicitud.folio} exitosamente`,
                toast: true,
                position: 'top-end',
                timer: 5000,
                showConfirmButton: false
            })
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
    }, [solicitud]);

    return (
        <select 
            className="form-control"
            onChange={(e) => onChangeAnalista(e)}
            defaultValue={solicitud.analista === null || solicitud.analista === '--' || solicitud.analista === 'N/A'
                ? 'sin analista ' + solicitud.analista
                : solicitud.analista
            }

        >
            <option value={"sin analista"}>Sin analista</option>  
            { 
                analistas.map(
                    analista => (
                        <option
                            key={analista.id}
                            value={analista.id} 
                        >
                            {`${analista.nombre} ${analista.apellido_paterno}`}
                        </option>
                    ) 
                )
            }
        </select>
    );
}
 
export default SelectAnalista;