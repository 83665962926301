import { removeToken } from '../utils/auth';
import Swal from "sweetalert2";

const callError = err => {
  let message = 'Ocurrio un error';
  let status = 400;
  let data = {};

  if (err.response) {
    status = err.response.status;
    // console.log(err.response);
    if (status === 403) {
      try{
        const responseData = err.response.data || {};
        Swal.fire({
          type: 'warning',
          text: responseData.detail || 'No tienes permisos para realizar esta acción',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false
        });
      }catch (e) {
        console.log(e);
      }

      message = err.response.data.detail;
    } else if (status === 400) {
      message = 'Error en el formulario.';
      data = err.response.data;
    } else if (status === 401) {
      removeToken();
      window.location.replace('/iniciar-sesion');
    }
  } else if (err.message && err.message === 'Network Error') {
    message = 'No tiene conexion a internet.';
    status = 408;
  } else if (err.message) {
    message = err.message;
  }

  const error = { message, status, data };
  throw error;
};

export { callError };
