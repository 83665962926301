import React, { Component } from 'react';

import {
    obtenerPrestamo,
    obtenerAmortizacionesPrestamo,
    // obtenerPagosPrestamo
} from '../api/Prestamos'
import { obtenerEmpleado } from '../api/Empleados'

import { quitarNulos, frecuenciaCobro } from '../utils/functions'
import { dateFormat, currencyFormat } from '../utils/filters'

import logo_yuhu from '../img/logo_yuhu.png';


class EstadoCuenta extends Component {
    state = {
        empleado: {},
        domicilio: {},
        prestamo: {},
        amortizaciones: []
    };

    componentWillMount() {
        const id = this.props.match.params.id
        this.setState({ id })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            let res = await obtenerPrestamo(id)
            let prestamo = res.data
            prestamo = quitarNulos(prestamo)

            let { empleado } = prestamo
            res = await obtenerEmpleado(empleado.id);
            empleado = quitarNulos(res.data)

            let { domicilio } = empleado
            domicilio = quitarNulos(domicilio)

            res = await obtenerAmortizacionesPrestamo(id)
            const amortizaciones = res.data

            let capital = 0
            let interes = 0
            let iva = 0
            let abonado = 0
            let total = 0
            amortizaciones.forEach(a => {
                capital = parseFloat(a.capital) + capital
                interes = parseFloat(a.interes) + interes
                iva = parseFloat(a.iva) + iva
                total = parseFloat(a.total) + total
                abonado = parseFloat(a.abonado) + abonado
            })
            let amortizacion = {
                num_amortizacion: 'Total',
                fecha: '',
                fecha_pagada: '',
                dias_retraso: '',
                capital,
                interes,
                iva,
                total,
                abonado
            }
            amortizacion = quitarNulos(amortizacion)
            amortizaciones.push(amortizacion)
            
            this.setState({
                prestamo,
                empleado,
                domicilio,
                amortizaciones
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const {
            empleado,
            domicilio,
            prestamo,
            amortizaciones,
        } = this.state;

        return (
            <div className="white-bg border-bottom wrapper page-container">
                <div className="row padding-bottom">
                    <div className="col-md-8">
                        <h1><dt>Estado de Cuenta</dt></h1>
                        <h2><dt>Resumen de crédito</dt></h2>
                    </div>
                    <div className="col-md-4 align-center">
                        <h1><img width="120" src={logo_yuhu} alt="logo"/></h1>
                    </div>
                </div>
                <div className="col-45">
                    <div className="panel panel-default">
                        <div className="border-bottom">
                            <div className="align-center">
                                <h3>DATOS PERSONALES</h3>
                            </div>
                        </div>
                        <div className="">
                            <table>
                                <tbody>
                                    <tr><td><b>Cliente</b></td><td>{ empleado.nombre} {empleado.apellido_paterno} {empleado.apellido_materno !== '--' ? empleado.apellido_materno : ''}</td></tr>
                                    <tr><td><b>RFC</b></td><td>{ empleado.rfc }</td></tr>
                                    <tr><td><b>Empresa</b></td><td>{ prestamo.empresa }</td></tr>
                                    <tr><td><b>Puesto</b></td><td>{ empleado.puesto }</td></tr>
                                    <tr><td><b>Domicilio</b></td>
                                        <td>
                                            <div>{ domicilio.calle } { domicilio.numero_exterior } { domicilio.numero_interior ? `-${domicilio.numero_interior} ` : '' }</div>
                                            <div>{ domicilio.colonia }, { domicilio.codigo_postal }</div>
                                            <div>{ domicilio.municipio_str }, { domicilio.estado_str }</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-5"></div>
                <div className="col-45">
                    <div className="panel panel-default">
                        <div className="border-bottom">
                            <div className="align-center">
                                <h3>DATOS DE TU CRÉDITO</h3>
                            </div>
                        </div>
                        <div className="">
                            <table>
                                <tbody>
                                    <tr><td><b>Folio</b></td><td>{ prestamo.folio }</td></tr>
                                    <tr><td><b>Estatus</b></td><td>{ prestamo.estatus }</td></tr>
                                    <tr><td><b>Monto del crédito</b></td><td>{ currencyFormat(prestamo.monto) }</td></tr>
                                    <tr><td><b>Saldo por pagar a la fecha</b></td><td>{ currencyFormat(prestamo.adeudo_total) }</td></tr>
                                    <tr><td><b>Saldo capital</b></td><td>{ currencyFormat(prestamo.capital) }</td></tr>
                                    <tr><td><b>Periodo</b></td><td>{ prestamo.num_amortizaciones } { frecuenciaCobro(prestamo.frecuencia_cobro) }</td></tr>
                                    <tr><td><b>Pago por periodo</b></td><td>{ currencyFormat(prestamo.monto_pago) }</td></tr>
                                    <tr><td><b>Tasa de interés</b></td><td>{ prestamo.tasa_interes } %</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="border-bottom">
                        <div className="align-left">
                            <h2><dt>Amortización y pagos</dt></h2>
                        </div>
                    </div>
                    <div className="">
                        <table>
                            <thead className="border-bottom">
                                <tr>
                                    <th className="align-left">#</th>
                                    <th className="align-left">Fecha de vencimiento</th>
                                    <th className="align-left">Estatus</th>
                                    <th className="align-left">Fecha de pago</th>
                                    <th className="align-left">Capital</th>
                                    <th className="align-left">Interés</th>
                                    <th className="align-left">IVA</th>
                                    <th className="align-left">Total</th>
                                    <th className="align-left">Monto pagado</th>
                                </tr>
                            </thead>
                            <tbody>
                                { amortizaciones.map((amortizacion) => {
                                    return (
                                        <tr key={amortizacion.id}>
                                            <td>{ amortizacion.num_amortizacion }</td>
                                            <td>{ dateFormat(amortizacion.fecha) }</td>
                                            <td>{ (amortizacion.pagada || (amortizacion.dias_retraso === 0)) ? amortizacion.estatus : 
                                                    (amortizacion.dias_retraso > 0) ? amortizacion.estatus+' con '+amortizacion.dias_retraso+' ó más dias' : '--'} </td>
                                            <td>{ amortizacion.fecha_pagada ? dateFormat(amortizacion.fecha_pagada) : '--' }</td>
                                            <td>{ currencyFormat(amortizacion.capital) }</td>
                                            <td>{ currencyFormat(amortizacion.interes) }</td>
                                            <td>{ currencyFormat(amortizacion.iva) }</td>
                                            <td>{ currencyFormat(amortizacion.total) }</td>
                                            <td>{ currencyFormat(amortizacion.abonado) }</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <link  href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&display=swap" rel="stylesheet"></link>
                <style jsx="true">{`
                    body{
                        background-color: white;
                        font-family: 'Quicksand', sans-serif;
                          
                    }
                    .font-link{
                        font-family: 'Quicksand'; 
                        src: url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&display=swap');
                    }
                    .rowyh {
                        display: block;
                
                    }
                    .not-padding{
                        padding: 0px;
                    }
                    .padd{
                        padding-right: 15px;
                        padding-left: 15px;
                    }
                    .col-45{
                        width: 45%;
                        display: inline-block;
                    }
                    .col-5{
                        width: 5%;
                        display: inline-block;
                    }
                    .align-center {
                        text-align: center;
                    }
                    .align-left {
                        text-align: left;
                        padding: 5px;
                    }
                    table{
                        width: 100%;
                        text-align: left;
                    }
                    tr:nth-child(even) {
                        background-color: #eaeaea;
                    }
                    td{
                        padding: 5px 7px;
                    }
                    .padding-bottom{
                        padding-bottom: 15px;
                    }
                `}</style>
            </div>            
            
        );
    }
}

export default EstadoCuenta;
