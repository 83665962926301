import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import Paginacion from '../../components/Paginacion'
import { obtenerArchivosBancarios } from '../../api/TransaccionesBancarias'
import { verificarPermiso } from '../../utils/auth'
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias';
import { currencyFormat, dateFormat, dateTimeFormat } from '../../utils/filters';


class ArchivosBancarios extends Component {
    state = {
        titulo: 'Archivos Bancarios',
        objetos: [],
        cuentas: [],
        cuenta: '',
        totalRecords: 0,
        pageLimit: 50,
        params: {},
        datos: {
            cuenta: '',
        }
    }

    // getCuentasBancarias = async () => {
    //     await obtenerCuentasBancarias()
    //     .then(({data}) => {
    //       setCuentasBancarias(data);
    //     })
    //     .catch((error) =>{
    //       mostrarToast('No fue posible obtener las cuentas bancarias', 'error')
    //     })
    // };


    onChange = async(campo, e) => {
        const { datos } = this.state;
        let { params } = this.state;
        const valor = e.target.value;
        datos[campo] = valor;
        this.setState({ datos });
        if (campo === 'cuenta') {
            const { datos: { cuenta } } = this.state;
            delete params.cuenta;
            params = Object.assign({ cuenta }, params);
            let req = await obtenerArchivosBancarios(params);
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, cuenta, totalRecords, params })
        }
        if (valor === '') {
            await this.limpiar()
        }
    };

    async componentDidMount() {
        try {
            const { pageLimit } = this.state
            const params = { page_size: pageLimit }
            
            const cuentasBancarias = await obtenerCuentasBancarias()
            let cuentas = []
            cuentasBancarias.data.forEach(obj => {
                cuentas.push({ label: obj.banco+' - '+obj.no_cuenta, value: obj.id });
            })

            const req = await obtenerArchivosBancarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, cuentas, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
    }

    onPageChanged = async data => {
        const page = data.currentPage
        const { pageLimit, params } = this.state
        params.page = page
        params.page_size = pageLimit
        try {
            const req = await obtenerArchivosBancarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    buscar = async texto => {
        try {
            let { params } = this.state
            params = Object.assign({ search: texto }, params)
            const req = await obtenerArchivosBancarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
        console.log(texto)
    }

    agregar () {
        window.location.replace('/conciliaciones/agregar')
    }

    limpiar = async () => {
        try {
            const { pageLimit } = this.state
            const params = { page_size: pageLimit }
            const req = await obtenerArchivosBancarios(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
    };

    render () {
        const { titulo, objetos, cuentas, cuenta, totalRecords, pageLimit } = this.state
        const guia = [
            { nombre: titulo, url: '', clase: 'active' }
        ]
        return (
            <Autenticado titulo={ titulo } guia={ guia }>

                <div className="row">
                    <div className="col-lg-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">Lista de Archivos Bancarios</div>
                        <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                            <div className='row'>
                            <div className="col-sm-2">
                                <div className="input-group">
                                    <button type="button" className="btn btn-primary" onClick={this.agregar}>
                                        <i className="fa fa-plus fa-margin"/> Agregar
                                    </button>
                                </div>
                            </div>
                            </div>
                            <div className='row mt1'>
                            <div className="col-sm-2">
                                <div className="input-group">
                                <select
                                    value={cuenta}
                                    onChange={e => this.onChange('cuenta', e)}
                                    className="form-control m-b"
                                >
                                    <option value="">Selecciona cuenta</option>
                                    {cuentas.map(objeto => {
                                    return (
                                        <option
                                        key={objeto.value} 
                                        value={objeto.value}>
                                        {objeto.label}
                                        </option>
                                    );
                                    })}
                                </select>
                                </div>
                            </div>
                            </div>
                            {/* Tabla de Transacciones */}
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Estaus Procesamiento</th>
                                    <th>Cuenta</th>
                                    <th>Referencia Transaccion</th>
                                    <th>Fecha transacción más antigua</th>
                                    <th>Fecha transacción más reciente</th>
                                    <th>Balance de Apertura</th>
                                    <th>Balance de Cierre</th>
                                    <th>Fecha de Creación</th>
                                    
                                </tr>
                                </thead>
                                <tbody>
                                {objetos.map(obj => {
                                return (
                                    <tr key={obj.id}>
                                    <td>{obj.estatus_procesamiento}</td>
                                    <td>{obj.cuenta}</td>
                                    <td>{obj.referencia_transaccion}</td>
                                    <td>{dateFormat(obj.fecha_transaccion_antigua)}</td>
                                    <td>{dateFormat(obj.fecha_transaccion_reciente)}</td>
                                    <td>{currencyFormat(obj.monto_apertura_balance)}</td>
                                    <td>{currencyFormat(obj.monto_cierre_balance)}</td>
                                    <td>{dateFormat(obj.fecha_creacion)}</td>
                                    <td>
                                        <Link
                                        key={obj.id}
                                        to={`/conciliaciones/archivos-bancarios/ver/${obj.id}/`}
                                        className="btn btn-primary btn-sm btns-space"
                                        >
                                        <i className="fa fa-eye fa-margin"/>
                                        Ver
                                        </Link>
                                    </td>
                                    </tr>
                                );
                                })}
                                {/*  sin resultados */}
                                {objetos.length === 0 &&
                                <tr>
                                    <td colSpan="8" className="text-center">No se encontraron resultados</td>
                                </tr>
                                }
                                </tbody>
                            </table>
                            <Paginacion
                                totalRecords={totalRecords}
                                pageLimit={pageLimit}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Paginacion
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                />
            </Autenticado>
        )
    }
}

export default ArchivosBancarios
