import React, { useEffect, useState } from 'react';
import { generarDatosCobranza } from '../../../api/Prestamos';
import { verificarPermiso } from '../../../utils/auth';

const GenerarDatosCobranza = ({ prestamo }) => {
	const { datos_cobranza = {} } = prestamo;

  const [datosCobranza, setDatosCobranza] = useState({
    clabe_stp: null,
    codigo_paynet: null,
    liga_paynet: null
  });

	const [loading, setLoading] = useState(false)
	const [showButton, setShowButton] = useState(false)

  useEffect(() => {
		setShowButton(false)

		if (prestamo.datos_cobranza === undefined || prestamo.datos_cobranza === "--" ) {

			setDatosCobranza({
				clabe_stp: null,
				codigo_paynet: null,
				liga_paynet: null,
			})

			if (prestamo.id) {
				setShowButton(true)
			}
		}
		else {
			const {clabe_stp, codigo_paynet, liga_paynet} = prestamo.datos_cobranza;

			setDatosCobranza({
				clabe_stp: clabe_stp,
				codigo_paynet: codigo_paynet,
				liga_paynet: liga_paynet,
			})

			setShowButton(false)
		}
  }, [prestamo]);

  const obtenerDatosCobranza = async () => {
		try {
			setLoading(true)
      const res = await generarDatosCobranza(prestamo.id);
      const { clabe_stp, codigo_paynet, liga_paynet } = res.data;

			setDatosCobranza({
				clabe_stp,
				codigo_paynet,
				liga_paynet,
			})

			setShowButton(false)
			setLoading(false)

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-lg-12">
      <div className="panel panel-default">
        <div className="panel-heading separate-titiles">
          Información de Cobranza
          { showButton && verificarPermiso('cobranza.add_prestamoinformacioncobranza,') && (
            <button
							disabled={loading}
              type="button"
              class="btn btn-primary btn-sm btns-space right center-conbranza-btn"
              onClick={obtenerDatosCobranza}
            >
							{loading ? "Generando ..." : "Generar datos de cobranza"}
            </button>
          )}
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-12 col-md-6 sol">
              <label>Clabe STP</label>
              <label>{datosCobranza.clabe_stp || '--'}</label>
            </div>
            <div className="col-sm-12 col-md-6 sol">
              <label>Código paynet</label>
              <label>{datosCobranza.codigo_paynet || '--'}</label>
            </div>
            <div className="col-sm-12 col-md-6 sol">
              <label>Liga paynet</label>
              <label>
                <a href={datosCobranza.liga_paynet || '--'}>
                  {datosCobranza.liga_paynet || '--'}
                </a>
              </label>
            </div>
            <div className="col-sm-12 col-md-6 sol">
              <label>Tipo de descuento</label>
              <label>{prestamo.tipo_descuento_str || '--'}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerarDatosCobranza;
