import React, {useEffect, useState} from 'react';
import ModalExcepcionRiesgo from './ModalExcepcionRiesgo';

const ExcepcionRiesgo = ({ solicitud, notasCredito }) => {
  useEffect(() => {
  
  }, [solicitud]);

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="row">
      { notasCredito.excepcion_riesgo_listado_array && notasCredito.excepcion_riesgo_listado_array.map( (excepcion, index) => (
        <div className="col-6" key={index}>
          <i className="fa fa-check"></i> {excepcion}
        </div>

      ) ) }
      { notasCredito.excepcion_riesgo_listado_array && notasCredito.excepcion_riesgo_listado_array.length === 0 &&
        <div className="col-12 text-center">
          <p>No cuenta con excepciones de riesgo</p>
        </div>
      }
      { notasCredito.excepcion_riesgo_comentario &&
        <div className="col-12 m-t-sm">
          <p><strong>Comentario:</strong> {notasCredito.excepcion_riesgo_comentario}</p>
        </div>
      }
      { solicitud.estatus === 'rechazada' || solicitud.estatus === 'credito generado' || solicitud.estatus === 'cancelada'
        ? null
        : <div className="col-12 text-center">
          <button onClick={() => setShowModal(true)} className="btn btn-primary">Editar Excepciones</button>
        </div>
      }
      <ModalExcepcionRiesgo showModal={showModal} setShowModal={setShowModal} listaExcepcionesDefault={notasCredito.excepcion_riesgo_listado_array} comentarioExcepcionDefault={notasCredito.excepcion_riesgo_comentario} solicitud={solicitud} />
    </div>
  )
}

export default ExcepcionRiesgo;
