import React, { useEffect, useState } from 'react';
import { getOcrPoaData } from '../../../api/Solicitudes';


const OcrPoa = ({idSolicitud}) => {
    const [data, setData] = useState({});

    const getData = async () => {
        try {
            const response = await getOcrPoaData(idSolicitud);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        if (idSolicitud !== undefined) {
            getData();
        }        
    }, [idSolicitud]);

    return (
        <div className="panel panel-default">
            <style jsx="true">{`
                .capitalize {
                    text-transform: capitalize;
                }
                .uppercase {
                    text-transform: uppercase;
                }
            `}
            </style>
            <div className="panel-heading">
                Validación de comprobante de domicilio
            </div>
            <div className="panel-body">
            { Object.keys(data).length === 0 
                ? 'No hay datos'
                : <div className="row">

                    <div className="col-md-10 m-t">
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Calle</span>
                            <span>{data.calle}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Número Exterior</span>
                            <span>{data.numero_exterior || '--'}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Número Interior</span>
                            <span>{data.numero_interior || '--'}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Colonia</span>
                            <span>{data.colonia}</span>
                        </div>
                        
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Código Postal</span>
                            <span>{data.codigo_postal}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Municipio</span>
                            <span>{data.municipio}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Estado</span>
                            <span>{data.estado}</span>
                        </div>
                        
                        {/* <div className="col-sm-12 col-md-12 sol">
                            <span>Comprobante</span>
                            <span>{data.comprobante}</span>
                        </div> */}
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Domicilio Completo</span>
                            <span>{data.domicilio_completo}</span>
                        </div>
                        
                        {/* <div className="col-sm-12 col-md-12 sol">
                            <span>Estatus</span>
                            <span>{data.estatus}</span>
                        </div> */}
                        {/* <div className="col-sm-12 col-md-12 sol">
                            <span>{data.fecha_creacion}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>{data.fecha_edicion}</span>
                        </div> */}
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Fecha de emisión</span>
                            <span>{data.fecha_emision}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Identificador</span>
                            <span>{data.identificador}</span>
                        </div>
                        
                        
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Proveedor</span>
                            <span className="capitalize">{data.proveedor}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Tipo de documento</span>
                            <span className="uppercase">{data.tipo_documento}</span>
                        </div>
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Valido</span>
                            <span>
                                { data.valido
                                    ? <><i className="fa fa-check text-navy"></i> <span>Si</span></>
                                    : <><i className="fa fa-times text-danger"></i> <span>No</span></>
                                }
                            </span>
                        </div>

        
                    </div>

                    <div className="col-md-2 m-t">
                        <a href={data.comprobante} title="Comprobante de domicilio" target="_blank">
                            <img src={data.comprobante} alt="Comprobante de domicilio" className="w-100" />
                        </a>
                    </div>

                </div>
            }
                
            </div>
        </div>
    );
}
 
export default OcrPoa;