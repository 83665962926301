import React from 'react';
import BuscadorSolicitud from './BuscadorSolicitud';
import SelectCanal from './SelectCanal';

const NavPeriodos = ({periodo, setPeriodo, periodos, solicitud, setSolicitud, onClickBusquedaPorSolicitud, canales, setCanal}) => {
    
    
    const onClickPeriodo = (periodo) => {
        setPeriodo(periodo);
        setSolicitud('');
    }

    return ( 
        <div className="row">
            <div className="col-sm-12">
                <div className="ibox">
                    <div className="ibox-content">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                {Object.entries(periodos).map( ([key, value]) => {
                                    return (
                                        <button
                                            key={key}
                                            type="button"
                                            className={`btn btn-xs ${key === periodo ? 'btn-primary' :  'btn-white'}`}
                                            onClick={() => onClickPeriodo(key)}
                                        >
                                            {value}
                                        </button>
                                    )
                                })}
                            </div>
                            

                            <div className="col-sm-12 col-md-3">
                                <SelectCanal
                                    canales={canales}
                                    setCanal={setCanal}
                                />
                            </div>

                            <div className="col-sm-12 col-md-3">
                                <BuscadorSolicitud
                                    solicitud={solicitud}
                                    setSolicitud={setSolicitud}
                                    onClickBusquedaPorSolicitud={onClickBusquedaPorSolicitud}
                                /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default NavPeriodos;