import React, {useState} from 'react';

import GraficaPorEtapa from './GraficaPorEtapa';
import GraficaPorEtapasAgrupadas from './GraficaPorEtapasAgrupadas';

const TrackingEtapas = ({duraciones, pipelines, periodo}) => {
    const [grafica, setGrafica] = useState('etapa')    

    const onChangeSelect = (e) => {
        setGrafica(e.target.value);
    }

    return (
        <div className="ibox ">
            <div className="ibox-title">
                <div className="ibox-tools">
                    {/* <span className="label label-info float-right">{periodo}</span> */}
                    <select className="form-control" onChange={onChangeSelect}>
                        <option value="etapa">Por etapa</option>
                        <option value="pipeline">Por pipeline</option>
                    </select>
                </div>
                <h5>Tiempo promedio por etapa</h5>
            </div>
            <div className="ibox-content">
                {grafica === 'etapa' ?
                    <GraficaPorEtapa
                        duraciones={duraciones}
                    /> 
                        :
                    <GraficaPorEtapasAgrupadas
                        pipelines={pipelines}
                    />
                }
            </div>
        </div>
    );
}
 
export default TrackingEtapas;