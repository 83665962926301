import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerGruposEmpresas(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/grupos-empresas/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerGrupoEmpresa(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/grupos-empresas/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarGrupoEmpresa(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/grupos-empresas/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarGrupoEmpresa(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/grupos-empresas/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarGrupoEmpresa(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(`/grupos-empresas/${id}/`);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerGruposEmpresas,
  obtenerGrupoEmpresa,
  agregarGrupoEmpresa,
  actualizarGrupoEmpresa,
  eliminarGrupoEmpresa
};
