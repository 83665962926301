import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerUnidadesOperativasList(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/unidades-operativas-list/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerUnidadesOperativas(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/unidades-operativas/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerUnidadOperativa(id) {
const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/unidades-operativas/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function exportarUnidadesOperativas(params = {}) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    params.export = true;
    return await API.get('/unidades-operativas/', { params });
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerUnidadesOperativasList,
  obtenerUnidadesOperativas,
  obtenerUnidadOperativa,
  exportarUnidadesOperativas
};