import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerDisposiciones(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/disposiciones/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerDisposicion(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/disposiciones/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function aprobarDisposicion(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/disposiciones/${id}/aprobar/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function rechazarDisposicion(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/disposiciones/${id}/rechazar/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function exportarDisposicion(id) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    const resp = await API.get(`/admin/disposiciones/${id}/exportar`);
    return resp;
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerDisposiciones,
  obtenerDisposicion,
  aprobarDisposicion,
  rechazarDisposicion,
  exportarDisposicion
};
