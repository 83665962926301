import React from 'react';

const SelectCanal = ({canales, setCanal}) => {

    const onChangeCanal = (e) => {
        setCanal(e.target.value);
    }

    return (
        <select className="form-control" onChange={onChangeCanal}>
            <option
                value=''
            >
                canal
            </option>
            {canales.map( ({nombre, valor}, index) => {
                return (
                    <option
                        value={valor}
                        key={index}
                    >
                        {nombre}
                    </option>
                )
            } )}
        </select>
    );
}
 
export default SelectCanal;