import React, { Component } from 'react';
import { obtenerEmpresasList } from '../api/Empresas';
import Select from 'react-select';

class EmpresasSelect extends Component {

  state = {
    empresas: []
  };

  async componentDidMount() {

    try {
      const req = await obtenerEmpresasList();

      const empresas = req.data;

      this.setState({empresas});

    } catch (e) {
      console.log(e);
    }

  }

  async componentDidUpdate(prevProps, prevState) {

    if (prevProps.cuenta_maestra === this.props.cuenta_maestra){
      return
    }

    const cuenta_maestra = this.props.cuenta_maestra;

    try {

      const req = await obtenerEmpresasList({cuenta_maestra:cuenta_maestra});

      const empresas = req.data;

      this.setState({empresas});

    } catch (e) {
      console.log(e);
    }

  }

  render() {

    const { empresas } = this.state;

    const { onChange } = this.props;

    const { isMulti} = this.props;

    const empresas_values = empresas.map( empresa => {
      return {label: empresa.nombre, value: empresa.id};
    });

    if(isMulti){
      empresas_values.unshift({label: "Todas", value: ""})
    }else{
      empresas_values.unshift({label: "Selecciona una Empresa", value: ""})
    }
    

    return (
      <Select
        isMulti={isMulti}
        name="empresa"
        placeholder="Selecciona una Empresa"
        className="select"
        onChange={onChange}
        options={empresas_values}
      />
    )
  }

}

export default EmpresasSelect;