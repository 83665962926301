import axios from 'axios';
import { getToken } from '../utils/auth';
import { BASE_URL } from '../utils/config';

function ConstructorAPI() {
  const token = getToken();

  return axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
      Accept: '*/*'
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    timeout: 5000,
    type: 'json'
  });
}

async function ConstructorAPIAsync(timeout = 10000, responseType = 'json', allow_origin=false) {
  const token = getToken();

  const headers = {
    'content-type': 'application/json',
    Accept: '*/*'
  };

  if (token !== null) {
    headers.Authorization = `Bearer ${token}`;
  }

  const instance = await axios.create({
    baseURL: BASE_URL,
    headers,
    responseType,
    responseEncoding: 'utf8',
    timeout,
    type: 'json'
  });

  return instance;
}

export { ConstructorAPI, ConstructorAPIAsync };
