import React from 'react';

const BuscadorSolicitud = ({solicitud, setSolicitud, onClickBusquedaPorSolicitud}) => {
    return (
        <div className="input-group">
            <input
                type="text"
                className="form-control"
                value={solicitud}
                onChange={(e) => setSolicitud(e.target.value)}
            />
            <span className="input-group-append">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onClickBusquedaPorSolicitud}
                >
                    <i className="fa fa-search"></i>
                </button>
            </span>
        </div>
    );
}
 
export default BuscadorSolicitud;