import React, {useState, useEffect} from 'react';
import { Bar } from 'react-chartjs-2';

const TrackingMeses = ({data, periodo}) => {
    const [meses, setMeses] = useState([]);
    const [analisisPromedio, setAnalisisPromedio] = useState([]);

    const [automatizacionPromedio, setAutomatizacionPromedio] = useState([]);

    const [preanalisisPromedio, setPreanalisisPromedio] = useState([]);
    const [documentacionPromedio, setDocumentacionPromedio] = useState([]);
    const [formalizacionPromedio, setFormalizacionPromedio] = useState([]);
    const [otorgamientoPromedio, setOtorgamientoPromedio] = useState([]);
 
    useEffect(() => {
        let meses = [];
        let automatizacionPromedio = [];
        let preanalisisPromedio = [];
        let documentacionPromedio = [];
        let analisisPromedio = [];
        let formalizacionPromedio = [];
        let otorgamientoPromedio = [];
        
        data.forEach(d => {
            meses.push(d.mes);
            automatizacionPromedio.push(d.automatizacion_promedio || 0);
            preanalisisPromedio.push(d.preanalisis_promedio || 0);
            documentacionPromedio.push(d.documentacion_promedio || 0);
            analisisPromedio.push(d.analisis_promedio || 0);
            formalizacionPromedio.push(d.formalizacion_promedio || 0);
            otorgamientoPromedio.push(d.otorgamiento_promedio || 0);

        });

        setMeses(meses);
        setAutomatizacionPromedio(automatizacionPromedio);      
        setPreanalisisPromedio(preanalisisPromedio);
        setDocumentacionPromedio(documentacionPromedio);
        setAnalisisPromedio(analisisPromedio);
        setFormalizacionPromedio(formalizacionPromedio);
        setOtorgamientoPromedio(otorgamientoPromedio);   
    }, [data])

    return (
        <div className="ibox " >
            <div className="ibox-title">
                <h5>Tiempo promedio por mes</h5>
            </div>
            <div className="ibox-content">
                <Bar
                    style={{height: "350px"}}
                    data={{
                        labels: meses,
                        datasets: [
                            {
                                label: 'Promedio de automatización',
                                data: automatizacionPromedio, //[0,0,0,0,35,42,22,36,12,63,52,36],
                                backgroundColor: [
                                    'rgba(254, 202, 87, 0.6)',
                                ],
                                borderColor: [
                                    'rgba(254, 202, 87, 1)',
                                ],
                                borderWidth: 1,
                            },
                            {
                                label: 'Promedio de preanálisis',
                                data: preanalisisPromedio, //[0,0,0,0,35,42,22,36,12,63,52,36],
                                backgroundColor: [
                                    'rgba(255, 107, 107, 0.6)',
                                ],
                                borderColor: [
                                    'rgba(255, 107, 107, 1)',
                                ],
                                borderWidth: 1,
                            },
                            {
                                label: 'Promedio de documentación',
                                data: documentacionPromedio, //[0,0,0,0,35,42,22,36,12,63,52,36],
                                backgroundColor: [
                                    'rgba(72, 219, 251, 0.6)',
                                ],
                                borderColor: [
                                    'rgba(72, 219, 251, 1)',
                                ],
                                borderWidth: 1,
                            },
                            {
                                label: 'Promedio de Análisis',
                                data: analisisPromedio, //[0,0,0,0,40,25,44,14,23,41,52,32],
                                backgroundColor: [
                                    'rgba46, 134, 222, 0.6)',
                                    
                                ],
                                borderColor: [
                                    'rgba(46, 134, 222, 1)',
                                ],
                                borderWidth: 1,
                            },
                            {
                                label: 'Promedio de formalización',
                                data: formalizacionPromedio, //[0,0,0,0,35,42,22,36,12,63,52,36],
                                backgroundColor: [
                                    'rgba(29, 209, 161, 0.6)',
                                ],
                                borderColor: [
                                    'rgba(29, 209, 161, 1)',
                                ],
                                borderWidth: 1,
                            },
                            {
                                label: 'Promedio de otorgamiento',
                                data: otorgamientoPromedio, //[0,0,0,0,35,42,22,36,12,63,52,36],
                                backgroundColor: [
                                    'rgba(131, 149, 167, 0.6)',
                                ],
                                borderColor: [
                                    'rgba(131, 149, 167, 1)',
                                ],
                                borderWidth: 1,
                            },
                        ]
                    }}
                    options={{
                        plugins:{
                            title: {
                                display: true,
                                text: ''
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    // label(context) {
                                    //     console.log(context);
                                    //     // console.log(data[context.dataIndex]);
                                    //     // const l = data.findIndex()
                                    //     // console.log(d)
                                    //     return context.dataset.label;
                                    // }
                                }                           
                            },
                        },
                        scales: {
                            x: {
                                stacked: true
                            },
                            y: {
                                stacked: true
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false
                    }}                    
                /> 
            </div>
        </div>
    );
}
 
export default TrackingMeses;

