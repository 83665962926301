import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Paginacion from './Paginacion';
import '../css/bootstrap.css';
import '../font-awesome/css/font-awesome.css';
import '../css/animate.css';
import '../css/style.css';
import { dateFormat, dateTimeFormat, currencyFormat } from '../utils/filters';

class Tabla extends Component {
  constructor(props) {
    super(props);
    const {
      objetos,
      columnas,
      botones,
      urlBotonAgregar,
      registrosPorPagina,
      buscar,
      filtrar,
      paginacion,
      opcionesFiltro,
      btnExportar,
      fnExportar,
      nombreExportar
      // resaltarUltimo
    } = this.props;
    this.state = {
      objetos: objetos || [],
      columnas: columnas || [],
      botones: botones || [],
      urlBotonAgregar: urlBotonAgregar || '',
      registrosPorPagina: registrosPorPagina || 2,
      buscar: buscar || false,
      filtrar: filtrar || false,
      btnExportar: btnExportar || false,
      fnExportar: fnExportar || null,
      nombreExportar: nombreExportar || '',
      opcionesFiltro: opcionesFiltro || [],
      paginacion: paginacion || false,
      texto: '',
      codigo: ''
      // resaltarUltimo: resaltarUltimo || true,
      // total: objetos.length || 0
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      objetos: nextProps.objetos || [],
      columnas: nextProps.columnas || [],
      botones: nextProps.botones || [],
      urlBotonAgregar: nextProps.urlBotonAgregar || null,
      buscar: nextProps.buscar,
      paginacion: nextProps.paginacion,
      fnExportar: nextProps.fnExportar || null,
      nombreExportar: nextProps.nombreExportar || ''
      // resaltarUltimo: nextProps.resaltarUltimo
    });
  }

  renderizarBotones(btn, obj) {
    if (btn.hasOwnProperty('showIf') && obj.estatus === 'rechazada') {
      const { showIf } = btn;
      const llaves = Object.keys(showIf);
      console.log('llaves que se van a validar', llaves);
      llaves.forEach(llave => {
        console.log(`valor del objeto con llave ${llave}`, obj[llave]);
        console.log(`validaciones para llave ${llave}`, showIf[llave]);
        showIf[llave].forEach(validacion => {
          console.log('validación', validacion);
        });
      });
    }

    if (btn.url !== null) {
      console.log('objeto', obj);
      if (btn.url === '') return ''
      return (
        <Link
          key={btn.etiqueta}
          to={`${btn.url}${obj.id}/`}
          className="btn btn-primary btn-sm btns-space"
        >
          <i className={`${btn.icono} fa-margin`} />
          {btn.etiqueta}
        </Link>
      )
    }
    if (btn.funcion !== null) {
      console.log('objeto', obj);
      return (
        <button
          type="button"
          key={btn.etiqueta}
          className="btn btn-primary btn-sm btns-space"
          onClick={() => btn.funcion(btn, obj)}
        >
          <i className={`${btn.icono} fa-margin`} />
          {btn.etiqueta}
        </button>
      );
    }
  }

  onFindClicked = () => {
    const { texto } = this.state;
    const { onFindClicked = f => f } = this.props;
    onFindClicked(texto);
  };

  onCleanClicked = () => {
    const { onCleanClicked = f => f } = this.props;
    this.setState({ texto: '' });
    onCleanClicked();
  };

  onFilterChanged = () => {
    const { codigo } = this.state;
    const { onFilterChanged = f => f } = this.props;
    onFilterChanged(codigo);
  };

  onChange = e => {
    const texto = e.target.value;
    this.setState({ texto });
    if (texto === '') {
      const { onCleanClicked = f => f } = this.props;
      onCleanClicked();
    }
  }

  onKeyDown = async(e) => {
    if (e.key === 'Enter') {
      const { texto } = this.state;
      const { onFindClicked = f => f } = this.props;
      onFindClicked(texto);
    }
  }

  exportar = async () => {
    const { fnExportar, nombreExportar } = this.state;
    if (!fnExportar) {
      return;
    }
    try {
      const resp = await fnExportar();
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_${!!nombreExportar && `${nombreExportar}_`}${moment().format(
          'YYYYMMDD'
        )}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      columnas,
      objetos,
      botones,
      urlBotonAgregar,
      registrosPorPagina,
      buscar,
      filtrar,
      paginacion,
      total,
      resaltarUltimo,
      texto,
      opcionesFiltro,
      codigo,
      btnExportar,
      // fnExportar
    } = this.state;

    const { cambiarPagina = () => {} } = this.props;

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-content">
              {urlBotonAgregar !== null && urlBotonAgregar.length > 0 && (
                <div className="row">
                  <div className="col-sm-3 m-b-xs">
                    {urlBotonAgregar !== '|' && (
                      <Link
                        to={`${urlBotonAgregar}`}
                        className="btn btn-sm btn-primary fa-margin"
                      >
                        <i className="fa fa-plus fa-margin" />
                        Agregar
                      </Link>
                    )}
                    {btnExportar && (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm btns-space"
                        onClick={this.exportar}
                      >
                        <i className="fa fa-download fa-margin" />
                        Exportar
                      </button>
                    )}
                  </div>
                  <div className="col-sm-1 m-b-xs" />

                  {/* <div className="col-sm-2 m-b-xs" /> */}

                  <div className="col-sm-3">
                    {filtrar === true && (
                      <div className="input-group">
                        <select
                          value={codigo}
                          // onChange={e => onChange(field, e)}
                          className="form-control m-b"
                        >
                          <option value="">Selecciona un código</option>
                          {opcionesFiltro.map((o, indice) => {
                            return (
                              <option key={indice} value={o.valor}>
                                {o.llave}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="col-sm-5">
                    {buscar === true && (
                      <div className="input-group">
                        <input
                          value={texto}
                          type="text"
                          className="form-control form-control-sm"
                          onChange={e => this.onChange(e)}
                          onKeyDown={e => this.onKeyDown(e)}
                        />
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-sm btn-info"
                            onClick={() => this.onCleanClicked()}
                          >
                            <i className="fa fa-close fa-margin" />
                          </button>
                        </span>
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => this.onFindClicked()}
                          >
                            <i className="fa fa-search fa-margin" />
                            Buscar
                          </button>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {columnas.map(col => {
                        return (
                          <th
                            key={col.llave}
                            className={col.clase !== undefined ? col.clase : ''}
                          >
                            {col.etiqueta}
                          </th>
                        );
                      })}
                      {botones.length > 0 && <th>Acciones</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {objetos.map((obj, indice) => {
                      return (
                        <tr key={indice}>
                          {columnas.map(col => {
                            if (total - 1 === indice && resaltarUltimo) {
                              if (col.clase === undefined) {
                                col.clase = 'negritas';
                              } else {
                                col.clase += ' negritas';
                              }
                            }

                            const key = String(indice) + col.llave;
                            let valor = obj[col.llave];
                            switch (col.tipo) {
                              case 'fecha':
                                valor = dateFormat(valor);
                                break;
                              case 'fechaTiempo':
                                valor = dateTimeFormat(valor);
                                break;
                              case 'dinero':
                                valor = currencyFormat(valor);
                                break;
                              case 'icono':
                                return (
                                  <td
                                    key={key}
                                    className={
                                      col.clase !== undefined ? col.clase : ''
                                    }
                                  >
                                    {valor === true ? (
                                      <i className="fa fa-check verde" />
                                    ) : (
                                      <i className="fa fa-close rojo" />
                                    )}
                                  </td>
                                );
                              default:
                                break;
                            }

                            return (
                              <td
                                key={key}
                                className={
                                  col.clase !== undefined ? col.clase : ''
                                }
                              >
                                {valor}
                              </td>
                            );
                          })}
                          <td>
                            {botones.map(btn => {
                              return this.renderizarBotones(btn, obj);
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {paginacion === true && (
                <div className="row">
                  <div className="col-md-12">
                    <Paginacion
                      totalRecords={objetos.length}
                      pageLimit={registrosPorPagina}
                      pageNeighbours={1}
                      onPageChanged={cambiarPagina}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
        `}</style>
      </div>
    );
  }
}

export default Tabla;
