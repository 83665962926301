import React, {useEffect, useState} from 'react';

import { obtenerReporteNip } from '../../api/Reportes';
import Autenticado from '../../components/Autenticado';
import Tabla from '../../components/Tabla';
import Buscador from '../../components/reportes/ReporteNip/Buscador';
import DescargaCSV from '../../components/reportes/ReporteNip/DescargaCSV';
import Paginacion from '../../components/Paginacion';


const guia = [
    { nombre: 'Reportes', url: '', clase: '' },
    { nombre: 'Reporte Nip', url: '/reportes/reporte-nip', clase: 'active' }
];

const columnas = [
    {
        llave: 'nombre',
        etiqueta: 'Nombre'
    },
    {
        llave: 'apellido_paterno',
        etiqueta: 'Apellido paterno'
    },
    {
        llave: 'apellido_materno',
        etiqueta: 'Apellido materno'
    },
    {
        llave: 'email',
        etiqueta: 'Email'
    },
    {
        llave: 'folio_solicitud',
        etiqueta: 'Folio solicitud'
    },
    {
        llave: 'fecha_autorizacion',
        etiqueta: 'Fecha autorizacion'
    },
    {
        llave: 'fecha_creacion',
        etiqueta: 'Fecha creacion'
    },
    {
        llave: 'numero_referencia_operador',
        etiqueta: 'Numero de referencia operador'
    },
]

const ReporteNip = () => {
    const [reporte, setReporte] = useState([]);
    const [busqueda, setBusqueda] = useState({
        fechaInicio: '',
        fechaFin: ''
    });
    const [paginacion, setPaginacion] = useState({
        totalRegistros: 0,
        limitePagina: 20,
        page: 1
    })
    const {totalRegistros, limitePagina} = paginacion;
    const {fechaInicio, fechaFin} =  busqueda;

    const reporteNip = async (parametros = {}) => {
        try {
            const resp = await obtenerReporteNip(parametros);
            setReporte(resp.data.results)
            setPaginacion({
                ...paginacion,
                totalRegistros: resp.data.count
            })
        } catch (err) {
            console.log('Error al cargar los datos');
        }
    }

    useEffect(() => {
        reporteNip();
    }, [])

    const onPageChanged = async data => {

        const page = data.currentPage;
        const params = {
            page,
            page_size: limitePagina,
        }
        try {
            if (fechaInicio && fechaFin) {
                params.fecha_inicio = fechaInicio;
                params.fecha_fin = fechaFin;
            }   
            const req = await reporteNip(params);
            const objetos = req.data.results;
            const totalRegistros = req.data.count;
            setBusqueda(objetos);
            setPaginacion(
                ...busqueda,
                totalRegistros
            );
        } catch (error) {
          console.log('Error: ', error);
        }
      }
 
    return (
        <Autenticado titulo="Reporte de Nip" guia={guia}>
            <div className="row justify-content-md-center">
                <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="row actions-buttons-row m-b-sm">
                                <div className="col-sm-4">
                                    <DescargaCSV 
                                        busqueda={busqueda}
                                    />
                                </div>
                            </div>

                            <Buscador
                                fechaInicio={fechaInicio}
                                fechaFin={fechaFin}
                                reporteNip={reporteNip}
                                busqueda={busqueda}
                                setBusqueda={setBusqueda}
                            />

                            <Tabla
                                objetos={reporte}
                                columnas={ columnas }
                            />
                            <Paginacion
                                totalRecords={totalRegistros}
                                pageLimit={limitePagina}
                                pageNeighbours={1}
                                onPageChanged={onPageChanged}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Autenticado>
    );
}
 
export default ReporteNip;