import React, {useState, useEffect} from 'react';

const colorStyle = {
    display: 'inline-block',
    width: '20px',
    borderRadius: '50%'
}

const colorRojo = {
    backgroundColor: 'red'
}

const colorVerde = {
    backgroundColor: 'green'
}

const colorAmarillo = {
    backgroundColor: 'yellow'
}

const colorNaranja = {
    backgroundColor: 'orange'
}


const SemaforoColor = ({colorBuro}) => {
    const [color, setColor] = useState(null);

    const setStyle = () => {
        switch (color) {
            case 'verde':
                return {...colorStyle, ...colorVerde};
            case 'amarillo':
                return {...colorStyle, ...colorAmarillo};
            case 'naranja':
                return {...colorStyle, ...colorNaranja};
            case 'rojo':
                return {...colorStyle, ...colorRojo};
            default:
                return {};
        }
    }
    
    useEffect(() => {
        setColor(colorBuro);
    }, [colorBuro])

    return (
        <label style={setStyle()}>
            { color ? null : 'Sin color'}
        </label>
    );
}
 
export default SemaforoColor;