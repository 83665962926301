import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import Autenticado from '../../components/Autenticado';
import {
  obtenerDisposicion,
  aprobarDisposicion,
  rechazarDisposicion, exportarDisposicion
} from '../../api/Disposiciones';
import Tabla from '../../components/Tabla';
import {
  dateFormat,
  dateTimeFormat,
  currencyFormat
} from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import { verificarPermiso } from '../../utils/auth';
// import { exportarSolicitud } from '../../api/Solicitudes';

class Form extends Component {
  state = {
    id: null,
    titulo: 'Ver disposición',
    disposicion: {
      id: 0,
      num_disposicion: 0,
      empleado: '',
      empresa: '',
      monto: '0',
      estatus: '',
      frecuencia_cobro: '',
      num_amortizaciones: 3,
      ultimos_prestamos_emergentes: [],
      saldo_disponible: '0',
      fecha_primera_amortizacion: null,
      fecha_dispersion: null,
      fecha_creacion: null,
      editable: false,
      cotizacion: {
        pagos: [],
        totales: {
          capital: '0',
          interes: '0',
          iva: '0',
          total: '0'
        }
      },
      usuario_verifico: null
    },
    cotCreColumnas: [
      {
        llave: 'num_pago',
        etiqueta: 'Plazo'
      },
      {
        llave: 'fecha',
        etiqueta: 'Fecha',
        tipo: 'fecha'
      },
      {
        llave: 'capital',
        etiqueta: 'Capital',
        tipo: 'dinero'
      },
      {
        llave: 'interes',
        etiqueta: 'Interes',
        tipo: 'dinero'
      },
      {
        llave: 'iva',
        etiqueta: 'IVA',
        tipo: 'dinero'
      },
      {
        llave: 'total_pagar',
        etiqueta: 'Total',
        tipo: 'dinero'
      },
      {
        llave: 'saldo_final',
        etiqueta: 'Saldo final',
        tipo: 'dinero'
      }
    ],
    historialPrestamosCols: [
      {
        llave: 'folio',
        etiqueta: 'Folio'
      },
      {
        llave: 'fecha_creacion',
        etiqueta: 'Fecha',
        tipo: 'fecha'
      },
      {
        llave: 'monto',
        etiqueta: 'Monto',
        tipo: 'dinero'
      },
      {
        llave: 'adeudo_total',
        etiqueta: 'Adeudo total',
        tipo: 'dinero'
      },
      {
        llave: 'num_amortizaciones',
        etiqueta: 'No. amortizaciones'
      },
      {
        llave: 'estatus',
        etiqueta: 'Estatus'
      }
    ],
    verModal: false,
    accion: 'aprobar',
    fechaPrimeraAmortizacion: new Date(),
    fechaDispersion: new Date(),
    motivoRechazo: '',
    errores: {},
    mostratAlerta: false,
    textoAlerta: ''
  };

  componentWillMount() {
    const id = this.props.match.params.id;
    this.setState({ id });
  }

  async componentDidMount() {
    const { id } = this.state;

    try {
      const resp = await obtenerDisposicion(id);
      let disposicion = resp.data;
      let fechaPrimeraAmortizacion = new Date();
      let fechaDispersion = new Date();
      if (disposicion.fecha_primera_amortizacion) {
        fechaPrimeraAmortizacion = moment(
          disposicion.fecha_primera_amortizacion
        ).toDate();
      }
      if (disposicion.fecha_dispersion) {
        fechaDispersion = disposicion.fecha_dispersion;
      }
      disposicion = quitarNulos(disposicion);
      this.setState({
        disposicion,
        fechaPrimeraAmortizacion,
        fechaDispersion
      });
    } catch (error) {
      console.log(error);
    }
  }

  exportar = async () => {
    const { id, disposicion } = this.state;
    try {
      const resp = await exportarDisposicion(id);
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `disposicion_${disposicion.num_disposicion}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  renderRow = (label, value) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        <strong>{value}</strong>
      </div>
    </div>
  );

  handleChangeDate = (name, date) => {
    const fecha = date[0];
    this.setState({ [name]: fecha });
  };

  submit = async () => {
    const {
      fechaPrimeraAmortizacion,
      fechaDispersion,
      motivoRechazo,
      accion,
      id,
      disposicion
    } = this.state;
    try {
      let resp = {};
      let datos = {};
      if (accion === 'aprobar') {
        datos = {
          fecha_primera_amortizacion: moment(fechaPrimeraAmortizacion).format(
            'YYYY-MM-DD'
          ),
          fecha_dispersion: moment(fechaDispersion).format('YYYY-MM-DD')
        };
        resp = await aprobarDisposicion(datos, id);
        disposicion.estatus = resp.data.estatus;
        disposicion.editable = resp.data.editable;
        disposicion.fecha_primera_amortizacion =
          resp.data.fecha_primera_amortizacion;
        disposicion.fecha_dispersion = resp.data.fecha_dispersion;
        disposicion.usuario_verifico = resp.data.usuario_verifico;
        this.setState({
          disposicion,
          mostratAlerta: true,
          textoAlerta: 'Disposición aprobada exitosamente.',
          verModal: false
        });
      } else {
        datos = {
          motivo_rechazo: motivoRechazo
        };
        resp = await rechazarDisposicion(datos, id);
        disposicion.estatus = resp.data.estatus;
        disposicion.editable = resp.data.editable;
        disposicion.usuario_verifico = resp.data.usuario_verifico;
        this.setState({
          disposicion,
          mostratAlerta: true,
          textoAlerta: 'Disposición rechazada exitosamente.',
          verModal: false
        });
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  render() {
    const guia = [
      { nombre: 'Disposiciones', url: '/disposiciones', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];

    const {
      // id,
      titulo,
      disposicion,
      cotCreColumnas,
      historialPrestamosCols,
      verModal,
      accion,
      fechaPrimeraAmortizacion,
      fechaDispersion,
      motivoRechazo,
      errores,
      mostratAlerta,
      textoAlerta
    } = this.state;

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <SweetAlert
          show={mostratAlerta}
          title={textoAlerta}
          type="success"
          onConfirm={() => {
            this.setState({ mostratAlerta: false });
          }}
          onEscapeKey={() => this.setState({ mostratAlerta: false })}
          onOutsideClick={() => this.setState({ mostratAlerta: false })}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-content">
                        <h3>
                          <strong>Información de la disposición</strong>
                        </h3>
                        {this.renderRow(
                          'No. de diposición',
                          disposicion.num_disposicion
                        )}
                        {this.renderRow('Empleado', disposicion.empleado)}
                        {this.renderRow('Banco', disposicion.banco || '--')}
                        {this.renderRow('Clabe', disposicion.clabe || '--')}
                        {this.renderRow('Empresa', disposicion.empresa)}
                        {this.renderRow(
                          'Monto',
                          currencyFormat(disposicion.monto)
                        )}
                        {this.renderRow(
                          'No. de amotizaciones',
                          disposicion.num_amortizaciones
                        )}
                        {this.renderRow(
                          'Frecuencia de cobro',
                          disposicion.frecuencia_cobro
                        )}
                        {this.renderRow(
                          'Saldo disponible',
                          currencyFormat(disposicion.saldo_disponible)
                        )}
                        {this.renderRow(
                          'Fecha primera amotización',
                          dateFormat(disposicion.fecha_primera_amortizacion)
                        )}
                        {this.renderRow(
                          'Fecha de creación',
                          dateTimeFormat(disposicion.fecha_creacion)
                        )}
                        {this.renderRow('Estatus', disposicion.estatus)}
                        {this.renderRow(
                          'Usuario verificador',
                          disposicion.usuario_verifico
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div className="col-md-3 pb25">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-block"
                    onClick={this.exportar}
                  >
                    <i className="fa fa-download" /> Exportar
                  </button>

                  { disposicion.editable && verificarPermiso('disposiciones.aprobar_disposicion') &&
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-block"
                      onClick={() =>
                        this.setState({ verModal: true, accion: 'aprobar' })
                      }
                    >
                      <i className="fa fa-check" /> Aprobar
                    </button>
                  }

                  { disposicion.editable && verificarPermiso('disposiciones.rechazar_disposicion') &&
                    <button
                      type="button"
                      className="btn btn-danger btn-sm btn-block"
                      onClick={() =>
                        this.setState({ verModal: true, accion: 'rechazar' })
                      }
                    >
                      <i className="fa fa-times" /> Rechazar
                    </button>
                  }
                </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">Cotización de crédito</div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">
                        <Tabla
                          objetos={disposicion.cotizacion.pagos}
                          columnas={cotCreColumnas}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Historial de creditos del empleado
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">
                        <Tabla
                          objetos={disposicion.ultimos_prestamos_emergentes}
                          columnas={historialPrestamosCols}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal visible={verModal} width="50%" effect="fadeInUp">
          <div className="modal-content animated bounceInRight">
            <div className="modal-header">
              {accion === 'aprobar' ? (
                <h4 className="modal-title">Aprobar disposición</h4>
              ) : (
                <h4 className="modal-title">Rechazar disposición</h4>
              )}
            </div>
            <div className="modal-body">
              {accion === 'aprobar' ? (
                <div>
                  <div className="form-group">
                    <label htmlFor="fecha">Fecha primera amortización</label>
                    <Flatpickr
                      className="form-control date"
                      value={fechaPrimeraAmortizacion}
                      options={{
                        // minDate: moment().format('YYYY-MM-DD'),
                        dateFormat: 'd-m-Y'
                      }}
                      onChange={date =>
                        this.handleChangeDate('fechaPrimeraAmortizacion', date)
                      }
                    />
                    {errores.fechaPrimeraAmortizacion &&
                      errores.fechaPrimeraAmortizacion.map((obj, index) => (
                        <span
                          className="form-text m-b-none text-error"
                          key={`efpa${index}`}
                        >
                          {obj}
                        </span>
                      ))}
                  </div>
                  <div className="form-group">
                    <label htmlFor="fecha">Fecha dispersión</label>
                    <Flatpickr
                      className="form-control date"
                      value={fechaDispersion}
                      options={{
                        // minDate: moment().format('YYYY-MM-DD'),
                        dateFormat: 'd-m-Y'
                      }}
                      onChange={date =>
                        this.handleChangeDate('fechaDispersion', date)
                      }
                    />
                    {errores.fechaDispersion &&
                      errores.fechaDispersion.map((obj, index) => (
                        <span
                          className="form-text m-b-none text-error"
                          key={`efpa${index}`}
                        >
                          {obj}
                        </span>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="fecha">Motivo de rechazo</label>
                  <textarea
                    value={motivoRechazo}
                    name="motivoRechazo"
                    onChange={e =>
                      this.setState({ motivoRechazo: e.target.value })
                    }
                    className="form-control"
                  />
                  {errores.motivoRechazo &&
                    errores.motivoRechazo.map((obj, index) => (
                      <span
                        className="form-text m-b-none text-error"
                        key={`efpa${index}`}
                      >
                        {obj}
                      </span>
                    ))}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-white"
                onClick={() => this.setState({ verModal: false })}
              >
                Cerrar
              </button>
              <button
                type="button"
                className={`btn ${
                  accion === 'aprobar' ? 'btn-primary' : 'btn-danger'
                }`}
                onClick={this.submit}
              >
                {accion === 'aprobar'
                  ? 'Aprobar disposición'
                  : 'Rechazar disposición'}
              </button>
            </div>
          </div>
        </Modal>
        <style jsx="true">{`
            .sol{
                display: flex;
                justify-content: space-between;
            }
            .ibox-content{
                border-style: none;
            }
            .fa-margin {
                margin-right: 5px;
            }
            .right {
                float: right;
            }
            .celda-delgada {
                padding: 0.3rem !important;
            }chuy
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
