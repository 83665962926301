import { resetWarningCache } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { enviarDatosContratoWhirlpool, getDatosContratoWhirlpool } from '../../../api/Solicitudes';



const CamposContrato = ({ solicitud, mostrar, setMostrar, cargarDocumentos }) => {
    const [ data, setData ] = useState( {} );
    const [ cargando, setCargando ] = useState( false );
    const [ errores, setErrores ] = useState( {} );
    const [ enviarContrato, setEnviarContrato ] = useState(false);
    const [domicilioCompleto, setDomicilioCompleto] = useState(false)

    useEffect(() => {
        if ( solicitud !== undefined ) {
            getData( solicitud.id );
        }
   
    }, [ solicitud ]);

    const getData = async ( id ) => {
        try {
            const { data } = await getDatosContratoWhirlpool( id );
            if ( !data.estado.value && !data.estado.value ) {
                setDomicilioCompleto( false );
                return;
            }
            setDomicilioCompleto( true );
            setData( data );
        } catch ( err ) {
            console.log( err );
        }
    }

    const onClickSendData = async () => {
        try {
            setCargando( true );
            let payload = { enviar_contrato: enviarContrato };
            Object.keys( data ).forEach( key => {
                payload[ key ] = data[ key ].value;
            });
            await enviarDatosContratoWhirlpool( solicitud.id, payload );
            await cargarDocumentos(solicitud.id);
            Swal.fire({
                type: 'success',
                text: 'Se creado el contrato correctamente',
                toast: true,
                position: 'top-end',
                timer: 6000,
                showConfirmButton: false
            });
            setMostrar( false );
        } catch ( err ) {
            console.log( err );
            setErrores(err.data);
            Swal.fire({
                type: 'error',
                text: 'Ha habido un error al crear el contrato',
                toast: true,
                position: 'top-end',
                timer: 6000,
                showConfirmButton: false
            });
        }
        setCargando( false );
    }


    const onChangeData = ( e ) => {
        setData({
            ...data,
            [ e.target.name ]: { ...data[ e.target.name ], value: e.target.value }
        });
    }

    const dataFields = ( title, typeFields ) => {
        return (
            <div className="ibox">
                <div className="ibox-title">
                    <h4>{ title }</h4>
                </div>
                <div className="ibox-content">

                    <div className="row">
                        {
                            Object.entries( data ).filter( field => field[1].tipo === typeFields ).map( (field, index) => (
                                <div className="col-md-3"  key={ index }>
                                    <div className="form-group">
                                        <label>{ field[1].label }</label>
                                            <input type="text" className={`form-control ${errores.hasOwnProperty( field[0] ) ? 'has-error' : ''}`} name={ field[0] } value={ data[field[0]].value } onChange={ onChangeData } />
                                            { errores.hasOwnProperty( field[0] ) && <span className="form-text m-b-none text-error">{ errores[ field[0] ] }</span> }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    
                </div>   
            </div>
        )
    }

    return (
        <>
            
            { mostrar && domicilioCompleto &&
                <>
                    <div className="modal-header">
                        <h3 className="modal-title">Información para generación de contrato</h3>
                    </div>

                    { dataFields( "Información del solicitante", "empleado" ) }

                    { dataFields( "Información del domicilio", "domicilio" ) }

                    { dataFields( "Información de la empresa", "empresa" ) }

                    { dataFields( "Información del crédito", "credito" ) }

                    <div className="ibox">
                        <div className="ibox-title">
                            <h4>Envío de contrato</h4>
                        </div>
                        <div className="ibox-content">

                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Enviar contrato</label>
                                        <br />
                                        <input type="checkbox"  name="enviar_contrato" value={ enviarContrato } onChange={() => setEnviarContrato(!enviarContrato)} />
                                    </div>
                                </div>
                            </div>
                            
                        </div>   
                    </div>

                    <div className="modal-footer">
                        
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={ cargando }
                            onClick={ onClickSendData }
                        >
                            { cargando ? 'Generando contrato...' : 'Generar contrato' }
                        </button>
                        
                    </div>
                </>
            }

            { !domicilioCompleto && 
                <div className="alert alert-warning m-t">
                    Para poder generar el contrato es necesario que el empleado tenga el domicilio completo
                </div>
            }
        </>
    );
}
 
export default CamposContrato;