import React, {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { remplazarDaysToDias } from '../../utils/functions';

const GraficaPorEtapa = ({duraciones}) => {
    const [etapasChart, setEtapasChart] = useState([]);
    const [duracionesMinsChart, setDuracionesMinsChart] = useState([]);


    useEffect(() => {
        let etapas = [];
        let duracionesMins = [];

        duraciones.forEach(({duracion, etapa}) => {
            etapas.push(etapa);
            duracionesMins.push(remplazarDaysToDias(duracion));
        });

        setEtapasChart(etapas);
        setDuracionesMinsChart(duracionesMins);
        return () => new AbortController();
    }, [duraciones]);

    return (        
        <Bar
            style={{height: "500px"}}
            data={{
                labels: etapasChart,
                datasets: [
                    {
                        label: 'Etapas',
                        data: duracionesMinsChart, // [45,30,15,41,56,32,44,12,25,54,33,45,25,19,55,47,53,25],
                        
                        backgroundColor: [
                            'rgba(41, 179, 148, 0.6)',
                        ],
                        borderColor: [
                            'rgba(41, 179, 148, 1)',
                        ],
                        borderWidth: 2,
                    }
                ]
            }}
            options={{
                indexAxis: 'y',
                plugins:{
                    title: {
                        display: false,
                        text: ''
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label(context) {
                                let duracionSTR = duraciones.find(duracion => duracion.etapa === context.label);
                                return duracionSTR.duracion_str;
                            }
                        }                           
                    },
                },
                responsive: true,
                maintainAspectRatio: false
            }}
        />
    );
}
 
export default GraficaPorEtapa;