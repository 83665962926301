import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerUsuarios(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/usuarios/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerUsuario(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/usuarios/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarUsuario(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/usuarios/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarUsuario(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/usuarios/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function cambiarContrasena(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/usuarios/${id}/cambiar-contrasena/`, datos);
  } catch (err) {
    callError(err);
  }
}

export { obtenerUsuarios, obtenerUsuario, agregarUsuario, actualizarUsuario, cambiarContrasena };
