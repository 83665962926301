import React from 'react';

const InputFile = ({
  error,
  label,
  type,
  name,
  id = '',
  helpText,
  className = 'col-sm-6',
  labelClassName = 'col-sm-4',
  controlClassName = 'col-sm-8',
  onChange,
  validate
}) => (
  <div className={className}>
    <div className={`form-group row ${error.length > 0 && 'has-error'}`}>
      <label className={`${labelClassName} col-form-label`}>{label}</label>
      <div className={controlClassName}>
        <input
          type={type}
          name={name}
          id={id || name || ''}
          onChange={onChange}
          className="form-control"
          onBlur={validate}
        />
        <span className="form-text m-b-none">{helpText}</span>
        {error.map((obj, index) => (
          <span className="form-text m-b-none text-error" key={index}>
            {obj}
          </span>
        ))}
      </div>
    </div>
  </div>
);

export default InputFile;
