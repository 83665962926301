import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado'
// import Tabla from '../../components/Tabla'
import Paginacion from '../../components/Paginacion'
import { obtenerRecargas, obtenerProveedoresRecargas } from '../../api/EMIDA';
import { dateTimeFormat, currencyFormat } from '../../utils/filters'

class Lista extends Component {
    state = {
        titulo: 'Recargas',
        objetos: [],
        totalRecords: 0,
        pageLimit: 50,
        params: {},
        datos: {},
        proveedores: []
    }

    async componentDidMount() {
        const { pageLimit } = this.state
        const params = { page_size: pageLimit }
        this.obtenerObjetos(params)
        const req = await obtenerProveedoresRecargas()
        const proveedores = req.data
        this.setState({ proveedores })
    }

    obtenerObjetos = async params => {
        try {
            const req = await obtenerRecargas(params)
            const objetos = req.data.results
            const totalRecords = req.data.count
            this.setState({ objetos, totalRecords, params })
        } catch (error) {
            console.log(error)
        }
    }
    
    onPageChanged = async data => {
        const page = data.currentPage
        const { pageLimit, params } = this.state
        params.page = page
        params.page_size = pageLimit
        this.obtenerObjetos(params)
    }

    limpiar = async () => {
        this.setState({
            params: {},
            datos: {
                search: '',
                proveedor: '',
                estatus: ''
            } 
        })
        const { pageLimit } = this.state
        const params = { page_size: pageLimit }
        this.obtenerObjetos(params)
    }

    buscar = async() => {
        let { params } = this.state
        const search = this.state.datos.search
        delete params.search
        delete params.page
        params = Object.assign({ search }, params)
        this.obtenerObjetos(params)
    }

    onChange = async(campo, e) => {
        const { datos } = this.state
        let { params } = this.state
        const valor = e.target.value
        datos[campo] = valor
        this.setState({ datos })
        if (campo === 'proveedor' || campo === 'estatus'){
            const proveedor = this.state.datos.proveedor
            const estatus = this.state.datos.estatus
            delete params.proveedor
            delete params.estatus
            delete params.page
            params = Object.assign({ proveedor, estatus }, params)
            this.obtenerObjetos(params)            
        }
        if (valor === '') {
            await this.limpiar()
        }
    }


    handleKeyDown = async(e) => {
        if (e.key === 'Enter') {
            await this.buscar()
        }
    }

    render () {
        const { titulo, objetos, totalRecords, pageLimit, proveedores } = this.state
        const guia = [
            { nombre: titulo, url: '', clase: 'active' }
        ]
        const { datos } = this.state
        const { proveedor, estatus, search } = datos


        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="input-group">
                                            <select
                                                value={proveedor}
                                                onChange={e => this.onChange('proveedor', e)}
                                                className="form-control m-b"
                                            >
                                                <option value="">Selecciona proveedor</option>
                                                { proveedores.map(p => {
                                                    return <option key={ p.id } value={ p.id }>{ p.nombre }</option>
                                            })}
                                                
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="input-group">
                                            <select
                                                value={estatus}
                                                onChange={e => this.onChange('estatus', e)}
                                                className="form-control m-b"
                                            >
                                                <option value="">Selecciona estatus</option>
                                                <option value="exito">Exito</option>
                                                <option value="pendiente">Pendiente</option>
                                                <option value="error">Error</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-group">
                                            <input
                                                value={ search }
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={(e) => this.onChange('search', e)}
                                                onKeyDown={e => this.handleKeyDown(e)}
                                                placeholder="Busca por folio, teléfono o empleado"
                                            />
                                            <span className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-info"
                                                    onClick={() => this.limpiar() }
                                                >
                                                    <i className="fa fa-close fa-margin" />
                                                </button>
                                            </span>
                                            <span className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => this.buscar()}
                                                >
                                                    <i className="fa fa-search fa-margin" />
                                                    Buscar
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Creación</th>
                                                <th>Folio</th>
                                                <th>Empleado</th>
                                                <th>Teléfono</th>
                                                <th>Producto</th>
                                                <th>Proveedor</th>
                                                <th>Monto</th>
                                                <th>Comisión</th>
                                                <th>Total</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { objetos.map(obj => {
                                                return <tr key={ obj.id }>
                                                    <td>{ dateTimeFormat(obj.fecha_creacion) }</td>
                                                    <td>{ obj.folio }</td>
                                                    <td>{ obj.empleado }</td>
                                                    <td>{ obj.numero_telefono }</td>
                                                    <td>{ obj.producto }</td>
                                                    <td>{ obj.proveedor }</td>
                                                    <td>{ currencyFormat(obj.monto) }</td>
                                                    <td>{ currencyFormat(obj.comision) }</td>
                                                    <td>{ currencyFormat(obj.total) }</td>
                                                    <td>{ obj.estatus }</td>
                                                    <td>
                                                        <Link
                                                            key={obj.id}
                                                            to={`/recargas/ver/${obj.id}/`}
                                                            className="btn btn-primary btn-sm btns-space"
                                                        >
                                                            <i className="fa fa-eye fa-margin" />
                                                            Ver
                                                        </Link>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Paginacion
                            totalRecords={totalRecords}
                            pageLimit={pageLimit}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                        />
                    </div>
                </div>
                <style jsx="true">{`
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .centrado{
                        text-align: center;
                    }
                    .rojo{
                        color: red;
                    }
                    .verde{
                        color: green;
                    }
                    .negritas{
                        font-weight: bold;
                    }
                    .clean-search{
                        position: absolute;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Lista
