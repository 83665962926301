import React, { Component } from 'react'
import NoAutenticado from '../components/NoAutenticado'
import { Link } from 'react-router-dom'

export class IniciarSesion extends Component {
    render () {
        return (
            <NoAutenticado>
                <h1>404</h1>
                <h3 className="font-bold">Página no encontrada</h3>

                <div className="error-desc">
                    <p>Lo sentimos, pero la página que estás buscando ha sido encontrada. Intente verificar la URL para ver si hay errores, luego presione el botón de actualización en su navegador o regrese al inicio.</p>
                    <Link to="/" className="btn btn-primary">Ir al inicio</Link>
                </div>
            </NoAutenticado>
        )
    }
}

export default IniciarSesion
