import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerDocumentos() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/tipos-documentos/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerDocumento(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/documentos/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarDocumento(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/documentos/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarDocumento(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/documentos/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerDocumentos,
  obtenerDocumento,
  agregarDocumento,
  actualizarDocumento
};
