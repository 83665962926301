import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function validarCrearArchivoRespuesta(datos) {
  const API = await ConstructorAPIAsync(180000);
  try {
    return await API.post('/admin/domiciliacion/validar-crear/', datos);
  } catch (err) {
    callError(err);
  }
}

// async function crearPagoMasivo(datos) {
//   const API = await ConstructorAPIAsync(120000);
//   try {
//     return await API.post('/admin/domiciliacion/pagos_masivos/', datos);
//   } catch (err) {
//     callError(err);
//   }
// }

async function configuracionBanco() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/domiciliacion/bancos_config/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerBancosList(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/bancos-list/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerPrestamosPendientes(datos) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.get('/admin/domiciliacion/busqueda/', { params: datos });
  } catch (err) {
    callError(err);
  }
}

async function generar_archivo_hsbc(datos, fecha_transferencia, tipo_operacion, express=false) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    return await API.post('/admin/domiciliacion/hsbc/', { prestamos: datos, fecha_transferencia: fecha_transferencia, tipo_operacion:tipo_operacion, express:express});
  } catch (err) {
    callError(err);
  }
}

async function generar_archivo_banorte(datos, express=false) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    return await API.post('/admin/domiciliacion/banorte/', {prestamos: datos, express:express});
  } catch (err) {
    callError(err);
  }
}

async function generar_archivo_vepormas(datos, express=false) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    return await API.post('/admin/domiciliacion/vepormas/', {prestamos: datos, express:express});
  } catch (err) {
    callError(err);
  }
}

async function generar_archivo_excel(datos){
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    return await API.get('/admin/domiciliacion/busqueda/', { params: datos });
  } catch (err) {
    callError(err);
  }
}

async function obtener_prestamos_sin_alta(){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.get('/admin/domiciliacion/prestamos_sin_alta/');
  } catch (err) {
    callError(err);
  }
}

async function generar_archivo_altas(){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post('/admin/domiciliacion/generar_archivo_alta/');
  } catch (err) {
    callError(err);
  }
}

async function eliminar_prestamo_domiciliacion(id, motivo, comentario){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post(`/admin/domiciliacion/${id}/bloquear/`, {motivo: motivo, comentario: comentario});
  } catch (err) {
    callError(err);
  }
}

async function validarArchivoPromesas(datos) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post('/admin/domiciliacion/promesas/validar/', datos);
  } catch (err) {
    callError(err);
  }
}

async function obtener_prestamos_bloqueados(parametros = ''){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.get('/admin/domiciliacion/prestamos_bloqueados/', { params: parametros});
  } catch (err) {
    callError(err);
  }
}

async function reactivar_prestamo_domiciliacion(id){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post(`/admin/domiciliacion/${id}/reactivar/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerHistoriales(datos){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.get('/admin/domiciliacion/historiales/busqueda/', { params: datos });
  } catch (err) {
    callError(err);
  }
}

async function obtenerDetallesHistorialPagos(id){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.get(`/admin/domiciliacion/historial/pagos/${id}/detalles/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerDetallesHistorialEmpleados(id){
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.get(`/admin/domiciliacion/historial/empleados/${id}/detalles/`);
  } catch (err) {
    callError(err);
  }
}





export {
  configuracionBanco,
  validarCrearArchivoRespuesta,
  // crearPagoMasivo,
  obtenerPrestamosPendientes,
  obtenerBancosList,
  generar_archivo_hsbc,
  generar_archivo_excel,
  generar_archivo_banorte,
  obtener_prestamos_sin_alta,
  generar_archivo_altas,
  eliminar_prestamo_domiciliacion,
  validarArchivoPromesas,
  obtener_prestamos_bloqueados,
  reactivar_prestamo_domiciliacion,
  obtenerHistoriales,
  obtenerDetallesHistorialPagos,
  obtenerDetallesHistorialEmpleados,
  generar_archivo_vepormas 
};
