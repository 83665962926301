import React, { Component } from 'react'
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Autenticado from '../../components/Autenticado'
// import Tabla from '../../components/Tabla'
// import Paginacion from '../../components/Paginacion'
import { exportarPagosEmergentes, reportePagosEmergentes } from '../../api/Pagos';
import { /*dateTimeFormat,*/ currencyFormat, dateFormat } from '../../utils/filters';
import { obtenerEmpresasList } from '../../api/Empresas';
import Select from 'react-select'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { mostrarToast } from '../../utils/alerts';
// import { exportarPrestamos } from '../../api/Prestamos';
import { Link } from 'react-router-dom';
import Paginacion from '../../components/Paginacion';

class Lista extends Component {
  state = {
    titulo: 'Reporte de Pagos Emergentes',
    objetos: [],
    params: {
      empresa: null,
      frecuencia_cobro: null,
      fecha_inicio: moment(new Date()).format('YYYY-MM-DD'),
      fecha_fin: moment(new Date()).format('YYYY-MM-DD'),
    },
    empresas: [],
    loading: false,
    exportando: false,
    totalRecords: 0,
    pageLimit: 50,
  }

  componentDidMount = async() => {
    try {
      //await this.getReportePagosEmergentes();
      this.getEmpresas();
    } catch (error) {
      console.log(error)
    }
  }

  async getEmpresas() {
    try {
      const req = await obtenerEmpresasList();
      this.setState({empresas: req.data});
    }catch (e) {
      console.log('Ocurrio un error');
    }
  }

  getReportePagosEmergentes = async () => {
    const {params, pageLimit} = this.state;
    params['page_size'] =  pageLimit;
    this.setState({loading: true});
    try {
      const req = await reportePagosEmergentes(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params, loading: false });
    } catch (error) {
      console.log(error);
      mostrarToast('Ocurrió un error en el reporte', 'error');
      this.setState({loading: false});
    }
  }

  handleFechaInicio = async (date) => {
    const {params} = this.state;
    const fecha_inicio = moment(date[0]).format('YYYY-MM-DD');
    params.fecha_inicio = fecha_inicio;
    this.setState({params});
    //await this.getReportePagosEmergentes();
  }

  handleFechaFin= async (date) => {
    const {params} = this.state;
    const fecha_fin = moment(date[0]).format('YYYY-MM-DD');
    params.fecha_fin = fecha_fin;
    this.setState({params});
    //await this.getReportePagosEmergentes();
  }

  handleEmpresa = async (data) => {
    const {params} = this.state;
    params.empresa = data.value;
    this.setState({params});
    //await this.getReportePagosEmergentes();
  }

  handleFiltro = async (e) => {
    const {params} = this.state;
    params[e.target.name] = e.target.value;
    this.setState({params});
    //await this.getReportePagosEmergentes();
  }

  buscar = async () => {
    await this.getReportePagosEmergentes();
  };

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    delete params.export;
    try {
      const req = await reportePagosEmergentes(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  exportar = async () =>{
    const {params} = this.state;
    delete params.page;
    delete params.page_size;
    this.setState({exportando: true});
    try {
      const resp = await exportarPagosEmergentes( params );
      console.log(resp.data);
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `REPORTE-PAGOS-EMERGENTES-${moment().format('YYYYMMDD')}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }catch (e) {
      console.log(e);
    }
    this.setState({exportando: false});
  }

  render () {
    const { titulo, objetos, empresas, params, totalRecords, pageLimit } = this.state
    const guia = [
      { nombre: titulo, url: '', clase: 'active' }
    ]

    let empresasOptions = [{label: 'Todas', value: ''}];
    empresas.forEach(empresa => {
      empresasOptions.push({label: empresa.nombre, value: empresa.id});
    });

    return (
      <Autenticado titulo={ titulo } guia={ guia }>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm m-b-sm"
                      onClick={this.exportar}
                      disabled={this.state.exportando}
                    >
                      <i className="fa fa-download fa-margin"/>
                      { this.state.exportando ? 'Exportando...' : 'Exportar' }
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <Select
                      placeholder="Seleccionar empresa"
                      options={empresasOptions}
                      noOptionsMessage={()=> 'Sin opciones'}
                      onChange={this.handleEmpresa}
                    />
                  </div>
                  {/*<div className="col-sm-3">*/}
                  {/*  <select name="frecuencia_cobro" id="" className="form-control form-control-lg" onChange={(e) => this.handleFiltro(e)}>*/}
                  {/*    <option value="">Selecciona la frecuencia</option>*/}
                  {/*    <option value="semanal">Semanal</option>*/}
                  {/*    <option value="decenal">Decenal</option>*/}
                  {/*    <option value="catorcenal">Catorcenal</option>*/}
                  {/*    <option value="quincenal">Quincenal</option>*/}
                  {/*    <option value="mensual">Mensual</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                  <span>Desde</span>
                  <div className="col-sm-2">
                    <Flatpickr
                      className="form-control date"
                      value={ moment(params.fecha_inicio).format('DD-MM-YY') }
                      name='fecha inicio'
                      options={{ dateFormat: 'd-m-Y', locale: Spanish }}
                      onChange={this.handleFechaInicio}
                    />
                  </div>
                  <span>Hasta</span>
                  <div className="col-sm-2">
                    <Flatpickr
                      className="form-control date"
                      value={ moment(params.fecha_fin).format('DD-MM-YY') }
                      name='fecha fin'
                      options={{ dateFormat: 'd-m-Y', locale: Spanish }}
                      onChange={this.handleFechaFin}
                    />
                  </div>
                  <div className="col-sm-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={() => this.buscar()}
                    >
                      <i className="fa fa-search fa-margin" />
                      Buscar
                    </button>
                  </div>
                </div>
                {
                  this.state.loading ?
                    <div className="spiner-example">
                      <div className="sk-spinner sk-spinner-wandering-cubes">
                        <div className="sk-cube1"></div>
                        <div className="sk-cube2"></div>
                      </div>
                    </div>
                    :
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                        <tr>
                          <th>Prestamo</th>
                          <th>Empresa</th>
                          <th>Empleado</th>
                          <th>Destino</th>
                          <th>Fecha Dispersión</th>
                          <th>Fecha Primera Amortización</th>
                          {/*<th>Frecuencia</th>*/}
                          <th>Monto Préstamo</th>
                          <th>Fecha Registro</th>
                          <th>Fecha Pago</th>
                          <th>Monto</th>
                          <th>Usuario Registro</th>
                          <th>Referencia</th>
                          <th>Comentario</th>
                        </tr>
                        </thead>
                        <tbody>
                        { objetos.map(obj => {
                          return <tr key={ obj.id }>
                            <td>
                              <Link to={`/prestamos/ver/${obj.id_prestamo}`} target="_blank">{obj.folio_prestamo}</Link>
                            </td>
                            <td>{ obj.empresa }</td>
                            <td>{ obj.empleado }</td>
                            <td>{ obj.destino }</td>
                            <td>{ dateFormat(obj.fecha_dispersion) }</td>
                            <td>{ dateFormat(obj.fecha_primera_amortizacion) }</td>
                            {/*<td className="text-capitalize">{ obj.frecuencia_cobro }</td>*/}
                            <td>{ currencyFormat(obj.monto_credito) }</td>
                            <td>{ dateFormat(obj.fecha_creacion) }</td>
                            <td>{ dateFormat(obj.fecha_pago) }</td>
                            <td>{ currencyFormat(obj.monto) }</td>
                            <td>{ obj.usuario_creo}</td>
                            <td>{ obj.referencia}</td>
                            <td>{ obj.comentario}</td>
                          </tr>
                        })}
                        </tbody>
                      </table>
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
        <Paginacion
          totalRecords={totalRecords}
          pageLimit={pageLimit}
          pageNeighbours={1}
          onPageChanged={this.onPageChanged}
        />
        <style jsx="true">{`
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .centrado{
                        text-align: center;
                    }
                    .rojo{
                        color: red;
                    }
                    .verde{
                        color: green;
                    }
                    .negritas{
                        font-weight: bold;
                    }
                    .clean-search{
                        position: absolute;
                    }
                    .right {
                        float: right;
                    }
                `}</style>
      </Autenticado>
    )
  }
}

export default Lista