import React, { Component } from 'react'
import Select from 'react-select'
import Autenticado from '../components/Autenticado'
import '../css/chartist.min.css'
import {
    dashboardEmpleados,
    dashboardMontoPrestado,
    dashboardMontoEmergente,
    dashboardPagadoCobrar,
    dashboardSaldo,
    dashboardMontos, dashboardactualizarSaldo
} from '../api/Dashboard';
import { obtenerEmpresas } from '../api/Empresas'
import { currencyFormat, dateTimeFormat } from '../utils/filters'
import ChartistGraph from 'react-chartist'

class Inicio extends Component {
    state = {
        empleados: [],
        totalEmpleados: 0,
        montosPrestados: [],
        totalPrestado: 0,
        montosEmergentes: [],
        totalEmergente: 0,
        pagado: [],
        porCobrar: [],
        saldo: {},
        saldoServicios: {},
        anios: [],
        empresas: [],
        anioEmpleados: new Date().getUTCFullYear().toString(),
        anioMontoPrestado: new Date().getUTCFullYear().toString(),
        anioMontoEmergente: new Date().getUTCFullYear().toString(),
        anioPagadoCobrar: new Date().getUTCFullYear().toString(),
        empresaMontoPrestado: null,
        empresaMontoEmergente: null,
        empresaPagadoCobrar: null,
        montos: {}
    }

    async componentDidMount() {
        this.obtenerSaldo()
        this.obtenerInfoEmpleados()
        try {
            const anios = []
            const anioActual = new Date().getUTCFullYear()
            for (let anio = 2017; anio <= anioActual; anio++) { 
                const obj = { value: anio.toString(), label: anio }
                anios.push(obj)
            }

            const empresas = []
            // let empresa = null
            const params = { page_size: 1000 }
            let req = await obtenerEmpresas(params)
            req.data.results.forEach(e => {
                const obj = { value: e.id, label: e.nombre }
                empresas.push(obj)
            })

            req = await dashboardMontos()
            const montos = req.data
            
            this.setState({
                anios,
                empresas,
                montos
            })

            this.obtenerInfoEmpleados()
            this.obtenerInfoMontoPrestado()
            this.obtenerInfoMontoEmergente()
            this.obtenerInfoPagadoCobrar()
        } catch (error) {
            console.log(error)
        }
    }
    
    handleChange = (name, value) => {
        const valor = name.value
        const variable = value.name
        if (variable === 'anioEmpleados'){
            this.setState({ anioEmpleados: valor }, () => this.obtenerInfoEmpleados())
        }
        if (variable === 'anioMontoPrestado'){
            this.setState({ anioMontoPrestado: valor }, () => this.obtenerInfoMontoPrestado())
        }
        if (variable === 'empresaMontoPrestado'){
            this.setState({ empresaMontoPrestado: valor }, () => this.obtenerInfoMontoPrestado())
        }
        if (variable === 'anioMontoEmergente'){
            this.setState({ anioMontoEmergente: valor }, () => this.obtenerInfoMontoEmergente())
        }
        if (variable === 'empresaMontoEmergente'){
            this.setState({ empresaMontoEmergente: valor }, () => this.obtenerInfoMontoEmergente())
        }
        if (variable === 'anioPagadoCobrar'){
            this.setState({ anioPagadoCobrar: valor }, () => this.obtenerInfoPagadoCobrar())
        }
        if (variable === 'empresaPagadoCobrar'){
            this.setState({ empresaPagadoCobrar: valor }, () => this.obtenerInfoPagadoCobrar())
        }
    }

    obtenerSaldo = async () => {
        const req = await dashboardSaldo()
        const disponible = req.data.saldo_tae
        const fechaActualizacion = req.data.fecha_actualizacion_tae
        const disponibleServicios = req.data.saldo_pago_servicios
        const fechaActualizacionServicios = req.data.fecha_actualizacion_pago_servicios
        const saldo = { disponible, fechaActualizacion }
        const saldoServicios = { disponible: disponibleServicios, fechaActualizacion: fechaActualizacionServicios }
        this.setState({ saldo, saldoServicios })
    }

    actualizarSaldo = async () => {
        const req = await dashboardactualizarSaldo();
        const disponible = req.data.saldo_tae
        const fechaActualizacion = req.data.fecha_actualizacion_tae
        const disponibleServicios = req.data.saldo_pago_servicios
        const fechaActualizacionServicios = req.data.fecha_actualizacion_pago_servicios
        const saldo = { disponible, fechaActualizacion }
        const saldoServicios = { disponible: disponibleServicios, fechaActualizacion: fechaActualizacionServicios }
        this.setState({ saldo, saldoServicios })
    }

    async obtenerInfoEmpleados(){
        try {
            const { anioEmpleados } = this.state
            const params = { year: anioEmpleados }
            const req = await dashboardEmpleados(params)
            const empleados = []
            const totalEmpleados = req.data.total
            req.data.results.forEach(o => {
                empleados.push(o.empleados)
            })
            this.setState({empleados, totalEmpleados})
        } catch (error) {
            console.log(error)
        }
    }

    async obtenerInfoMontoPrestado(){
        const { anioMontoPrestado, empresaMontoPrestado} = this.state
        let params = { year: anioMontoPrestado }
        if (empresaMontoPrestado !== null){
            params = { year: anioMontoPrestado, empresa: empresaMontoPrestado }
        }
        try {
            const req = await dashboardMontoPrestado(params)
            const montosPrestados = []
            const totalPrestado = req.data.total
            req.data.results.forEach(o => {
                montosPrestados.push(o.monto)
            })
            this.setState({ montosPrestados, totalPrestado})
        } catch (error) {
            console.log(error)
        }
    }

    async obtenerInfoMontoEmergente(){
        const { anioMontoEmergente, empresaMontoEmergente} = this.state
        let params = { year: anioMontoEmergente, empresa: empresaMontoEmergente }
        if (empresaMontoEmergente !== null){
            params = { year: anioMontoEmergente, empresa: empresaMontoEmergente }
        }
        try {
            const req = await dashboardMontoEmergente(params)
            const montosEmergentes = []
            const totalEmergente = req.data.total
            req.data.results.forEach(o => {
                montosEmergentes.push(o.monto)
            })
            this.setState({ montosEmergentes, totalEmergente })
        } catch (error) {
            console.log(error)
        }
    }

    async obtenerInfoPagadoCobrar() {
        const { anioPagadoCobrar, empresaPagadoCobrar} = this.state
        let params = { year: anioPagadoCobrar, empresa: empresaPagadoCobrar }
        if (empresaPagadoCobrar !== null){
            params = { year: anioPagadoCobrar, empresa: empresaPagadoCobrar }
        }
        try {
            const req = await dashboardPagadoCobrar(params)
            const pagado = []
            const porCobrar = []
            req.data.results.forEach(o => {
                pagado.push(o.monto_pagado)
                porCobrar.push(o.por_cobrar)
            })
            this.setState({ pagado, porCobrar })
        } catch (error) {
            console.log()
        }
    }

    render () {
        const guia = [{ nombre: 'Inicio', url: '', clase: 'active' }]

        const {
            empleados,
            totalEmpleados,
            montosPrestados,
            totalPrestado,
            montosEmergentes,
            totalEmergente,
            pagado,
            porCobrar,
            saldo,
            saldoServicios,
            anios,
            empresas,
            empresaMontoPrestado,
            empresaMontoEmergente,
            empresaPagadoCobrar,
            anioEmpleados,
            anioMontoPrestado,
            anioMontoEmergente,
            anioPagadoCobrar,
            montos
        } = this.state

        const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

        const datosMontosPrestados = {
            labels: meses,
            series: [montosPrestados]
        }

        const datosMontosEmergentes = {
            labels: meses,
            series: [montosEmergentes]
        }

        const datosEmpleados = {
            labels: meses,
            series: [empleados]
        }

        const datosPagodoPorCobrar = {
            labels: meses,
            series: [pagado, porCobrar]
        }

        return (
            <Autenticado titulo="Inicio" guia={ guia }>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Meta del mes</h5>
                            </div>
                            <div className="ibox-content">
                                <h1 className="no-margins">{ currencyFormat(montos.meta_mes)}</h1>
                                <small>{ montos.meta_mes_fecha }</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Monto prestado</h5>
                            </div>
                            <div className="ibox-content">
                                <h1 className="no-margins">{ currencyFormat(montos.monto_prestado)}</h1>
                                <div className="stat-percent font-bold text-info"></div>
                                <small>Total</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Saldo EMIDA</h5>
                            </div>
                            <div className="ibox-content">
                                <h1 className="no-margins">{ currencyFormat(saldo.disponible) }</h1>
                                <small>Última actualización: { dateTimeFormat(saldo.fechaActualizacion) }</small>
                                <span
                                    className="label label-warning float-right btn-actualizar-saldo"
                                    onClick={ this.actualizarSaldo }
                                >
                                        Actualizar
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Saldo pago de servicios</h5>
                            </div>
                            <div className="ibox-content">
                                <h1 className="no-margins">{ currencyFormat(saldoServicios.disponible) }</h1>
                                <small>Última actualización: { dateTimeFormat(saldoServicios.fechaActualizacion) }</small>
                                <span
                                    className="label label-warning float-right btn-actualizar-saldo"
                                    onClick={ this.actualizarSaldo }
                                >
                                        Actualizar
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Monto prestado</h5>
                                <h5 className="float-right">Total: { currencyFormat(totalPrestado) }</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row flex">
                                    <div className="col-md-6">
                                        <Select
                                            name="empresaMontoPrestado"
                                            value={empresas.filter(({value}) => value === empresaMontoPrestado)}
                                            onChange={this.handleChange}
                                            options={empresas}
                                            placeholder="Seleccionar empresa"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            name="anioMontoPrestado"
                                            value={anios.filter(({value}) => value === anioMontoPrestado)}
                                            onChange={this.handleChange}
                                            options={anios}
                                            placeholder="Seleccionar año"
                                        />
                                    </div>
                                </div>
                                <br/>
                                <ChartistGraph data={datosMontosPrestados} options={{seriesBarDistance: 10}} type={'Bar'} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Monto prestado emergente</h5>
                                <h5 className="float-right">Total: { currencyFormat(totalEmergente) }</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row flex">
                                    <div className="col-md-6">
                                        <Select
                                            name="empresaMontoEmergente"
                                            value={empresas.filter(({value}) => value === empresaMontoEmergente)}
                                            onChange={this.handleChange}
                                            options={empresas}
                                            placeholder="Seleccionar empresa"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            name="anioMontoEmergente"
                                            value={anios.filter(({value}) => value === anioMontoEmergente)}
                                            onChange={this.handleChange}
                                            options={anios}
                                            placeholder="Seleccionar año"
                                        />
                                    </div>
                                </div>
                                <br/>
                                <ChartistGraph data={datosMontosEmergentes} options={{seriesBarDistance: 10}} type={'Bar'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Empleados agregados por año</h5>
                                <h5 className="float-right">Total: { totalEmpleados }</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row flex">
                                    <div className="col-md-6">
                                        <Select
                                            name="anioEmpleados"
                                            value={anios.filter(({value}) => value === anioEmpleados)}
                                            onChange={this.handleChange}
                                            options={anios}
                                            placeholder="Seleccionar año"
                                        />
                                    </div>
                                </div>
                                <br/>
                                <ChartistGraph data={datosEmpleados} options={{seriesBarDistance: 10}} type={'Bar'} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ibox ">
                            <div className="ibox-title">
                                <h5>Monto pagado y por cobrar </h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Select
                                            name="empresaPagadoCobrar"
                                            value={empresas.filter(({value}) => value === empresaPagadoCobrar)}
                                            onChange={this.handleChange}
                                            options={empresas}
                                            placeholder="Seleccionar empresa"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            name="anioPagadoCobrar"
                                            value={anios.filter(({value}) => value === anioPagadoCobrar)}
                                            onChange={this.handleChange}
                                            options={anios}
                                            placeholder="Seleccionar año"
                                        />
                                    </div>
                                </div>
                                <br/>
                                <ChartistGraph data={datosPagodoPorCobrar} options={{seriesBarDistance: 10}} type={'Bar'} />
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .relative {
                        position: relative;
                    }
                    .flex {
                        display: flex;
                    }
                    .margin {
                        margin-left: 10px;
                    }
                    .btn-actualizar-saldo {
                        cursor: pointer;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Inicio
