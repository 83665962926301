import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerProductos(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/productos/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerProducto(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/productos/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarProducto(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/productos/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarProducto(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/productos/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function cotizarProducto(id, datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/productos/${id}/cotizar/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerPlazosFijos() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/productos/configuracion-plazos-fijos/');
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerProductos,
  obtenerProducto,
  agregarProducto,
  actualizarProducto,
  cotizarProducto,
  obtenerPlazosFijos
};
