import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { configuracionBanco, obtenerHistoriales, obtenerDetallesHistorialPagos, obtenerDetallesHistorialEmpleados } from '../../api/Domiciliacion'
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { currencyFormat } from '../../utils/filters'
import Modal from 'react-awesome-modal';

class HistorialDomiciliacion extends Component {
  state = {
    loading: false,
    titulo: 'Historial de respuestas',
    disabledSearch: false,
    objetos: [],
    objetos_detalles: [],
    bancosConfig: [],
    datos: {
      tipo_historial: '',
      fecha_inicio: moment().format("YYYY-MM-DD"),
      fecha_fin: moment().format("YYYY-MM-DD"),
      search: '',
      banco: ''
    },
    banco: '',
    search: '',
    id_detalles: 0,
    modal_detalles_pagos: false,
    modal_detalles_empleados: false,
    fechaInicio: moment().format("DD-MM-YYYY"),
    mensaje: 'La búsqueda no arroja ningún historial'
  };

  
  async componentDidMount() {
    try {
      const respBanco = await configuracionBanco()
      const bancosConfig = respBanco.data.map(obj => {
        return { label: obj.banco, value: obj.id };
      });
      bancosConfig.unshift({label: 'Todos', value: ''})
      this.setState({bancosConfig})
    } catch (error) {
      console.log(error);
    }
  }

  bancoChange = async (e) => {
    const banco = e.value;
    const {datos} = this.state;
    datos.banco = banco;
    this.setState({ datos });
  }

  fechasChange = date => {
    const fecha_inicio = date[0];
    const fecha_fin = date[1];
    const { datos } = this.state;
    const fechaInicio= moment(fecha_inicio).format('YYYY-MM-DD');
    const fechaFin = moment(fecha_fin).format('YYYY-MM-DD');
    datos.fecha_inicio = fechaInicio
    datos.fecha_fin = fechaFin
    this.setState({datos});
  }

  fechaRecibirChange = date => {
    const fecha_transferencia = date[0];
    const { datos_generacion } = this.state;
    const fechaTransferencia= moment(fecha_transferencia).format('YYYY-MM-DD');
    datos_generacion.fecha_transferencia = fechaTransferencia
    this.setState({datos_generacion, fecha_transferencia});
  }

  tipoHistorialChange = async (e) => {
    const tipo_historial = e.value
    const { datos } = this.state;
    datos.tipo_historial = e.value;
    this.setState({ datos, tipo_historial });
  }

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  obtenerHistoriales = async params => {
    try {
      this.setState({loading:true, disabledSearch: true})
      const req = await obtenerHistoriales(params);
      const objetos = req.data;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, loading:false, disabledSearch: false});
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { datos } = this.state;
    this.obtenerHistoriales(datos);
  };

  searchHandleChage = (e) => {
    const search = e.target.value;
    const { datos } = this.state;

    datos.search = search;
    this.setState({search, datos});
  }
  
  ver_detalles= async (id, tipo) => {
    try {
      this.setState({loading:true, disabledSearch: true})
      if(tipo === "generacion pagos" || tipo === "lectura pagos"){
        const req = await obtenerDetallesHistorialPagos(id);
        const objetos_detalles = req.data;
        // const totalRecords = req.data.count;
        this.setState({id_detalles: id, modal_detalles_pagos: true, objetos_detalles, loading:false, disabledSearch: false})
      }else{
        const req = await obtenerDetallesHistorialEmpleados(id);
        const objetos_detalles = req.data;
        // const totalRecords = req.data.count;
        this.setState({id_detalles: id, modal_detalles_empleados: true, objetos_detalles, loading:false, disabledSearch: false})
      }
    } catch (error) {
      console.log(error);
    }
    
  }


  render() {
    const {
      titulo,
      objetos,
      fechaInicio,
      search,
      bancosConfig,
      // fechaHoy,
      mensaje,
      loading,
      disabledSearch,
      modal_detalles_pagos,
      modal_detalles_empleados,
      objetos_detalles,
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-sm-3">
                        <Flatpickr
                          name='rango-fechas'
                          className="form-control date"
                          value={fechaInicio}
                          placeholder="Rango de Fechas"
                          options={{ dateFormat: 'd-m-Y', mode: 'range'}}
                          onChange={this.fechasChange}
                        />
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                        <Select
                            name="banco"
                            placeholder="Banco"
                            className="select"
                            onChange={this.bancoChange}
                            options={bancosConfig}
                            defaultValue={{label: 'Todos', value: ''}}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                          <Select
                            name="tipo_historial"
                            placeholder="Tipo de Historial"
                            className="select"
                            onChange={this.tipoHistorialChange}
                            options={[{label: 'Todos', value: ''},
                                      {label: 'Generación Pagos', value: 'generacion pagos'},
                                      {label: 'Lectura pagos', value: 'lectura pagos' },
                                      {label: 'Generación alta empleados', value: 'generacion alta empleados' },
                                      {label: 'Lectura alta empleados', value: 'lectura alta empleados' }
                                    ]}
                            defaultValue={{label: 'Todos', value: ''}}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="input-group">
                          <input
                            name="search"
                            value={search}
                            type="text"
                            className="form-control form-control-sm"
                            onChange={e => this.searchHandleChage(e)}
                          />
                          <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => this.onCleanClicked()}
                              >
                                <i className="fa fa-close fa-margin" />
                              </button>
                            </span>
                          <span className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                disabled={disabledSearch}
                                onClick={() => this.buscar()}
                              >
                                <i className="fa fa-search fa-margin" />
                                Buscar
                              </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    {
                        loading ?
                        <div className="spiner-example">
                          <div className="sk-spinner sk-spinner-wandering-cubes">
                            <div className="sk-cube1"></div>
                            <div className="sk-cube2"></div>
                          </div>
                        </div>
                      :
                        objetos.length > 0 ?
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Folio</th>
                                  <th>Fecha</th>
                                  <th>Banco</th>
                                  <th>Tipo</th>
                                  <th>Cantidad de Registros</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {objetos.map(obj => {
                                  return (
                                    <tr key={obj.id}>
                                      <td>{obj.id}</td>
                                      <td>{moment(obj.fecha_creacion).format("DD-MM-YYYY HH:MM:SS")}</td>
                                      <td>{obj.banco}</td>
                                      <td>{obj.tipo}</td>
                                      <td>{obj.cantidad_registros}</td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-lg btn-secondary"
                                          onClick={() => this.ver_detalles(obj.id, obj.tipo)}
                                        >
                                          Ver Detalles
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        :
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="text-center p-lg">
                                  <h2>{ mensaje }</h2>
                              </div>
                            </div>
                          </div>
                    }
                  </div>
                  <Modal visible={ modal_detalles_pagos } width="70%" height="55%" effect="fadeInUp">
                    <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                        <div className="modal-header">
                            <h4 className="modal-title">Detalle de Historial</h4>
                        </div>
                        <div className="modal-body" style={{'overflowY':'scroll'}}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table id="tabla_prestamos_sin_alta" className="table table-striped"  style={{'overflowY':'scroll'}}>
                                    <thead>
                                      <tr>
                                        <th>Folio</th>
                                        <th>Monto</th>
                                        <th>Motivo</th>
                                        <th>Pago préstamo</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {
                                      objetos_detalles.map(obj => {
                                        return (
                                          <tr key={obj.id+"_detalle"}>
                                            <td>{obj.folio}</td>
                                            <td>{currencyFormat(obj.monto)}</td>
                                            <td>{obj.motivo}</td>
                                            <td>{obj.pago_prestamo}</td>
                                          </tr>
                                        );
                                      })
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-white"
                              onClick={() => this.setState({modal_detalles_pagos: false}) }
                            >
                              Cancelar
                            </button>
                        </div>
                    </div>
                  </Modal>
                  <Modal visible={ modal_detalles_empleados } width="70%" height="55%" effect="fadeInUp">
                    <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                        <div className="modal-header">
                            <h4 className="modal-title">Detalle de Historial</h4>
                        </div>
                        <div className="modal-body" style={{'overflowY':'scroll'}}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table id="tabla_prestamos_sin_alta" className="table table-striped"  style={{'overflowY':'scroll'}}>
                                    <thead>
                                      <tr>
                                        <th>Folio</th>
                                        <th>Nombre</th>
                                        <th>Motivo</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {
                                      objetos_detalles.map(obj => {
                                        return (
                                          <tr key={obj.id+"_detalle"}>
                                            <td>{obj.folio}</td>
                                            <td>{obj.nombre}</td>
                                            <td>{obj.motivo}</td>
                                          </tr>
                                        );
                                      })
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-white"
                              onClick={() => this.setState({modal_detalles_empleados: false}) }
                            >
                              Cancelar
                            </button>
                        </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default HistorialDomiciliacion;
