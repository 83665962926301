import { ConstructorAPIAsync } from './API'
import { callError } from './Error';

async function obtenerCuentasBancarias (parametros = '') {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/admin/cuentas-bancarias/', { params: parametros })
  } catch (err) {
    callError(err);
  }
}

async function obtenerCuentaBancaria (id) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/admin/cuentas-bancarias/${id}/`)
  } catch (err) {
    callError(err);
  }
}

async function agregarCuentaBancaria (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/admin/cuentas-bancarias/', datos)
  } catch (err) {
    callError(err);
  }
}

async function actualizarCuentaBancaria (datos, id) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.put(`/admin/cuentas-bancarias/${id}/`, datos)
  } catch (err) {
    callError(err);
  }
}

async function eliminarCuentaBancaria (id) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.delete(`/admin/cuentas-bancarias/${id}/`)
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerCuentasBancarias,
  obtenerCuentaBancaria,
  agregarCuentaBancaria,
  actualizarCuentaBancaria,
  eliminarCuentaBancaria
}
