import React, { useEffect, useState } from 'react';
import { getPLDData } from '../../../api/Solicitudes';
import { dateFormat } from '../../../utils/filters';


const PLD = ({idSolicitud}) => {

    const [data, setData] = useState({});

    const getData = async () => {
        try {
            const response = await getPLDData(idSolicitud);
            setData(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        if (idSolicitud !== undefined) {
            getData();
        }        
    }, [idSolicitud]);

    return (
        <div className="panel panel-default">
            <style jsx="true">{`
                .capitalize {
                    text-transform: capitalize;
                }
                .green-square {
                    display: inline-block;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background-color: #00a65a;
                }
                .red-square {
                    background-color: #dd4b39;
                }
            `}
            </style>
            <div className="panel-heading">
                PLD
            </div>
            <div className="panel-body">
            { Object.keys(data).length === 0
                ? 'No hay datos'
                : <div className="row">
                    <div className="col-md-6 m-t">
                        <div className="col-sm-12 col-md-12 sol">
                            <span>Nombre consultado</span>
                            <span>{data.nombre_consultar}</span>
                        </div>

                        <div className="col-sm-12 col-md-12 sol">
                            <span>Fecha de consulta</span>
                            <span>{dateFormat(data.fecha_consulta)} - {data.fecha_consulta && data.fecha_consulta.split('T')[1].split('.')[0]}</span>
                        </div>

                        <div className="col-sm-12 col-md-12 sol">
                            <span>Estatus</span>
                            <span>

                                { data.estatus === 'hit'
                                    ? <><span className="text-capitalize text-danger">{data.estatus}</span> <i className="red-square"></i></>
                                    : <><span className="text-capitalize text-primary">{data.estatus}</span> <i className="green-square"></i></>
                                } 
                            </span>
                        </div>

                        <div className="col-sm-12 col-md-12 sol">
                            <span>Proveedor</span>
                            <span className="capitalize">{data.proveedor}</span>
                        </div>
                        { data.link && data.estatus === 'hit' &&
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Link</span>
                                <span className="capitalize"><a href={data.link} target="_blank">{data.link}</a></span>
                            </div>
                        }
                    </div>

                    <div className="col-md-6 m-t">
                        { data.listas
                            ? <>
                                <div className="col-sm-12 col-md-12 sol"><h3>Listas en las que aparece</h3></div>
                                <div className="col-sm-12 col-md-12 sol">
                                ●{ data.listas.replace(/\s/g, '●').replace(/,/g, ' ') }
                                </div>
                            </>
                            : <div className="col-sm-12 col-md-12 sol">
                                <span>
                                    Listas en las que aparece
                                </span>
                                <span>
                                    No se encontraron listas
                                </span>
                            </div>
                        }
                        
                    </div>
                </div>
            }
                
            </div>
        </div>
    );
}
 
export default PLD;