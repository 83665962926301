import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function tiemposPromediosPorEtapa(params = {}) {
  const API = await ConstructorAPIAsync(180000);
  try {
    return await API.get('/admin/dashboard/tracking/', { params });
  } catch (err) {
    callError(err);
  }
}

export {
    tiemposPromediosPorEtapa
}