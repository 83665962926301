import React, { Component } from 'react'
import Swal from 'sweetalert2'
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    actualizarUsuario,
    agregarUsuario,
    obtenerUsuario,
    cambiarContrasena
} from '../../api/Usuarios'
import {
    obtenerPermisos,
    obtenerPermisosUsuario,
    actualizarPermisosUsuario
} from '../../api/Permisos'
import { obtenerGrupos } from '../../api/Grupos'
// import { verificarPermiso } from '../../utils/auth'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        pestanaSeleccionada: 1,
        data: {},
        errors: {},
        grupos: [],
        permisos: [],
        permisosUsuario: [],
        gruposUsuario: [],
        datosContrasenia: {
            'nueva_contrasenia': '',
            'conf_nueva_contrasenia': ''
        },
        erroresContrasenia: {
            nueva_contrasenia: [],
            conf_nueva_contrasenia: []
        },
        ocultarTabs: true
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = id ? 'Editar usuario' : 'Agregar usuario'
        const accion = id ? 'Editar' : 'Agregar'
        this.setState({ id, titulo, accion })
    }

    async componentDidMount() {
        const { id } = this.state

        let resp = await obtenerPermisos()
        const permisos = resp.data
        let gruposPermisos = permisos.map((permission) => {
            return permission.content_type
        })
        gruposPermisos = [...new Set(gruposPermisos)]

        const grupos = []
        gruposPermisos.forEach((obj) => {
            const grupo = {
                name: obj,
                permissions: permisos.filter(p => p.content_type === obj)
            }
            grupos.push(grupo)
        })
        this.setState({ grupos })

        try {
            const params = { page_size: 1000 };
            resp = await obtenerGrupos(params)
            const gruposUsuario = resp.data.results.map(obj =>{
                return { label: obj.nombre, value: obj.id }
            })

            this.setState({ gruposUsuario })
        } catch (error) {
            console.log(error)
        }

        if (id){
            try {
                const ocultarTabs = false
                resp = await obtenerUsuario(id)
                const { data } = resp
                resp = await obtenerPermisosUsuario(id)
                const pGrupos = resp.data.permisos_grupos
                const pUsuario = resp.data.permisos_usuario
                let permisosUsuario = pGrupos.concat(pUsuario)
                permisosUsuario = [...new Set(permisosUsuario)]

                this.setState({ data, permisosUsuario, ocultarTabs })
            } catch (error) {
                console.log(error)
            }
        } else {
            this.setState({ data: { 'activo': true}})
        }
    }

    mostrarToast = (mensaje, tipo = 'success') => {
        Swal.fire({
            type: tipo,
            text: mensaje,
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
        })
    }

    onChangePermission = id => {
        const { permisosUsuario } = this.state
        const index = permisosUsuario.indexOf(id)
        if (index === -1) { permisosUsuario.push(id) } else { permisosUsuario.splice(index, 1) }
        this.setState({ permisosUsuario }, () => console.log(permisosUsuario))
    }

    guardarPermisos = async () => {
        const { id, permisosUsuario } = this.state
        const datos = { permisos_usuario: permisosUsuario }
        try {
            /* const resp =  */await actualizarPermisosUsuario(datos, id)
            this.mostrarToast('Permisos guardados')
        } catch (error) {
            console.log(error)
        }
    }

    cambiarContrasenia = async() => {
        const { id } = this.state
        let { datosContrasenia, erroresContrasenia } = this.state
        const nuevaContrasenia = datosContrasenia.nueva_contrasenia
        const confNuevaContrasenia = datosContrasenia.conf_nueva_contrasenia
        erroresContrasenia.nueva_contrasenia = []
        erroresContrasenia.conf_nueva_contrasenia = []
        if (nuevaContrasenia === ''){
            erroresContrasenia.nueva_contrasenia = ['Este campo es requerido']
        }
        if (confNuevaContrasenia === ''){
            erroresContrasenia.conf_nueva_contrasenia = ['Este campo es requerido']
        }
        this.setState({ erroresContrasenia })
        if (nuevaContrasenia !== confNuevaContrasenia){
            erroresContrasenia = {
                nueva_contrasenia: ['Las contraseñas no coinciden'],
                conf_nueva_contrasenia: []
            }
            this.setState({ erroresContrasenia })
        }
        if (erroresContrasenia.nueva_contrasenia.length === 0 && erroresContrasenia.conf_nueva_contrasenia.length === 0){
            const password = datosContrasenia.nueva_contrasenia
            const datos = { password }
            try {
                await cambiarContrasena(datos, id)
                this.mostrarToast('Contraseña cambiada')
                datosContrasenia = {
                    'nueva_contrasenia': '',
                    'conf_nueva_contrasenia': ''
                }
                this.setState({ datosContrasenia })
            } catch (error) {
                const errors = error.data
                this.setState({ errors })
            }
        }
    }

    handleChange = async (field, e) => {
        const { datosContrasenia } = this.state
        const {
          target: { name,/*  files, */ value }
        } = e
        datosContrasenia[name] = value
    
        this.setState({ datosContrasenia })
    }

    seleccionarPestana(pestanaSeleccionada){
        this.setState({ pestanaSeleccionada })
    }

    buscarPermiso(id){
        const { permisosUsuario } = this.state
        const indice = permisosUsuario.findIndex(o => o === id)
        return indice !== -1
    }

    render () {
        let { id, pestanaSeleccionada, titulo, accion, grupos/* , permisosUsuario  */} = this.state

        const guia = [
            { nombre: 'Usuarios', url: '/usuarios', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]
        const { gruposUsuario, datosContrasenia, erroresContrasenia, ocultarTabs } = this.state


        let form = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    if (id){
                        try {
                            await actualizarUsuario(values, id)
                            this.mostrarToast('Usuario guardado')
                            this.setState({ errors: []})
                        } catch (error) {
                            const errors = error.data
                            this.setState({ errors })
                        }
                    } else {
                        try {
                            await agregarUsuario(values)
                            this.mostrarToast('Usuario guardado')
                        } catch (error) {
                            const errors = error.data
                            this.setState({ errors })
                        }
                    }
                }
            },
            fields:[{
                name:'nombre',
                label:'Nombre',
                type:'text',
                helpText:''
            },{
                name:'apellido_paterno',
                label:'Apellido Materno',
                type:'text',
                helpText:''
            },{
                name:'apellido_materno',
                label:'Apellido paterno',
                type:'text',
                helpText:''
            },{
                name:'email',
                label:'Correo electrónico',
                type:'text',
                helpText:''
            },{
                name:'password',
                label:'Contraseña',
                type:'password',
                helpText:''
            },{
                name: 'grupos',
                label: 'Grupos',
                type: 'select-multiple',
                options: gruposUsuario,
                helpText: ''
            },{
                name:'activo',
                label:'Activo',
                type:'checkbox',
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }

        if (this.props.match.params.id !== undefined){
            form.fields.splice(4, 1)
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <ul className="nav nav-tabs" role="tablist">
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 1 && 'active show'}`} onClick={(e) => this.seleccionarPestana(1)} >General</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 2 && 'active show'}`} onClick={(e) => this.seleccionarPestana(2)} >Permisos</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 3 && 'active show'}`} onClick={(e) => this.seleccionarPestana(3)} >Cambiar contraseña</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div role="tabpanel" className={pestanaSeleccionada === 1?'tab-pane active':'tab-pane'}>
                                    <div className="panel-body">
                                        <Formulario
                                            buttonLabel={ form.button.label }
                                            onSubmit={ form.button.onSubmit }
                                            fields={ form.fields }
                                            data={ form.data }
                                            errors={ form.errors }
                                        />
                                    </div>
                                </div>
                                <div role="tabpanel" className={pestanaSeleccionada === 2?'tab-pane active':'tab-pane'}>
                                    <div className="panel-body">
                                        <div className="row">
                                            {grupos.map((grupo, indice) => {
                                                return (
                                                    <div className="col-lg-4" key={ indice }>
                                                        <div className="ibox">
                                                            <div>
                                                                <h2>{ grupo.name }</h2>
                                                                <ul className="todo-list m-t small-list">
                                                                    {grupo.permissions.map(permiso => {
                                                                        return (
                                                                            <li key={ permiso.id }>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={ this.buscarPermiso(permiso.id) }
                                                                                    onChange={ () => this.onChangePermission(permiso.id) }
                                                                                />
                                                                                <span className="m-l-xs">{ permiso.name }</span>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm btns-space"
                                                onClick={() => { this.guardarPermisos()} }
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className={pestanaSeleccionada === 3?'tab-pane active':'tab-pane'}>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${erroresContrasenia.nueva_contrasenia.length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Nueva contraseña</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            value={ datosContrasenia.nueva_contrasenia }
                                                            name="nueva_contrasenia"
                                                            onChange={e => this.handleChange('nueva_contrasenia', e)}
                                                            className="form-control"
                                                        />
                                                        { erroresContrasenia.nueva_contrasenia.map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${erroresContrasenia.conf_nueva_contrasenia.length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Confirmar contraseña</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            value={ datosContrasenia.conf_nueva_contrasenia}
                                                            name="conf_nueva_contrasenia"
                                                            onChange={e => this.handleChange('conf_nueva_contrasenia', e)}
                                                            className="form-control"
                                                        />
                                                        { erroresContrasenia.conf_nueva_contrasenia.map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-4 col-sm-offset-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={ () => this.cambiarContrasenia() }
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Autenticado>
        )
    }
}

export default Form
