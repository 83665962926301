import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function ConsultarFechaExpiracionBuroCredito(data) {
    const API = await ConstructorAPIAsync();
    try {
      return await API.post('/integraciones/fecha-expiracion-buro-credito/', data);
    } catch (err) {
      callError(err);
    }
}
  
export {
    ConsultarFechaExpiracionBuroCredito
};