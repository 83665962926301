import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerSolicitudes(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/solicitudes/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerSolicitudesTotales(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/solicitudes/totales/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function exportarSolicitudes(params={}) {
  params.export = true;
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    const resp = await API.get('/admin/solicitudes/', { params });
    delete params.export;
    return resp;
  } catch (err) {
    delete params.export;
    callError(err);
  }
}

async function exportarSolicitud(id) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    const resp = await API.get(`/admin/solicitudes/${id}/exportar`);
    return resp;
  } catch (err) {
    callError(err);
  }
}

async function obtenerSolicitud(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function actualizarSolicitud(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/solicitudes/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function solicitudPartialUpdate(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.patch(`/admin/solicitudes/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerOrdenesDispersionSolicitud(id){
  const API = await ConstructorAPIAsync();
  try{
    return await API.get(`/admin/solicitudes/${id}/ordenes-dispersion/`);
  }catch(err){
    callError(err);
  }
}

async function agregarOrdenDispersionSolicitud(datos, id) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.post(`/admin/solicitudes/${id}/ordenes-dispersion/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarOrdenDispersionSolicitud(datos, id, idOrdenDisp) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.patch(`/admin/solicitudes/${id}/ordenes-dispersion/${idOrdenDisp}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarOrdenDispersionSolicitud(id, idOrdenDisp) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(`/admin/solicitudes/${id}/ordenes-dispersion/${idOrdenDisp}/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerComprobantesSolicitud(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/comprobantes/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarComprobanteSolicitud(datos, id) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.post(`/admin/solicitudes/${id}/comprobantes/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarComprobanteSolicitud(
  datos,
  solicitudId,
  comprobanteId
) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.put(
      `/admin/solicitudes/${solicitudId}/comprobantes/${comprobanteId}/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function eliminarComprobantesSolicitud(solicitudId, comprobanteId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/admin/solicitudes/${solicitudId}/comprobantes/${comprobanteId}/`
    );
  } catch (err) {
    callError(err);
  }
}

async function obtenerMensajeSolicitud(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/comentarios/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarMensajeSolicitud(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/comentarios/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarMensajeSolicitud(solicitudId, mensajeId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/admin/solicitudes/${solicitudId}/comentarios/${mensajeId}/`
    );
  } catch (err) {
    callError(err);
  }
}

async function obtenerDocumentosSolicitud(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/documentos/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarDocumentosSolicitud(datos, id) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.post(`/admin/solicitudes/${id}/documentos/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarDocumentosSolicitud(solicitudId, documentoId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/admin/solicitudes/${solicitudId}/documentos/${documentoId}/`
    );
  } catch (err) {
    callError(err);
  }
}

async function agregarDocumentosFirmadosSolicitud(
  datos,
  solicitudId,
  documentoId
) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.post(
      `/admin/solicitudes/${solicitudId}/documentos/${documentoId}/firmados/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function eliminarDocumentosFirmadosSolicitud(
  solicitudId,
  documentoId,
  docFirmadoId
) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/admin/solicitudes/${solicitudId}/documentos/${documentoId}/firmados/${docFirmadoId}`
    );
  } catch (err) {
    callError(err);
  }
}

async function obtenerSeguimiento(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/seguimiento/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerReferenciasSolicitud(id) {
  const API = await ConstructorAPIAsync();

  try {
    return await API.get(`/admin/solicitudes/${id}/referencias/`);
  } catch (err) {
    callError(err);
  }
}

async function cotizarSolicitud(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/cotizar-solicitud/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function rechazarSolicitud(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/solicitudes/${id}/rechazar/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function cancelarSolicitud(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/solicitudes/${id}/cancelar/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarEstatusSolicitud(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/solicitudes/${id}/seguimiento/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function generarPrestamo(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/generar-prestamo/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarReferenciaSolicitud(datos, solicitud_id, referencia_id){
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/solicitudes/${solicitud_id}/referencias/${referencia_id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function agregarReferenciaSolicitud(datos, solicitud_id){
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${solicitud_id}/referencias/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function solicitudGenerarOrdenVenta(solicitud_id, datos){
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${solicitud_id}/generar-orden-venta/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function solicitudObtenerOrdenVenta(solicitud_id){
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${solicitud_id}/obtener-orden-venta/`);
  } catch (err) {
    callError(err);
  }
}

async function crearVinculoRenovacion(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/vinculo-renovacion/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarVinculoRenovacion(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/renovacion/${id}/eliminar-renovacion/`);
  } catch (err) {
    callError(err);
  }
}

async function generarCartaBuro(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/generar-carta-buro/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function enviarCartaBuroFirmamex(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/enviar-carta-buro-firmamex/`);
  } catch (err) {
    callError(err);
  }
}

async function reactivarSolicitudCredito(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/reactivar/`);
  } catch (err) {
    return callError(err);
  }
}

async function consultarBuroCredito(id, data) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post(`/buro-credito/solicitudes/${id}/consultar-buro/`, data);
  } catch (err) {
    callError(err);
  }
}

async function getJsonBuroCredito(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/buro-credito/empleados/${id}/json-buro/`, data);
  } catch (err) {
    callError(err);
  }
}

async function saveBuroCredito(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/buro-credito/solicitudes/${id}/save-buro/`, data);
  } catch (err) {
    callError(err);
  }
}

async function getCuestionarioCompliance(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/cuestionario-compliance/`)
  } catch (err) {
    callError(err)
  }
}

async function getNotasCredito(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/notas-de-credito/`)
  } catch (err) {
    callError(err)
  }
}

async function updateNotasCredito(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/notas-de-credito/`, data)
  } catch (err) {
    callError(err)
  }
}

async function enviarContratoParaFirma(id_solicitud, id_contrato, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`admin/solicitudes/${id_solicitud}/documentos/${id_contrato}/enviar-contrato/`, data);
  } catch (err) {
    callError(err);
  }
}

async function reenviarContratoParaFirma(id_solicitud, id_contrato) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`admin/solicitudes/${id_solicitud}/documentos/${id_contrato}/reenviar-contrato/`);
  } catch (err) {
    callError(err);
  }
}

async function cancelarContratoParaFirma(id_solicitud, id_contrato, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`admin/solicitudes/${id_solicitud}/documentos/${id_contrato}/cancelar-contrato/`, data);
  } catch (err) {
    callError(err);
  }
}

async function getCuentasCDD(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/api/v1/cdd/lista/${id}`);
  } catch (err) {
    callError(err);
  }
}

async function getResumenCuentasCDD(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/api/v1/cdd/resumen/${id}`);
  } catch (err) {
    callError(err);
  }
}

async function updateCuentasCDD(id, uuid, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/api/v1/cdd/${id}/actualizar/${uuid}`, data);
  } catch (err) {
    callError(err);
  }
}

async function aprobarCuentaCDD(id, uuid){
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/api/v1/cdd/${id}/aprobar/${uuid}`);
  } catch (err) {
    callError(err);
  }
}

async function removerCuentaCDD(id, uuid){
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/api/v1/cdd/${id}/remover/${uuid}`);
  } catch (err) {
    callError(err);
  }
}

async function solicitudvalidarInformacionBancaria(id_solicitud) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`admin/solicitudes/${id_solicitud}/verificar-informacion-bancaria/`);
  } catch (err) {
    callError(err);
  }
}

async function enviarOrdenDispersionSolicitud(id, idOrdenDisp) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${idOrdenDisp}/enviar-orden-dispersion/`);
  } catch (err) {
    callError(err);
  }
}

async function analizarSolicitudIA(id){
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.post(`/admin/solicitudes/${id}/analizar-ia/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerSolicitudAnalizarIA(id){
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/analizar-ia/`);
  } catch (err) {
    callError(err);
  }
}

async function getValidacionesRescateSolicitud(id){
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/validaciones-rescate/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarValidacionRescateSolicitud(id, data){
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/validaciones-rescate/`, data);
  } catch (err) {
    callError(err);
  }
}

async function getAnalistas() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/usuarios/analistas/');
  } catch (err) {
    callError(err);
  }
}

async function asignarAnalista(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/admin/solicitudes/${id}/asignar-analista/`, data);
  } catch (err) {
    callError(err);
  }
}

async function getExcepcionesRiesgo() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/solicitudes/excepciones-riesgo/');
  } catch (err) {
    callError(err);
  }
}

async function getOcrIneData(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/informacion_ocr_ine/`);
  } catch (err) {
    callError(err);
  }
}

async function getOcrPoaData(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/informacion_ocr_poa/`);
  } catch (err) {
    callError(err);
  }
}

async function getPLDData(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/solicitudes/${id}/informacion_pld/`);
  } catch (err) {
    callError(err);
  }
}

async function invalidarINE(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/solicitudes/${id}/invalidar_ine/`);
  } catch (err) {
    return callError(err);
  }
}

async function enviarDatosContratoWhirlpool(id, data) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`admin/solicitudes/${id}/enviar-datos-contrato-whirlpool/`, data);
  } catch (err) {
    return callError(err);
  }
}

async function getDatosContratoWhirlpool(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`admin/solicitudes/${id}/datos-contrato-whirlpool/`);
  } catch (err) {
    callError(err);
  }
}

async function getDatosUnificacion(id_empleado) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empleados/unificacion/${id_empleado}/`);
  } catch (err) {
    callError(err);
  }
}

async function UnificarEmpleado(id_empleado, data) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post(`/empleados/unificacion/${id_empleado}/`, data);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerSolicitudes,
  obtenerSolicitudesTotales,
  obtenerSolicitud,
  obtenerOrdenesDispersionSolicitud,
  agregarOrdenDispersionSolicitud,
  actualizarOrdenDispersionSolicitud,
  eliminarOrdenDispersionSolicitud,
  obtenerComprobantesSolicitud,
  agregarComprobanteSolicitud,
  actualizarComprobanteSolicitud,
  eliminarComprobantesSolicitud,
  obtenerMensajeSolicitud,
  agregarMensajeSolicitud,
  eliminarMensajeSolicitud,
  obtenerDocumentosSolicitud,
  agregarDocumentosSolicitud,
  eliminarDocumentosSolicitud,
  agregarDocumentosFirmadosSolicitud,
  eliminarDocumentosFirmadosSolicitud,
  obtenerSeguimiento,
  actualizarSolicitud,
  cotizarSolicitud,
  rechazarSolicitud,
  cancelarSolicitud,
  actualizarEstatusSolicitud,
  generarPrestamo,
  obtenerReferenciasSolicitud,
  actualizarReferenciaSolicitud,
  agregarReferenciaSolicitud,
  exportarSolicitudes,
  exportarSolicitud,
  solicitudPartialUpdate,
  solicitudGenerarOrdenVenta,
  solicitudObtenerOrdenVenta,
  crearVinculoRenovacion,
  eliminarVinculoRenovacion,
  generarCartaBuro,
  enviarCartaBuroFirmamex,
  reactivarSolicitudCredito,
  consultarBuroCredito,
  getJsonBuroCredito,
  saveBuroCredito,
  getCuestionarioCompliance,
  getNotasCredito,
  updateNotasCredito,
  enviarContratoParaFirma,
  getCuentasCDD,
  updateCuentasCDD,
  aprobarCuentaCDD,
  removerCuentaCDD,
  getResumenCuentasCDD,
  solicitudvalidarInformacionBancaria,
  enviarOrdenDispersionSolicitud,
  reenviarContratoParaFirma,
  cancelarContratoParaFirma,
  analizarSolicitudIA,
  obtenerSolicitudAnalizarIA,
  getValidacionesRescateSolicitud,
  agregarValidacionRescateSolicitud,
  getAnalistas,
  asignarAnalista,
  getExcepcionesRiesgo,
  getOcrIneData,
  getOcrPoaData,
  getPLDData,
  invalidarINE,
  getDatosContratoWhirlpool,
  enviarDatosContratoWhirlpool,
  getDatosUnificacion,
  UnificarEmpleado

};
