import React, { Component } from 'react'
import Swal from 'sweetalert2'
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    actualizarEmpresa,
    agregarEmpresa,
    obtenerEmpresa,
    guardarDomicilioEmpresa,
    agregarContactoEmpresa,
    editarContactoEmpresa,
    eliminarContactoEmpresa,
    guardarLogo,
    obtenerConfigEmergenteEmpresa,
    actualizarConfigEmergenteEmpresa
} from '../../api/Empresas'
import { obtenerGruposEmpresas } from '../../api/GrupoEmpresas'
import { obtenerEstados, obtenerMunicipios } from '../../api/Sistema'
import Modal from 'react-awesome-modal'
import { verificarPermiso } from '../../utils/auth'
import { quitarNulos } from '../../utils/functions'
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts'


class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        pestanaSeleccionada: 1,
        data: {},
        errors: [],
        domicilio: {},
        erroresDomicilio:[],
        contacto: {},
        erroresContacto: [],
        grupos: [],
        estados: [],
        municipios: [],
        ocultarTabs: true,
        mostratFormulario: false,
        mostrarConfirmacion: false,
        contactos: [],
        contactoId: null,
        indiceContacto: null,
        profileImagePreview: null,
        perfil: "http://www.14riverside.com/wp-content/uploads/2015/12/placeholder-company.png",
        configEmergente: {},
        erroresConfigEmergente: {
            recargas_activo: [],
            pago_servicios_activo: [],
            disposiciones_activo: [],
            personalizado: [],
            saldo_dinamico: [],
            frecuencia_cobro: [],
            num_amortizaciones: [],
            saldo_preaprobado: [],
            tasa_interes: [],
        },
        des: null
    }

    componentWillMount() {
        const id = this.props.match.params.id
        this.setTitulo(id)
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            let res = await obtenerGruposEmpresas()
            let datos = res.data
            const grupos = datos.map(obj =>{
                return { label: obj.nombre, value: obj.id }
            })
            grupos.unshift({
                label:'Seleccionar...', value:'' 
            })

            res = await obtenerEstados()
            datos = res.data
            const estados = datos.map(obj =>{
                return { label: obj.nombre, value: obj.id }
            })
            estados.unshift({
                label:'Seleccionar...', value:'' 
            })

            this.setState({ grupos, estados })
        } catch (error) {
            console.log(error)
        }

        if (id){
            try {
                let resp = await obtenerEmpresa(id)
                const { data } = resp
                const profileImagePreview = data.logotipo
                const domicilio = data.domicilio || {}
                let { contactos } = data
                contactos = quitarNulos(contactos)
                const ocultarTabs = false

                resp = await obtenerConfigEmergenteEmpresa(id)
                const configEmergente =  resp.data
                let des = true
                if (!configEmergente.personalizado){
                    des = false
                }

                let municipios = []
                if (domicilio.estado){
                    const res = await obtenerMunicipios(domicilio.estado)
                    const datos = res.data
                    municipios = datos.map(obj =>{
                        return { label: obj.nombre, value: obj.id }
                    })
                    municipios.unshift({
                        label:'Seleccionar...', value:'' 
                    })
                }

                this.setState({
                    data,
                    domicilio,
                    contactos,
                    ocultarTabs,
                    municipios,
                    profileImagePreview,
                    configEmergente,
                    des
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    setTitulo = (id) => {
        const titulo = id ? 'Editar empresa' : 'Agregar empresa'
        const accion = id ? 'Editar' : 'Agregar'
        this.setState({ id, titulo, accion })
    }

    mostrarToast = (mensaje, tipo = 'success') => {
        Swal.fire({
            type: tipo,
            text: mensaje,
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
        })
    }

    eliminarContacto = async() => {
        try {
            const { id, contactoId, contactos, indiceContacto } = this.state
            await eliminarContactoEmpresa(id, contactoId)
            contactos.splice(indiceContacto, 1)
            this.setState({ contactos, mostrarConfirmacion: false })
            this.mostrarToast('Contacto eliminado')
        } catch (error) {
            console.log(error)
        }
    }

    guardarContacto = async() => {
        const values = this.state.contacto
        const { id, contactoId } = this.state
        if (values.id){
            try {
                await editarContactoEmpresa(values, id, contactoId)
                this.setState({ erroresContacto: [] })
                this.cerrarModal()
                this.mostrarToast('Contacto guardado')
            } catch (error) {
                this.setState({ erroresContacto: error.data })
            }
        } else {
            try {
                const resp = await agregarContactoEmpresa(values, id)
                const contacto = resp.data
                const { contactos } = this.state
                contactos.push(contacto)
                this.setState({ contacto: {}, contactos, erroresContacto: [] })
                this.cerrarModal()
                this.mostrarToast('Contacto guardado')
            } catch (error) {
                this.setState({ erroresContacto: error.data })
            }
        }
    }

    editarContacto = (indice) => {
        const { contactos } = this.state
        const contacto = contactos[indice]
        const indiceContacto = indice
        const contactoId = contacto.id
        this.setState({ contacto, indiceContacto, contactoId })
        this.mostrarFormulario('Editar contacto')
    }

    mostrarConfirmacion(contactoId, indiceContacto) {
        this.setState({ mostrarConfirmacion : true, contactoId, indiceContacto })
    }

    mostrarFormulario(tituloFrm) {
        this.setState({ mostratFormulario : true, tituloFrm })
    }

    cerrarModal() {
        this.setState({
            mostratFormulario : false,
            mostrarConfirmacion : false,
            erroresContacto: [],
            contacto: {}
        })
    }

    seleccionarPestana(pestanaSeleccionada) {
        this.setState({ pestanaSeleccionada })
    }

    submitEmpresa = async (event, values) => {

        const {id, ocultarTabs} = this.state

        if (id){
            try {
                delete values.logotipo
                await actualizarEmpresa(values, id)
                this.mostrarToast('Empresa guardada')
                this.setState({ errors: [] })
            } catch (error) {
                const errors = error.data
                this.setState({ errors })
            }
        } else {
            try {
                if (ocultarTabs){
                    delete values.logotipo
                    const resp = await agregarEmpresa(values)
                    const empresa = resp.data
                    const id = empresa.id
                    this.setTitulo(id)
                    this.props.history.push(`/empresas/editar/${id}`)
                    this.setState({ id, ocultarTabs: false })
                    this.mostrarToast('Empresa guardada')
                    this.setState({ errors: [] })
                }
                else{
                    const { data, id } = this.state
                    delete data.logotipo
                    await actualizarEmpresa(data, id)
                    this.mostrarToast('Empresa guardada')
                    this.setState({ errors: [] })
                }
            } catch (error) {
                const errors = error.data
                this.setState({ errors })
            }
        }
    }

    submitDomicilio = async (event, values) => {
        const {id} = this.state
        try {
            await guardarDomicilioEmpresa(values, id)
            this.setState({ erroresDomicilio: [] })
            this.mostrarToast('Domicilio guardado')
        } catch (error) {
            this.setState({ erroresDomicilio: error.data })
        }
    }

    submitContacto = async (event, values) => {

        const { id, contactoId } = this.state

        if (values.id){
            try {
                await editarContactoEmpresa(values, id, contactoId)
                this.setState({ erroresContacto: [] })
            } catch (error) {
                this.setState({ erroresContacto: error.response.data })
            }
        } else {
            try {
                const resp = await agregarContactoEmpresa(values, id)
                const contacto = resp.data
                let { contactos } = this.state
                contactos.push(contacto)
                this.setState({ contacto: {}, contactos, erroresContacto: [] })
            } catch (error) {
                this.setState({ erroresContacto: error.response.data })
            }
        }
    }

    handleLogoChange = async e => {
        const { id } = this.state
        e.preventDefault()
        const {
            target: { files }
        } = e;
        const file = files[0];
        if (file) {
            cargando();
            const formData = new FormData();
            formData.append('logotipo', file);
            try {
                await guardarLogo(formData, id);
                cerrarAlertas();
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({
                        profileImagePreview: reader.result
                    });
                };
                reader.readAsDataURL(file);
                mostrarToast('Logo guardado');
            } catch (error) {
                cerrarAlertas();
                mostrarToast('No fue posible actualizar el logo.', 'error');
            }
        }
    };

    handleChange = async (type, e) => {
        const { configEmergente } = this.state
        let { des } = this.state
        const {
            target: { name, checked, value }
        } = e
        if (type === 'checkbox') {
            configEmergente[name] = checked
            if (name === 'personalizado'){
                if(checked){
                    des = true
                } else {
                    des = false
                }
            }
        } else {
            configEmergente[name] = value
        }
    
        this.setState({ configEmergente, des })
    }

    guardarConfigEmergente = async () => {
        const { configEmergente, erroresConfigEmergente, id } = this.state
        try {
            await actualizarConfigEmergenteEmpresa(configEmergente, id)
            this.setState({ 
                erroresConfigEmergente: {
                    personalizado: [],
                    saldo_dinamico: [],
                    frecuencia_cobro: [],
                    num_amortizaciones: [],
                    saldo_preaprobado: [],
                    tasa_interes: [],
                    recargas_activo: [],
                    pago_servicios_activo: [],
                    disposiciones_activo: [],
                }
            })
            this.mostrarToast('Configuración guardada')
        } catch (error) {
            const errorsKeys = Object.keys(error.data)
            errorsKeys.forEach(e => {
                erroresConfigEmergente[e] = error.data[e]
            });
            
            this.setState({ erroresConfigEmergente })
        }
    }

    handleChangeModuloEmergente = (e) => {
        const { configEmergente } = this.state;
        configEmergente[e.target.name] = e.target.checked;
        this.setState({configEmergente});
    }

    handleChangeDomicilio = async (e) => {
        const { domicilio } = this.state;
        const {target: {name, value}} = e;

        domicilio[name] = value;

        if (name === 'estado') {
            const respMunicipios = await obtenerMunicipios(value);
            const municipios = [{label: '--Selecciona--', value: ''}];
            respMunicipios.data.forEach(m => {
                municipios.push({label: m.nombre, value: m.id});
            });
            domicilio.municipio = '';
            this.setState({ domicilio, municipios })
        } else {
            this.setState({ domicilio })
        }

    }

    submitDomicilio = async() => {

        const {domicilio, id} = this.state;

        try {
            /* const resp =  */await guardarDomicilioEmpresa(domicilio, id);
            this.setState(
              { erroresDomicilio: [] },
              () =>{ this.mostrarToast('Domicilio guardado') }
            )
        } catch (error) {
            this.setState({ erroresDomicilio: error.data })
        }

    }

    domicilioForm(){

        const { domicilio, erroresDomicilio, estados, municipios } = this.state;

        return (
          <div>
              <div className="row">
                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.calle ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Calle *</label>
                          <div className="col-sm-8">
                              <input type="text" name="calle" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.calle || ''} />
                              { erroresDomicilio.calle && <span className="form-text m-b-none text-error">{ erroresDomicilio.calle }</span> }
                          </div>
                      </div>
                  </div>

                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.numero_exterior ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Número exterior *</label>
                          <div className="col-sm-8">
                              <input type="text" name="numero_exterior" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.numero_exterior || ''} />
                              { erroresDomicilio.numero_exterior && <span className="form-text m-b-none text-error">{ erroresDomicilio.numero_exterior }</span> }
                          </div>
                      </div>
                  </div>

                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.numero_interior ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Número interior</label>
                          <div className="col-sm-8">
                              <input type="text" name="numero_interior" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.numero_interior || ''} />
                              { erroresDomicilio.numero_interior && <span className="form-text m-b-none text-error">{ erroresDomicilio.numero_interior }</span> }
                          </div>
                      </div>
                  </div>

                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.colonia ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Colonia</label>
                          <div className="col-sm-8">
                              <input type="text" name="colonia" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.colonia || ''} />
                              { erroresDomicilio.colonia && <span className="form-text m-b-none text-error">{ erroresDomicilio.colonia }</span> }
                          </div>
                      </div>
                  </div>

                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.codigo_postal ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Código postal *</label>
                          <div className="col-sm-8">
                              <input type="text" name="codigo_postal" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.codigo_postal || ''} />
                              { erroresDomicilio.codigo_postal && <span className="form-text m-b-none text-error">{ erroresDomicilio.codigo_postal }</span> }
                          </div>
                      </div>
                  </div>

                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.estado ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Estado *</label>
                          <div className="col-sm-8">
                              <select name="estado" id="estado_select" className="form-control" value={domicilio.estado} onChange={this.handleChangeDomicilio}>
                                  { estados.map((estado) => {
                                      return <option key={`estado-${estado.value}`} value={estado.value}>{estado.label}</option>
                                  }) }
                              </select>
                              { erroresDomicilio.estado && <span className="form-text m-b-none text-error">{ erroresDomicilio.estado }</span> }
                          </div>
                      </div>
                  </div>

                  <div className="col-sm-6">
                      <div className={ erroresDomicilio.municipio ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Municipio *</label>
                          <div className="col-sm-8">
                              <select name="municipio" id="municipio_select" className="form-control" value={domicilio.municipio} onChange={this.handleChangeDomicilio}>
                                  { municipios.map((municipio) => {
                                      return <option key={`municipio-${municipio.value}`} value={municipio.value}>{municipio.label}</option>
                                  }) }
                              </select>
                              { erroresDomicilio.municipio && <span className="form-text m-b-none text-error">{ erroresDomicilio.municipio }</span> }
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-sm-12">
                      <button className="btn btn-primary btn-sm" onClick={this.submitDomicilio}>Guardar</button>
                  </div>
              </div>
          </div>
        )
    }

    render () {
        const {/*  contactoId, */ accion, /* logo, */ perfil } = this.state
        const { profileImagePreview } = this.state;

        const guia = [
            { nombre: 'Empresas', url: '/empresas', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]

        let {
            // id,
            pestanaSeleccionada,
            grupos,
            // estados,
            contactos,
            ocultarTabs,
            titulo,
            mostratFormulario,
            mostrarConfirmacion,
            tituloFrm,
            configEmergente,
            erroresConfigEmergente,
            des
        } = this.state

        const form = {
            button:{
                label:'Guardar',
                onSubmit: this.submitEmpresa
            },
            fields:[{
                name:'nombre',
                label:'Nombre *',
                type:'text',
                helpText:''
            },{
                name:'frecuencia_pago',
                label:'Frecuencia del pago *',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Semanal', value:'semanal'},
                    {label:'Decenal', value:'decenal'},
                    {label:'Catorcenal', value:'catorcenal'},
                    {label:'Quincenal', value:'quincenal'},
                    {label:'Mensual', value:'mensual'}
                ],
                helpText:''
            },{
                name: 'dias_cobro',
                label: 'Días de pago',
                type: 'text'
            },{
                name:'grupo',
                label:'Grupo',
                type:'select',
                options: grupos,
                helpText:''
            },{
                name:'razon_social',
                label:'Razón social',
                type:'text',
                helpText:''
            },{
                name:'rfc',
                label:'RFC',
                type:'text',
                helpText:'',
                maxLength: 13
            },{
                name:'telefono',
                label:'Teléfono',
                type:'text',
                helpText:'',
                maxLength: 10
            },{
                name:'email',
                label:'Correo electrónico',
                type:'text',
                helpText:''
            },{
                name:'tipo_descuento',
                label:'Tipo del descuento',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Domiciliación', value:'domiciliacion'},
                    {label:'Retención de nómina', value:'retencion'}
                ],
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }
/* 
        const frmDomicilio = {
            button:{
                label:'Guardar',
                onSubmit: this.submitDomicilio
            },
            fields:[{
                name:'calle',
                label:'Calle *',
                type:'text',
                helpText:''
            },{
                name:'numero_exterior',
                label:'Número exterior *',
                type:'text',
                helpText:''
            },{
                name:'numero_interior',
                label:'Número interior',
                type:'text',
                helpText:''
            },{
                name:'colonia',
                label:'Colonia',
                type:'text',
                helpText:''
            },{
                name:'codigo_postal',
                label:'Código postal',
                type:'text',
                helpText:''
            },{
                name:'estado',
                label:'Estado *',
                type:'select',
                options: estados,
                helpText:'',
                child: {
                    name:'municipio',
                    func: obtenerMunicipios
                }
            },{
                name:'municipio',
                label:'Municipio *',
                type:'select',
                options: this.state.municipios,
                helpText:''
            }],
            data: this.state.domicilio,
            errors: this.state.erroresDomicilio
        }
 */
        const frmContacto = {
            button:{
                label:'Guardar',
                onSubmit: this.submitContacto
            },
            fields:[{
                name:'nombre',
                label:'Nombre *',
                type:'text',
                helpText:''
            },{
                name:'telefono',
                label:'Teléfono *',
                type:'text',
                helpText:'',
                maxLength: 10
            },{
                name:'telefono2',
                label:'Teléfono 2',
                type:'text',
                helpText:'',
                maxLength: 10
            },{
                name:'email',
                label:'Correo electrónico',
                type:'text',
                helpText:''
            }],
            data: this.state.contacto,
            errors: this.state.erroresContacto
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <ul className="nav nav-tabs" role="tablist">
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 1 && 'active show'}`} onClick={(e) => this.seleccionarPestana(1)} >General</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empresas.change_empresadomicilio,') ? '' : ' ocultar'}`}
                                >
                                    <a className={`nav-link ${pestanaSeleccionada === 2 && 'active show'}`} onClick={(e) => this.seleccionarPestana(2)} >Domicilio</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empresas.view_empresacontacto,') ? '' : ' ocultar'}`}
                                >
                                    <a className={`nav-link ${pestanaSeleccionada === 3 && 'active show'}`} onClick={(e) => this.seleccionarPestana(3)} >Contactos</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empresas.view_empresacontacto,') ? '' : ' ocultar'}`}
                                >
                                    <a className={`nav-link ${pestanaSeleccionada === 4 && 'active show'}`} onClick={(e) => this.seleccionarPestana(4)} >Configuración emergente</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                    <div className="panel-body">
                                        { accion === 'Editar' &&
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="ibox ">
                                                        <div className="ibox-content text-center p-md ibox-content-mod">
                                                            <div className="m-t-md m-t-md-mod">
                                                                <div className="p-lg p-lg-mod">
                                                                    <img
                                                                        className="img-fluid img-shadow cursor-img"
                                                                        src={profileImagePreview || perfil}
                                                                        alt=""
                                                                        width="200"
                                                                    />
                                                                    <div className="overlay">
                                                                        <input
                                                                            type="file"
                                                                            accept="image/x-png,image/gif,image/jpeg"
                                                                            id="fileLogo"
                                                                            name="logotipo"
                                                                            style={{ display: 'none' }}
                                                                            onChange={e => this.handleLogoChange(e)}
                                                                        />
                                                                    </div>
                                                                <label className="cambiar-logo" htmlFor="fileLogo">Cambiar logotipo</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <Formulario
                                            buttonLabel={ form.button.label }
                                            onSubmit={ form.button.onSubmit }
                                            fields={ form.fields }
                                            data={ form.data }
                                            errors={ form.errors }
                                        />
                                    </div>
                                </div>
                                <div
                                    role="tabpanel"
                                    className={`tab-pane ${pestanaSeleccionada === 2 && 'active'}${verificarPermiso('empresas.change_empresadomicilio,') ? '' : ' ocultar'}`}
                                >
                                    <div className="panel-body">
                                        { this.domicilioForm() }
                                        {/*<Formulario*/}
                                        {/*    buttonLabel={ frmDomicilio.button.label }*/}
                                        {/*    onSubmit={ frmDomicilio.button.onSubmit }*/}
                                        {/*    fields={ frmDomicilio.fields }*/}
                                        {/*    data={ frmDomicilio.data }*/}
                                        {/*    errors={ frmDomicilio.errors }*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                                <div
                                    role="tabpanel"
                                    className={`tab-pane ${pestanaSeleccionada === 3 && 'active'}${verificarPermiso('empresas.view_empresacontacto,') ? '' : ' ocultar'}`}
                                >
                                    <div className="panel-body">
                                        { contactos.length > 0 ?
                                            <div>
                                                { verificarPermiso('empresas.add_empresacontacto,') && 
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn btn-primary"
                                                                onClick={() => this.mostrarFormulario('Agregar contacto')}
                                                            >
                                                                Agregar
                                                            </button>
                                                        </span>
                                                    </div>
                                                }
                                                <br/>
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Contacto</th>
                                                                <th></th>
                                                                <th>Teléfono</th>
                                                                <th></th>
                                                                <th>Teléfono 2</th>
                                                                <th></th>
                                                                <th>Correo electrónico</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { contactos.map(( obj, indice) => (
                                                                <tr key={ obj.id }>
                                                                    <td className="client-link">{ obj.nombre }</td>
                                                                    <td className="contact-type"><i className="fa fa-phone"> </i></td>
                                                                    <td>{ obj.telefono }</td>
                                                                    <td className="contact-type"><i className="fa fa-phone"> </i></td>
                                                                    <td>{ obj.telefono2 }</td>
                                                                    <td className="contact-type"><i className="fa fa-envelope"> </i></td>
                                                                    <td>{ obj.email }</td>
                                                                    <td>
                                                                        { verificarPermiso('empresas.change_empresacontacto,') &&
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-warning btn-sm btns-space"
                                                                                onClick={() => this.editarContacto(indice) }
                                                                            >
                                                                                Editar
                                                                            </button>
                                                                        }
                                                                        { verificarPermiso('empresas.delete_empresacontacto,') &&
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm btns-space"
                                                                                onClick={() => this.mostrarConfirmacion(obj.id, indice) }
                                                                            >
                                                                                Eliminar
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        :
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="wrapper wrapper-content">
                                                    <div className="ibox-content m-b-sm">
                                                        <div className="text-center p-lg">
                                                            <h2>Aún no hay contactos agregados</h2>
                                                            { verificarPermiso('empresas.add_empresacontacto,') &&
                                                                <button 
                                                                    className="btn btn-primary btn-sm"
                                                                    type="button"
                                                                    onClick={() => this.mostrarFormulario('Agregar contacto')}
                                                                >
                                                                    <i className="fa fa-plus"></i>
                                                                    <span className="bold">Agregar</span>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <Modal visible={ mostratFormulario } width="50%" height="45%" effect="fadeInUp">
                                        <div className="modal-content animated bounceInRight">
                                            <div className="modal-header">
                                                <h4 className="modal-title">{ tituloFrm}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <Formulario
                                                    fields={ frmContacto.fields }
                                                    data={ frmContacto.data }
                                                    errors={ frmContacto.errors }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                <button type="button" className="btn btn-primary" onClick={() => this.guardarContacto()}>Guardar</button>
                                            </div>
                                        </div>
                                    </Modal>
                                    <Modal visible={ mostrarConfirmacion } width="50%" height="31%" effect="fadeInUp">
                                        <div className="modal-content animated bounceInRight">
                                            <div className="modal-header">
                                                <h4 className="modal-title">Eliminar contacto</h4>
                                            </div>
                                            <div className="modal-body">
                                                <p>
                                                    ¿Estás seguro de eliminar este contacto?
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                <button type="button" className="btn btn-primary" onClick={() => this.eliminarContacto()}>Aceptar</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div
                                    role="tabpanel"
                                    className={`tab-pane ${pestanaSeleccionada === 4 && 'active'}${verificarPermiso('empresas.view_empresacontacto,') ? '' : ' ocultar'}`}
                                >
                                    <div className="panel-body">
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-4 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="id_recargas_activo">
                                                            <input checked={configEmergente.recargas_activo} onChange={(e) => this.handleChangeModuloEmergente(e)} type="checkbox" name='recargas_activo' className='' id='id_recargas_activo' value={configEmergente.recargas_activo} /> Módulo de recargas activo
                                                            { erroresConfigEmergente.recargas_activo.map((obj, index) => (
                                                              <span className="form-text m-b-none text-error" key={`errores_recargas-${index}`}>
                                                                {obj}
                                                            </span>
                                                            ))}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="id_pago_servicios_activo">
                                                            <input checked={configEmergente.pago_servicios_activo} onChange={(e) => this.handleChangeModuloEmergente(e)} type="checkbox" name='pago_servicios_activo' className='' id='id_pago_servicios_activo' value={configEmergente.pago_servicios_activo} /> Módulo de pago de serivicios activo
                                                            { erroresConfigEmergente.pago_servicios_activo.map((obj, index) => (
                                                              <span className="form-text m-b-none text-error" key={`errores_pago_servicios-${index}`}>
                                                                {obj}
                                                            </span>
                                                            ))}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="id_disposiciones_activo">
                                                            <input checked={configEmergente.disposiciones_activo} onChange={(e) => this.handleChangeModuloEmergente(e)} type="checkbox" name='disposiciones_activo' className='' id='id_disposiciones_activo' value={configEmergente.disposiciones_activo} /> Módulo de disposiciones activo
                                                            { erroresConfigEmergente.disposiciones_activo.map((obj, index) => (
                                                              <span className="form-text m-b-none text-error" key={`errores_disposiciones-${index}`}>
                                                                {obj}
                                                            </span>
                                                            ))}
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr/>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">Personalizado</label>
                                                        <div className="col-sm-8 center-vertical">
                                                            <input
                                                                type="checkbox"
                                                                name="personalizado"
                                                                className="form-control"
                                                                checked={ configEmergente.personalizado }
                                                                value={ configEmergente.personalizado }
                                                                onChange={ e => this.handleChange('checkbox', e) }
                                                            />
                                                            <small className="form-text m-b-none">Si este campo está activado permite editar la configuración del empleado</small>
                                                            { erroresConfigEmergente.personalizado.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">Saldo dinámico</label>
                                                        <div className="col-sm-8 center-vertical">
                                                            { des ?
                                                                <input
                                                                    type="checkbox"
                                                                    name="saldo_dinamico"
                                                                    className="form-control"
                                                                    checked={ configEmergente.saldo_dinamico}
                                                                    value={ configEmergente.saldo_dinamico}
                                                                    onChange={ e => this.handleChange('checkbox', e) }
                                                                />
                                                            :
                                                                <input
                                                                    type="checkbox"
                                                                    name="saldo_dinamico"
                                                                    className="form-control"
                                                                    disabled
                                                                    value={ configEmergente.saldo_dinamico}
                                                                    onChange={ e => this.handleChange('checkbox', e) }
                                                                />
                                                            }
                                                            <small className="form-text m-b-none"></small>
                                                            { erroresConfigEmergente.saldo_dinamico.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">Frecuencia del cobro</label>
                                                        <div className="col-sm-8">
                                                            { des ?
                                                                <select
                                                                    name="frecuencia_cobro"
                                                                    className="form-control m-b"
                                                                    value={ configEmergente.frecuencia_cobro}
                                                                    onChange={ e => this.handleChange('select', e) }
                                                                >
                                                                    <option value="">Seleccionar...</option>
                                                                    <option value="semanal">Semanal</option>
                                                                    <option value="decenal">Decenal</option>
                                                                    <option value="catorcenal">Catorcernal</option>
                                                                    <option value="quincenal">Quincenal</option>
                                                                    <option value="mensual">Mensual</option>
                                                                </select>
                                                            :
                                                                <select
                                                                    name="frecuencia_cobro"
                                                                    className="form-control m-b"
                                                                    disabled
                                                                    value={ configEmergente.frecuencia_cobro}
                                                                    onChange={ e => this.handleChange('select', e) }
                                                                >
                                                                    <option value="">Seleccionar...</option>
                                                                    <option value="semanal">Semanal</option>
                                                                    <option value="decenal">Decenal</option>
                                                                    <option value="catorcenal">Catorcernal</option>
                                                                    <option value="quincenal">Quincenal</option>
                                                                    <option value="mensual">Mensual</option>
                                                                </select>
                                                            }
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.frecuencia_cobro.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">
                                                            Número de amortizaciones
                                                        </label>
                                                        <div className="col-sm-8">
                                                            { des ?
                                                                <input
                                                                    type="text"
                                                                    name="num_amortizaciones"
                                                                    className="form-control"
                                                                    value={ configEmergente.num_amortizaciones}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            :
                                                                <input
                                                                    type="text"
                                                                    name="num_amortizaciones"
                                                                    className="form-control"
                                                                    disabled
                                                                    value={ configEmergente.num_amortizaciones}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            }
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.num_amortizaciones.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">
                                                            Saldo preaprobado
                                                        </label>
                                                        <div className="col-sm-8">
                                                            { des ?
                                                                <input
                                                                    type="text"
                                                                    name="saldo_preaprobado"
                                                                    className="form-control"
                                                                    value={ configEmergente.saldo_preaprobado}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            :
                                                                <input
                                                                    type="text"
                                                                    name="saldo_preaprobado"
                                                                    className="form-control"
                                                                    disabled
                                                                    value={ configEmergente.saldo_preaprobado}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            }
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.saldo_preaprobado.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">
                                                            Tasa de interés
                                                        </label>
                                                        <div className="col-sm-8">
                                                            { des ?
                                                                <input
                                                                    type="text"
                                                                    name="tasa_interes"
                                                                    className="form-control"
                                                                    value={ configEmergente.tasa_interes}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            :
                                                                <input
                                                                    type="text"
                                                                    name="tasa_interes"
                                                                    className="form-control"
                                                                    disabled
                                                                    value={ configEmergente.tasa_interes}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            }
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.tasa_interes.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group row">
                                                <div className="col-sm-4 col-sm-offset-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                        onClick={ this.guardarConfigEmergente}
                                                    >
                                                        Guardar
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .ocultar {
                        display: none !important;
                    }
                    .ibox-content-mod {
                        border-style: none !important;
                        padding: 0 !important;
                    }
                    .p-lg-mod {
                        padding: 0 !important;
                        padding-bottom: 15px !important;
                        z-index: 1000;
                    }
                    .m-t-md-mod {
                        margin-top: 0 !important;
                    }
                    .cambiar-logo{
                        height: 200px;
                        position: absolute;
                        top: -40px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 200px;
                        margin: auto;
                        color: white;
                        z-index: 3000;
                        opacity: 0;
                    }
                    .cambiar-logo:hover{
                        background-color: rgba(0, 0, 0, 0.7);
                        cursor: pointer;
                        opacity: 1;
                        padding-top: 90px;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
