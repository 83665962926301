import React, { Component } from 'react'
import Swal from 'sweetalert2';
import moment from 'moment';
import Autenticado from '../../components/Autenticado'
import { descargarContratosPrestamo } from '../../api/Prestamos'


class DescargarContratos extends Component {
    state = {
      id: null,
      titulo: null,
      accion: null,
      data: {},
      errors: {
        prestamos: []
      },
      loading: false
    }

    mostrarAlerta = (mensaje, tipo = 'info') => {
      Swal.fire({
        type: tipo,
        html: mensaje,
      })
    }

    componentWillMount() {
      const titulo = 'Descarga de contratos'
      const accion = 'Descarga de contratos'
      this.setState({ titulo, accion })
    }

    handlePrestamos = (e) => {
      const { data } = this.state
      data[e.target.name] = e.target.value
      this.setState({data})
    }

    handleSubmit = async (e) => {
      e.preventDefault()
      const { data } = this.state
      this.setState({loading: true})

      try {
        const response = await descargarContratosPrestamo(data)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `yuhu_contratos_${moment().format('YYYYMMDD')}.zip`
        );
        document.body.appendChild(link);
        link.click();
      } catch (error){
        Swal.fire({
          type: 'error',
          html: 'Ocurrio un error al descargar los contratos. Verifica que el número de folios no exceda los 200 registros.'
        })
      }

      this.setState({loading: false})
    }

    render () {
        const { accion, titulo, data, loading, errors } = this.state
        const guia = [
            { nombre: 'Reportes', url: '', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
              <div className="row">
                <div className="col-lg-12">
                  <div className="ibox ">
                      <div className="ibox-content">
                        <form onSubmit={this.handleSubmit}>
                          <div className='form-group'>
                            <label>
                              Prestamos
                            </label>
                            <textarea 
                              name='prestamos'
                              className='form-control'
                              rows={10}
                              onChange={(e) => this.handlePrestamos(e)}  value={data.prestamos || ''}
                              placeholder='Folios separados por coma'
                            />

                          </div>
                          <div className='form-group'>
                            <button className='btn btn-primary' disabled={loading}>
                              { loading ? 'Descargando contratos...' : 'Descargar contratos'}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            </Autenticado>
        )
    }
}

export default DescargarContratos
