import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    actualizarCuentaBancaria,
    agregarCuentaBancaria,
    obtenerCuentaBancaria
} from '../../api/CuentasBancarias'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        data: {},
        errors: [],
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = id ? 'Editar cuenta bancaria' : 'Agregar cuenta bancaria'
        const accion = id ? 'Editar' : 'Agregar'
        this.setState({ id, titulo, accion })
    }

    async componentDidMount() {
        const { id } = this.state
        if (id){
            try {
                const resp = await obtenerCuentaBancaria(id)
                const { data } = resp
                this.setState({ data })
            } catch (error) {
                console.log(error)
            }
        }
    }

    render () {
        const { accion, titulo } = this.state
        const { history } = this.props;
        const guia = [
            { nombre: 'Cuentas bancarias', url: '/cuentas-bancarias', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]

        const form = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    const { id } = this.state
                    try {
                        if (id){
                            await actualizarCuentaBancaria(values, id)
                        } else {
                            await agregarCuentaBancaria(values)
                        }
                        history.push('/cuentas-bancarias')
                    } catch (error) {
                        const errors = error.data
                        this.setState({ errors })
                    }
                }
            },
            fields:[{
                name:'banco',
                label:'Banco *',
                type:'text',
                helpText:''
            },{
                name:'no_cuenta',
                label:'Número de cuenta *',
                type:'text',
                helpText:''
            },{
                name:'clabe',
                label:'CLABE',
                type:'text',
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <Formulario
                                    buttonLabel={ form.button.label }
                                    onSubmit={ form.button.onSubmit }
                                    fields={ form.fields }
                                    data={ form.data }
                                    errors={ form.errors }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Autenticado>
        )
    }
}

export default Form
