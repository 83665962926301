import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado';
import spinner from '../../img/spinner.gif';
import {importarDatos } from '../../api/Sepomex';

class Importar extends Component {
  state = {
    titulo: 'Importar Archivo de SEPOMEX',
    archivo: null,
    success: null,
    errores: {
      archivo: []
    },
  };


  cargando = () => {
    Swal.fire({
      text: 'Espera un momento por favor... \n No recargues la página...',
      showConfirmButton: false,
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      imageUrl: spinner,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Espera un momento'
    });
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };

  cerrarAlertas = () => {
    Swal.close();
  };

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState({ [name]: value });
  };

  handleImageChange = async e => {
    e.preventDefault();
    const {
      target: { files }
    } = e;
    const file = files[0];
    if (file) {
      this.setState({ archivo: file });
    } else {
      this.setState({ archivo: null });
    }
  };

  procesar = async () => {
    this.cargando();
    const { archivo } = this.state;
    // const { success } = this.state;
    const formData = new FormData();
    formData.append('archivo', archivo);
    try {
      /* const resp = */ await importarDatos(formData);
      this.cerrarAlertas();
      this.setState({success: true})
    } catch (error) {
      console.log('Error importador:', error);
      this.cerrarAlertas();
      this.mostrarToast(error.message, 'error');
      if (error.status === 400){
        this.setState({errores: error.data});
      }
    }
  };
  render() {
    const {
      titulo,
      errores,
      success
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];


    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="archivo">Archivo</label>
                          <input
                            type="file"
                            className="form-control p36"
                            id="archivo"
                            onChange={this.handleImageChange}
                          />
                          { success ?
                            <span
                            className="form-text m-b-none text-success"
                            >
                              Importación completada.
                            </span>
                          :
                            <p></p>
                          }
                          { errores.correcto && errores.archivo.map((obj, index) => (
                            <span
                              className="form-text m-b-none text-error"
                              key={`ea${index}`}
                            >
                              {obj}
                            </span>
                          ))}
                        </div>
                      </div>
                      </div>
                      <div className="row">

                      <div className="col-sm-2">
                        <label>&nbsp;</label>
                        <button
                          type="button"
                          className="btn btn-success btn-sm btn-block"
                          onClick={this.procesar}
                        >
                          <i className="fa fa-cloud-upload" /> Procesar archivo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Importar;
