import React, { Component } from 'react';
import Select from 'react-select';
import { obtenerUnidadesOperativasList } from '../api/UnidadesOperativas';

class UnidadOperativaSelect extends Component {

  state = {
    unidadesOperativas: []
  };

  async componentDidUpdate(prevProps, prevState) {

    if (prevProps.empresa === this.props.empresa){
      return
    }

    const empresa = this.props.empresa;

    try {

      const req = await obtenerUnidadesOperativasList({empresa: empresa});

      const unidadesOperativas = req.data;

      this.setState({unidadesOperativas});

    } catch (e) {
      console.log(e);
    }

  }

  render() {

    const { unidadesOperativas } = this.state;

    const { onChange, value } = this.props;

    const unidadesOperativasValues = unidadesOperativas.map( uo => {
      return {label: uo.nombre, value: uo.id};
    });

    return (
      <Select
        name="unidad_operativa"
        placeholder="Selecciona una unidad operativa"
        className="select"
        onChange={onChange}
        options={unidadesOperativasValues}
        value={value}
      />
    )
  }

}

export default UnidadOperativaSelect;