import React, { Component } from 'react'
import '../css/bootstrap.css'
import '../font-awesome/css/font-awesome.css'
import '../css/animate.css'
import '../css/style.css'

class NoAutenticado extends Component {
    render () {
        return (
            <div className="gray-bg">
                <div className="middle-box text-center loginscreen animated fadeInDown">
                    { this.props.children }
                </div>
            </div>   
        )
    }
}

export default NoAutenticado
