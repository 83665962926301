import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';

const DatePicker = ({
  error,
  label,
  value,
  helpText,
  className = 'col-sm-6',
  labelClassName = 'col-sm-4',
  controlClassName = 'col-sm-8',
  options = {},
  dataEnableTime = false,
  onChange
}) => (
  <div className={className}>
    <div className={`form-group row ${error.length > 0 && 'has-error'}`}>
      <label className={`${labelClassName} col-form-label`}>{label}</label>
      <div className={controlClassName}>
        <Flatpickr
          className="form-control date"
          data-enable-time={dataEnableTime}
          value={value}
          options={options}
          onChange={onChange}
        />
        <span className="form-text m-b-none">{helpText}</span>
        {error.map((obj, index) => (
          <span className="form-text m-b-none text-error" key={index}>
            {obj}
          </span>
        ))}
      </div>
    </div>
    <style jsx="true">{`
      .date {
        background-color: #fff !important;
      }
    `}</style>
  </div>
);

export default DatePicker;
