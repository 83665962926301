import React, { Component } from 'react'
import NoAutenticado from '../components/NoAutenticado'

export class OlvideMiContrasenia extends Component {
    render () {
        return (
            <NoAutenticado>
                <div>
                    <div>
                        <h1 className="logo-name">IN+</h1>
                    </div>
                    <h3>Recupera tu contraseña</h3>
                    <p>Ingresa tu correo electrónico para recuperar tu contraseña</p>
                    <form className="m-t">
                        <div className="form-group">
                            <input type="email" className="form-control" placeholder="Correo electrónico" />
                        </div>
                        <button type="submit" className="btn btn-primary block full-width m-b">Reiniciar contraseña</button>
                    </form>
                </div>
            </NoAutenticado>
        )
    }
}

export default OlvideMiContrasenia
