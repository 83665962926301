import React, { Component, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Autenticado from '../../components/Autenticado';
// import Tabla from '../../components/Tabla';
import Paginacion from '../../components/Paginacion';
import { obtenerPrestamos, exportarPrestamos, reporteCastigos } from '../../api/Prestamos';
import { obtenerEmpresas } from '../../api/Empresas';
import { currencyFormat, dateFormat } from '../../utils/filters'

class Lista extends Component { 
  state = {
    titulo: 'Préstamos',
    objetos: [],
    totalRecords: 0,
    pageLimit: 50,
    params: {},
    empresas: [],
    datos: {
      empresa: '',
      estatus: '',
      tipo: '',
      frecuenciaCobro: '',
      year: null,
      search: ''
    },
    disabledExport: false,
    disabledExportCastigo: false
  };
  
  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      console.log(params);
      const promesas = [obtenerPrestamos(params), obtenerEmpresas({page_size: 1000})];
      const [req, resp] = await Promise.all(promesas);
      const objetos = req.data.results;
      // console.log(objetos);

      const totalRecords = req.data.count;
      const empresas = resp.data.results.map(obj => {
        return { label: obj.nombre, value: obj.id };
      });
      this.setState({ objetos, totalRecords, params, empresas });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    // console.log(page);
    const { pageLimit, params } = this.state;
    // console.log(params);
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerPrestamos(params);
      const objetos = req.data.results;
      console.log(objetos);
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerObjetos = async params => {
    try {
      const req = await obtenerPrestamos(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async(campo, e) => {
    
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (
      campo === 'empresa' ||
      campo === 'estatus' ||
      campo === 'tipo' ||
      campo === 'year' ||
      campo === 'frecuenciaCobro'
    ) {
      const {
        datos: { empresa, estatus, tipo, frecuenciaCobro, year }
      } = this.state;
      delete params.empresa;
      delete params.estatus;
      delete params.tipo;
      delete params.frecuencia_cobro;
      delete params.page;
      delete params.year;
      params = Object.assign(
        {
          empresa,
          estatus,
          tipo,
          year,
          frecuencia_cobro: frecuenciaCobro
        },
        params
      );
      this.obtenerObjetos(params);
      if (estatus =='castigo'){
        this.refs.btnCastigos.style.visibility = 'visible'
      }else{
        this.refs.btnCastigos.style.visibility = 'hidden'
      }
      
    }
    if (valor === '') {
      await this.limpiar()
    }
  };

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        empresa: '',
        estatus: '',
        tipo: '',
        frecuenciaCobro: '',
        search: ''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(params);
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  exportar = async () => {
  this.setState({disabledExport: true })
    const { params } = this.state;
    try {
      const resp = await exportarPrestamos( params );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_prestamos_${moment().format('YYYYMMDD')}.csv`
      );
      document.body.appendChild(link);
      link.click();
      this.setState({disabledExport: false })
    } catch (error) {
      console.log(error);
    }
  };
  descargarReporteCastigos = async() =>{
    this.setState({disabledExportCastigo: true })
    try{
      const resp = await reporteCastigos();
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_prestamos_castigos_${moment().format('YYYYMMDD')}.csv`
      );
      document.body.appendChild(link);
      link.click();
      this.setState({disabledExportCastigo: false })
    }catch(error){
      console.log(error)
    }
  }
  
  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      empresas,
      datos: { empresa, estatus, tipo, frecuenciaCobro, search, year },
      disabledExport,
      disabledExportCastigo
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];

    // Generamos array de años para filtro
    const max = new Date().getFullYear()
    const min = 2019
    const years = []

    for (let i = max; i >= min; i--) {
      years.push(i)
    }

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <div className="row mb-2">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm btns-space"
                      onClick={this.exportar}
                      disabled={disabledExport}
                    >
                      <i className="fa fa-download fa-margin"/> 
                      {!disabledExport ? 
                        'Exportar' : 
                        'Descargando...'
                      }
                      
                    </button>
                    <button

                      type="button"
                      className="btn btn-primary btn-sm btns-space"
                      onClick={this.descargarReporteCastigos}
                      disabled={disabledExportCastigo}
                      style={{visibility:'hidden'}}
                      ref="btnCastigos"
                    >
                      <i className="fa fa-download fa-margin"/> 
                      {!disabledExportCastigo ? 
                        'Reporte Castigos' : 
                        'Descargando...'
                      }
                      
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <div className="input-group">
                      <select
                        value={empresa}
                        onChange={e => this.onChange('empresa', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona empresa</option>
                        {empresas.map(emp => {
                          return (
                            <option key={emp.value} value={emp.value}>
                              {emp.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="input-group">
                      <select
                        value={estatus}
                        onChange={e => this.onChange('estatus', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona estatus</option>
                        <option value="activo">Activo</option>
                        <option value="cancelado">Cancelado</option>
                        <option value="liquidado">Liquidado</option>
                        <option value="cartera vencida">Cartera vencida</option>
                        <option value="castigo">Castigo</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="input-group">
                      <select
                        value={frecuenciaCobro}
                        onChange={e => this.onChange('frecuenciaCobro', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona frecuencia</option>
                        <option value="semanal">Semanal</option>
                        <option value="decenal">Decenal</option>
                        <option value="catorcenal">Catorcenal</option>
                        <option value="quincenal">Quincenal</option>
                        <option value="mensual">Mensual</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="input-group">
                      <select
                        value={tipo}
                        onChange={e => this.onChange('tipo', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona tipo</option>
                        <option value="tradicional">Tradicional</option>
                        <option value="emergente">Emergente</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-1">
                    <div className="input-group">
                      <select
                        value={year}
                        onChange={e => this.onChange('year', e)}
                        className="form-control m-b"
                      >
                        <option value="">Año</option>
                        {
                          years.map( year => (
                            <option key={year} value={year}>{year}</option>
                            )
                          )
                        }

                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={e => this.onChange('search', e)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Buscar"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin"/>
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin"/>
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th>Empleado</th>
                      <th>Empresa</th>
                      <th>Folio</th>
                      <th>Tipo</th>
                      <th>Monto</th>
                      <th className="centrado">Amortizaciones</th>
                      <th>Estatus</th>
                      <th>Fecha Creación</th>
                      <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {objetos.map(obj => {
                      return (
                        <tr key={obj.id}>
                          <td>{obj.empleado}</td>
                          <td>{obj.empresa}</td>
                          <td>{obj.folio}</td>
                          <td className="capitalize">{obj.tipo}</td>
                          <td>{currencyFormat(obj.monto)}</td>
                          <td className="centrado">
                            {obj.num_amortizaciones}
                          </td>
                          <td>{obj.estatus}</td>
                          <td>{dateFormat(obj.fecha_creacion)}</td>
                          <td>
                            <Link
                              key={obj.id}
                              to={`/prestamos/ver/${obj.id}/`}
                              className="btn btn-primary btn-sm btns-space"
                            >
                              <i className="fa fa-eye fa-margin"/>
                              Ver
                            </Link>
                          </td>
                        </tr>
                      );
                    })}

                    {/*  sin resultados */}
                    {objetos.length === 0 &&
                      <tr>
                        <td colSpan="8" className="text-center">No se encontraron resultados</td>
                      </tr>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
          .capitalize{
            text-transform: capitalize;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
