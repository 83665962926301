import Swal from 'sweetalert2';
import spinner from '../img/spinner.gif';

const cargando = () => {
  Swal.fire({
    text: 'Espera un momento por favor...',
    showConfirmButton: false,
    position: 'center',
    allowOutsideClick: false,
    allowEscapeKey: false,
    imageUrl: spinner,
    imageWidth: 100,
    imageHeight: 100,
    imageAlt: 'Espera un momento'
  });
};

/**
 * Muestra un toast en la esquina superior derecha
 * @param {string} mensaje Texto del toast
 * @param {string} tipo success (default), warning, error, info, and question
 */
const mostrarToast = (mensaje, tipo = 'success') => {
  Swal.fire({
    type: tipo,
    text: mensaje,
    toast: true,
    position: 'top-end',
    timer: 3000,
    showConfirmButton: false
  });
};

const cerrarAlertas = () => {
  Swal.close();
};

export { cargando, mostrarToast, cerrarAlertas };
