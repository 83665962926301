import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerEmpresas(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/empresas/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerEmpresasList(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/empresas-list/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function exportarEmpresas(params = {}) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    params.export = true;
    console.log(params)
    return await API.get('/empresas/', { params });
  } catch (err) {
    callError(err);
  }
}

async function obtenerEmpresa(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empresas/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarEmpresa(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/empresas/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarEmpresa(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/empresas/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarEmpresa(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(`/empresas/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function guardarDomicilioEmpresa(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empresas/${id}/domicilio/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerContactosEmpresa(empresa_id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empresas/${empresa_id}/contactos/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarContactoEmpresa(datos, empresa_id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empresas/${empresa_id}/contactos/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function editarContactoEmpresa(datos, empresa_id, contacto_id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(
      `/empresas/${empresa_id}/contactos/${contacto_id}/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function eliminarContactoEmpresa(empresa_id, contacto_id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/empresas/${empresa_id}/contactos/${contacto_id}/`
    );
  } catch (err) {
    callError(err);
  }
}

async function guardarLogo(datos, id) {
  const API = await ConstructorAPIAsync(20000);
  try {
    return await API.patch(`/empresas/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerConfigEmergenteEmpresa(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empresas/${id}/configuracion-emergente/`);
  } catch (err) {
    callError(err);
  }
}

async function actualizarConfigEmergenteEmpresa(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/empresas/${id}/configuracion-emergente/`, datos);
  } catch (err) {
    callError(err);
  }
}

// eslint-disable-next-line consistent-return
async function empresasWhirlpool() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/empresas-whirlpool-convenio/');
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerEmpresas,
  exportarEmpresas,
  obtenerEmpresa,
  agregarEmpresa,
  actualizarEmpresa,
  eliminarEmpresa,
  guardarDomicilioEmpresa,
  obtenerContactosEmpresa,
  agregarContactoEmpresa,
  editarContactoEmpresa,
  eliminarContactoEmpresa,
  guardarLogo,
  obtenerConfigEmergenteEmpresa,
  actualizarConfigEmergenteEmpresa,
  obtenerEmpresasList,
  empresasWhirlpool
};
