import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    obtenerDetallePago,
    verificarPago,
    cancelarPago,
    rechazarPago
} from '../../api/Pagos'
import Modal from 'react-awesome-modal'
import { dateFormat, currencyFormat } from '../../utils/filters'
import { quitarNulos } from '../../utils/functions'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { verificarPermiso } from '../../utils/auth'
import { mostrarToast } from '../../utils/alerts';

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        pestanaSeleccionada: 1,
        objeto: {},
        prestamo: {},
        data: {},
        errors: [],
        mostrarConfVerificarPago: false,
        mostrarConfCancelarPago: false,
        mostrarConfRechazarPago: false,
        mostratAlerta: false,
        textoAlerta: '',
        mostratAlertaError: false,
        textoAlertaError: '',
        textoAlertaErrorMsj: ''
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Ver pago'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            const res = await obtenerDetallePago(id)
            let objeto = res.data
            let { prestamo } = objeto
            objeto = quitarNulos(objeto)
            prestamo = quitarNulos(prestamo)
            this.setState({
                objeto, prestamo
            })
        } catch (error) {
            console.log(error)
        }
    }

    confirmarVerificarPago(){
        this.setState({ mostrarConfVerificarPago : true })
    }

    confirmarCancelarPago(){
        this.setState({ mostrarConfCancelarPago : true })
    }

    confirmarRechazarPago(){
        this.setState({ mostrarConfRechazarPago : true })
    }

    async verificarPago(e){
        const el = e.target
        const { id, objeto } = this.state
        el.disabled = true

        try {
            await verificarPago(id)
            objeto.estatus = 'verificado'
            this.setState({ objeto })
            this.cerrarModal()
            this.setState({
                mostratAlerta: true,
                textoAlerta: 'Pago verificado'
            })
        } catch (error) {
            const errors = error.data
            if(errors.excepcion){
                mostrarToast(errors.excepcion, 'error');
            }else{
                this.setState({ errors })
                this.setState({
                    mostratAlertaError: true,
                    textoAlertaError: 'Error al verificar el pago',
                    textoAlertaErrorMsj: error.response.data.detail
                })
            }
            this.cerrarModal()
        } finally {
            el.disabled = false
        }
    }

    async cancelarPago(e){
        const el = e.target
        const { id, objeto } = this.state
        let { data } = this.state
        el.disabled = true
        data = Object.assign({ cancelar_pago: true}, data)

        try {
            const res = await cancelarPago(data, id)
            console.log('res cancelarPago', res)
            objeto.estatus = 'cancelado'
            this.setState({ objeto })
            this.cerrarModal()
            this.setState({
                mostratAlerta: true,
                textoAlerta: 'Pago cancelado'
            })
        } catch (error) {
            let message = 'Error al cancelar el pago'
            if(error.data.hasOwnProperty('message')){
                message = error.data.message[0]
            } if(error.data.hasOwnProperty('error')){
                message = error.data.error
            } else{
                message = error.message
            }

            this.cerrarModal()
            this.setState({
                mostratAlertaError: true,
                textoAlertaError: message
            })
        } finally {
            el.disabled = false
        }
    }

    async rechazarPago(e){
        const el = e.target
        const { id, objeto } = this.state
        el.disabled = true

        try {
            await rechazarPago(id)
            objeto.estatus = 'rechazado'
            this.setState({ objeto })
            this.cerrarModal()
            this.setState({
                mostratAlerta: true,
                textoAlerta: 'Pago rechazado'
            })
        } catch (error) {
            this.cerrarModal()
            this.setState({
                mostratAlertaError: true,
                textoAlertaError: 'Error al rechazar el pago',
                textoAlertaErrorMsj: error.response.data.detail
            })
        } finally {
            el.disabled = false
        }
    }

    cerrarModal() {
        this.setState({
            mostrarConfVerificarPago: false,
            mostrarConfCancelarPago: false,
            mostrarConfRechazarPago: false,
            data: {},
            errors: []
        });
    }

    render () {
        const guia = [
            { nombre: 'Pagos', url: '/pagos', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        const {
            pestanaSeleccionada,
            titulo,
            objeto,
            prestamo,
            mostrarConfVerificarPago,
            mostrarConfCancelarPago,
            mostrarConfRechazarPago,
            mostratAlerta,
            textoAlerta,
            mostratAlertaError,
            textoAlertaError,
            textoAlertaErrorMsj
        } = this.state

        const frmCancelarPago = {
            fields:[{
                name:'comentario',
                label:'Motivo / Comentario',
                type:'text',
                helpText:'',
                className: 'col-sm-12',
                labelClassName: 'col-sm-3',
                controlClassName: 'col-sm-9'
            }],
            data: this.state.data,
            errors: this.state.errors
        }

        let folio = prestamo.folio ? prestamo.folio : ""
        let tipo_prestamo = prestamo.tipo ? prestamo.tipo : ""

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <SweetAlert
                    show={mostratAlerta}
                    title={textoAlerta}
                    type="success"
                    onConfirm={() => {
                        this.setState({ mostratAlerta: false });
                    }}
                    onEscapeKey={() => this.setState({ mostratAlerta: false })}
                    onOutsideClick={() => this.setState({ mostratAlerta: false })}
                />
                <SweetAlert
                    show={mostratAlertaError}
                    title={textoAlertaError}
                    type="error"
                    text={textoAlertaErrorMsj}
                    onConfirm={() => {
                        this.setState({ mostratAlertaError: false });
                    }}
                    onEscapeKey={() => this.setState({ mostratAlertaError: false })}
                    onOutsideClick={() => this.setState({ mostratAlertaError: false })}
                />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <div className="tab-content">
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                    <div className="panel-body">
                                        <div className="row">                                            
                                            <div className="col-sm-12 m-b-xs">
                                                { (folio.includes("CRE") || tipo_prestamo === 'emergente') && objeto.estatus === 'pendiente' && verificarPermiso('pagos.verificar_pagoprestamo,') &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger right"
                                                        onClick={() => this.confirmarRechazarPago()}
                                                    >
                                                        Rechazar
                                                    </button>
                                                }
                                                { (folio.includes("CRE") || tipo_prestamo === 'emergente') && objeto.estatus === 'pendiente' && verificarPermiso('pagos.verificar_pagoprestamo,') &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary fa-margin right"
                                                        onClick={() => this.confirmarVerificarPago()}
                                                    >
                                                        Verificar
                                                    </button>
                                                }
                                                {/*SE DESHABILITA BOTON PARA CANCELAR PAGO*/}
                                                { objeto.cancelable && verificarPermiso('pagos.cancelar_pagoprestamo,') &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger right"
                                                        onClick={() => this.confirmarCancelarPago()}
                                                    >
                                                        Cancelar
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <Modal visible={ mostrarConfVerificarPago } width="50%" height="31%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Verificar pago</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        ¿Estás seguro de verificar este pago?
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={(e) => this.verificarPago(e)}>Aceptar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal visible={ mostrarConfCancelarPago } width="50%" height="31%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Cancelar pago</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        ¿Estás seguro de cancelar este pago?
                                                    </p>
                                                    <Formulario
                                                        fields={ frmCancelarPago.fields }
                                                        data={ frmCancelarPago.data }
                                                        errors={ frmCancelarPago.errors }
                                                    />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={(e) => this.cancelarPago(e)}>Aceptar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal visible={ mostrarConfRechazarPago } width="50%" height="31%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Rechazar pago</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        ¿Estás seguro de rechazar este pago?
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={(e) => this.rechazarPago(e)}>Aceptar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Información del pago
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Folio</label>
                                                                <label>{ objeto.folio }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Referencia</label>
                                                                <label>{ objeto.referencia }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de pago</label>
                                                                <label>{ dateFormat(objeto.fecha_pago) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Estatus</label>
                                                                <label>{ objeto.estatus }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de la amortización</label>
                                                                <label>{ objeto.fecha_amortizacion ? dateFormat(objeto.fecha_amortizacion) : '--' }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Aplicado</label>
                                                                <label>{ objeto.aplicado === true ? 'Sí' : 'No' }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Tipo de pago</label>
                                                                <label>{ objeto.tipo_pago }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Capital</label>
                                                                <label>{ currencyFormat(objeto.capital) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto</label>
                                                                <label>{ currencyFormat(objeto.monto) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Interes</label>
                                                                <label>{ currencyFormat(objeto.interes) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>IVA</label>
                                                                <label>{ currencyFormat(objeto.iva) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Cuenta</label>
                                                                <label>{ objeto.cuenta }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label># pago</label>
                                                                <label>{ objeto.num_pago }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Pago masivo</label>
                                                                <label>{ objeto.pago_masivo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Motivo</label>
                                                                <label>{ objeto.motivo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Comentario</label>
                                                                <label>{ objeto.comentario }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Comprobante</label>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm btns-space"
                                                                    onClick={()=> window.open(objeto.comprobante, "_blank")}
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                    Ver
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Usuario que creó</label>
                                                                <label>{ objeto.usuario_creo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de creación</label>
                                                                <label>{ dateFormat(objeto.fecha_creacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Usuario que verificó</label>
                                                                <label>{ objeto.usuario_verifico }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de verificación</label>
                                                                <label>{ dateFormat(objeto.fecha_verificacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Usuario que canceló</label>
                                                                <label>{ objeto.usuario_cancelo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de cancelación</label>
                                                                <label>{ dateFormat(objeto.fecha_cancelacion) }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Información del prestamo
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Empleado</label>
                                                                <label>{ prestamo.empleado }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de creación</label>
                                                                <label>{ dateFormat(prestamo.fecha_creacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Folio</label>
                                                                <label><a href={`/prestamos/ver/${prestamo.id}`}>{ prestamo.folio }</a></label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Plazo</label>
                                                                <label>{ prestamo.plazo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Amortizaciones pendientes</label>
                                                                <label>{ prestamo.amortizaciones_restantes }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Total pagado</label>
                                                                <label>{ currencyFormat(prestamo.total_pagado) } %</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Adeudo total</label>
                                                                <label>{ currencyFormat(prestamo.adeudo_total) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto</label>
                                                                <label>{ currencyFormat(prestamo.monto) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Capital</label>
                                                                <label>{ currencyFormat(prestamo.capital) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Interes</label>
                                                                <label>{ currencyFormat(prestamo.interes) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>IVA</label>
                                                                <label>{ currencyFormat(prestamo.iva) }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
