import React, { Component } from 'react';
import {
  obtenerInfoCrediticia,
  agregarInfoCrediticia
} from '../../api/Promotores';
import { obtenerSolicitud } from '../../api/Solicitudes';
import Autenticado from '../../components/Autenticado';

class InformacionCrediticia extends Component {
  state = {
    creditoHipotecario: 'no lo se',
    creditoAutomotriz: 'no lo se',
    tarjetaCredito: 'no lo se',
    errores: {},
    empleado: ''
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let empleado = '';
    try {
      const resp = await obtenerSolicitud(id);
      empleado = `${resp.data.empleado.nombre || ''} ${resp.data.empleado
        .apellido_paterno || ''} ${resp.data.empleado.apellido_materno || ''}`;
    } catch (error) {
      console.log('Error al obtener solicitud:', error);
    }

    try {
      const resp = await obtenerInfoCrediticia(id);
      this.setState({
        creditoHipotecario: resp.data.credito_hipotecario || 'no lo se',
        creditoAutomotriz: resp.data.credito_automotriz || 'no lo se',
        tarjetaCredito: resp.data.tarjeta_credito || 'no lo se',
        empleado
      });
    } catch (error) {
      console.log('Error:', error);
    }
  };

  validar = () => {
    let validado = true;
    const errores = {};
    const {
      creditoHipotecario,
      creditoAutomotriz,
      tarjetaCredito
    } = this.state;

    if (!creditoHipotecario) {
      validado = false;
      errores.credito_hipotecario = ['Esta respuesta es requerida.'];
    }
    if (!creditoAutomotriz) {
      validado = false;
      errores.credito_automotriz = ['Esta respuesta es requerida.'];
    }
    if (!tarjetaCredito) {
      validado = false;
      errores.tarjeta_credito = ['Esta respuesta es requerida.'];
    }

    this.setState({ errores });
    return validado;
  };

  submit = async () => {
    if (!this.validar()) {
      return;
    }
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const {
      creditoHipotecario,
      creditoAutomotriz,
      tarjetaCredito
    } = this.state;
    const datos = {
      credito_hipotecario: creditoHipotecario,
      credito_automotriz: creditoAutomotriz,
      tarjeta_credito: tarjetaCredito
    };
    try {
      await agregarInfoCrediticia(id, datos);
      this.props.history.push(`/solicitudes/${id}/comprobantes`);
    } catch (error) {
      console.log('error:', error);
    }
  };

  render() {
    const {
      creditoHipotecario,
      creditoAutomotriz,
      tarjetaCredito,
      errores,
      empleado
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    const rowPregunta = (
      pregunta,
      nombre,
      checkSi,
      checkNo,
      checkNoSe,
      onChangeSi,
      onChangeNo,
      onChangeNoSe,
      error = [],
      respuestaSi = 'Si',
      respuestaNo = 'No tengo, y nunca he tenido',
      respuestaNoSe = 'No lo se',
      nota = null
    ) => (
      <div className="row mt2">
        <div className="col-sm-6">
          <span>{pregunta}</span>
          {nota && (
            <small className="font-italic">
              <br />
              {nota}
            </small>
          )}
          {error.map((obj, index) => (
            <div key={`${nombre}-${index}`} className="invalid-feedback">
              {obj}
            </div>
          ))}
        </div>
        <div className="col-sm-6 flex-start">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id={`${nombre}1`}
              className="custom-control-input"
              value="si"
              checked={checkSi}
              onChange={onChangeSi}
            />
            <label className="custom-control-label" htmlFor={`${nombre}1`}>
              {respuestaSi}
            </label>
          </div>
          <div className="custom-control custom-radio ml2">
            <input
              type="radio"
              id={`${nombre}2`}
              className="custom-control-input"
              value="no"
              checked={checkNo}
              onChange={onChangeNo}
            />
            <label className="custom-control-label" htmlFor={`${nombre}2`}>
              {respuestaNo}
            </label>
          </div>
          <div className="custom-control custom-radio ml2">
            <input
              type="radio"
              id={`${nombre}3`}
              className="custom-control-input"
              value=""
              checked={checkNoSe}
              onChange={onChangeNoSe}
            />
            <label className="custom-control-label" htmlFor={`${nombre}3`}>
              {respuestaNoSe}
            </label>
          </div>
        </div>
      </div>
    );
    const guia = [
      { nombre: 'Solicitudes', url: '/solicitudes', clase: '' },
      { nombre: 'Información crediticia', url: '', clase: 'active' }
    ];
    return (
      <Autenticado titulo={`Información crediticia de ${empleado}`} guia={guia}>
        <div className="ibox">
          <div className="ibox-content">
            {rowPregunta(
              '¿TIENES O HAS TENIDO CRÉDITO HIPOTECARIO?',
              'creditoHipotecario',
              creditoHipotecario === 'si',
              creditoHipotecario === 'no',
              creditoHipotecario === 'no lo se',
              () => this.setState({ creditoHipotecario: 'si' }),
              () => this.setState({ creditoHipotecario: 'no' }),
              () => this.setState({ creditoHipotecario: 'no lo se' }),
              errores.credito_hipotecario
            )}
            {rowPregunta(
              '¿TIENES O HAS TENIDO CRÉDITO AUTOMOTRIZ?',
              'creditoAutomotriz',
              creditoAutomotriz === 'si',
              creditoAutomotriz === 'no',
              creditoAutomotriz === 'no lo se',
              () => this.setState({ creditoAutomotriz: 'si' }),
              () => this.setState({ creditoAutomotriz: 'no' }),
              () => this.setState({ creditoAutomotriz: 'no lo se' }),
              errores.credito_automotriz
            )}
            {rowPregunta(
              '¿TIENES TARJETA DE CRÉDITO BANCARIA?',
              'tarjetaCredito',
              tarjetaCredito === 'si',
              tarjetaCredito === 'no',
              tarjetaCredito === 'no lo se',
              () => this.setState({ tarjetaCredito: 'si' }),
              () => this.setState({ tarjetaCredito: 'no' }),
              () => this.setState({ tarjetaCredito: 'no lo se' }),
              errores.tarjeta_credito,
              'Si',
              'No',
              'No lo se',
              'No aplica departamental liverpool y similares'
            )}
            <div className="row mt2">
              <div className="col-12">
                <small>
                  Autorizo a proquatro SAPI DE CV SOFOM ENR* para que consulte
                  mi historial crediticio de acuerdo con los artículos 13 y 28
                  de la Ley para Regular las Sociedades de Información
                  Crediticia. Igualmente, declaro que conozco la naturaleza y el
                  alcance de la información que se solicitará.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex-between">
            <a
              className="btn btn-secondary mt2"
              href={`/solicitudes/${id}/referencias`}
            >
              Regresar
            </a>
            <button
              className="btn btn-success mt2"
              type="button"
              onClick={this.submit}
            >
              Siguiente
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .custom-control-input:checked ~ .custom-control-label::before {
            color: #fff;
            border-color: #25d07b;
            background-color: #25d07b;
          }
          .invalid-feedback {
            display: block;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default InformacionCrediticia;
