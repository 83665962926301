import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { aprobarPagoMasivo, detallePagoMasivo, listaPagosMasivosPrestamo, rechazarPagoMasivo } from '../../api/Pagos';
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias';
import { currencyFormat, dateFormat, dateTimeFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import { cargando } from '../../utils/alerts';
import { cerrarAlertas } from '../../utils/alerts';

class MasivosDetalle extends Component {
  state = {
    id: null,
    titulo: 'Ver pago masivo',
    pagoMasivo: {
      empresa: 0,
      monto_total: 0,
      fecha_pago: '',
      estatus: '',
      puede_verificarse_manual: false,
      motivo: '',
      tipo_pago: '0',
      comprobante: null,
      referencia: '',
      comentario: '',
      aplicado: false,
      usuario_creo: null,
      usuario_verifico: null,
      usuario_cancelo: null,
      fecha_creacion: null,
      fecha_verificacion: null,
      fecha_cancelacion: null,
      pagos_prestamos: []
    },
    pageLimit: 1000,
    params: {},
    pagosMasivosPrestamos: [],
    totalPagosMasivosPrestamos: 0,
    cuentas: [],
    datos: {
      cuenta: '',
      search: ''
    },
    verModal: false,
    accion: 'aprobar',
    mostratAlerta: false,
    textoAlerta: ''
  };

  componentWillMount() {
    const id = this.props.match.params.id;
    this.setState({ id });
  }

  onPageChanged = async data => {
    cargando();
    const page = data.currentPage;
    const { id, pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await listaPagosMasivosPrestamo(id, params);
      const pagosMasivosPrestamos = req.data.results;
      const totalPagosMasivosPrestamos = req.data.count;
      this.setState({ pagosMasivosPrestamos, totalPagosMasivosPrestamos, params });
    } catch (error) {
      console.log('Error: ', error);
    }
    cerrarAlertas();
  };

  obtenerObjetos = async (id, params) => {
    cargando();
    try {
      const req = await listaPagosMasivosPrestamo(id, params);
      const pagosMasivosPrestamos = req.data.results;
      const totalPagosMasivosPrestamos = req.data.count;
      this.setState({ pagosMasivosPrestamos, totalPagosMasivosPrestamos, params });
    } catch (error) {
      console.log(error);
    }
    cerrarAlertas();
  };

  limpiar = async () => {
    const { id } = this.state;
    this.setState({
      params: {},
      datos: {
        search: '',
        cuenta: '',
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(id, params);
  };

  buscar = async () => {
    const { id } = this.state;
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(id, params);
  };

  onChange = (campo, e) => {
    const { id } = this.state;
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (campo === 'cuenta'){
      const {datos: { cuenta }} = this.state;
      delete params.cuenta;
      delete params.page;
      params = Object.assign({ cuenta }, params);
      this.obtenerObjetos(id, params);
    }
  };
  
  async componentDidMount() {
    const { id, params, pageLimit } = this.state;
    params.page_size = pageLimit;
    cargando();

    try {
      const responsePagoMasivo = await detallePagoMasivo(id);
      const responsePagosMasivosPrestamos = await listaPagosMasivosPrestamo(id, params);

      // Obtenemos los datos de la respuesta
      const pagoMasivo = responsePagoMasivo.data;

      const pagosMasivosPrestamos = responsePagosMasivosPrestamos.data.results;
      const totalPagosMasivosPrestamos = responsePagosMasivosPrestamos.data.count;

      const responseCuentas = await obtenerCuentasBancarias();
      let cuentas = [];
      responseCuentas.data.forEach(obj => {
          cuentas.push({ label: obj.banco+' - '+obj.no_cuenta, value: obj.id });
      });
      // Actualizamos el estado con los datos obtenidos
      this.setState({ pagoMasivo, pagosMasivosPrestamos, totalPagosMasivosPrestamos, cuentas });

    } catch (error) {
      console.log(error);
    }
    cerrarAlertas();
  }

  renderRow = (label, value) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        <strong>{value}</strong>
      </div>
    </div>
  );

  submit = async () => {
    let { accion, id, pagoMasivo } = this.state;
    try {
      let resp = {};
      let textoAlerta = '';
      if (accion === 'aprobar') {
        resp = await aprobarPagoMasivo(id);
        textoAlerta = 'Pago masivo aplicado exitosamente.';
      } else {
        resp = await rechazarPagoMasivo(id);
        textoAlerta = 'Pago masivo rechazados exitosamente.';
      }
      pagoMasivo.estatus = resp.data.estatus;
      pagoMasivo.puede_verificarse_manual = resp.data.puede_verificarse_manual;
      pagoMasivo.aplicado = resp.data.aplicado;
      pagoMasivo.usuario_verifico = resp.data.usuario_verifico;
      pagoMasivo.usuario_cancelo = resp.data.usuario_cancelo;
      pagoMasivo.fecha_verificacion = resp.data.fecha_verificacion;
      pagoMasivo.fecha_cancelacion = resp.data.fecha_cancelacion;
      pagoMasivo = quitarNulos(pagoMasivo);
      this.componentDidMount()
      this.setState({
        pagoMasivo,
        mostratAlerta: true,
        textoAlerta,
        verModal: false
      });
    } catch (error) {
      console.log('Error:', error);
    }
  };

  render() {
    const guia = [
      { nombre: 'Pagos masivos', url: '/pagos/masivos', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];

    const {
      // id,
      titulo,
      pagoMasivo,
      pagosMasivosPrestamos,
      totalPagosMasivosPrestamos,
      pageLimit,
      cuentas,
      datos: { cuenta, search },
      verModal,
      accion,
      mostratAlerta,
      textoAlerta
    } = this.state;

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <SweetAlert
          show={mostratAlerta}
          title={textoAlerta}
          type="success"
          onConfirm={() => {
            this.setState({ mostratAlerta: false });
          }}
          onEscapeKey={() => this.setState({ mostratAlerta: false })}
          onOutsideClick={() => this.setState({ mostratAlerta: false })}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-content">
                        <h3>
                          <strong>Información del pago masivo</strong>
                        </h3>
                        <h4>
                        <strong className='rojo'>
                          { 
                            !pagoMasivo.puede_verificarse_manual && pagoMasivo.estatus === 'pendiente' 
                            ? 'El pago será verificado automáticamente en un máximo de 15 min.'
                            : '' 
                          }
                        </strong>
                        </h4>
                        {this.renderRow('Empresa', pagoMasivo.empresa)}
                        {this.renderRow(
                          'Monto total',
                          currencyFormat(pagoMasivo.monto_total)
                        )}
                        {this.renderRow(
                          'Fecha de pago',
                          dateFormat(pagoMasivo.fecha_pago)
                        )}
                        {this.renderRow('Tipo de pago', pagoMasivo.tipo_pago)}
                        {this.renderRow('Referencia', pagoMasivo.referencia)}
                        {this.renderRow('Estatus', pagoMasivo.estatus)}
                        {this.renderRow('Motivo', pagoMasivo.motivo)}
                        {this.renderRow('Comentario', pagoMasivo.comentario)}
                        {this.renderRow(
                          'Aplicado',
                          pagoMasivo.aplicado ? 'Si' : 'No'
                        )}
                        {this.renderRow(
                          'Fecha de creación',
                          dateTimeFormat(pagoMasivo.fecha_creacion)
                        )}
                        {this.renderRow('Usuario creador', pagoMasivo.usuario_creo)}
                        {this.renderRow(
                          'Fecha de verificación',
                          dateTimeFormat(pagoMasivo.fecha_verificacion)
                        )}
                        {this.renderRow(
                          'Usuario verificador',
                          pagoMasivo.usuario_verifico
                        )}
                        <a
                          className="btn btn-primary col-6 btn-sm btn-block"
                          href={pagoMasivo.archivo}>
                          <i className='fa fa-download' /> Descargar Archivo
                        </a>

                        {/* {this.renderRow(
                          'Fecha de cancelación',
                          dateTimeFormat(pagos.fecha_cancelacion)
                        )}
                        {this.renderRow(
                          'Usuario que cancelo',
                          pagos.usuario_cancelo
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {pagoMasivo.puede_verificarse_manual === true && (
                <div className="col-md-3 pb25">
                  <button
                    type="button"
                    className="btn btn-success btn-sm btn-block"
                    onClick={() =>
                      this.setState({ verModal: true, accion: 'aprobar' })
                    }
                  >
                    <i className="fa fa-check" /> Aprobar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-block"
                    onClick={() =>
                      this.setState({ verModal: true, accion: 'rechazar' })
                    }
                  >
                    <i className="fa fa-times" /> Rechazar
                  </button>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">Lista de pagos</div>
                  <div className="panel-body">
                    <div className="row">
                    <div className="col-sm-3">
                      <div className="input-group">
                        <select
                          value={cuenta}
                          onChange={e => this.onChange('cuenta', e)}
                          className="form-control m-b"
                        >
                        <option value="">Selecciona cuenta</option>
                        {cuentas.map(cuenta => {
                          return (
                            <option key={cuenta.value} value={cuenta.value}>
                              {cuenta.label}
                            </option>
                          );
                        })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-9">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={e => this.onChange('search', e)}
                        placeholder="Busca por referencia o folio de préstamo"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                      <div className="col-md-12">
                      <table className="table table-striped">
                        <thead>
                        <tr>
                          <th>Préstamo</th>
                          <th>Monto</th>
                          <th>Referencia</th>
                          <th>Cuenta</th>
                          <th className='text-center'>Pago Aplicado</th>
                        </tr>
                        </thead>
                        <tbody>
                        { pagosMasivosPrestamos && pagosMasivosPrestamos.map( pago => {
                          return (
                            <tr key={`pago-${pago.id}`}>
                              <td>
                                <Link to={`/prestamos/ver/${pago.prestamo}`}> {pago.prestamo_folio} </Link>
                              </td>
                              <td>{ currencyFormat(pago.monto) }</td>
                              <td>{ pago.referencia }</td>
                              <td>{ pago.cuenta }</td>
                              <td className='text-center'>{ pago.pago_generado ? 
                              <span>
                                <i className="fa fa-check text-success"></i>
                              </span> : 
                              <span>
                                <i className="fa fa-times text-danger"></i>
                              </span> }</td>
                            </tr>
                          )
                        }) }
                        {
                          pagosMasivosPrestamos && pagosMasivosPrestamos.length === 0 &&
                            <tr>
                              <td colSpan="9" className="text-center">No se encontraron pagos</td>
                            </tr>
                        }
                        </tbody>
                        </table>
                        <Paginacion
                          totalRecords={totalPagosMasivosPrestamos}
                          pageLimit={pageLimit}
                          pageNeighbours={1}
                          onPageChanged={this.onPageChanged}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal visible={verModal} width="50%" effect="fadeInUp">
          <div className="modal-content animated bounceInRight">
            <div className="modal-header">
              {accion === 'aprobar' ? (
                <h4 className="modal-title">Aplicar pago masivo</h4>
              ) : (
                <h4 className="modal-title">Rechazar pago masivo</h4>
              )}
            </div>
            <div className="modal-body">
              {accion === 'aprobar' ? (
                <div>¿Esta seguro que desea aplicar este pago masivo?</div>
              ) : (
                <div className="form-group">
                  ¿Esta seguro que desea rechazar este pago masivo?
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-white"
                onClick={() => this.setState({ verModal: false })}
              >
                Cancelar
              </button>
              <button
                type="button"
                className={`btn ${
                  accion === 'aprobar' ? 'btn-primary' : 'btn-danger'
                }`}
                onClick={this.submit}
              >
                {accion === 'aprobar' ? 'Aplicar pago' : 'Rechazar pago'}
              </button>
            </div>
          </div>
        </Modal>
        <style jsx="true">{`
            .sol{
                display: flex;
                justify-content: space-between;
            }
            .rojo{
              color: red;
            }
            .ibox-content{
                border-style: none;
            }
            .fa-margin {
                margin-right: 5px;
            }
            .right {
                float: right;
            }
            .celda-delgada {
                padding: 0.3rem !important;
            }chuy
        `}</style>
      </Autenticado>
    );
  }
}

export default MasivosDetalle;
