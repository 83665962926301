const USER_AUTH_KEY = 'auth-token';

const setToken = data => {
  localStorage.setItem(USER_AUTH_KEY, data.token);
  localStorage.setItem('nombre', data.user.nombre);
  localStorage.setItem('tipo', data.user.tipo);
  localStorage.setItem('imagenPerfil', data.user.imagen_perfil || '');
};

const setImagenPerfil = urlImagen => {
  localStorage.setItem('imagenPerfil', urlImagen);
};

const setPermissions = permisos => {
  localStorage.setItem('permisos', permisos + ',');
};

const getToken = () => {
  return localStorage.getItem(USER_AUTH_KEY);
};

const removeToken = () => {
  // Limpiamos el localStorage
  localStorage.clear();
  // localStorage.removeItem(USER_AUTH_KEY)
  // localStorage.removeItem('nombre')
  // localStorage.removeItem('tipo')
  // localStorage.removeItem('imagenPerfil')
  // localStorage.removeItem('permisos')
};

const isLogin = () => {
  return !!getToken();
};

const verificarPermiso = permiso => {
  const permisos = localStorage.getItem('permisos')

  if (permisos != null){
    // Si tiene los permisos seteados en localStorage
    return permisos.search(permiso) !== -1
  }

  // Si no tiene los permisos seteados en localStorage hacemos una logOut
  removeToken();

}

export {
  setToken,
  setImagenPerfil,
  setPermissions,
  getToken,
  removeToken,
  isLogin,
  verificarPermiso
};
