import React, {useEffect, useState} from 'react';
import Modal from 'react-awesome-modal';
import { getExcepcionesRiesgo, updateNotasCredito } from '../../api/Solicitudes';
import { currencyFormat } from '../../utils/filters';

const ModalExcepcionRiesgo = ({ showModal, setShowModal, listaExcepcionesDefault, comentarioExcepcionDefault, solicitud }) => {

  const listaExcepciones = [
    'Antigüedad laboral',
    'Renovación anticipada',
    'Renovación con cliente que ha caído en mora',
    'Compra de deuda MOP > 02 o 00',
    'Compra de deuda depositada al cliente',
    'Cliente con mora de retención',
    'Cliente con mora en yuhu',
    'Sin recibo de nomina con CFDI',
    'Mal buró',
    'Independiente',
    'Capacidad de pago Excedente 5-15%',
    'Capacidad de pago Excedente 15-30%',
    'Capacidad de pago Excedente mas de 30%',
    'CAC',
    'Otros'
  ]

  const [listaExcepcionesSeleccionadas, setListaExcepcionesSeleccionadas] = useState([]);

  const [comentarioExcepcion, setComentarioExcepcion] = useState('');
  const [excepciones, setExcepciones] = useState({});

  useEffect(() => {
    setListaExcepcionesSeleccionadas(listaExcepcionesDefault);
    obtenerExcepciones();
  }, [listaExcepcionesDefault]);

  useEffect(() => {
    setComentarioExcepcion(comentarioExcepcionDefault)
  }, [comentarioExcepcionDefault]);

  const seleccionarExcepcion = (excepcion) => {

    // Verificar si la opción ya se encuentra seleccionada
    if (listaExcepcionesSeleccionadas.includes(excepcion)){
      const index = listaExcepcionesSeleccionadas.indexOf(excepcion);
      if (index > -1) {
        const auxListaExcepcionesSeleccionadas = [...listaExcepcionesSeleccionadas]
        auxListaExcepcionesSeleccionadas.splice(index, 1);
        setListaExcepcionesSeleccionadas(auxListaExcepcionesSeleccionadas);
      }
    } else {
      setListaExcepcionesSeleccionadas([...listaExcepcionesSeleccionadas, excepcion]);
    }
  }

  const handleChangeSubmit = async () => {

    const data = {
      excepcion_riesgo_listado: listaExcepcionesSeleccionadas.join(),
      excepcion_riesgo_comentario: comentarioExcepcion
    }

    try {
      const req = await updateNotasCredito(solicitud.id, data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }

    setShowModal(false);


  }

  const obtenerExcepciones = async () => {
    try {
      const res = await getExcepcionesRiesgo();
      setExcepciones(res.data);
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <Modal visible={showModal} width='60%' height='638px' effect='fadeInUp'>
      <div className="modal-header">
        {/* <div className="row">         */}
          <div className="col-4">
            <h3>Excepciones de Riesgo</h3>
          </div>
          <div className="col-4">
            <strong>Mes anterior</strong> <br />
            <span>Solicitudes: {excepciones.solicitudes_mes_anterior} </span> <br />
            <span> Monto: {currencyFormat(excepciones.monto_mes_anterior)}</span>
          </div>
          <div className="col-4">
            <strong>Mes actual</strong> <br />
            <span>Solicitudes: {excepciones.solicitudes_mes_actual} </span>  <br />
            <span> Monto: {currencyFormat(excepciones.monto_mes_actual)}</span>
          </div>
        {/* </div> */}
        {/* <div className="row">
          <div className="col-6">
            <h4 className="modal-title">Excepciones de Riesgo</h4>
          </div>
          <div className="col-3">
            <p>Solicitudes: 30</p>
            <p>Monto: 100</p>
          </div>
          <div className="col-3">
            <p>Solicitudes: 30</p>
            <p>Monto: 100</p>
          </div>
        </div> */}
      </div>

      <div className="modal-body">
        <div className="row">
          <div className="col-12 m-b-md">
            <h4>Selecciona las opciones que desees</h4>
          </div>
          { listaExcepciones && listaExcepciones.map((excepcion, index) => (
            <div className="col-4 m-b-md" key={index}>
              <span style={{'cursor': 'pointer'}} className={listaExcepcionesSeleccionadas && listaExcepcionesSeleccionadas.includes(excepcion) ? 'text-info' : ''} onClick={ () => seleccionarExcepcion(excepcion) }>
                { listaExcepcionesSeleccionadas && listaExcepcionesSeleccionadas.includes(excepcion) && <i className="fa fa-check"></i> } {excepcion}
              </span>
              {/*<div className="row">*/}
              {/*  <div className="col-2 text-right">*/}
              {/*    <input type="checkbox" name="no_valido_antiguedad" onChange={() => setShowModal(false)} checked={false} />*/}
              {/*  </div>*/}
              {/*  <div className="col-10 text-left">{excepcion}</div>*/}
              {/*</div>*/}
            </div>
          )) }
        </div>
        <div className="row m-t-md">
          <div className="col-12 form-group">
            <label htmlFor="" className="">Comentario de excepción</label>
            <textarea
              className="form-control"
              name="comentario"
              rows="3"
              cols="30"
              placeholder='Escribe un comentario'
              value={comentarioExcepcion}
              onChange={(e) => setComentarioExcepcion(e.target.value)}
              required></textarea>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-white" onClick={() => setShowModal(false) }>Cerrar</button>
        <button type="button" className="btn btn-primary" onClick={() => handleChangeSubmit()  } >Aceptar</button>
      </div>
    </Modal>
  )
}

export default ModalExcepcionRiesgo;
