import React, {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';

const GraficaPorEtapasAgrupadas = ({pipelines}) => {
    const [nombresChart, setNombresChart] = useState([]);
    const [duracionesMinsChart, setDuracionesMinsChart] = useState([]);

    useEffect(() => {
        let nombres = [];
        let duracionesMins = [];
        
        if (Object.entries(pipelines).length !== 0) {
            console.log('estos son los pipes ', pipelines);
            pipelines.forEach((pipeline) => {
                nombres.push(pipeline.nombre);
                duracionesMins.push(pipeline.duracion);
            });
        }
        
        setNombresChart(nombres);
        setDuracionesMinsChart(duracionesMins);
    }, [pipelines])

    return (
        <Bar
            style={{height: "500px"}}
            data={{
                labels: nombresChart,
                datasets: [
                    {
                        label: 'Etapas',
                        data: duracionesMinsChart, // [45,30,15,41,56,32,44,12,25,54,33,45,25,19,55,47,53,25],
                        
                        backgroundColor: [
                            'rgba(41, 179, 148, 0.6)',
                        ],
                        borderColor: [
                            'rgba(41, 179, 148, 1)',
                        ],
                        borderWidth: 2,
                    }
                ]
            }}
            options={{
                indexAxis: 'y',
                plugins:{
                    title: {
                        display: false,
                        text: ''
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label(context) {
                                let duracionSTR = pipelines.find(duracion => duracion.nombre === context.label);
                                return duracionSTR.duracion_str;
                            }
                        }                           
                    },
                },
                responsive: true,
                maintainAspectRatio: false
            }}
        />
    );
}
 
export default GraficaPorEtapasAgrupadas;