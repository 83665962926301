import React from 'react';

const Card = ({periodo, titulo, cantidad, tipo, extra = null}) => {
    return (
        <div className="ibox">
            <div className="ibox-title">
                <div className="ibox-tools">
                    <span className={`label label-${tipo || 'primary'} float-right`}>{periodo}</span>
                </div>
                <h5>{titulo}</h5>
            </div>
            <div className="ibox-content">
                <h1 className="no-margins">{cantidad}</h1>
                {extra && 
                    <>
                        <div className={`stat-percent font-bold text-${tipo || 'primary'}`}>{extra.cantidad}</div>
                        <small>{extra.titulo}</small>
                    </>
                }
                
            </div>
        </div>
    );
}
 
export default Card;