import React, { Component } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import Autenticado from '../../components/Autenticado';
import spinner from '../../img/spinner.gif';
import { obtenerEmpresas } from '../../api/Empresas';
import { crearPagoMasivo, validarArchivoPagoMasivo } from '../../api/Pagos';
import { currencyFormat } from '../../utils/filters';
import { validateDecimal } from '../../utils/regex';
import { BASE_URL } from '../../utils/config';

class Masivos extends Component {
  state = {
    titulo: 'Pagos masivos',
    empresas: [],
    empresa: '',
    archivo: null,
    errores: {
      empresa: [],
      archivo: [],
      tipo_pago: [],
      fecha_pago: [],
      monto_total: [],
      referencia: [],
      comprobante: [],
      comentario: []
    },
    pagos: [],
    pagosErroneos: [],
    totalPagos: 0,
    pagosSinError: 0,
    montoTotal: '',
    fechaPago: new Date(),
    tipoPago: '',
    referencia: '',
    comentario: '',
    comprobante: null,
    verLista: true,
    motivo: '',
    options_motivo: [],
  };

  componentDidMount = async () => {
    const resp = await obtenerEmpresas({page_size: 1000});
    const empresas = resp.data.results.map(obj => {
      return { label: obj.nombre, value: obj.id };
    });
    empresas.unshift({
      label: 'Varias empresas',
      value: ''
    });
    this.setState({ empresas });
  };

  cargando = () => {
    Swal.fire({
      text: 'Espera un momento por favor...',
      showConfirmButton: false,
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      imageUrl: spinner,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Espera un momento'
    });
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 5000,
      showConfirmButton: false
    });
  };

  cerrarAlertas = () => {
    Swal.close();
  };

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState({ [name]: value });
  };

  handleImageChange = async e => {
    e.preventDefault();
    const {
      target: { name, files }
    } = e;
    const file = files[0];
    if (file) {
      this.setState({ [name]: file });
    } else {
      this.setState({ [name]: null });
    }
  };

  handleChangeDate = date => {
    const fechaPago = date[0];
    this.setState({ fechaPago });
  };

  handleKeyPress = (validation, e) => {
    const charCode = e.which ? e.which : e.keyCode;
    switch (validation) {
      case undefined:
        return true;
      case 'only_numbers':
        if (charCode >= 48 && charCode <= 57) return true;
        e.preventDefault();
        break;
      case 'read_only': {
        const _e = e || window.event;
        _e.preventDefault();
        break;
      }
      case 'decimal': {
        const value = `${e.target.value}${e.key}`;
        if (!validateDecimal(value)) e.preventDefault();
        break;
      }
      default:
        return true;
    }
    return true;
  };

  validarArchivo = () => {
    let validado = true;
    const errores = {
      empresa: [],
      archivo: [],
      tipo_pago: [],
      fecha_pago: [],
      monto_total: [],
      referencia: [],
      comprobante: [],
      comentario: [],
      motivo: []
    };
    const { archivo } = this.state;

    if (!archivo) {
      validado = false;
      errores.archivo = ['Selecciona un archivo por favor.'];
    }

    this.setState({ errores });
    return validado;
  };

  validarForm = () => {
    let validado = true;
    const errores = {
      empresa: [],
      archivo: [],
      tipo_pago: [],
      fecha_pago: [],
      monto_total: [],
      referencia: [],
      comprobante: [],
      comentario: [],
      motivo: []
    };
    const { montoTotal, tipoPago, motivo } = this.state;

    if (montoTotal.length === 0) {
      errores.monto_total = ['Este campo es requerido.'];
      validado = false;
    }

    if (tipoPago.length === 0) {
      errores.tipo_pago = ['Selecciona un tipo de pago.'];
      validado = false;
    }

    if (motivo.length === 0){
      errores.motivo = ['Selecciona un motivo.'];
      validado = false;
    }

    this.setState({ errores });
    return validado;
  };

  procesar = async () => {
    if (!this.validarArchivo()) {
      return;
    }
    this.cargando();
    const { archivo } = this.state;
    const formData = new FormData();
    formData.append('archivo', archivo);
    try {
      const resp = await validarArchivoPagoMasivo(formData);
      let pagos = resp.data.validated_data;
      let totalPagos = pagos.length;
      pagos = pagos.slice(-100);

      this.options_motivo = [
        { label: 'Seleccionar...', value: '' },
        { label: 'Amortización regular', value: 'amortizacion regular' }
      ]
      this.setState({ pagos:pagos, totalPagos: totalPagos  });
      this.cerrarAlertas();
    } catch (error) {
      if (error.status === 400 && error.hasOwnProperty('data')) {
        if (error.data.hasOwnProperty('errors')) {
          // let pagos = error.data.pagos;
          let pagosErroroneos = error.data.errors;
          let totalPagos = pagosErroroneos.length;
          pagosErroroneos = pagosErroroneos.filter(pago => {if(Object.keys(pago).length !== 0) {return true} return false } );

          pagosErroroneos = pagosErroroneos.slice(-100);
          this.setState({ pagosErroneos: pagosErroroneos, totalPagos: totalPagos });
          this.mostrarToast('Se encontraron errores en el archivo', 'error');

        }else if(error.data.hasOwnProperty('archivo')){
          this.mostrarToast(error.data.archivo, 'error');
        }

      }else{
        this.cerrarAlertas();
        this.mostrarToast(error.message, 'error');
      }
    }
  };

  importar = async enviarEmail => {
    if (!this.validarForm()) {
      return;
    }
    this.cargando();
    const {
      empresa,
      montoTotal,
      fechaPago,
      tipoPago,
      referencia,
      comentario,
      comprobante,
      archivo,
      motivo
    } = this.state;
    const formData = new FormData();
    if (empresa) {
      formData.append('empresa', empresa);
    }
    if (referencia) {
      formData.append('referencia', referencia);
    }
    if (comentario) {
      formData.append('comentario', comentario);
    }
    if (comprobante) {
      formData.append('comprobante', comprobante);
    }
    formData.append('archivo', archivo);
    formData.append('monto_total', montoTotal);
    formData.append('fecha_pago', moment(fechaPago).format('YYYY-MM-DD'));
    formData.append('tipo_pago', tipoPago);
    formData.append('motivo', motivo);

    try {
      await crearPagoMasivo(formData);
      this.cerrarAlertas();
      this.mostrarToast(`Se creo correctamente el pago masivo.`);
      this.props.history.push('/pagos/masivos');
    } catch (error) {
      console.log('Error importador:', error);
      this.cerrarAlertas();
      if (error.status === 500) {
        this.mostrarToast('Ocurrio un error en el servidor.', 'error');
      } else if (error.status === 400) {
        const oErrores = {
          empresa: [],
          archivo: [],
          tipo_pago: [],
          fecha_pago: [],
          monto_total: [],
          referencia: [],
          comprobante: [],
          comentario: [],
          motivo: []
        };
        const errores = { ...oErrores, ...error.data };
        this.setState({ errores });
      } else {
        this.mostrarToast(error.message, 'error');
      }
    }
  };

  render() {
    const {
      titulo,
      empresas,
      empresa,
      errores,
      pagos,
      totalPagos,
      pagosErroneos,
      pagosSinError,
      montoTotal,
      fechaPago,
      tipoPago,
      referencia,
      comentario,
      // comprobante,
      verLista,
      motivo
    } = this.state;
    const guia = [
      { nombre: titulo, url: '/pagos/masivos', clase: 'active' },
      { nombre: 'Crear', url: '', clase: '' }
    ];

    const showErrors = (pago) => {
      // TODO: Regresar más de 1 error
      for (const [key, value] of Object.entries(pago)){ return <li className='text-danger'>{value}</li> }
    };

    const ListaDePagosErroneos = () => (
      <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Advertencia</th>
          </tr>
        </thead>
        <tbody>
          {pagosErroneos.map((pago, index) => (
            <tr key={`pago${index}`}>
              {/* Iterar por cada error */}
              <td>
                <ul>
                  {showErrors(pago)}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    );

    const ListaDePagos = () => (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Préstamo</th>
              <th>Monto</th>
              <th>Banco</th>
              <th>Referencia</th>
            </tr>
          </thead>
          <tbody>
            {pagos.map((pag, index) => (
              <tr key={`pag${index}`}>
                <td>{pag.prestamo}</td>
                <td>{currencyFormat(pag.monto)}</td>
                <td>{pag.cuenta}</td>
                <td>{pag.referencia}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    const formulario = pEmpresas => (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="empresa">Empresa</label>
              <select
                className="form-control"
                id="empresa"
                name="empresa"
                value={empresa}
                onChange={this.handleChange}
              >
                {pEmpresas.map(emp => (
                  <option key={`optemp${emp.value}`} value={emp.value}>
                    {emp.label}
                  </option>
                ))}
              </select>
              {errores.empresa.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`ee${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="tipoPago">Tipo de pago</label>
              <select
                className="form-control"
                id="tipoPago"
                name="tipoPago"
                value={tipoPago}
                onChange={this.handleChange}
              >
                <option value="">Selecciona...</option>
                <option value="cheque">Cheque</option>
                <option value="depósito en efectivo">
                  Depósito en efectivo
                </option>
                <option value="SPEI">SPEI</option>
                <option value="pago referenciado">Pago referenciado</option>
                <option value="cargo openpay">Cargo Openpay</option>
              </select>
              {errores.tipo_pago.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`tpe${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="motivo">Motivo</label>
                <select
                    className="form-control"
                    id="motivo"
                    name="motivo"
                    value={motivo}
                    onChange={this.handleChange}
                >
                { this.options_motivo.map((motivo) => {
                    return <option key={`m-${motivo.value}`} value={motivo.value}>{motivo.label}</option>
                }) }
              </select>
              {errores.motivo.map((obj, index) => (
                  <span
                    className="form-text m-b-none text-error"
                    key={`me${index}`}
                  >
                  {obj}
                  </span>
              ))}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="fechaPago">Fecha de pago</label>
              <Flatpickr
                className="form-control date"
                value={fechaPago}
                options={{
                  dateFormat: 'd-m-Y'
                }}
                id="fechaPago"
                onChange={this.handleChangeDate}
              />
              {errores.fecha_pago.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`fpe${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="montoTotal">Monto total</label>
              <input
                id="montoTotal"
                name="montoTotal"
                type="text"
                placeholder="Ej: 1000"
                className="form-control"
                value={montoTotal}
                onChange={this.handleChange}
                onKeyPress={e => this.handleKeyPress('decimal', e)}
              />
              {errores.monto_total.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`mte${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="referencia">Referencia</label>
              <input
                id="referencia"
                name="referencia"
                type="text"
                placeholder="Ingresa la referencia"
                value={referencia}
                className="form-control"
                onChange={this.handleChange}
              />
              {errores.referencia.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`ea${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="comprobante">Comprobante</label>
              <input
                type="file"
                className="form-control p36"
                id="comprobante"
                name="comprobante"
                onChange={this.handleImageChange}
              />
              {errores.comprobante.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`ce${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="comentario">Comentario</label>
              <textarea
                id="comentario"
                name="comentario"
                type="text"
                placeholder="Ingresa un comentario"
                className="form-control"
                rows="3"
                value={comentario}
                onChange={this.handleChange}
              />
              {errores.comentario.map((obj, index) => (
                <span
                  className="form-text m-b-none text-error"
                  key={`come${index}`}
                >
                  {obj}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <a href={`${BASE_URL}/download/pago-masivo-layout/`} className="btn btn-sm btn-warning pull-right">
                          <i className="fa fa-download"></i> Descargar layout
                        </a>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="archivo">Archivo</label>
                          <input
                            type="file"
                            className="form-control p36"
                            id="archivo"
                            name="archivo"
                            onChange={this.handleImageChange}
                          />
                          {errores.archivo.map((obj, index) => (
                            <span
                              className="form-text m-b-none text-error"
                              key={`ea${index}`}
                            >
                              {obj}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label>&nbsp;</label>
                        <button
                          type="button"
                          className="btn btn-success btn-sm btn-block"
                          onClick={this.procesar}
                        >
                          <i className="fa fa-cloud-upload" /> Procesar archivo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {pagosErroneos.length > 0 && (
              <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-content">
                        {ListaDePagosErroneos()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            )}

            {pagos.length > 0 && (
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>
                            <strong>
                              Resumen de {pagos.length.toString()} de{' '} {totalPagos} pagos totales a importar.
                            </strong>
                          </h3>
                          {verLista ? ListaDePagos() : formulario(empresas)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {verLista ? (
                  <div className="col-md-3 pb25">
                    {pagos.length > 0 ? (
                      <button
                        type="button"
                        className="btn btn-success btn-sm btn-block"
                        onClick={() => this.setState({ verLista: false })}
                      >
                        <i className="fa fa-arrow-right " /> Siguiente
                      </button>
                    ) : (
                      <span>
                        Para importar pagos el archivo no debe de contener
                        ningún error, por favor corrija el archivo y vuelva a
                        intentarlo.
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="col-md-3 pb25">
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-block"
                      onClick={() => this.setState({ verLista: true })}
                    >
                      <i className="fa fa-arrow-left " /> Anterior
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm btn-block"
                      onClick={this.importar}
                    >
                      <i className="fa fa-floppy-o " /> Crear pago masivo
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Masivos;
