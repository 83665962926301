import React, { Component } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import Autenticado from '../../components/Autenticado';
import spinner from '../../img/spinner.gif';
// import { obtenerEmpresas } from '../../api/Empresas';
import { validarCrearArchivoRespuesta, /*crearPagoMasivo,*/ configuracionBanco } from '../../api/Domiciliacion'
import { currencyFormat/*, dateFormat, dateTimeFormat, timeFormat*/ } from '../../utils/filters';
// import { validateDecimal } from '../../utils/regex';
// import SemaforoColor from '../../components/ColorSemaforo';
// import { Link } from 'react-router-dom';
// import TablaCotizacion from '../../components/TablaCotizacion';
// import CuestionarioCompliance from '../../components/CuestionarioCompliance';
// import Tabla from '../../components/Tabla';
// import CartaBuro from '../solicitudes/CartaBuro';
// import Modal from 'react-awesome-modal';
// import Formulario from '../../components/form/Form';
// import { verificarPermiso } from '../../utils/auth';
// import DatePicker from '../../components/form/fields/DatePicker';

class DomiciliacionLeer extends Component {
  state = {
    loading: false,
    titulo: 'Leer archivo domiciliación',
    archivo_pagos: null,
    archivo_empleados: null,
    fechaPago: new Date(),
    // tipoPago: '',
    referencia: '',
    // comentario: '',
    // comprobante: null,
    errores: {
      archivo_pagos: [],
      archivo_empleados: [],
      // tipo_pago: [],
      fecha_pago: [],
      // monto_total: [],
      referencia: [],
      // comentario: []
    },
    pagos_efectuados: [],
    pagos_no_efectuados: [],
    pagos_excedentes: [],
    monto_total_aplicado: 0,
    altas_efectuadas: [],
    altas_no_efectuadas: [],

    bancosEmpleados: [],
    bancoEmpleados: 0,
    bancosPagos: [],
    bancoPagos: 0,
    bancosReferencia:[
      'banorte'
    ],

    proceso_pagos_terminado: false,
    proceso_altas_terminado: false,
    pestanaSeleccionadaRespuesta: 1,
    pestanaSeleccionadaResultadosPagos: 1,
    pestanaSeleccionadaResultadosAltas: 1,

    id_pago_masivo: null,
  };

  componentDidMount = async () => {
    const respBanco = await configuracionBanco()
    const bancosPagos = respBanco.data.map(obj => {
      if(obj.pagos) {
        return { label: obj.banco, value: obj.id };
      }
    });
    bancosPagos.unshift({ label: 'Seleccionar Banco', value: ''});

    let bancosEmpleados = []
    respBanco.data.forEach(obj => {
      if(obj.empleados) {
        bancosEmpleados.push({ label: obj.banco, value: obj.id })
      }
    });

    bancosEmpleados.unshift({ label: 'Seleccionar Banco', value: ''});

    // console.log(bancosEmpleados, bancosPagos)
    this.setState({bancosEmpleados, bancosPagos });
  };

  cargando = (loading=true) => {
    if(loading){
      Swal.fire({
        text: 'Espera un momento por favor...',
        showConfirmButton: false,
        position: 'center',
        allowOutsideClick: false,
        allowEscapeKey: false,
        imageUrl: spinner,
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Espera un momento'
      });
    }else {
      Swal.close();
    }
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };

  handleChange = e => {
    let { titulo, bancosPagos} = this.state
    const {
      target: { name, value }
    } = e;
    let banco_nombre = ''
    titulo = 'Leer archivo domiciliación'
    if (name === 'bancoPagos' || name === 'bancoEmpleados'){
      let banco = bancosPagos.filter(b => b.value === parseInt(value))
      if (banco.length > 0)
        banco_nombre = titulo + ' - ' + banco[0].label
      else
        banco_nombre = titulo

      this.setState({ [name]: value, titulo: banco_nombre });
    }
    this.setState({ [name]: value})
  };

  handleFileChange = async e => {
    e.preventDefault();
    const {
      target: { name, files }
    } = e;
    const file = files[0];
    if (file) {
      this.setState({ [name]: file });
    } else {
      this.setState({ [name]: null });
    }
  };

  handleChangeDate = date => {
    const fechaPago = date[0];
    this.setState({ fechaPago });
  };

  seleccionarPestana(pestanaSeleccionada, pagos=false, altas=false) {
    if(pagos) {
      this.setState({ pestanaSeleccionadaResultadosPagos: pestanaSeleccionada })
    }else if(altas){
      this.setState({ pestanaSeleccionadaResultadosAltas: pestanaSeleccionada })
    }else {
      this.setState({ pestanaSeleccionadaRespuesta: pestanaSeleccionada })
    }
  }

  mostrarReferencia = (idBancoConfig) =>{
    const {bancosReferencia, bancosPagos} = this.state;
    let bancoSeleccionado = bancosPagos.find(banco => banco.value == idBancoConfig)
    if(bancoSeleccionado && bancoSeleccionado.hasOwnProperty('label')) {
      return bancosReferencia.some(banco => banco.toUpperCase() === bancoSeleccionado.label.toUpperCase())
    }
    return false
  }

  volverAProcesarArchivo = (pagos=false, altas=false ) => {
    let archivo_pagos = null
    let archivo_empleados = null
    let fechaPago = new Date()
    let referencia = ''
    let errores = {
      archivo_pagos: [],
      archivo_empleados: [],
      fecha_pago: [],
      referencia: [],
    }

    let bancoPagos = 0
    let bancoEmpleados = 0

    let pagos_efectuados = []
    let pagos_no_efectuados = []
    let pagos_excedentes = []
    let monto_total_aplicado = 0
    let altas_efectuadas = []
    let altas_no_efectuadas = []

    let proceso_pagos_terminado = false
    let proceso_altas_terminado = false

    let pestanaSeleccionadaResultadosAltas = 1
    let pestanaSeleccionadaResultadosPagos = 1

    let id_pago_masivo = null

    this.setState({
      archivo_pagos,
      archivo_empleados,
      fechaPago,
      referencia,
      errores,
      bancoPagos,
      bancoEmpleados,
      pagos_efectuados,
      pagos_no_efectuados,
      pagos_excedentes,
      monto_total_aplicado,
      altas_efectuadas,
      altas_no_efectuadas,
      proceso_pagos_terminado,
      proceso_altas_terminado,
      pestanaSeleccionadaResultadosAltas,
      pestanaSeleccionadaResultadosPagos,
      id_pago_masivo,
    })
    if(pagos) {
      this.setState({
        pestanaSeleccionadaRespuesta: 1,
      })
    }else if(altas){
      this.setState({
        pestanaSeleccionadaRespuesta: 2,
      })
    }
  }

  validarArchivo = () => {
    let validado = true;
    const { pestanaSeleccionadaRespuesta } = this.state
    const errores = {
      archivo_pagos: [],
      archivo_empleados: [],
      // tipo_pago: [],
      fecha_pago: [],
      // monto_total: [],
      referencia: [],
      // comprobante: [],
      // comentario: []
    };

    if(pestanaSeleccionadaRespuesta === 1) {
      const { archivo_pagos, referencia, bancoPagos } = this.state;

      if (!archivo_pagos) {
        validado = false;
        errores.archivo_pagos = ['Selecciona un archivo por favor.'];
      }
      if (referencia === '' && this.mostrarReferencia(bancoPagos)) {
        validado = false;
        errores.referencia = ['Debe colocar una Referencia'];
      }

      this.setState({
        errores,
      });

    }else if(pestanaSeleccionadaRespuesta === 2) {
      const { archivo_empleados } = this.state;

      if (!archivo_empleados) {
        validado = false;
        errores.archivo_empleados = ['Selecciona un archivo por favor.'];
      }

      this.setState({
        errores,
      });

    }

    return validado;
  };

  procesarArchivo = async () => {
    const { pestanaSeleccionadaRespuesta } = this.state;
    if (!this.validarArchivo()) {
      return;
    }
    this.cargando();
    const formData = new FormData();
    if(pestanaSeleccionadaRespuesta === 1) {
      const { archivo_pagos, bancoPagos, fechaPago, referencia } = this.state;
      formData.append('domi_pago', true);
      formData.append('archivo', archivo_pagos);
      formData.append('banco', bancoPagos);
      formData.append('fecha_pago', moment(fechaPago).format('YYYY-MM-DD'));
      formData.append('referencia', referencia);

    }else if(pestanaSeleccionadaRespuesta === 2) {
      const { archivo_empleados, bancoEmpleados } = this.state;
      formData.append('domi_empleado', true);
      formData.append('archivo', archivo_empleados);
      formData.append('banco', bancoEmpleados);
    }
    try {
      const resp = await validarCrearArchivoRespuesta(formData);
      const data = resp.data;
      console.log(data)
      if(pestanaSeleccionadaRespuesta === 1) {
        const pagos_efectuados = data.pagos_efectuados
        const pagos_excedentes = data.pagos_excedentes
        const pagos_no_efectuados = data.pagos_no_efectuados
        const pagos_con_excepcion = data.pagos_con_excepcion
        const monto_total_aplicado = data.monto_total_aplicado
        const id_pago_masivo = data.id_pago_masivo
        this.setState({
          pagos_efectuados,
          pagos_excedentes,
          pagos_no_efectuados,
          monto_total_aplicado,
          proceso_pagos_terminado: true,
          id_pago_masivo,
          pagos_con_excepcion
        });

      }else if(pestanaSeleccionadaRespuesta === 2) {
        const altas_efectuadas = data.altas_efectuadas
        const altas_no_efectuadas = data.altas_no_efectuadas
        this.setState({
          altas_efectuadas,
          altas_no_efectuadas,
          proceso_altas_terminado: true,
        });
      }
      this.cargando(false);
      this.mostrarToast("Se ha procesado el archivo")
    } catch (error) {
      console.log('Error importador:', error);
      let message = ''
      if(error.data.hasOwnProperty('message')){
        message = error.data.message[0]
      } if(error.data.hasOwnProperty('error')){
        message = error.data.error
      } else{
        message = error.message
      }
      this.mostrarToast(message, 'error');
    }
  };

  render() {
    const {
      titulo,
      errores,
      fechaPago,
      // tipoPago,
      referencia,
      // comentario,
      // comprobante,

      bancosEmpleados,
      bancoEmpleados,

      bancosPagos,
      bancoPagos,

      pagos_efectuados,
      pagos_excedentes,
      pagos_no_efectuados,
      monto_total_aplicado,
      id_pago_masivo,

      altas_efectuadas,
      altas_no_efectuadas,

      proceso_altas_terminado,
      proceso_pagos_terminado,
      pestanaSeleccionadaRespuesta,
      pestanaSeleccionadaResultadosPagos,
      pestanaSeleccionadaResultadosAltas,
      pagos_con_excepcion,
    } = this.state;

    const guia = [
      { nombre: titulo, url: '/domiciliacion/leer', clase: 'active' },
      { nombre: 'Leer Archivo', url: '', clase: '' }
    ];

    const ListaDePagosEfectuados = () => (
      <div className="table-responsive" style={{'height':'500px'}}>
        <table className="table table-striped" style={{'overflowY':'scroll'}}>
          <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Empleado</th>
            {/* <th>Frecuencia</th> */}
            <th>Fecha de Aplicación</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>Monto</th>
            <th>Referencia</th>
            <th className="text-center">Estatus</th>
            <th>Código Respuesta</th>
            {/*<th>Advertencia</th>*/}
          </tr>
          </thead>
          <tbody>
          {pagos_efectuados.map((pag, index) => (
            <tr key={`pag${index}`}>
              <td>{pag.folio}</td>
              <td>{pag.empresa}</td>
              <td>{pag.empleado}</td>
              {/* <td>{pag.frecuencia}</td> */}
              <td>{pag.fecha_aplicacion}</td>
              <td>{pag.banco}</td>
              <td>{pag.cuenta}</td>
              <td>{currencyFormat(pag.monto)}</td>
              <td>{pag.referencia}</td>
              <td className="text-center">
                {pag.is_valid ? (
                  <i
                    className="fa fa-check text-success"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-times text-danger" aria-hidden="true" />
                )}
              </td>
              <td>{pag.codigo_respuesta}</td>
              {/*<td>*/}
              {/*  {pag.errors ? (*/}
              {/*    <ul>*/}
              {/*      {Object.keys(pag.errors).map(k => (*/}
              {/*        <li key={`li${k}`} className="text-danger">*/}
              {/*          { k === 'non_field_errors' ? pag.errors[k][0] : `${k}: ${pag.errors[k][0]}`}*/}
              {/*        </li>*/}
              {/*      ))}*/}
              {/*    </ul>*/}
              {/*  ) : (*/}
              {/*    <span>-</span>*/}
              {/*  )}*/}
              {/*</td>*/}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );

    const ListaDePagosNoEfectuados = () => (
      <div className="table-responsive" style={{'height':'500px'}}>
        <table className="table table-striped" style={{'overflowY':'scroll'}}>
          <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Empleado</th>
            {/* <th>Frecuencia</th> */}
            <th>Fecha de Aplicación</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>Monto</th>
            <th>Referencia</th>
            <th className="text-center">Estatus</th>
            <th>Código Respuesta</th>
            <th>Advertencia</th>
          </tr>
          </thead>
          <tbody>
          {pagos_no_efectuados.map((pag, index) => (
            <tr key={`pag${index}`}>
              <td>{pag.folio}</td>
              <td>{pag.empresa}</td>
              <td>{pag.empleado}</td>
              {/* <td>{pag.frecuencia}</td> */}
              <td>{pag.fecha_aplicacion}</td>
              <td>{pag.banco}</td>
              <td>{pag.cuenta}</td>
              <td>{currencyFormat(pag.monto)}</td>
              <td>{pag.referencia}</td>
              <td className="text-center">
                {pag.is_valid ? (
                  <i
                    className="fa fa-check text-success"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-times text-danger" aria-hidden="true" />
                )}
              </td>
              <td>{pag.codigo_respuesta}</td>
              <td>
                {pag.errors ? (
                  <ul>
                    {Object.keys(pag.errors).map(k => (
                      <li key={`li${k}`} className="text-danger">
                        { k === 'non_field_errors' ? pag.errors[k][0] : `${k}: ${pag.errors[k]}`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
    const ListaDePagosConExcepcion = () => (
      <div className="table-responsive" style={{'height':'500px'}}>
        <table className="table table-striped" style={{'overflowY':'scroll'}}>
          <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Empleado</th>
            {/* <th>Frecuencia</th> */}
            <th>Fecha de Aplicación</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>Monto</th>
            <th>Referencia</th>
            <th className="text-center">Estatus</th>
            <th>Código Respuesta</th>
            <th>Advertencia</th>
          </tr>
          </thead>
          <tbody>
          {pagos_con_excepcion.map((pag, index) => (
            <tr key={`pag${index}`}>
              <td>{pag.folio}</td>
              <td>{pag.empresa}</td>
              <td>{pag.empleado}</td>
              {/* <td>{pag.frecuencia}</td> */}
              <td>{pag.fecha_aplicacion}</td>
              <td>{pag.banco}</td>
              <td>{pag.cuenta}</td>
              <td>{currencyFormat(pag.monto)}</td>
              <td>{pag.referencia}</td>
              <td className="text-center">
                {pag.is_valid ? (
                  <i
                    className="fa fa-check text-success"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-times text-danger" aria-hidden="true" />
                )}
              </td>
              <td>{pag.codigo_respuesta}</td>
              <td>
                {pag.errors ? (
                  <ul>
                    {Object.keys(pag.errors).map(k => (
                      <li key={`li${k}`} className="text-danger">
                        { k === 'non_field_errors' ? pag.errors[k][0] : `${k}: ${pag.errors[k]}`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );

    const ListaDePagosExcedentes = () => (
      <div className="table-responsive" style={{'height':'500px'}}>
        <table className="table table-striped" style={{'overflowY':'scroll'}}>
          <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Empleado</th>
            {/* <th>Frecuencia</th> */}
            <th>Fecha de Aplicación</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>Monto</th>
            <th>Excedente</th>
            <th>Referencia</th>
            <th className="text-center">Estatus</th>
            <th>Código Respuesta</th>
            <th>Advertencia</th>
          </tr>
          </thead>
          <tbody>
          {pagos_excedentes.map((pag, index) => (
            <tr key={`pag${index}`}>
              <td>{pag.folio}</td>
              <td>{pag.empresa}</td>
              <td>{pag.empleado}</td>
              {/* <td>{pag.frecuencia}</td> */}
              <td>{pag.fecha_aplicacion}</td>
              <td>{pag.banco}</td>
              <td>{pag.cuenta}</td>
              <td>{currencyFormat(pag.monto)}</td>
              <td><b>{currencyFormat(pag.excedente)}</b></td>
              <td>{pag.referencia}</td>
              <td className="text-center">
                {pag.is_valid ? (
                  <i
                    className="fa fa-check text-success"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-times text-danger" aria-hidden="true" />
                )}
              </td>
              <td>{pag.codigo_respuesta}</td>
              <td>
                {pag.errors ? (
                  <ul>
                    {Object.keys(pag.errors).map(k => (
                      <li key={`li${k}`} className="text-danger">
                        { k === 'non_field_errors' ? pag.errors[k][0] : `${k}: ${pag.errors[k]}`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );

    const ListaDeAltasEfectuados = () => (
      <div className="table-responsive" style={{'height':'500px'}}>
        <table className="table table-striped" style={{'overflowY':'scroll'}}>
          <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Empleado</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>Tipo Cuenta</th>
            <th>Documento Identificacion</th>
            <th>RFC/CURP</th>
            <th className="text-center">Estatus</th>
            <th>Código Respuesta</th>
            <th>Advertencia</th>
          </tr>
          </thead>
          <tbody>
          {altas_efectuadas.map((alta, index) => (
            <tr key={`pag${index}`}>
              <td>{alta.folio}</td>
              <td>{alta.empresa}</td>
              <td>{alta.empleado}</td>
              <td>{alta.banco}</td>
              <td>{alta.cuenta}</td>
              <td>{alta.tipo_cuenta}</td>
              <td>{alta.documento_identificacion}</td>
              <td>{alta.rfc_curp_titular_cargo}</td>
              <td className="text-center">
                {alta.is_valid ? (
                  <i
                    className="fa fa-check text-success"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-times text-danger" aria-hidden="true" />
                )}
              </td>
              <td>{alta.clave_rechazo}</td>
              <td>
                {alta.errors ? (
                  <ul>
                    {Object.keys(alta.errors).map(k => (
                      <li key={`li${k}`} className="text-danger">
                        { k === 'non_field_errors' ? alta.errors[k][0] : `${k}: ${alta.errors[k]}`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );

    const ListaDeAltasNoEfectuados = () => (
      <div className="table-responsive" style={{'height':'500px'}}>
        <table className="table table-striped" style={{'overflowY':'scroll'}}>
          <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Empleado</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>Tipo Cuenta</th>
            <th>Documento Identificacion</th>
            <th>RFC/CURP</th>
            <th className="text-center">Estatus</th>
            <th>Código Respuesta</th>
            <th>Advertencia</th>
          </tr>
          </thead>
          <tbody>
          {altas_no_efectuadas.map((alta, index) => (
            <tr key={`pag${index}`}>
              <td>{alta.folio}</td>
              <td>{alta.empresa}</td>
              <td>{alta.empleado}</td>
              <td>{alta.banco}</td>
              <td>{alta.cuenta}</td>
              <td>{alta.tipo_cuenta}</td>
              <td>{alta.documento_identificacion}</td>
              <td>{alta.rfc_curp_titular_cargo}</td>
              <td className="text-center">
                {alta.is_valid ? (
                  <i
                    className="fa fa-check text-success"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-times text-danger" aria-hidden="true" />
                )}
              </td>
              <td>{alta.clave_rechazo}</td>
              <td>
                {alta.errors ? (
                  <ul>
                    {Object.keys(alta.errors).map(k => (
                      <li key={`li${k}`} className="text-danger">
                        { k === 'non_field_errors' ? alta.errors[k][0] : `${k}: ${alta.errors[k]}`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                <li>

                  <a className={`nav-link ${pestanaSeleccionadaRespuesta === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1)} >Pagos</a>
                </li>
                <li>
                  <a className={`nav-link ${pestanaSeleccionadaRespuesta === 2 && 'active'}`} onClick={() => this.seleccionarPestana(2)} >Empleados</a>
                </li>
              </ul>
              <div className="tab-content">
                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaRespuesta === 1 && 'active'}`}>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-12 m-b-xs">
                        { id_pago_masivo && <>
                          <div className="row">
                            <div className="col-lg-12">
                              <b><a href={`/pagos/masivos/${id_pago_masivo}`} target="_blank" rel="noopener noreferrer">Pago Masivo ({pagos_efectuados.length + pagos_excedentes.length}) - {currencyFormat(monto_total_aplicado)}</a></b>
                              <button
                                type="button"
                                className="btn btn-success btn-sm btns-space right"
                                onClick={() => this.volverAProcesarArchivo(true, false)}
                              >
                                Procesar de nuevo
                              </button>
                            </div>
                          </div>
                        </>}
                      </div></div>


                    { !proceso_pagos_terminado && <>
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label htmlFor="banco">Banco</label>
                            <select
                              className="form-control"
                              id="bancoPagos"
                              name="bancoPagos"
                              value={bancoPagos}
                              onChange={this.handleChange}
                            >
                              {bancosPagos.map(bancoPagos => (
                                <option key={`obancoPag${bancoPagos.value}`} value={bancoPagos.value}>
                                  {bancoPagos.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="archivo">Archivo</label>
                            <input
                              type="file"
                              className="form-control p36"
                              id="archivo_pagos"
                              name="archivo_pagos"
                              onChange={this.handleFileChange}
                              disabled={bancoPagos === '' || bancoPagos === 0}
                            />
                            {errores.archivo_pagos.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`ea${index}`}
                              >
                            {obj}
                          </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <label>&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-success btn-sm btn-block"
                            onClick={this.procesarArchivo}
                            disabled={bancoPagos === '' || bancoPagos === 0}
                          >
                            <i className="fa fa-cloud-upload" /> Procesar archivo
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label htmlFor="fechaPago">Fecha de pago</label>
                            <Flatpickr
                              className="form-control date"
                              value={fechaPago}
                              options={{
                                dateFormat: 'd-m-Y'
                              }}
                              id="fechaPago"
                              onChange={this.handleChangeDate}
                              disabled={bancoPagos === '' || bancoPagos === 0}
                            />
                            {errores.fecha_pago.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`fpe${index}`}
                              >
                            {obj}
                          </span>
                            ))}
                          </div>
                        </div>
                        {this.mostrarReferencia(bancoPagos) &&
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="referencia">Referencia</label>
                            <input
                              id="referencia"
                              name="referencia"
                              type="text"
                              placeholder="Ingresa la referencia"
                              value={referencia}
                              className="form-control"
                              onChange={this.handleChange}
                            />
                            {errores.referencia.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`ea${index}`}
                              >
                              {obj}
                            </span>
                            ))}
                          </div>
                        </div>
                        }
                      </div>
                    </>}

                    
                    { proceso_pagos_terminado && <>
                      <div className="tabs-container">
                        <ul className="nav nav-tabs" role="tablist">
                          <li>
                            <a className={`nav-link ${pestanaSeleccionadaResultadosPagos === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1, true)} >Pagos Efectuados ({pagos_efectuados.length})</a>
                          </li>
                          <li>
                            <a className={`nav-link ${pestanaSeleccionadaResultadosPagos === 2 && 'active'}`} onClick={() => this.seleccionarPestana(2, true)} >Pagos Excedentes ({pagos_excedentes.length})</a>
                          </li>
                          <li>
                            <a className={`nav-link ${pestanaSeleccionadaResultadosPagos === 3 && 'active'}`} onClick={() => this.seleccionarPestana(3, true)} >Pagos No Efectuados ({pagos_no_efectuados.length})</a>
                          </li>
                          <li>
                            <a className={`nav-link ${pestanaSeleccionadaResultadosPagos === 4 && 'active'}`} onClick={() => this.seleccionarPestana(4, true)} >Pagos Sin Factura ({pagos_con_excepcion.length})</a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaResultadosPagos === 1 && 'active'}`}>
                            <div className="panel-body">
                              <div className="row">
                                {/*<div className="col-sm-12">*/}
                                {/*  <h3>Monto aplicado: {currencyFormat(monto_total_aplicado)}</h3>*/}
                                {/*</div>*/}
                                {ListaDePagosEfectuados()}
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaResultadosPagos === 2 && 'active'}`}>
                            <div className="panel-body">
                              <div className="row">
                                {ListaDePagosExcedentes()}
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaResultadosPagos === 3 && 'active'}`}>
                            <div className="panel-body">
                              <div className="row">
                                {ListaDePagosNoEfectuados()}
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaResultadosPagos === 4 && 'active'}`}>
                            <div className="panel-body">
                              <div className="row">
                                {ListaDePagosConExcepcion()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}
                  </div>
                </div>
                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaRespuesta === 2 && 'active'}`}>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-12 m-b-xs">
                        { proceso_altas_terminado && <>
                          <div className="row">
                            <div className="col-lg-12">
                              <button
                                type="button"
                                className="btn btn-success btn-sm btns-space right"
                                onClick={() => this.volverAProcesarArchivo(false, true)}
                              >
                                Procesar de nuevo
                              </button>
                            </div>
                          </div>
                        </>}
                      </div></div>
                    { !proceso_altas_terminado &&
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="form-group">
                            <label htmlFor="banco">Banco</label>
                            <select
                              className="form-control"
                              id="bancoEmpleados"
                              name="bancoEmpleados"
                              value={bancoEmpleados}
                              onChange={this.handleChange}
                            >
                              {bancosEmpleados.map(bancoEmpleados => (
                                <option key={`obancoEmpl${bancoEmpleados.value}`} value={bancoEmpleados.value}>
                                  {bancoEmpleados.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="archivo">Archivo</label>
                            <input
                              type="file"
                              className="form-control p36"
                              id="archivo_empleados"
                              name="archivo_empleados"
                              onChange={this.handleFileChange}
                              disabled={bancoEmpleados === '' || bancoEmpleados === 0}
                            />
                            {errores.archivo_empleados.map((obj, index) => (
                              <span
                                className="form-text m-b-none text-error"
                                key={`ea${index}`}
                              >
                              {obj}
                            </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <label>&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-success btn-sm btn-block"
                            onClick={this.procesarArchivo}
                            disabled={bancoEmpleados === '' || bancoEmpleados === 0}
                          >
                            <i className="fa fa-cloud-upload" /> Procesar archivo
                          </button>
                        </div>
                      </div>
                    }
                    { proceso_altas_terminado && <>
                      <div className="tabs-container">
                        <ul className="nav nav-tabs" role="tablist">
                          <li>
                            <a className={`nav-link ${pestanaSeleccionadaResultadosAltas === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1, false, true)} >Altas Efectuados ({altas_efectuadas.length})</a>
                          </li>
                          <li>
                            <a className={`nav-link ${pestanaSeleccionadaResultadosAltas === 2 && 'active'}`} onClick={() => this.seleccionarPestana(2, false, true)} >Altas No Efectuados ({altas_no_efectuadas.length})</a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaResultadosAltas === 1 && 'active'}`}>
                            <div className="panel-body">
                              <div className="row">
                                {ListaDeAltasEfectuados()}
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={`tab-pane ${pestanaSeleccionadaResultadosAltas === 2 && 'active'}`}>
                            <div className="panel-body">
                              <div className="row">
                                {ListaDeAltasNoEfectuados()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
          .fa-margin {
            margin-right: 5px;
          }
          .right {
            float: right;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default DomiciliacionLeer;
