import React, { Component,useRef, useState } from 'react';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Autenticado from '../../components/Autenticado';
// import ToggleableLink from '../../components/ToggleableLink';
import Formulario from '../../components/form/Form';
import { reactivar_prestamo_domiciliacion, eliminar_prestamo_domiciliacion } from '../../api/Domiciliacion'
import {
  agregarComentariosPrestamo,
  agregarDocumentosPrestamo,
  cambiarFechaPrestamo,
  eliminarComentariosPrestamo,
  eliminarDocumentosPrestamo,
  obtenerAmortizacionesPrestamo,
  obtenerGastosCobranzaPrestamo,
  obtenerComentariosPrestamo,
  obtenerDocumentosPrestamo,
  obtenerMovimientosPrestamo,
  obtenerPagosPrestamo,
  obtenerPrestamo,
  actualizarFechasPrestamo,
  obtenerHistorialPagosDomiciliadosPrestamo,
  obtenerExcedentesPrestamo,
  crearReembolso
} from '../../api/Prestamos';
import { crearPago } from '../../api/Pagos';
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias';
import Tabla from '../../components/Tabla';
import Modal from 'react-awesome-modal';
import { currencyFormat, dateFormat, dateTimeFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
// import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { mostrarToast } from '../../utils/alerts';
import Checkbox from '../../components/form/fields/Checkbox';
import DatePicker from '../../components/form/fields/DatePicker';
import { verificarPermiso } from '../../utils/auth';
import { Link } from 'react-router-dom';
import Paginacion from '../../components/Paginacion';
import GenerarDatosCobranza from '../../components/prestamos/informacion/GenerarDatosCobranza';

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        pestanaSeleccionada: 1,
        prestamo: {},
        empleado: {},
        datos_cobranza: {},
        gastos_cobranza: [],
        amortizaciones: [],
        cuentas: [],
        excedentes: [],
        amortizacionesCols: [
            {
                llave: 'num_amortizacion',
                etiqueta: '#',
                clase: 'centrado'
            },{
                llave: 'fecha',
                etiqueta: 'Fecha',
                tipo: 'fecha'
            },{
                llave: 'fecha_pagada',
                etiqueta: 'Fecha de pago',
                tipo: 'fecha'
            },{
                llave: 'dias_retraso',
                etiqueta: 'Días de retraso',
                clase: 'centrado'
            },{
                llave: 'capital',
                etiqueta: 'Capital',
                tipo: 'dinero'
            },{
                llave: 'interes',
                etiqueta: 'Interes',
                tipo: 'dinero'
            },{
                llave: 'iva',
                etiqueta: 'IVA',
                tipo: 'dinero'
            },{
                llave: 'gasto_cobranza',
                etiqueta: 'G.Cobranza',
                tipo: 'dinero'
            },
            {
                llave: 'total',
                etiqueta: 'Total',
                tipo: 'dinero'
            },
            {
                llave: 'abonado',
                etiqueta: 'Abonado',
                tipo: 'dinero'
            }
        ],
        pagos: [],
        pagosCols: [
            {
                llave: 'num_pago',
                etiqueta: '#',
                clase: 'centrado'
            },{
                llave: 'folio',
                etiqueta: 'Folio'
            },{
                llave: 'fecha_pago',
                etiqueta: 'Fecha de pago',
                tipo: 'fecha'
            },{
                llave: 'monto_aplicable',
                etiqueta: 'Monto Aplicable',
                tipo: 'dinero'
            },
            {
                llave: 'excedente',
                etiqueta: 'Excedente',
                tipo: 'dinero'
            },
            {
                llave: 'condonado',
                etiqueta: 'Condonado',
                tipo: 'dinero'
            },{
                llave: 'motivo',
                etiqueta: 'Motivo'
            },{
                llave: 'referencia',
                etiqueta: 'Referencia'
            },{
                llave: 'fecha_creacion',
                etiqueta: 'F. registro',
                tipo: 'fechaTiempo'
            },{
                llave: 'usuario_creo',
                etiqueta: 'Usuario que registró'
            },{
                llave: 'comentario',
                etiqueta: 'Comentario'
            },{
                llave: 'estatus',
                etiqueta: 'Estatus'
            },{
                llave: 'aplicado',
                etiqueta: 'Aplicado',
                tipo: 'icono',
                clase: 'centrado'
            }
        ],
        documentos: [],
        dataDocumento: {},
        errorsDocumento: [],
        datosElimDocumento: {},
        mostrarFrmDocumento: false,
        mostrarConfElimDocumento: false,
        comentarios: [],
        dataComentario: {
            'visible_empleado': true
        },
        errorsComentario: [],
        datosElimComentario: {},
        mostrarFrmComentario: false,
        mostrarConfElimComentario: false,
        movimientos: [],
        movimientosCols: [
            {
                llave: 'fecha_creacion',
                etiqueta: 'Fecha de creación',
                tipo: 'fecha'
            },{
                llave: 'usuario',
                etiqueta: 'Usuario'
            },{
                llave: 'movimiento',
                etiqueta: 'Movimiento'
            },{
                llave: 'comentario',
                etiqueta: 'Comentario'
            }
        ],
        domiciliacion: [],
        domiciliacionCols: [
            {
                llave: 'tipo',
                etiqueta: 'Tipo'
            },{
                llave: 'banco',
                etiqueta: 'Banco'
            },{
                llave: 'monto',
                etiqueta: 'Monto'
            },{
                llave: 'motivo',
                etiqueta: 'Motivo'
            },{
                llave: 'codigo_respuesta',
                etiqueta: 'Codigo de Respuesta'
            },{
                llave: 'fecha_creacion',
                etiqueta: 'Fecha de creación',
                tipo: 'fechaTiempo'
            }
        ],
        mostratAlerta: false,
        textoAlerta: '',
        mostrarCambiarFecha: false,
        fecha: moment().toDate(),
        errores_fecha: [],
        mostrarFrmPago: false,
        mostrarFrmReembolso : false,
        datosPago: {},
        datosReembolso: {},
        errorsPago: [],
        errorsReembolso:[],
        disabledSinIO: true,
        disabledLiquidacionAnticipada: true,
        disabledLiquidacionReembolso: true,
        mostrarActFecha: false,
        primer_amortizacion_sin_factura: null,
        fecha_inicio: moment().add(3, 'd').toDate(),
        dias_cobro: '',
        dias_cobro_error: { },
        // pagination
        totalRecords: 0,
        pageLimit: 50,
        modal_reactivar_domiciliacion: false,
        modal_quitar_domiciliacion: false,
        motivo_quitar_domiciliacion: '',
        comentario_quitar_domiciliacion: '',
        erroresDomiciliacion: {
            motivo: [],
            comentario: []
        }
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const tab = localStorage.getItem('tab')
        const pestanaSeleccionada = tab === null ? 1: parseInt(tab)
        this.setState({ pestanaSeleccionada })
        localStorage.removeItem('tab')
        const titulo = 'Ver prestamo'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            let res = await obtenerPrestamo(id)
            let prestamo = res.data
            prestamo = quitarNulos(prestamo)
            let { empleado,datos_cobranza } = prestamo
            empleado = quitarNulos(empleado)
            res = await obtenerGastosCobranzaPrestamo(id)
            const gastos_cobranza = res.data
            res = await obtenerAmortizacionesPrestamo(id)
            const amortizaciones = res.data
            res = await obtenerExcedentesPrestamo(id)
            const excedentes = res.data
            let capital = 0
            let interes = 0
            let iva = 0
            let gasto_cobranza = 0
            let abonado = 0
            let total = 0
            amortizaciones.forEach(a => {
                capital = a.capital_total
                interes = Math.round(parseFloat(a.interes)*100)/100 + interes
                iva = Math.round(parseFloat(a.iva)*100)/100 + iva
                total = Math.round(parseFloat(a.total)*100)/100 + total
                abonado = Math.round(parseFloat(a.abonado)*100)/100 + abonado
                gasto_cobranza = parseFloat(a.gasto_cobranza) + gasto_cobranza
            })
            let amortizacion = {
                num_amortizacion: 'Total',
                fecha: '',
                fecha_pagada: '',
                dias_retraso: '',
                capital,
                interes,
                iva,
                gasto_cobranza,
                total,
                abonado
            }
            amortizacion = quitarNulos(amortizacion)
            amortizaciones.push(amortizacion)
            res = await obtenerPagosPrestamo(id)
            const pagos = res.data
            res = await obtenerDocumentosPrestamo(id)
            const documentos = res.data
            res = await obtenerComentariosPrestamo(id)
            const comentarios = res.data
            res = await obtenerMovimientosPrestamo(id)
            const movimientos = res.data
            res = await obtenerHistorialPagosDomiciliadosPrestamo(id)
            const domiciliacion = res.data
            res = await obtenerCuentasBancarias()
            let cuentas = []
            res.data.forEach(obj => {
                cuentas.push({ label: obj.banco+' - '+obj.no_cuenta, value: obj.id });
            })
            cuentas.unshift({
                label:'Seleccionar...', value:''
            })

            const datosPago = prestamo.monto_amortizacion_pendiente ? {monto: prestamo.monto_amortizacion_pendiente} : {};
            const datosReembolso = {};
            const titulo = `Préstamo #${prestamo.folio}`;

            this.setState({
                prestamo,
                empleado,
                datos_cobranza,
                amortizaciones,
                gastos_cobranza,
                excedentes,
                pagos,
                documentos,
                comentarios,
                movimientos,
                domiciliacion,
                datosPago,
                datosReembolso,
                titulo,
                cuentas
            })
        } catch (error) {
            console.log(error)
        }
    }

    mostrarFormCambiarFecha() {
        this.setState({ mostrarCambiarFecha : true })
    }

    async cambiarFecha() {
        try {
            const { fecha, id } = this.state
            const datos = { fecha_inicio: moment(fecha).format('YYYY-MM-DD')}
            const resp = await cambiarFechaPrestamo(datos, id)
            console.log(resp)
            this.cerrarModal()
            await this.componentDidMount()
            this.setState({
                mostratAlerta: true,
                textoAlerta: 'Fechas cambiadas'
            })
        } catch (error) {
            console.log('Error: ' + error)
        }
    }

    mostrarFormActFecha() {
        const { prestamo } = this.state
        let primer_amortizacion_sin_factura = prestamo.primer_amortizacion_sin_factura
        let dias_cobro = prestamo.dias_cobro

        this.setState({ mostrarActFecha : true, primer_amortizacion_sin_factura, dias_cobro })
    }

    async actualizarFechas() {
        try {
            const { prestamo, fecha_inicio, primer_amortizacion_sin_factura, dias_cobro, id } = this.state
            const datos = {
                frecuencia: prestamo.frecuencia_cobro,
                fecha_inicio: moment(fecha_inicio).format('YYYY-MM-DD'),
                primer_amortizacion_sin_factura,
                dias_cobro,
            }
            const resp = await actualizarFechasPrestamo(datos, id)
            console.log(resp)
            this.cerrarModal()
            await this.componentDidMount()
            mostrarToast("Se actualizaron las fechas de amortización");
        } catch (error) {
            console.log(error)
            let message = 'Error al actualizar amortizaciones'
            if(error.hasOwnProperty('data')) {
                if (error.data.hasOwnProperty('dias_cobro')) {
                    mostrarToast(error.data.dias_cobro, 'error');
                    this.setState({dias_cobro_error:error.data.dias_cobro})
                } else if (error.data.hasOwnProperty('mensaje')) {
                    mostrarToast(error.data.mensaje, 'error');
                }else{
                    this.cerrarModal()
                    mostrarToast(message, 'error');
                }
            }else{
                this.cerrarModal()
                mostrarToast(message, 'error');
            }
        }
    }

    mostrarFormDocumento() {
        this.setState({ mostrarFrmDocumento : true })
    }

    mostrarConfElimDocumento(idDocumento, indice) {
        const datosElimDocumento = { idDocumento, indice }
        this.setState({ mostrarConfElimDocumento : true, datosElimDocumento })
    }

    async agregarDocumento(){
        const { id, documentos, dataDocumento } = this.state
        try {
            const formData = new FormData()
            Object.keys(dataDocumento).forEach((key) => {
                formData.append(key, dataDocumento[key])
            })
            const resp = await agregarDocumentosPrestamo(formData, id)
            documentos.push(resp.data)
            this.setState({
                dataDocumento: {},
                documentos,
                mostrarFrmDocumento: false,
                mostratAlerta: true,
                textoAlerta: 'Documento agregado'
            })
        } catch (error) {
            const errorsDocumento = error.data
            this.setState({ errorsDocumento })
        }
    }

    async eliminarDocumento() {
        const { id, documentos, datosElimDocumento } = this.state
        const { idDocumento, indice } = datosElimDocumento
        await eliminarDocumentosPrestamo(id, idDocumento)
        documentos.splice(indice, 1)
        this.setState({
            documentos,
            mostrarConfElimDocumento : false,
            mostratAlerta: true,
            textoAlerta: 'Documento eliminado'
        })        
    }

    mostrarFormComentario() {
        this.setState({ mostrarFrmComentario : true })
    }

    mostrarConfElimComentario(idComentario, indice) {
        const datosElimComentario = { idComentario, indice }
        this.setState({ mostrarConfElimComentario : true, datosElimComentario })
    }

    async agregarComentario(){
        const { id, dataComentario } = this.state
        try {
            const resp = await agregarComentariosPrestamo(dataComentario, id)
            const { comentarios } = this.state
            comentarios.push(resp.data)
            this.setState({
                dataComentario: { 'visible_empleado': true },
                comentarios,
                mostrarFrmComentario: false,
                mostratAlerta: true,
                textoAlerta: 'Comentario agregado'
            })
        } catch (error) {
            const errorsComentario = error.data
            this.setState({ errorsComentario })
        }
    }

    async eliminarComentario() {
        const { id, comentarios, datosElimComentario } = this.state
        const { idComentario, indice } = datosElimComentario
        await eliminarComentariosPrestamo(id, idComentario)
        comentarios.splice(indice, 1)
        this.setState({
            comentarios,
            mostrarConfElimComentario : false,
            mostratAlerta: true,
            textoAlerta: 'Comentario eliminado'
        })        
    }

    cerrarModal() {

        const { prestamo } = this.state;

        const datosPago = prestamo.monto_amortizacion_pendiente ? {monto: prestamo.monto_amortizacion_pendiente} : {};
        const datosReembolso = {};
        this.setState({
            mostrarFrmComentario : false,
            mostrarConfElimComentario : false,
            dataComentario: {},
            errorsComentario: [],
            mostrarFrmDocumento : false,
            mostrarConfElimDocumento : false,
            dataDocumento: {},
            errorsDocumento: [],
            mostrarCambiarFecha: false,
            mostrarFrmPago: false,
            mostrarFrmReembolso : false,
            mostrarActFecha: false,
            datosPago,
            datosReembolso,
            errorsPago: [],
            errorsReembolso:[]
        });
    }

    seleccionarPestana(pestanaSeleccionada) {
        this.setState({ pestanaSeleccionada })
    }

    handleChangeDate = date => {
        const fecha_inicio = date[0]
        this.setState({ fecha_inicio })
    }

    mostrarFormPago() {
        this.setState({ mostrarFrmPago : true })
    }
    mostrarFormReembolso(){
        this.setState({ mostrarFrmReembolso : true })
    }
    async crearPago() {
        try {
            let { datosPago } = this.state
            const { id } = this.state
            datosPago = Object.assign({ prestamo: id }, datosPago)
            const formData = new FormData()
            Object.keys(datosPago).forEach((key) => {
                if (key === 'fecha_pago'){
                    if (datosPago[key]){
                        formData.append(key, moment(datosPago[key]).format('YYYY-MM-DD'))
                    }
                } else {
                    formData.append(key, datosPago[key])
                }
            })
            console.log('formData', formData)
            // Deshabilitamos el boton del form
            this.setState({ loading: true });
            // Enviamos la petición
            await crearPago(formData)
            // Habilitamos el boton del form
            this.setState({ loading: false });
            // Cerramos el form
            this.setState({ mostrarFrmPago : false })
            localStorage.setItem('tab', '4')
            window.location.reload(false)
        } catch (error) {
            // Habilitamos el boton del form
            this.setState({ loading: false });
            const errorsPago = error.data
            if(errorsPago.excepcion){
                mostrarToast(errorsPago.excepcion, 'error');
            }
            this.setState({ errorsPago })
        }

    }
    async crearReembolso(){
        try{
            let { datosReembolso } = this.state
            const { id } = this.state
            datosReembolso['comprobante'] = document.getElementById('comprobante_reembolso').files[0];
            datosReembolso = Object.assign({ prestamo: id }, datosReembolso)
            const formData = new FormData()
            Object.keys(datosReembolso).forEach((key) => {
                if (key === 'fecha_pago'){
                    if (datosReembolso[key]){
                        formData.append(key, moment(datosReembolso[key]).format('YYYY-MM-DD'))
                    }
                } else {
                    formData.append(key, datosReembolso[key])
                }
            }) 
            formData.append('estatus', 'pendiente');
            formData.append('tipo', 'reembolso');
            // Deshabilitamos el boton del form
            this.setState({ loading: true });
            // Enviamos la petición
            await crearReembolso(formData,id)
            // Habilitamos el boton del form
            this.setState({ loading: false });
            // Cerramos el form
            this.setState({ mostrarFrmReembolso : false })
            localStorage.setItem('tab', '9')
            window.location.reload(false)

        } catch (error) {
            // Habilitamos el boton del form
            console.log(error)
            this.setState({ loading: false });
            const errorsReembolso = error.data
            if(errorsReembolso.excepcion){
                mostrarToast(errorsReembolso.excepcion, 'error');
            }
            this.setState({ errorsReembolso })
        }
    }
    handleChangeData = async (e) => {
        const { datosPago } = this.state;
        const { datosReembolso } = this.state;
        const { target: { name, checked, files, value } } = e;
        let { disabledSinIO, disabledLiquidacionAnticipada, disabledLiquidacionReembolso } = this.state

        if (name === 'comprobante') {
            datosPago[name] = files[0];
            datosReembolso[name]=files[0];
        } else if (name === 'condonar_intereses_ordinarios') {
            datosPago[name] = checked;
        } else if (name === 'liquidacion_anticipada'){
            datosPago[name] = checked;
        } else if (name === 'liquidacion_reembolso'){
            datosPago[name] = checked;
        }else {
            if (name === 'motivo') {
                // disabledSinIO = !(value === 'liquidacion' || value === 'pago anticipado');
                disabledSinIO = !(value === 'liquidacion');
                disabledLiquidacionReembolso = !(value === 'liquidacion')
                disabledLiquidacionAnticipada = !(value === 'pago anticipado');

                if (disabledSinIO){
                    datosPago.condonar_intereses_ordinarios = false;
                }

                if (disabledLiquidacionAnticipada){
                    datosPago.liquidacion_anticipada = false;
                }
                if (disabledLiquidacionReembolso){
                    datosPago.liquidacion_reembolso = false;
                }
            }

            datosPago[name] = value;
            datosReembolso[name] = value;
        }

        this.setState({ datosPago, disabledSinIO, disabledLiquidacionAnticipada, disabledLiquidacionReembolso, datosReembolso})
    }

    InputComprobante() {
        const [file, setFile] = useState(null);
        const fileInputRef = useRef(null);
        
        const handleFileChange = () => {
            const selectedFile = fileInputRef.current.files[0];
            setFile(selectedFile);
        };
        return (
          <div>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} name="comprobante_reembolso" id="comprobante_reembolso" className="form-control"/>
          </div>
        );
    }

    handleDiasCobro = async (e) => {
        let { dias_cobro } = this.state;
        const { target: { name, /* checked, files, */ value } } = e;

        if (name === 'dias_cobro') {
            dias_cobro = value;
        }

        this.setState({ dias_cobro })
    }

    reactivar_domiciliacion(){
        this.setState({modal_reactivar_domiciliacion: true})
        console.log(this.state.prestamo)
    }

    btn_reactivar_domiciliacion = async () => {
        try{
            this.setState({ loading: true });
            const { prestamo } = this.state
            await reactivar_prestamo_domiciliacion(prestamo.id);
            this.setState({ modal_reactivar_domiciliacion:false, loading:false });
            mostrarToast('Préstamo reactivado correctamente.');
            localStorage.setItem('tab', '8')
            window.location.reload(false)
        }catch(error){
            console.log(error)
            mostrarToast(error.message, 'error');
        }
    }

    quitar_domiciliacion_change = (e) => {
        const motivo_quitar_domiciliacion = e.target.value;
        this.setState({motivo_quitar_domiciliacion});
    }
    
    handle_quitar_domiciliacion_change = (e) => {
        const comentario_quitar_domiciliacion = e.target.value;
        this.setState({ comentario_quitar_domiciliacion });
    }

    validar_quitar_domiciliacion = () => {
        let validado = true;
        const erroresDomiciliacion = {
          motivo: [],
          comentario: []
        };
        const { motivo_quitar_domiciliacion, comentario_quitar_domiciliacion } = this.state;

        if (motivo_quitar_domiciliacion === "Otro" && comentario_quitar_domiciliacion.length === 0){
            erroresDomiciliacion.comentario = ['Este campo es requerido.']
            validado = false
        }
        
        if(motivo_quitar_domiciliacion.length === 0){
            erroresDomiciliacion.motivo = ['Selecciona un motivo.']
            validado = false
        }
        
        this.setState({ erroresDomiciliacion });
        return validado;
    }

    quitar_domiciliacion(){
        this.setState({modal_quitar_domiciliacion: true, motivo_quitar_domiciliacion:''})
    }

    btn_quitar_prestamo_domiciliacion = async () => {
        if (!this.validar_quitar_domiciliacion()) {
            return;
        }
        try{
            const {prestamo, motivo_quitar_domiciliacion, comentario_quitar_domiciliacion} = this.state
            await eliminar_prestamo_domiciliacion(prestamo.id, motivo_quitar_domiciliacion, comentario_quitar_domiciliacion);
            mostrarToast('Préstamo bloqueado de la domiciliación correctamente.');
            this.setState({ modal_quitar_domiciliacion:false });
            localStorage.setItem('tab', '8')
            window.location.reload(false)
        }catch(error){
            mostrarToast(error);
        }
    }
    

    pagoForm(){

        const { prestamo, datosPago, errorsPago, cuentas, disabledSinIO, disabledLiquidacionAnticipada, disabledLiquidacionReembolso } = this.state;

        let options_motivo = []

        if(prestamo.tipo === 'tradicional') {
            // Si no ha generado ninguna factura y ningun pago o va al corriente, puede generar PAGO CAPITAL O PAGO LIQUIDACION
            if (prestamo.avance_capital === 0 || !prestamo.amortizaciones_con_factura_sin_pagar) {
                options_motivo = [
                    { label: 'Seleccionar...', value: '' },
                    { label: 'Pago capital', value: 'pago anticipado' },
                    { label: 'Liquidación', value: 'liquidacion' }
                ]
            }

            // Si tiene dias de retraso o facturas pendientes por pagar, puede generar PAGO NORMAL O PAGO MORATORIO
            if (prestamo.dias_retraso > 0 || prestamo.amortizaciones_con_factura_sin_pagar) {
                options_motivo = [
                    { label: 'Seleccionar...', value: '' },
                    { label: 'Amortización regular', value: 'amortizacion regular' },
                    { label: 'Penalización', value: 'penalizacion' }
                ]
            }
        }else{
            options_motivo = [
                { label: 'Seleccionar...', value: '' },
                { label: 'Amortización regular', value: 'amortizacion regular' },
                { label: 'Pago capital', value: 'pago anticipado' },
                { label: 'Liquidación', value: 'liquidacion' }
            ]
        }

        let options_tipo_pago = [
            {label:'Seleccionar...', value:''},
            {label:'Cheque', value:'cheque'},
            {label:'Depósito en efectivo', value:'depósito en efectivo'},
            {label:'SPEI', value:'SPEI'},
            {label:'Pago referenciado', value:'pago referenciado'},
            {label:'Cargo Openpay', value:'cargo openpay'},
            {label:'Liquidación virtual', value:'liquidacion virtual'}
        ]

        return (
          <div>
              <div className="row">
                  <div className="col-sm-6">
                      <div className={ errorsPago.monto !== undefined && errorsPago.monto.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Monto*</label>
                          <div className="col-sm-8">
                              <input type="text" name="monto" className="form-control"  onChange={this.handleChangeData} value={datosPago.monto || ''} />
                              { errorsPago.monto !== undefined && errorsPago.monto.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.monto }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsPago.motivo !== undefined && errorsPago.motivo.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Motivo*</label>
                          <div className="col-sm-8">
                              <select name="motivo" id="m_select" className="form-control" value={datosPago.motivo} onChange={this.handleChangeData}>
                                  { options_motivo.map((motivo) => {
                                      return <option key={`m-${motivo.value}`} value={motivo.value}>{motivo.label}</option>
                                  }) }
                              </select>
                              { errorsPago.motivo !== undefined && errorsPago.motivo.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.motivo }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsPago.tipo_pago !== undefined && errorsPago.tipo_pago.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Tipo de Pago*</label>
                          <div className="col-sm-8">
                              <select name="tipo_pago" id="tp_select" className="form-control" value={datosPago.tipo_pago} onChange={this.handleChangeData}>
                                  { options_tipo_pago.map((tipo_pago) => {
                                      return <option key={`tp-${tipo_pago.value}`} value={tipo_pago.value}>{tipo_pago.label}</option>
                                  }) }
                              </select>
                              { errorsPago.tipo_pago !== undefined && errorsPago.tipo_pago.length > 0 && <span className="form-text m-b-none text-error">{ errorsPago.tipo_pago }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsPago.cuenta !== undefined && errorsPago.cuenta.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Cuenta entrante*</label>
                          <div className="col-sm-8">
                              <select name="cuenta" id="c_select" className="form-control" value={datosPago.cuenta} onChange={this.handleChangeData}>
                                  { cuentas.map((cuenta) => {
                                      return <option key={`tp-${cuenta.value}`} value={cuenta.value}>{cuenta.label}</option>
                                  }) }
                              </select>
                              { errorsPago.cuenta !== undefined && errorsPago.cuenta.length > 0 && <span className="form-text m-b-none text-error">{ errorsPago.cuenta }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsPago.comprobante !== undefined && errorsPago.comprobante.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Comprobante</label>
                          <div className="col-sm-8">
                              <input type="file" name="comprobante" className="form-control"  onChange={this.handleChangeData} value={datosPago.comprobante || ''} />
                              { errorsPago.comprobante !== undefined && errorsPago.comprobante.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.comprobante }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsPago.referencia !== undefined && errorsPago.referencia.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Referencia</label>
                          <div className="col-sm-8">
                              <input type="text" name="referencia" className="form-control"  onChange={this.handleChangeData} value={datosPago.referencia || ''} />
                              { errorsPago.referencia !== undefined && errorsPago.referencia.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.referencia }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsPago.comentario !== undefined && errorsPago.comentario.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Comentario</label>
                          <div className="col-sm-8">
                              <input type="text" name="comentario" className="form-control"  onChange={this.handleChangeData} value={datosPago.comentario || ''} />
                              { errorsPago.comentario !== undefined && errorsPago.comentario.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.comentario }</span> }
                          </div>
                      </div>
                  </div>
                  <DatePicker
                      key={'fecha_pago'}
                      label={'Fecha pago*'}
                      name={'fecha_pago'}
                      value={datosPago.fecha_pago}
                      options={{dateFormat: 'd-m-Y', locale: Spanish, maxDate: 'today' }}
                      helpText={''}
                      error={[]}
                      onChange={(v) => {
                          datosPago.fecha_pago = v[0];
                          this.setState({ datosPago });
                      }}
                  />
              </div>
                    {!disabledSinIO && prestamo.tipo === 'tradicional' && <>
                        <div className='row'>
                            <Checkbox
                                key={'condonar_intereses_ordinarios'}
                                label={'Condonar Interés Ordinario'}
                                name={'condonar_intereses_ordinarios'}
                                value={datosPago.condonar_intereses_ordinarios || ''}
                                type={'checkbox'}
                                onChange={this.handleChangeData}
                                //validate={this.validate.bind(this, field.name, field.validate)}
                                // helpText={'Aplica solo para motivo Liquidación o Pago Capital.'}
                                helpText={'Aplica solo para motivo Liquidación.'}
                                error={[]}
                                disabled={disabledSinIO}
                            />
                        </div>
                        <div className='row'>
                            <div className="col-sm-6">
                                <small>{"Si aplica con Interés Ordinario, el monto a colocar debe incluirlo [$"+prestamo.intereses_ordinarios+"]"}</small>
                            </div>
                        </div>
                    </>}
                    {/* Liquidación anticipada para 4m100 */}
                    {!disabledLiquidacionAnticipada && prestamo.msi && <>
                        <div className='row'>
                            <Checkbox
                                key={'liquidacion_anticipada'}
                                label={'Liquidación Anticipada'}
                                name={'liquidacion_anticipada'}
                                value={datosPago.liquidacion_anticipada || ''}
                                type={'checkbox'}
                                onChange={this.handleChangeData}
                                helpText={'Aplica solo para motivo Pago Capital.'}
                                error={[]}
                                disabled={disabledLiquidacionAnticipada}
                            />
                        </div>
                        { errorsPago.liquidacion_anticipada !== undefined && errorsPago.liquidacion_anticipada.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.liquidacion_anticipada }</span> }

                    </>}
                    {/* Liquidación por Reembolso para 4m100 */}
                    {!disabledLiquidacionReembolso && prestamo.msi && <>
                        <div className='row'>
                            <Checkbox
                                key={'liquidacion_reembolso'}
                                label={'Liquidación por Reembolso'}
                                name={'liquidacion_reembolso'}
                                value={datosPago.liquidacion_reembolso || ''}
                                type={'checkbox'}
                                onChange={this.handleChangeData}
                                helpText={'Aplica solo para motivo Pago Liquidación.'}
                                error={[]}
                                disabled={disabledLiquidacionReembolso}
                            />
                        </div>
                        { errorsPago.liquidacion_reembolso !== undefined && errorsPago.liquidacion_reembolso.length > 0  && <span className="form-text m-b-none text-error">{ errorsPago.liquidacion_reembolso }</span> }
                    </>}
          </div>
        )
    }

    reembolsoForm(){

        const {errorsReembolso, cuentas, datosReembolso } = this.state;

        let options_motivo = []

        let options_tipo_pago = [
            {label:'Seleccionar...', value:''},
            {label:'Cheque', value:'cheque'},
            {label:'Depósito en efectivo', value:'depósito en efectivo'},
            {label:'SPEI', value:'SPEI'},
            {label:'Pago referenciado', value:'pago referenciado'},
            {label:'Cargo Openpay', value:'cargo openpay'},
            {label:'Liquidación virtual', value:'liquidacion virtual'}
        ]

        return (
          <div>
              <div className="row">
                  <div className="col-sm-6">
                      <div className={ errorsReembolso.monto !== undefined && errorsReembolso.monto.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Monto*</label>
                          <div className="col-sm-8">
                              <input type="text" name="monto" className="form-control"  onChange={this.handleChangeData} value={datosReembolso.monto}/>
                              { errorsReembolso.monto !== undefined && errorsReembolso.monto.length > 0  && <span className="form-text m-b-none text-error">{ errorsReembolso.monto }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsReembolso.cuenta !== undefined && errorsReembolso.cuenta.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Cuenta entrante*</label>
                          <div className="col-sm-8">
                              <select name="cuenta" id="c_select" className="form-control" value={datosReembolso.cuenta} onChange={this.handleChangeData}>
                                  { cuentas.map((cuenta) => {
                                      return <option key={`tp-${cuenta.value}`} value={cuenta.value}>{cuenta.label}</option>
                                  }) }
                              </select>
                              { errorsReembolso.cuenta !== undefined && errorsReembolso.cuenta.length > 0 && <span className="form-text m-b-none text-error">{ errorsReembolso.cuenta }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsReembolso.comprobante !== undefined && errorsReembolso.comprobante.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Comprobante</label>
                          <div className="col-sm-8">
                              <this.InputComprobante/>
                              {/* <input type="file" name="comprobante" className="form-control"  onChange={this.handleChangeData} defaulValue={datosReembolso.comprobante || ''} /> */}
                              { errorsReembolso.comprobante !== undefined && errorsReembolso.comprobante.length > 0  && <span className="form-text m-b-none text-error">{ errorsReembolso.comprobante }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errorsReembolso.referencia !== undefined && errorsReembolso.referencia.length > 0 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Referencia</label>
                          <div className="col-sm-8">
                              <input type="text" name="referencia" className="form-control"  onChange={this.handleChangeData} value={datosReembolso.referencia} />
                              { errorsReembolso.referencia !== undefined && errorsReembolso.referencia.length > 0  && <span className="form-text m-b-none text-error">{ errorsReembolso.referencia }</span> }
                          </div>
                      </div>
                  </div>
                </div>
                
                  <DatePicker
                      key={'fecha_creacion'}
                      label={'Fecha pago*'}
                      name={'fecha_creacion'}
                      value={datosReembolso.fecha_pago}
                      options={{dateFormat: 'd-m-Y', locale: Spanish, maxDate: 'today' }}
                      helpText={''}
                      error={[]}
                      onChange={(v) => {
                        datosReembolso.fecha_creacion = v[0];
                          this.setState({ datosReembolso });
                      }}
                  />
          
          </div>
        )
    }

    render () {
        const guia = [
            { nombre: 'Prestamos', url: '/prestamos', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        const pagoUrlBotonVer = verificarPermiso('pagos.view_pagoprestamo,') ? '/pagos/ver/' : ''
        const pagosBotones = [
            {
                etiqueta: 'Ver',
                url: pagoUrlBotonVer
            }
        ]

        const {
            pestanaSeleccionada,
            loading,
            titulo,
            prestamo,
            empleado,
            datos_cobranza,
            amortizaciones,
            gastos_cobranza,
            excedentes,
            // amortizacionesCols,
            pagos,
            pagosCols,
            documentos,
            mostrarFrmDocumento,
            mostrarConfElimDocumento,
            comentarios,
            mostrarFrmComentario,
            mostrarConfElimComentario,
            movimientos,
            movimientosCols,
            domiciliacion,
            domiciliacionCols,
            // mostratAlerta,
            // textoAlerta,
            mostrarCambiarFecha,
            fecha,
            errores_fecha,
            mostrarFrmPago,
            mostrarFrmReembolso,
            // cuentas,

            mostrarActFecha,
            primer_amortizacion_sin_factura,
            fecha_inicio,
            dias_cobro,
            dias_cobro_error,
            // pagination
            totalRecords,
            pageLimit,
            modal_reactivar_domiciliacion,
            modal_quitar_domiciliacion,
            motivo_quitar_domiciliacion,
            comentario_quitar_domiciliacion,
            erroresDomiciliacion
        } = this.state

        let {
            id
        } = this.state

        const formDocumento = {
            fields:[{
                name:'nombre',
                className: 'col-md-12',
                label:'Nombre',
                type:'text',
                helpText:''
            },{
                name:'documento',
                className: 'col-md-12',
                label:'Documento',
                type:'file',
                helpText:''
            }],
            data: this.state.dataDocumento,
            errors: this.state.errorsDocumento
        }

        const frmComentario = {
            fields:[{
                name:'comentario',
                label:'Comentario',
                type:'text',
                helpText:'',
                className: 'col-sm-12',
                labelClassName: 'col-sm-3',
                controlClassName: 'col-sm-9'
            },{
                name:'visible_empleado',
                label:'Enviar correo a empleado',
                type:'checkbox',
                helpText:'',
                className: 'col-sm-12',
                labelClassName: 'col-sm-3',
                controlClassName: 'col-sm-9'
            }],
            data: this.state.dataComentario,
            errors: this.state.errorsComentario
        }

/* 
        const formPago = {
            fields:[{
                name:'monto',
                label:'Monto *',
                type:'text',
                helpText:'',
                keyPressValidation: 'decimal'
            },{
                name:'motivo',
                label:'Motivo *',
                type:'select',
                helpText:'',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Amortización regular', value:'amortizacion regular'},
                    {label:'Pago capital', value:'pago anticipado'},
                    {label:'Liquidación', value:'liquidacion'},
                    // {label:'Penalización', value:'penalizacion'}
                ]
            },{
                name:'tipo_pago',
                label:'Tipo de pago *',
                type:'select',
                helpText:'',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Cheque', value:'cheque'},
                    {label:'Depósito en efectivo', value:'depósito en efectivo'},
                    {label:'SPEI', value:'SPEI'},
                    {label:'Pago referenciado', value:'pago referenciado'},
                    {label:'Cargo Openpay', value:'cargo openpay'},
                    {label:'Liquidación virtual', value:'liquidacion virtual'}
                ]
            },{
                name:'cuenta',
                label:'Cuenta Entrante *',
                type:'select',
                helpText:'',
                options: cuentas
            },{
                name:'comprobante',
                label:'Comprobante',
                type:'file',
                helpText:''
            },{
                name:'referencia',
                label:'Referencia',
                type:'text',
                helpText:''
            },{
                name:'comentario',
                label:'Comentario',
                type:'text',
                helpText:''
            },{
                name:'fecha_pago',
                label:'Fecha de pago *',
                type:'date',
                options: {dateFormat: 'd-m-Y', locale: Spanish, maxDate: 'today' },
                helpText:''
            }],
            data: this.state.datosPago,
            errors: this.state.errorsPago
        }
 */
        let estatus_class = '';

        if(prestamo.estatus === 'activo'){
            estatus_class = 'success';
        }else{
            estatus_class = 'danger';
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                {/*<SweetAlert*/}
                {/*    show={mostratAlerta}*/}
                {/*    title={textoAlerta}*/}
                {/*    type="success"*/}
                {/*    onConfirm={() => {*/}
                {/*        this.setState({ mostratAlerta: false });*/}
                {/*    }}*/}
                {/*    onEscapeKey={() => this.setState({ mostratAlerta: false })}*/}
                {/*    onOutsideClick={() => this.setState({ mostratAlerta: false })}*/}
                {/*/>*/}
                <Modal visible={ mostrarCambiarFecha } width="50%" height="35%" effect="fadeInUp">
                    <div className="modal-content animated bounceInRight">
                        <div className="modal-header">
                            <h4 className="modal-title">Cambiar fecha de un prestamo</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="fecha">Fecha</label>
                                        <Flatpickr
                                            className="form-control date"
                                            value={fecha}
                                            options={{
                                                minDate: moment().format('YYYY-MM-DD'),
                                                dateFormat: 'd-m-Y'
                                            }}
                                            onChange={this.handleChangeDate}
                                        />
                                        {errores_fecha.map((obj, index) => (
                                            <span
                                                className="form-text m-b-none text-error"
                                                key={`efi${index}`}
                                            >
                                                {obj}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.cambiarFecha()}>Guardar</button>
                        </div>
                    </div>
                </Modal>
                <Modal visible={ mostrarActFecha } width="30%" height="35%" effect="fadeInUp">
                    <div className="modal-content animated bounceInRight">
                        <div className="modal-header">
                            <h4 className="modal-title">Actualizar fechas amortizaciones</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="col-sm-12 col-form-label"><b>Desde amortización:</b> {primer_amortizacion_sin_factura}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha">Fecha</label>
                                        <Flatpickr
                                          className="form-control date"
                                          value={fecha_inicio}
                                          options={{
                                              minDate: moment().add(3, 'd').format('YYYY-MM-DD'),
                                              dateFormat: 'd-m-Y'
                                          }}
                                          onChange={this.handleChangeDate}
                                        />
                                        <span>Se le suma 3 dias posteriores por fecha de factura(-3 dias a la fecha amortización)</span>
                                        {errores_fecha.map((obj, index) => (
                                          <span
                                            className="form-text m-b-none text-error"
                                            key={`efi${index}`}
                                          >
                                                {obj}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                {prestamo.frecuencia_cobro === 'quincenal' &&
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="">Días de cobro*</label>
                                            <div className="">
                                                <input
                                                  type="text"
                                                  name="dias_cobro"
                                                  className="form-control"
                                                  onChange={this.handleDiasCobro}
                                                  value={dias_cobro || ''}/>
                                            </div>
                                            { dias_cobro_error !== undefined && dias_cobro_error.length > 0  && <span className="form-text m-b-none text-error">{ dias_cobro_error[0] }</span> }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.actualizarFechas()}>Guardar</button>
                        </div>
                    </div>
                </Modal>
                <div className="row">
                    <div className="col-lg-12">
                        <button
                            type="button"
                            className="btn btn-primary btn-sm btns-space"
                            onClick={()=>window.open(`/prestamos/estado-cuenta/${id}/`, "_blank")}
                        >
                            <i className="fa fa-download fa-margin"/>
                            Estado de Cuenta
                        </button>
                        <div className="right text-uppercase">
                            <h4><span className={`label label-${estatus_class}`}>{prestamo.estatus}</span></h4>
                        </div>
                        {/*SE DESHABILITA BOTON PARA LIQUIDAR*/}
                        {/*{ prestamo.estatus === 'activo' &&*/}
                        {/*    <ToggleableLink */}
                        {/*        disabled={!prestamo.puede_agregar_pago}*/}
                        {/*        to={`/prestamos/liquidar/${id}/`}*/}
                        {/*        className="btn btn-primary btn-sm btns-space right"*/}
                        {/*    >*/}
                        {/*        Liquidar*/}
                        {/*    </ToggleableLink>*/}
                        {/*}*/}
                        {/*SE DESHABILITA BOTON PARA CAMBIAR FECHA*/}
                        {/*{ prestamo.estatus === 'activo' &&*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        className="btn btn-sm btn-primary fa-margin right"*/}
                        {/*        onClick={() => this.mostrarFormCambiarFecha()}*/}
                        {/*    >*/}
                        {/*        Cambiar fecha*/}
                        {/*    </button>*/}
                        {/*}*/}
                        {/* OCULTAMOS BOTON DE RENOVAR HASTA NUEVA IDNICACION */}
                        {/*{ (prestamo.estatus === 'activo' && prestamo.adeudo_atrasado === 0) &&*/}
                        {/*    <ToggleableLink*/}
                        {/*      to={`/prestamos/${id}/renovacion`}*/}
                        {/*      className="btn btn-primary btn-sm btns-space right"*/}
                        {/*    >*/}
                        {/*        Renovar*/}
                        {/*    </ToggleableLink>*/}
                        {/*}*/}
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <ul className="nav nav-tabs" role="tablist">
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1)} >Información del préstamo</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 2 && 'active'}`} onClick={() => this.seleccionarPestana(2)} >Amortizaciones</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 3 && 'active'}`} onClick={() => this.seleccionarPestana(3)} >Gastos de Cobranza</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 4 && 'active'}`} onClick={() => this.seleccionarPestana(4)} >Movimientos</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 5 && 'active'}`} onClick={() => this.seleccionarPestana(5)} >Documentos</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 6 && 'active'}`} onClick={() => this.seleccionarPestana(6)} >Comentarios</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 7 && 'active'}`} onClick={() => this.seleccionarPestana(7)} >Bitácora</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 8 && 'active'}`} onClick={() => this.seleccionarPestana(8)} >Domiciliación</a>
                                </li>
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 9 && 'active'}`} onClick={() => this.seleccionarPestana(9)} >Reembolsos/Saldo a Favor</a>
                                </li>
                            </ul>
                            
                            <div className="tab-content">
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                    <div className="panel-body">
                                        <div className="row">

                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Datos del empleado
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Nombre</label>
                                                                <label>
                                                                    <Link to={`/empleados/ver/${empleado.id}`} target='_blank'>{ empleado.nombre }</Link>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Correo electrónico</label>
                                                                <label>{ empleado.email }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Puesto</label>
                                                                <label>{ empleado.puesto }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de nacimiento</label>
                                                                <label>{ dateFormat(empleado.fecha_nacimiento) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de contratación</label>
                                                                <label>{ dateFormat(empleado.fecha_contratacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>RFC</label>
                                                                <label>{ empleado.rfc }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>CURP</label>
                                                                <label>{ empleado.curp }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Teléfono de casa</label>
                                                                <label>{ empleado.telefono_casa }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Teléfono celular</label>
                                                                <label>{ empleado.telefono_celular }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>No. empleado</label>
                                                                <label>{ empleado.no_empleado }</label>
                                                            </div>

                                                            { prestamo.pedido_whirlpool && prestamo.pedido_whirlpool !== '--' &&
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Pedido Whirlpool</label>
                                                                    <label>{ prestamo.pedido_whirlpool }</label>
                                                                </div>
                                                            }

                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Unidad Operativa</label>
                                                                <label>{ empleado.unidad_operativa_str }</label>
                                                            </div>

                                                            { empleado.empresa_whirlpool_str !== '--' && 
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Empresa Whirlpool</label>
                                                                    <label>{ empleado.empresa_whirlpool_str }</label>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Detalles
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Empresa</label>
                                                                <label>{ prestamo.empresa }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Estatus</label>
                                                                <label>{ prestamo.estatus }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de creación</label>
                                                                <label>{ dateTimeFormat(prestamo.fecha_creacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha primer amortización</label>
                                                                <label>{ dateFormat(prestamo.fecha_primera_amortizacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Producto</label>
                                                                <label>{ prestamo.producto ? prestamo.producto.nombre : '--' }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de dispersión</label>
                                                                <label>{ prestamo.fecha_dispersion ? dateFormat(prestamo.fecha_dispersion) : '--' }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Folio</label>
                                                                <label>{ prestamo.folio }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Frecuencia de cobro</label>
                                                                <label>{ prestamo.frecuencia_cobro }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Número de amortizaciones</label>
                                                                <label>{ prestamo.num_amortizaciones }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Amortizaciones restantes</label>
                                                                <label>{ prestamo.amortizaciones_restantes }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Tipo</label>
                                                                <label>{ prestamo.tipo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Tasa de interés</label>
                                                                <label>{ prestamo.tasa_interes } %</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Próximo pago</label>
                                                                <label>{ dateFormat(prestamo.proximo_pago) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Último pago</label>
                                                                <label>{ dateFormat(prestamo.ultimo_pago) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto</label>
                                                                <label>{ currencyFormat(prestamo.monto) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Adeudo total (Capital e interes de todas las amortizaciones restantes)</label>
                                                                <label>{ currencyFormat(prestamo.adeudo_total) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Adeudo atrasado</label>
                                                                <label>{ currencyFormat(prestamo.adeudo_atrasado) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Capital</label>
                                                                <label>{ currencyFormat(prestamo.capital) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Días de retraso</label>
                                                                <label>{ prestamo.dias_retraso }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto pago</label>
                                                                <label>{ currencyFormat(prestamo.monto_pago) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Tipo de descuento</label>
                                                                <label>{ prestamo.tipo_descuento_str || '--' }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Clase del crédito</label>
                                                                <label>{ prestamo.clase_credito || '--' }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Intereses Ordinarios</label>
                                                                <label>{ currencyFormat(prestamo.intereses_ordinarios) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Gastos de Cobranza</label>
                                                                <label>{ currencyFormat(prestamo.gastos_cobranza) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Saldo para liquidar al día de hoy ({dateFormat(prestamo.today)})</label>
                                                                <label>{ currencyFormat(prestamo.saldo_para_liquidar) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Saldo a Favor</label>
                                                                <label>{ currencyFormat(prestamo.saldo_a_favor) }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <GenerarDatosCobranza prestamo={prestamo} />

                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 2 && 'active'}`}>
                                    <div className="panel-body">
                                        { amortizaciones.length > 0 ? <>
                                              {verificarPermiso('prestamos.reestructurar_prestamo,')  && prestamo.estatus !== "liquidado" &&
                                                  <div className="row">
                                                      <div className="col-lg-12">
                                                          <button
                                                            type="button"
                                                            className="btn btn-success btn-sm btns-space right"
                                                            onClick={() => this.mostrarFormActFecha()}
                                                          >
                                                              Actualizar Fechas
                                                          </button>
                                                      </div>
                                                  </div>
                                              }
                                              <div className="row">
                                                  <div className="col-lg-12">
                                                       <div className="panel panel-default">
                                                           <div className="panel-heading">
                                                               Tabla de amortizaciones
                                                           </div>
                                                           <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                {/*<Tabla*/}
                                                                {/*    objetos={ amortizaciones }*/}
                                                                {/*    columnas={ amortizacionesCols }*/}
                                                                {/*    resaltarUltimo*/}
                                                                {/*/>*/}
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Fecha</th>
                                                                                <th>Fecha de pago</th>
                                                                                <th>Días de atraso</th>
                                                                                <th>Capital</th>
                                                                                <th>Interés</th>
                                                                                <th>IVA</th>
                                                                                <th>G.Cobranza</th>
                                                                                <th>Total</th>
                                                                                <th>Abonado</th>
                                                                                <th>Adeudo</th>
                                                                                <th>Facturado</th>
                                                                                <th className="text-center">Liquidado</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        { amortizaciones.map((amortizacion, index) => {
                                                                            return (
                                                                                <tr key={amortizacion.id || index}>
                                                                                    <td>{ amortizacion.num_amortizacion }</td>
                                                                                    <td>{ dateFormat(amortizacion.fecha) }</td>
                                                                                    <td>{ amortizacion.fecha_pagada ? dateFormat(amortizacion.fecha_pagada) : '--' }</td>
                                                                                    <td>{ amortizacion.pagada ? '--' : amortizacion.dias_retraso }</td>
                                                                                    <td>{ currencyFormat(amortizacion.capital) }</td>
                                                                                    <td>{ currencyFormat(amortizacion.interes) }</td>
                                                                                    <td>{ currencyFormat(amortizacion.iva) }</td>
                                                                                    <td className={amortizacion.gasto_cobranza_aplicado ? ` ${amortizacion.gasto_cobranza && 'tachado'}` : ` ${amortizacion.gasto_cobranza > 0 && 'rojo'}`}> 
                                                                                        { currencyFormat(amortizacion.gasto_cobranza) }
                                                                                    </td>
                                                                                    <td>{ currencyFormat(amortizacion.total) }</td>
                                                                                    <td>{ currencyFormat(amortizacion.abonado) }</td>
                                                                                    <td>{ prestamo.estatus === 'liquidado' ?
                                                                                            currencyFormat(0)
                                                                                        :
                                                                                            currencyFormat(parseFloat(amortizacion.total) - parseFloat(amortizacion.abonado))
                                                                                    }</td>
                                                                                    {index + 1 !== amortizaciones.length ?
                                                                                        <td className="text-center">
                                                                                            {amortizacion.facturado ?
                                                                                              <i className="fa fa-check verde"/>
                                                                                              :
                                                                                              <i className="fa fa-close rojo"/>
                                                                                            }
                                                                                        </td>
                                                                                      :
                                                                                        <td></td>
                                                                                    }
                                                                                    {index + 1 !== amortizaciones.length ?
                                                                                        <td className="text-center">
                                                                                            {
                                                                                                amortizacion.pagada ?
                                                                                                <i
                                                                                                    className="fa fa-check verde"></i>
                                                                                                :
                                                                                                <i
                                                                                                    className="fa fa-close rojo"/>
                                                                                            }
                                                                                        </td>
                                                                                    :
                                                                                    <td></td>
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       </div>
                                                  </div>
                                              </div>
                                          </>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no hay amortizaciones registradas</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 3 && 'active'}`}>
                                    <div className="panel-body">
                                        { gastos_cobranza.length > 0 ? <>
                                              <div className="row">
                                                  <div className="col-lg-12">
                                                      <div className="panel panel-default">
                                                          <div className="panel-heading">
                                                              Tabla Gastos de Cobranza
                                                          </div>
                                                          <div className="panel-body">
                                                              <div className="row">
                                                                  <div className="col-md-12">
                                                                      <div className="table-responsive">
                                                                          <table className="table table-striped">
                                                                              <thead>
                                                                              <tr>
                                                                                  <th># Amortizacion</th>
                                                                                  <th>Monto</th>
                                                                                  <th>Interés</th>
                                                                                  <th>IVA</th>
                                                                                  <th>Abonado</th>
                                                                                  <th>Monto condonado</th>
                                                                                  <th>Adeudo</th>                                                                                  
                                                                                  <th>Estatus</th>
                                                                                  <th>Aplicado</th>
                                                                                  <th>Condonado</th>
                                                                                  <th>Fecha Aplicado</th>
                                                                                  <th>Fecha Condonado</th>
                                                                                  <th>Fecha Calculo</th>
                                                                              </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                              { gastos_cobranza.map((gasto, index) => {
                                                                                  return (
                                                                                    <tr key={gasto.id || index}>
                                                                                        <td>{ gasto.num_amortizacion }</td>
                                                                                        <td>{ currencyFormat(gasto.monto) }</td>
                                                                                        <td>{ currencyFormat(gasto.interes) }</td>
                                                                                        <td>{ currencyFormat(gasto.iva) }</td>
                                                                                        <td>{ currencyFormat(gasto.abonado) }</td>
                                                                                        <td>{ currencyFormat(gasto.monto_condonado) }</td>
                                                                                        <td>{ currencyFormat(gasto.adeudo) }</td>
                                                                                        <td>{ gasto.estatus }</td>
                                                                                          <td className="text-center">
                                                                                              {gasto.aplicado ?
                                                                                                <i className="fa fa-check verde"/>
                                                                                                :
                                                                                                <i className="fa fa-close rojo"/>
                                                                                              }
                                                                                          </td>

                                                                                          <td className="text-center">
                                                                                              {gasto.condonar ?
                                                                                                <i className="fa fa-check verde"/>
                                                                                                :
                                                                                                <i className="fa fa-close rojo"/>
                                                                                              }
                                                                                          </td>
                                                                                          
                                                                                        <td>{ dateFormat(gasto.fecha_aplicacion) }</td>
                                                                                        <td>{ dateFormat(gasto.fecha_condonacion) }</td>
                                                                                        <td>{ dateFormat(gasto.fecha_calculo) }</td>
                                                                                    </tr>
                                                                                  )
                                                                              })}
                                                                              </tbody>
                                                                          </table>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </>
                                          :
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="wrapper wrapper-content">
                                                      <div className="ibox-content m-b-sm">
                                                          <div className="text-center p-lg">
                                                              <h2>No hay Gastos de Cobranza registradas</h2>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                        }
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 4 && 'active'}`}>
                                    <div className="panel-body">
                                        { pagos.length > 0 ?
                                            <div className="row">
                                                <div className="col-sm-12 m-b-xs">
                                                    { (prestamo.estatus === 'activo' || prestamo.estatus === 'cartera vencida' || prestamo.estatus === 'castigo') && verificarPermiso('pagos.add_pagoprestamo,') &&
                                                        <button
                                                            disabled={!prestamo.puede_agregar_pago}
                                                            type="button"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={() => this.mostrarFormPago()}
                                                        >
                                                            <i className="fa fa-plus fa-margin"></i>
                                                            Agregar pago
                                                        </button>
                                                    }

                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Tabla de pagos
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                <Tabla
                                                                    objetos={ pagos }
                                                                    columnas={ pagosCols }
                                                                    botones={ pagosBotones }
                                                                    urlBotonAgregar='|'
                                                                    buscar={ false }
                                                                    paginacion={ null }
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no hay pagos registrados</h2>

                                                                {(prestamo.estatus === 'activo' || prestamo.estatus === 'castigo') &&
                                                                    <button
                                                                        disabled={!prestamo.puede_agregar_pago}
                                                                        className="btn btn-primary btn-sm"
                                                                        type="button"
                                                                        onClick={() => this.mostrarFormPago()}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                        <span className="bold">Agregar</span>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Modal visible={ mostrarFrmPago } width="50%" height="40%" effect="fadeInUp">
                                        <div className="modal-content animated bounceInRight">
                                            <div className="modal-header">
                                                <h4 className="modal-title">Agregar pago</h4>
                                            </div>
                                            <div className="modal-body">
                                                {
                                                    prestamo.proximo_pago &&
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            {prestamo.tipo === 'tradicional' && <>
                                                                { !prestamo.amortizaciones_con_factura_sin_pagar ?
                                                                    <h5 className="rojo">No puede agregar PAGO NORMAL, aún no hay facturas generadas.</h5>
                                                                  :
                                                                    <h5 className="rojo">Hay facturas generadas por pagar.</h5>
                                                                }
                                                                </>
                                                            }
                                                            <p>Fecha de la amortización: <span className="font-bold">{ dateFormat(prestamo.proximo_pago) }</span></p>
                                                        </div>
                                                    </div>
                                                }
                                                {/*<Formulario*/}
                                                {/*    fields={ formPago.fields }*/}
                                                {/*    data={ formPago.data }*/}
                                                {/*    errors={ formPago.errors }*/}
                                                {/*/>*/}
                                                { this.pagoForm() }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                <button type="button" className="btn btn-primary" disabled={loading} onClick={() => this.crearPago()}>Guardar</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 5 && 'active'}`}>
                                    <div className="panel-body">
                                        { documentos.length > 0 &&
                                            <div className="row">
                                                <div className="col-sm-12 m-b-xs">
                                                    { verificarPermiso('prestamos.add_documentoprestamo') &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={() => this.mostrarFormDocumento()}
                                                        >
                                                            <i className="fa fa-plus fa-margin"></i>
                                                            Agregar
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        { documentos.length > 0 ?
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Fecha de creación</th>
                                                            <th>Nombre</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { documentos.map((obj, indice) => (
                                                            <tr key={ obj.id }>
                                                                <td>{ dateTimeFormat(obj.fecha_creacion) }</td>
                                                                <td>{ obj.nombre }</td>
                                                                <td>
                                                                    { verificarPermiso('prestamos.view_documentoprestamo,') &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm btns-space"
                                                                            onClick={()=> window.open(obj.documento, "_blank")}
                                                                        >
                                                                            <i className="fa fa-eye fa-margin"></i>
                                                                            Ver
                                                                        </button>
                                                                    }
                                                                    { verificarPermiso('prestamos.delete_documentoprestamo,') &&
                                                                        <button
                                                                          type="button"
                                                                          className="btn btn-primary btn-sm btns-space"
                                                                          onClick={()=> this.mostrarConfElimDocumento(obj.id, indice)}
                                                                        >
                                                                            <i className="fa fa-trash fa-margin"></i>
                                                                            Eliminar
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no tienes documentos agregados</h2>
                                                                { verificarPermiso('prestamos.add_documentoprestamo') &&
                                                                    <button
                                                                      className="btn btn-primary btn-sm"
                                                                      type="button"
                                                                      onClick={() => this.mostrarFormDocumento()}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                        <span className="bold">Agregar</span>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <Modal visible={ mostrarFrmDocumento } width="50%" height="35%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Agregar comentario</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <Formulario
                                                        fields={ formDocumento.fields }
                                                        data={ formDocumento.data }
                                                        errors={ formDocumento.errors }
                                                    />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.agregarDocumento()}>Guardar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal visible={ mostrarConfElimDocumento } width="50%" height="31%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Eliminar documento</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        ¿Estás seguro de eliminar este documento?
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.eliminarDocumento()}>Aceptar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 6 && 'active'}`}>
                                    <div className="panel-body">
                                        { comentarios.length > 0 &&
                                            <div className="row">
                                                <div className="col-sm-12 m-b-xs">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => this.mostrarFormComentario()}
                                                    >
                                                        <i className="fa fa-plus fa-margin"></i>
                                                        Agregar
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        { comentarios.length > 0 ?
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Fecha y hora</th>
                                                            <th>Usuario</th>
                                                            <th>Comentario</th>
                                                            <th className="centrado">Correo a empleado</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { comentarios.map((obj, indice) => (
                                                            <tr key={ obj.id }>
                                                                <td>{ dateTimeFormat(obj.fecha_creacion) }</td>
                                                                <td>{ obj.usuario }</td>
                                                                <td>{ obj.comentario }</td>
                                                                <td className="centrado">
                                                                    { obj.visible_empleado === true ? 
                                                                        <i className="fa fa-check verde"></i>
                                                                    :
                                                                    <i className="fa fa-close rojo"></i>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm btns-space"
                                                                        onClick={()=> this.mostrarConfElimComentario(obj.id, indice)}
                                                                    >
                                                                        <i className="fa fa-trash fa-margin"></i>
                                                                        Eliminar
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no tienes comentarios agregados</h2>
                                                                { verificarPermiso('prestamos.add_comentarioprestamo') &&
                                                                    <button
                                                                        className="btn btn-primary btn-sm"
                                                                        type="button"
                                                                        onClick={() => this.mostrarFormComentario()}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                        <span className="bold">Agregar</span>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <Modal visible={ mostrarFrmComentario } width="50%" height="35%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Agregar comentario</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <Formulario
                                                        fields={ frmComentario.fields }
                                                        data={ frmComentario.data }
                                                        errors={ frmComentario.errors }
                                                    />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.agregarComentario()}>Guardar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal visible={ mostrarConfElimComentario } width="50%" height="31%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Eliminar comentario</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        ¿Estás seguro de eliminar este comentario?
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.eliminarComentario()}>Aceptar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 7 && 'active'}`}>
                                    <div className="panel-body">
                                        { movimientos.length > 0 ?
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Tabla de movimientos
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                <Tabla
                                                                    objetos={ movimientos }
                                                                    columnas={ movimientosCols }
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no hay movimientos registrados</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 8 && 'active'}`}>
                                    <div className="panel-body">
                                        {prestamo.estatus === 'activo' && prestamo.tipo_descuento === 'domiciliacion' ?
                                            <div className="m-b-xs">
                                                {prestamo.bloqueado_domiciliacion ?
                                                    <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => this.reactivar_domiciliacion()}
                                                    >
                                                        <i className="fa fa-plus-square fa-margin"></i>
                                                        Reactivar a la domiciliación
                                                    </button>
                                                :
                                                    <button
                                                    type="button"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={() => this.quitar_domiciliacion()}
                                                    >
                                                    <i className="fa fa-minus-square fa-margin"></i>
                                                    Quitar de la domiciliación
                                                    </button>
                                                }
                                            </div>
                                            :
                                            <div></div>
                                        }

                                        { domiciliacion.length > 0 ?
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Domiciliación 
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                <Tabla
                                                                    objetos={ domiciliacion }
                                                                    columnas={ domiciliacionCols }
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no hay domiciliaciones registradas</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Paginacion
                                        totalRecords={totalRecords}
                                        pageLimit={pageLimit}
                                        pageNeighbours={1}
                                        onPageChanged={this.onPageChanged}
                                        />
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 9 && 'active'}`}>
                                    <div className="panel-body">
                                        {
                                            verificarPermiso('prestamos.add_excedente,') &&
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.mostrarFormReembolso()}
                                            >
                                                <i className="fa fa-plus fa-margin"></i>
                                                Agregar reembolso
                                            </button>

}
                                        { excedentes.length > 0 ? <>
                                              <div className="row">
                                                  <div className="col-lg-12">
                                                      <div className="panel panel-default">
                                                            
                                                          <div className="panel-heading">
                                                              Tabla Excedentes
                                                          </div>
                                                          <div className="panel-body">
                                                              <div className="row">
                                                                  <div className="col-md-12">
                                                                      <div className="table-responsive">
                                                                          <table className="table table-striped">
                                                                              <thead>
                                                                              <tr>
                                                                                  <th>Monto</th>
                                                                                  <th>Estatus</th>
                                                                                  <th>Tipo</th>
                                                                                  <th>Pago Origen</th>
                                                                                  <th>Fecha de Registro</th>
                                                                                  <th>Pago Aplicacion</th>
                                                                                  <th>Fecha de Aplicacion</th>
                                                                              </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                              { excedentes.map((excedente, index) => {
                                                                                  return (
                                                                                    <tr key={excedente.id || index}>
                                                                
                                                                                        <td>{ currencyFormat(excedente.monto) }</td>
                                                                                        <td>{ excedente.estatus }</td>
                                                                                        <td>{ excedente.tipo }</td>
                                                                                        <td>{ excedente.pago_origen }</td>
                                                                                        <td>{ dateFormat( excedente.fecha_creacion)}</td>
                                                                                        <td>{ excedente.pago_aplicado }</td>
                                                                                        <td>{ dateFormat(excedente.fecha_aplicacion )}</td>
                                                                                    </tr>
                                                                                  )
                                                                              })}
                                                                              </tbody>
                                                                          </table>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </>
                                          :
                                          <div className="row">
                                              <div className="col-lg-12">
                                                  <div className="wrapper wrapper-content">
                                                      <div className="ibox-content m-b-sm">
                                                          <div className="text-center p-lg">
                                                              <h2>No hay Excedentes</h2>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                        }
                                    </div>
                                    <Modal visible={ mostrarFrmReembolso } width="50%" height="40%" effect="fadeInUp">
                                        <div className="modal-content animated bounceInRight">
                                            <div className="modal-header">
                                                <h4 className="modal-title">Agregar Reembolso</h4>
                                            </div>
                                            <div className="modal-body">
                                                {/*<Formulario*/}
                                                {/*    fields={ formPago.fields }*/}
                                                {/*    data={ formPago.data }*/}
                                                {/*    errors={ formPago.errors }*/}
                                                {/*/>*/}
                                                { this.reembolsoForm() }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                <button type="button" className="btn btn-primary" disabled={loading} onClick={() => this.crearReembolso()}>Guardar</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                <Modal visible={ modal_reactivar_domiciliacion } width="40%" height="25%" effect="fadeInUp">
                                    <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                        <div className="modal-header">
                                            <h4 className="modal-title">Reactivar préstamo</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                            <div className="col-sm-12">
                                                <p className="text-center">¿Deseas reactivar el préstamo para que aparezca en la búsqueda al generar archivos?</p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                            type="button"
                                            className="btn btn-white"
                                            onClick={() => this.setState({modal_reactivar_domiciliacion: false}) }
                                            >
                                            Cancelar
                                            </button>
                                            <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => this.btn_reactivar_domiciliacion()}
                                            >
                                                Reactivar
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal visible={ modal_quitar_domiciliacion } width="40%" height="40%" effect="fadeInUp">
                                  <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                      <div className="modal-header">
                                          <h4 className="modal-title">Quitar préstamo de la domiciliación</h4>
                                      </div>
                                      <div className="modal-body" style={{'overflowY':'scroll'}}>
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <p>Motivo:</p>
                                              
                                            <select
                                                name="motivo_quitar_domiciliacion"
                                                value={motivo_quitar_domiciliacion}
                                                type="text"
                                                className="form-control form-control-md"
                                                onChange={e => this.quitar_domiciliacion_change(e)}
                                            >
                                                <option value="">Seleccionar...</option>
                                                <option value="Pagó 3 veces por medios externos">Pagó 3 veces por medios externos</option>
                                                <option value="Crédito con atraso">Crédito con atraso</option>
                                                <option value="Cliente lo solicitó">Cliente lo solicitó</option>
                                                <option value="Cuenta cancelada o bloqueada">Cuenta cancelada o bloqueada</option>
                                                <option value="Otro">Otro</option>
                                            </select>
                                            {erroresDomiciliacion.motivo.map((obj, index) => (
                                                <span
                                                className="form-text m-b-none text-error"
                                                key={`ee${index}`}
                                                >
                                                {obj}
                                                </span>
                                            ))}
                                            </div>
                                          </div>

                                          {
                                            motivo_quitar_domiciliacion === "Otro" &&  

                                            <div className="row mt-4">
                                              <div className="col-sm-12">
                                                <p>Comentario:</p>
                                                <textarea
                                                  name="comentario_quitar_domiciliacion"
                                                  value={comentario_quitar_domiciliacion}
                                                  className="form-control form-control-md"
                                                  onChange={e => this.handle_quitar_domiciliacion_change(e)}
                                                >
                                                </textarea>
                                                {erroresDomiciliacion.comentario.map((obj, index) => (
                                                    <span
                                                        className="form-text m-b-none text-error"
                                                        key={`ee${index}`}
                                                    >
                                                        {obj}
                                                    </span>
                                                ))}
                                              </div>
                                            </div>
                                          }
                                      </div>

                                      <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-white"
                                            onClick={() => this.setState({modal_quitar_domiciliacion: false}) }
                                          >
                                            Cancelar
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            // disabled={motivo_quitar_domiciliacion.length === 0}
                                            onClick={() => this.btn_quitar_prestamo_domiciliacion()}
                                          >
                                              Eliminar
                                          </button>
                                      </div>
                                  </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .centrado{
                        text-align: center;
                    }
                    .rojo{
                        color: red;
                    }
                    .verde{
                        color: green;
                    }
                    .right {
                        float: right;
                    }
                    .tachado{
                        text-decoration: line-through
                    }

                    .center-conbranza-btn {
                        margin-top: -5px;
                        margin-right: 0px;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
