// BASE URL
// const DOMAIN = 'https://jerarquia-api.yuhu.mx'
// const DOMAIN = 'https://api.yuhu.mx/';
// const DEV = 'https://apidev.yuhu.mx/'
const DOMAIN = process.env.REACT_APP_API_URL || 'http://localhost:8000/';
// const API_VERSION = '/v1'
const BASE_URL = `${DOMAIN}`;

// const MOMENT_LOCALE = require('moment/locale/es.js')

export {
  BASE_URL
  // MOMENT_LOCALE
};
