import React, { Component } from 'react';
import { currencyFormat, dateFormat } from '../utils/filters';

class TablaCotizacion extends Component {

  render() {

    const { cotizacion } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
          <tr>
            <th>Plazo</th>
            <th>Fecha</th>
            <th>Capital</th>
            <th>Interés</th>
            <th>IVA</th>
            <th>Total</th>
            <th>Saldo final</th>
          </tr>
          </thead>
          <tbody>
          { cotizacion.pagos && cotizacion.pagos.map( pago => {
            return (
              <tr key={`amortizacion-${pago.num_pago}`}>
                <td>{ pago.num_pago }</td>
                <td>{ dateFormat(pago.fecha) }</td>
                <td>{ currencyFormat(pago.capital) }</td>
                <td>{ currencyFormat(pago.interes_sin_iva) }</td>
                <td>{ currencyFormat(pago.iva) }</td>
                <td>{ currencyFormat(pago.total_pagar) }</td>
                <td>{ currencyFormat(pago.saldo_final) }</td>
              </tr>
            )
          }) }
          {
            cotizacion.totales &&
            <tr className="font-bold">
              <td></td>
              <td>TOTAL</td>
              <td>{ currencyFormat(cotizacion.totales.capital) }</td>
              <td>{ currencyFormat(cotizacion.totales.interes) }</td>
              <td>{ currencyFormat(cotizacion.totales.iva) }</td>
              <td>{ currencyFormat(cotizacion.totales.total) }</td>
              <td></td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    )

  }

}

export default TablaCotizacion;