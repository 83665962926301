import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { obtenerDisposiciones } from '../../api/Disposiciones';
import { obtenerEmpresas } from '../../api/Empresas';
import { currencyFormat, dateFormat } from '../../utils/filters';

class Lista extends Component {
  state = {
    titulo: 'Disposiciones',
    objetos: [],
    totalRecords: 0,
    pageLimit: 50,
    params: {},
    empresas: [],
    datos: {
      empresa: '',
      estatus: '',
      search: ''
    }
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const promesas = [obtenerDisposiciones(params), obtenerEmpresas({page_size: 1000})];
      const [req, resp] = await Promise.all(promesas);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      const empresas = resp.data.results.map(obj => {
        return { label: obj.nombre, value: obj.id };
      });
      this.setState({ objetos, totalRecords, params, empresas });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerDisposiciones(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerObjetos = async params => {
    try {
      const req = await obtenerDisposiciones(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async(campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (campo === 'empresa' || campo === 'estatus') {
      const {
        datos: { empresa, estatus }
      } = this.state;
      delete params.empleado__empresa;
      delete params.estatus;
      delete params.page;
      params = Object.assign({ empleado__empresa: empresa, estatus }, params);
      this.obtenerObjetos(params);
    }
    if (valor === '') {
      await this.limpiar()
    }
  };

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: '',
        empresa: '',
        estatus: ''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(params);
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      empresas,
      datos: { empresa, estatus, search }
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="input-group">
                      <select
                        value={empresa}
                        onChange={e => this.onChange('empresa', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona empresa</option>
                        {empresas.map(emp => {
                          return (
                            <option key={emp.value} value={emp.value}>
                              {emp.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-group">
                      <select
                        value={estatus}
                        onChange={e => this.onChange('estatus', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona estatus</option>
                        <option value="pendiente">Pendiente</option>
                        <option value="aprobado">Aprobado</option>
                        <option value="rechazado">Rechazado</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={e => this.onChange('search', e)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Busca por empleado"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Empresa</th>
                        <th>Empleado</th>
                        <th>Clabe</th>
                        <th>Monto</th>
                        <th>Fecha de creación</th>
                        <th>Fecha de dispersión</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {objetos.map(obj => {
                        return (
                          <tr key={obj.id}>
                            <td>{obj.num_disposicion}</td>
                            <td>{obj.empresa}</td>
                            <td>{obj.empleado}</td>
                            <td>{ obj.banco && obj.clabe ? `${obj.banco} ${obj.clabe}` : '--' }</td>
                            <td>{currencyFormat(obj.monto)}</td>
                            <td>{dateFormat(obj.fecha_creacion)}</td>
                            <td>{dateFormat(obj.fecha_dispersion)}</td>
                            <td>{obj.estatus}</td>
                            <td>
                              <Link
                                key={obj.id}
                                to={`/disposiciones/ver/${obj.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-eye fa-margin" />
                                Ver
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
