const DECIMAL_REGEX = /^\d+\.?\d{0,2}$/i;
const NUMBER_REGEX = /^[0-9]*$/i;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const RFC_REGEX = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

const validateDecimal = value => value.match(DECIMAL_REGEX);

const validateNumber = value => value.match(NUMBER_REGEX);

const validateEmail = value => value.match(EMAIL_REGEX);

const validateRfc = value => value.match(RFC_REGEX);

export {
  DECIMAL_REGEX,
  NUMBER_REGEX,
  EMAIL_REGEX,
  RFC_REGEX,
  validateDecimal,
  validateNumber,
  validateEmail,
  validateRfc
};
