import React, {useEffect, useState} from 'react';
import { getCuestionarioCompliance } from '../api/Solicitudes';
import { dateFormat } from '../utils/filters';

const CuestionarioCompliance = ({solicitudId}) => {
    
    const [cuestionario, setCuestionario] = useState({});
    // State para saber si una solicitud tiene un cuestionario respondido
    const [respondido, setRespondido] = useState(false);

    useEffect(() => {
        
        const obtenerCuestionario = async () => {
            try {
                const resp = await getCuestionarioCompliance(solicitudId);
                setCuestionario(resp.data)
                setRespondido(true);
            } catch (err) {
                setRespondido(false)
            }
        }
        obtenerCuestionario();

    }, [solicitudId])

    return (
        <>
            {respondido ? 
                <>
                    <div className="col-sm-12 sol text-left">
                        <label>1. ¿Usted desempeña o a desempeñado funciones públicas destacadas en un país extranjero o en territor</label>
                        <label>{cuestionario.desempeno_funciones_publicas.charAt(0).toUpperCase() + cuestionario.desempeno_funciones_publicas.slice(1)}</label>
                    </div>
                    <div className="col-sm-12 sol text-left">
                        <label>2. ¿Algún familiar de usted de hasta segundo grado de consanguinidad o afinidad (conyugue, concubina</label>
                        <label>{cuestionario.familiar_funciones_publicas.charAt(0).toUpperCase() + cuestionario.familiar_funciones_publicas.slice(1)}</label>
                    </div>
                    <div className="col-sm-12 sol text-left">
                        <label>3. ¿Sus ingresos provienen de la actividad mencionada en la presente solicitud?</label>
                        <label>{cuestionario.origen_ingresos.charAt(0).toUpperCase() + cuestionario.origen_ingresos.slice(1)}</label>
                    </div>
                    <div className="col-sm-12 sol text-left">
                        <label>4. ¿Espera realizar pagos anticipados?</label>
                        <label>{cuestionario.pagos_anticipados.charAt(0).toUpperCase() + cuestionario.pagos_anticipados.slice(1)}</label>
                    </div>
                    <div className="col-sm-12 sol text-left">
                        <label>5. ¿Espera liquidar su crédito dentro de los 3 primeros meses a su otorgamiento?</label>
                        <label>{cuestionario.liquidar_credito.charAt(0).toUpperCase() + cuestionario.liquidar_credito.slice(1)}</label>
                    </div>
                    <div className="col-sm-12 sol text-left">
                        <label>Fecha que se respondio el cuestionario</label>
                        <label>{dateFormat(cuestionario.fecha_respondio)}</label>
                    </div>
                </>
                :
                    <div className="col-sm-12 sol text-left">
                        Cuestionario sin responder
                    </div>

            }
        </>
    );
}
 
export default CuestionarioCompliance;