import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import { obtenerGrupos } from '../../api/Grupos'
import { verificarPermiso } from '../../utils/auth'

class Lista extends Component {
    state = {
        objetos: []
    }

    async componentDidMount() {
        try {
            const res = await obtenerGrupos()
            const objetos = res.data.results
            this.setState({ objetos })
        } catch (error) {
            console.log(error)
        }   
    }

    render () {
        const { objetos } = this.state
        const guia = [
            { nombre: 'Grupos', url: '', clase: 'active' }
        ]

        const urlBotonAgregar = verificarPermiso('auth.add_group,') ? '/grupos/agregar' : ''
        const urlBotonEditar = verificarPermiso('auth.change_group,') ? '/grupos/editar/' : ''

        const botones = [
            {
                etiqueta: 'Editar',
                url: urlBotonEditar
            },{
                etiqueta: 'Ver',
                url: '/grupos/ver/'
            }
        ]
        const columnas = [
            {
                llave: 'nombre',
                etiqueta: 'Nombre'
            }
        ]
        return (
            <Autenticado titulo="Grupos" guia={ guia }>
                <Tabla
                    objetos={ objetos }
                    columnas={ columnas }
                    botones={ botones }
                    urlBotonAgregar={urlBotonAgregar}
                />
            </Autenticado>
        )
    }
}

export default Lista
