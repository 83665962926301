import React, {useEffect, useState} from 'react';
import Modal from 'react-awesome-modal';
import { cancelarContratoParaFirma, enviarContratoParaFirma, reenviarContratoParaFirma } from '../../api/Solicitudes';
import Loading from '../Loading';


const EnviarDocumento = ({solicitud, documento, actualizarSolicitud}) => {

    const [mostrarModal, setMostrarModal] = useState(false);
    const [mostrarModalReenviar, setMostrarModalReenviar] = useState(false);
    // const [mostrarModalCancelar, setMostrarModalCancelar] = useState(false);
    // const [motivoCancelacion, setMotivoCancelacion] = useState('');
    const [modoEnvio, setModo] = useState('sms');
    const [cargando, setCargando] = useState(false);
    const [mensajeEnvio, setMensajeEnvio] = useState('');
    const [errorMensaje, setMensajeError] = useState(false);

    useEffect(() => {
        
    }, [documento])

    const checkboxOnChange = (e) => {
        setModo(e.target.value);
    }

    const enviarContrato = async () => {
        setCargando(true);
        setMensajeEnvio('');
        try {
            const resp = await enviarContratoParaFirma(solicitud.id, documento.id, { modo_envio: modoEnvio });
            if (resp.status === 200) {
                setMensajeEnvio('El mensaje ha sido enviado con éxito');
                setMensajeError(false);
                actualizarSolicitud();
            }
        } catch (err) {
            setMensajeError(true);
            setMensajeEnvio('Hubo un error en enviar el mensaje, favor de verificarlo');
        }
        setCargando(false);
    }

    const reenviarContrato = async () => {
        setCargando(true);
        setMensajeEnvio('');
        try {
            const resp = await reenviarContratoParaFirma(solicitud.id, documento.id);
            if (resp.status === 200) {
                setMensajeEnvio('El mensaje ha sido renviado con exito');
                setMensajeError(false);
                actualizarSolicitud();
            }
        } catch (err) {
            setMensajeError(true);
            setMensajeEnvio('Hubo un error en enviar el mensaje, favor de verificarlo');
        }
        setCargando(false);
    }
    /*
    const cancelarContrato = async () => {
        setMensajeError(false);
        setMensajeEnvio('');
        // if (motivoCancelacion.trim() === '') {
        //     setCargando(false);
            
        //     return;
        // }
        setCargando(true);
        try {
            const resp = await cancelarContratoParaFirma(solicitud.id, documento.id, {motivo_cancelacion: motivoCancelacion});
            if (resp.status === 200) {
                setMensajeEnvio('El mensaje ha sido cancelado');
                setMensajeError(false);
                actualizarSolicitud();
            }
        } catch (err) {
            setMensajeError(true);
            setMensajeEnvio('Hubo un error en la cancelación del contrato, favor de verificarlo');
        }
        setCargando(false);
    }
    */
    
    return (
        <span className="btn btn-sm btns-space text-left">
            <Modal visible={ mostrarModal } width="550px" height="350px" effect="fadeInUp">
                <div className="modal-header">
                    <h4 className="modal-title">Enviar documento por SMS o email</h4>
                </div>
                <div className="modal-body">
                    { cargando ? 
                        <Loading />
                        :
                        <>
                            <p>¿Por qué vía quiere enviar el documento?</p>
                            <div className="form-group">
                                <select className="form-control" onChange={checkboxOnChange}>
                                    <option value="sms">SMS</option>
                                    <option value="email" disabled>email</option>
                                </select>
                            </div>
                            <p>El mensaje se enviara vía {modoEnvio} a <strong>{modoEnvio === 'email' ? solicitud.empleado.email : solicitud.empleado.telefono_celular}</strong></p>
                            { mensajeEnvio.trim() !== '' ?
                                errorMensaje ? 
                                    <p className="text-danger">
                                        <i className="fa fa-times fa-margin"></i>
                                        {mensajeEnvio}
                                    </p>
                                :
                                    <p className="text-info">
                                        <i className="fa fa-check fa-margin"></i>
                                        {mensajeEnvio}
                                    </p>
                                :
                                    null
                            }
                           
                        </>
                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-white" onClick={() => setMostrarModal(false) }>Cerrar</button>
                    { cargando ?
                        <button type="button" className="btn btn-primary" disabled={true}>
                            Enviando 
                            <span className="loading open-circle"></span>
                        </button>
                        : documento.documento_enviado
                            ?
                            <span className="text-info">Documento enviado</span>
                            :
                            <button type="button" className="btn btn-primary" onClick={enviarContrato}>Enviar</button>
                    }
                </div>
            </Modal>

            <Modal visible={ mostrarModalReenviar } width="550px" height="350px" effect="fadeInUp">
                <div className="modal-header">
                    <h4 className="modal-title">Reenviar documento por email</h4>
                </div>
                <div className="modal-body">
                    { cargando ? 
                        <Loading />
                        :
                        <>
                            <p>¿Quiere reenviar el documento?</p>
                            {/* <div className="form-group">
                                <select className="form-control" onChange={checkboxOnChange}>
                                    <option value="email">email</option>
                                    <option value="sms">SMS</option>
                                </select>
                            </div> */}
                            <p>El mensaje se reenviara vía SMS a <strong>{ solicitud.empleado.telefono_celular}</strong></p>
                            { mensajeEnvio.trim() !== '' ?
                                errorMensaje ? 
                                    <p className="text-danger">
                                        <i className="fa fa-times fa-margin"></i>
                                        {mensajeEnvio}
                                    </p>
                                :
                                    <p className="text-info">
                                        <i className="fa fa-check fa-margin"></i>
                                        {mensajeEnvio}
                                    </p>
                                :
                                    null
                            }
                           
                        </>
                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-white" onClick={() => setMostrarModalReenviar(false) }>Cerrar</button>
                    { cargando ?
                        <button type="button" className="btn btn-primary" disabled={true}>
                            Reenviando...
                        </button>
                        :
                        <button type="button" className="btn btn-primary" onClick={reenviarContrato}>Reenviar</button>
                    }
                </div>
            </Modal>

            {/* 
            <Modal visible={ mostrarModalCancelar } width="550px" height="350px" effect="fadeInUp">
                <div className="modal-header">
                    <h4 className="modal-title">Cancelar contrato</h4>
                </div>
                <div className="modal-body">
                    { cargando ? 
                        <Loading />
                        :
                        <>
                            <p>¿Quiere cancelar el contrato?</p>
                            <div className="form-group">
                                <input type="text" value={motivoCancelacion} onChange={(e) => setMotivoCancelacion(e.target.value)} autofocus className="form-control"/>
                            </div>
                            { motivoCancelacion.trim() === '' &&  <span className="text-danger">Debe de tener un motivo de cancelación</span> }
                            { documento.estatus_absign_str === 'cancelado' && <span className="text-info">El contrato ha sido cancelado</span> }
                           
                           
                        </>
                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-white" onClick={() => setMostrarModalCancelar(false) }>Cerrar</button>
                    { cargando ?
                        <button type="button" className="btn btn-primary" disabled={true}>
                            Cancelando...
                        </button>
                        : documento.estatus_absign_str === 'cancelado' ?
                        <span className="text-info">Documento cancelado</span>
                        :
                        <button type="button" className="btn btn-primary" onClick={cancelarContrato}>Aceptar</button>
                    }
                </div>
            </Modal>
             */}

            { documento.documento_enviado ? 
                <>
                    <button
                        type="button"
                        className="btn btn-info btn-sm btns-space"
                        onClick={() => setMostrarModalReenviar(true)}
                    >
                        {/* <i className="fa fa-send fa-margin"></i> */}
                        Reenviar Documento
                    </button>
                    <span className="text-info btns-space">Documento Enviado</span>
                </>
                :
                <button
                    type="button"
                    className="btn btn-primary btn-sm btns-space"
                    onClick={() => setMostrarModal(true)}
                >
                    <i className="fa fa-send fa-margin"></i>
                    Enviar Documento
                </button>
            }
        </span>
    );
}
 
export default EnviarDocumento;