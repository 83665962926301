import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerArchivosBancarios(parametros = '') {
    const API = await ConstructorAPIAsync();
    try {
      return await API.get('admin/archivos-bancarios/', { params: parametros });
    } catch (err) {
      callError(err);
    }
}

async function obtenerArchivoBancario(id) {
    const API = await ConstructorAPIAsync();
    try {
      return await API.get(`admin/archivos-bancarios/${id}/`);
    } catch (err) {
      callError(err);
    }
}

async function crearArchivoBancario(datos) {
    const API = await ConstructorAPIAsync(240000);
    try {
      return await API.post('admin/archivos-bancarios/', datos);
    } catch (err) {
      callError(err);
    }
}

async function obtenerTransaccionesBancarias(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('admin/transacciones-bancarias/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerTransaccionBancaria(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`admin/transacciones-bancarias/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function actualizarClasificacionTransaccionBancaria(id, datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`admin/transacciones-bancarias/${id}/actualizar-clasificacion/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerArchivoBancarioConciliacion(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`admin/archivos-bancarios/${id}/conciliacion/`);
  } catch (err) {
    callError(err);
  }
}


  export {
    obtenerArchivosBancarios,
    obtenerArchivoBancario,
    crearArchivoBancario,
    obtenerTransaccionesBancarias,
    obtenerTransaccionBancaria,
    actualizarClasificacionTransaccionBancaria,
    obtenerArchivoBancarioConciliacion
  };