import React from 'react';
import ChartistGraph from 'react-chartist';


const PorcentajesEtapas = ({data, periodo}) => {
    return (
        <div className="ibox">
            <div className="ibox-title">
                <div className="ibox-tools">
                    <span className="label label-info float-right">{periodo}</span>
                </div>
                <h5>Porcentaje de solicitudes por etapa terminada</h5>
            </div>
            <div className="ibox-content table-responsive">

                <div className="table-responsive">
                    <style jsx="true">
                    {`
                        .ct-series-a .ct-slice-pie {
                            fill: #1bb394;
                        }
                        .ct-series-b .ct-slice-pie {
                            fill: #d3d3d3;
                        }
                    `}
                    </style>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Etapa</th>
                                <th>Porcentaje</th>
                                <th>Grafica</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data.map( (etapa, index) => (

                                <tr key={index}>
                                    <td>{etapa.etapa}</td>
                                    <td>{etapa.porcentaje}%</td>
                                    <td width="50px">
                                        <ChartistGraph
                                            data={{
                                                series: [
                                                    etapa.porcentaje, 100-etapa.porcentaje
                                                ]
                                            }}
                                            options={{
                                                showLabel: false,
                                                height: '25px'
                                            }}
                                            type='Pie'
                                        />
                                    </td>
                                </tr>
                                
                            ) )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}
 
export default PorcentajesEtapas;