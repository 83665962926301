import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado';
import spinner from '../../img/spinner.gif';
import { obtenerEmpresas } from '../../api/Empresas';
import { importarEmpleado, importarEmpleados } from '../../api/Empleados';
import { BASE_URL } from '../../utils/config';
import Select from 'react-select';
import EmpresasSelect from '../../components/EmpresasSelect';
import { obtenerUnidadesOperativasList } from '../../api/UnidadesOperativas';

class Importar extends Component {
  state = {
    titulo: 'Importar empleados',
    empresas: [],
    empresaSelect: '',
    unidadesOperativas: [],
    unidadesOperativasSelect: '',
    unidadOperativa: null,
    empresa: null,
    archivo: null,
    errores: {
      empresa: [],
      unidad_operativa: [],
      archivo: []
    },
    empleados: [],
    empleadosSinError: 0
  };

  componentDidMount = async () => {
    const resp = await obtenerEmpresas({page_size:1000});
    const empresas = resp.data.results.map(obj => {
      return { label: obj.nombre, value: obj.id };
    });
    empresas.unshift({
      label: 'Seleccionar...',
      value: ''
    });
    this.setState({ empresas });
  };

  cargando = () => {
    Swal.fire({
      text: 'Espera un momento por favor...',
      showConfirmButton: false,
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      imageUrl: spinner,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Espera un momento'
    });
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };

  cerrarAlertas = () => {
    Swal.close();
  };

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState({ [name]: value });
  };

  handleImageChange = async e => {
    e.preventDefault();
    const {
      target: { files }
    } = e;
    const file = files[0];
    if (file) {
      this.setState({ archivo: file });
    } else {
      this.setState({ archivo: null });
    }
  };

  validar = () => {
    let validado = true;
    const errores = {
      empresa: [],
      unidad_operativa: [],
      archivo: []
    };
    const { empresa, unidadOperativa, archivo } = this.state;

    if (!empresa) {
      validado = false;
      errores.empresa = ['Selecciona una empresa por favor.'];
    }

    if (!unidadOperativa) {
      validado = false;
      errores.unidad_operativa = ['Selecciona una unidad operativa por favor.'];
    }

    if (!archivo) {
      validado = false;
      errores.archivo = ['Selecciona un archivo por favor.'];
    }

    this.setState({ errores });
    return validado;
  };

  procesar = async () => {
    if (!this.validar()) {
      return;
    }
    this.cargando();
    const { empresa, archivo, unidadOperativa } = this.state;
    const formData = new FormData();
    formData.append('empresa', empresa);
    formData.append('unidad_operativa', unidadOperativa);
    formData.append('archivo', archivo);
    try {
      const resp = await importarEmpleados(formData);
      const empleados = resp.data;
      let empleadosSinError = 0;
      empleados.forEach(emp => {
        emp.estatus = 'Pendiente';
        if (!emp.errors) {
          empleadosSinError += 1;
        }
      });
      this.setState({ empleados, empleadosSinError });
      this.cerrarAlertas();
    } catch (error) {
      console.log('Error importador:', error);
      this.cerrarAlertas();
      this.mostrarToast(error.message, 'error');
      if (error.status === 400){
        this.setState({errores: error.data});
      }
    }
  };

  importar = async enviarEmail => {
    this.cargando();
    const { empleados } = this.state;
    let empleadosImportados = 0;
    try {
      for (let i = 0; i < empleados.length; i++) {
        if (!empleados[i].errors && empleados[i].estatus === 'Pendiente') {
          try {
            empleados[i].enviar_email_activacion = enviarEmail;
            await importarEmpleado(empleados[i]);
            empleados[i].estatus = 'Importado';
            empleadosImportados += 1;
          } catch (error) {
            console.log('Error importar:', error);
            empleados[i].estatus = 'No importado';
            if (error.status === 500) {
              this.mostrarToast('Ocurrio un error en el servidor.', 'error');
            } else {
              this.mostrarToast(error.message, 'error');
            }
          }
        } else {
          empleados[i].estatus = 'No importado';
        }
      }
      this.setState({ empleados });
      this.cerrarAlertas();
      this.mostrarToast(`Se importaron ${empleadosImportados} empleados.`);
    } catch (error) {
      console.log('Error importador:', error);
      this.cerrarAlertas();
      if (error.status === 500) {
        this.mostrarToast('Ocurrio un error en el servidor.', 'error');
      } else {
        this.mostrarToast(error.message, 'error');
      }
    }
  };

  empresaChange = async (e) => {
    const empresa = e.value;

    this.setState({empresa, empresaSelect: e});

  //  Nos traemos las unidades Operativas
    try {
      const req = await obtenerUnidadesOperativasList({empresa});
      this.setState({unidadesOperativas: req.data});
    }catch (err) {
      console.log(err);
    }
  }

  unidadOperativaChange = async (e) => {
    const unidadOperativa = e.value;
    this.setState({unidadOperativa});
  }

  render() {
    const {
      titulo,
      // empresas,
      // empresa,
      errores,
      empleados,
      empleadosSinError,
      unidadesOperativas
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];

    const unidadesOperativasValues = unidadesOperativas.map( uo => {
      return {label: uo.nombre, value: uo.id};
    });

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <a target="_blank" href={`${BASE_URL}/download/empleados-layout/`} className="btn btn-sm btn-warning pull-right" rel="noopener noreferrer">
                          <i className="fa fa-download"></i> Descargar layout
                        </a>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="unidad_operativa">Empresa</label>
                          <EmpresasSelect
                            onChange={this.empresaChange}
                          />
                          { errores.empresa && errores.empresa.map((obj, index) => (
                            <span
                              className="form-text m-b-none text-error"
                              key={`ee${index}`}
                            >
                              {obj}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="unidad_operativa">Unidad Operativa</label>
                          <Select
                            name="empresa"
                            placeholder="Selecciona una Unidad Operativa"
                            className="select"
                            onChange={this.unidadOperativaChange}
                            options={unidadesOperativasValues}
                          />
                          { errores.unidad_operativa && errores.unidad_operativa.map((obj, index) => (
                            <span
                              className="form-text m-b-none text-error"
                              key={`ee${index}`}
                            >
                              {obj}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="archivo">Archivo</label>
                          <input
                            type="file"
                            className="form-control p36"
                            id="archivo"
                            onChange={this.handleImageChange}
                          />
                          { errores.archivo && errores.archivo.map((obj, index) => (
                            <span
                              className="form-text m-b-none text-error"
                              key={`ea${index}`}
                            >
                              {obj}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="col-sm-2">
                        <label>&nbsp;</label>
                        <button
                          type="button"
                          className="btn btn-success btn-sm btn-block"
                          onClick={this.procesar}
                        >
                          <i className="fa fa-cloud-upload" /> Procesar archivo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {empleados.length > 0 && (
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>
                            <strong>
                              Empleados a importar:{' '}
                              {empleadosSinError.toString()} de{' '}
                              {empleados.length.toString()}
                            </strong>
                          </h3>
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th>Email</th>
                                  <th>CURP</th>
                                  <th>Errores</th>
                                  <th className="text-center">Estatus</th>
                                </tr>
                              </thead>
                              <tbody>
                                {empleados.map((emp, index) => (
                                  <tr key={`emp${index}`}>
                                    <td>{`${emp.nombre} ${
                                      emp.apellido_paterno
                                    } ${emp.apellido_materno}`}</td>
                                    <td>{emp.email}</td>
                                    <td>{emp.curp}</td>
                                    <td>
                                      {emp.errors ? (
                                        <ul>
                                          {Object.keys(emp.errors).map(k => (
                                            <li
                                              key={`li${k}`}
                                              className="text-danger"
                                            >
                                              {`${k}: ${emp.errors[k][0]}`}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </td>
                                    <td>
                                      {emp.estatus === 'Pendiente' && (
                                        <span>
                                          <i
                                            className="fa fa-pause-circle-o"
                                            aria-hidden="true"
                                          />{' '}
                                          Pendiente
                                        </span>
                                      )}
                                      {emp.estatus === 'Importado' && (
                                        <span className="text-success">
                                          <i
                                            className="fa fa-check-circle-o"
                                            aria-hidden="true"
                                          />{' '}
                                          Importado
                                        </span>
                                      )}
                                      {emp.estatus === 'No importado' && (
                                        <span className="text-danger">
                                          <i
                                            className="fa fa-times-circle-o "
                                            aria-hidden="true"
                                          />{' '}
                                          No importado
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 pb25">
                  <button
                    type="button"
                    className="btn btn-success btn-sm btn-block"
                    onClick={() => this.importar(true)}
                  >
                    <i className="fa fa-check" /> Importar y enviar e-mail
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-block"
                    onClick={() => this.importar(false)}
                  >
                    <i className="fa fa-check" /> Importar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-block"
                    onClick={() =>
                      this.setState({
                        empleados: [],
                        empresa: '',
                        archivo: null
                      })
                    }
                  >
                    <i className="fa fa-ban" /> Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Importar;
