import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import { obtenerGrupoEmpresa, eliminarGrupoEmpresa } from '../../api/GrupoEmpresas'
import { verificarPermiso } from '../../utils/auth'

class Form extends Component {
    state = {
        id: null,
        objeto: {}
    }

    componentWillMount() {
        const id = this.props.match.params.id
        this.setState({ id })
    }

    async componentDidMount() {
        const { id } = this.state
        if (id){
            try {
                const resp = await obtenerGrupoEmpresa(id)
                const objeto = resp.data
                console.log(objeto)
                this.setState({ objeto })
            } catch (error) {
                console.log(error)
            }
        }
    }

    eliminar = async () => {
        const { id } = this.state
        try {
            await eliminarGrupoEmpresa(id)
            this.props.history.push('/grupos-de-empresas')
        } catch (error) {
            console.log(error)
        }
    }

    render () {
        const { objeto } = this.state

        const guia = [
            { nombre: 'Grupos de empresas', url: '/grupos-de-empresas', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        return (
            <Autenticado titulo="Ver grupo de empresa" guia={ guia }>
                <div className="row justify-content-md-center">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="ibox">
                                            <div className="ibox-content">
                                                <h3>
                                                    <strong>{objeto.nombre}</strong>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pb25">
                                { verificarPermiso('empresas.change_grupoempresa,') &&
                                    <a
                                        href={`/grupos-de-empresas/editar/${objeto.id}`}
                                        className="btn btn-primary btn-sm btn-block"
                                    >
                                        <i className="fa fa-pencil" /> Editar
                                    </a>
                                }
                                { verificarPermiso('empresas.delete_grupoempresa,') &&
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm btn-block"
                                        onClick={this.eliminar}
                                    >
                                        <i className="fa fa-trash" /> Eliminar
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .info {
                        display: flex;
                        justify-content: space-between;
                    }
                    .pb25 {
                        padding-bottom: 25px;
                    }
                    .pb5 {
                        padding-bottom: 5px;
                    }
                    .pl10 {
                        padding-left: 10px;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
