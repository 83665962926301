import React, { Component } from 'react';
import {
  obtenerComprobantes,
  agregarComprobante,
  terminarSolicitud
} from '../../api/Promotores';
import Autenticado from '../../components/Autenticado';
import { BASE_URL } from '../../utils/config';
import { agregarComprobanteSolicitud, obtenerSolicitud } from '../../api/Solicitudes';
// import Formulario from '../../components/form/Form';
import Modal from 'react-awesome-modal';
import Select from 'react-select';
import { obtenerDocumentos } from '../../api/Documentos';
import { mostrarToast } from '../../utils/alerts';


class OtrosCreditos extends Component {
  state = {
    comprobantes: [],
    empleado: '',
    agregarDocumento: null,
    listaDocumentos: [],
    showAgregarDocumento: false
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    let empleado = '';
    try {
      const resp = await obtenerSolicitud(id);
      empleado = `${resp.data.empleado.nombre || ''} ${resp.data.empleado
        .apellido_paterno || ''} ${resp.data.empleado.apellido_materno || ''}`;
      this.setState({empleado});
      await this.getComprobantesSolicitud();
    } catch (error) {
      console.log('Error al obtener solicitud:', error);
    }
  };

  getComprobantesSolicitud = async () =>{
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      const resp = await obtenerComprobantes(id);
      const comprobantes = [];
      resp.data.forEach(c => {
        comprobantes.push({
          ...c,
          nombreArchivo: '',
          error: ''
        });
      });
      this.setState({ comprobantes });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  handleImageChange = async e => {
    e.preventDefault();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const {
      target: { name, files }
    } = e;
    const file = files[0];
    if (file) {
      const nameArray = name.split('_');
      const { comprobantes } = this.state;
      const comprobante = comprobantes.find(
        c => c.id === parseInt(nameArray[1])
      );
      comprobante.nombreArchivo = file.name;
      comprobante.error = '';
      const formData = new FormData();
      formData.append('documento', file);
      try {
        const resp = await agregarComprobante(id, formData, comprobante.id);
        comprobante.documento = resp.data.documento;
      } catch (error) {
        console.log('Error:', error);
      }
      this.setState({ comprobantes });
    }
  };

  showAgregarDocumento = async () => {

    const { comprobantes } = this.state;

    try{
      const req = await obtenerDocumentos();
      const documentos = [];
      req.data.forEach(documento => {
        if (comprobantes.filter(e => e.tipo_documento_id === documento.id).length === 0) {
          documentos.push({value: documento.id, label: documento.nombre});
        }
      });
      this.setState({listaDocumentos: documentos})
    }catch (e) {
      console.log('Ocurrio un error al cargar los tipos de documentos');
    }
    this.setState({showAgregarDocumento: true});
  }

  handleChangeAgregarDocumento = (data) => {
    this.setState({agregarDocumento: data})
  }

  guardarComprobante = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { agregarDocumento, comprobantes } = this.state;

    try {
      const req = await agregarComprobanteSolicitud({tipo_documento: agregarDocumento.value}, id);
      comprobantes.push({
        ...req.data,
        id: req.data.id,
        tipo_documento_id: req.data.tipo_documento,
        nombre: req.data.tipo_documento_nombre,
        nombreArchivo: '',
        error: ''
      });
      mostrarToast('Se agregó el comprobante con éxito', 'success');
      this.setState({showAgregarDocumento: false, comprobantes, agregarDocumento: null});
    }catch (e) {
      console.log(e);
      mostrarToast('Ocurrió un error al agregar el comprobante', 'error');
      this.setState({showAgregarDocumento: false, agregarDocumento: null});
    }

  }

  submit = async (estatus='') => {

    const {
      match: {
        params: { id }
      }
    } = this.props;

    if (estatus === 'completa'){
      try {
        await terminarSolicitud(id);
        mostrarToast('Se completó la solicitud satisfactoriamente', 'success');
      } catch (error) {
        console.log('Error al terminar solicitud:', error);
      }
    }else {
      // Se deja incompleta
      mostrarToast('Se dejó incompleta la solicitud', 'success');
    }

    this.props.history.push(`/solicitudes/ver/${id}/`);


  };

  render() {
    const { comprobantes, empleado, listaDocumentos } = this.state;

    const {
      match: {
        params: { id }
      }
    } = this.props;
    const guia = [
      { nombre: 'Solicitudes', url: '/solicitudes', clase: '' },
      { nombre: 'Comprobantes', url: '', clase: 'active' }
    ];
    return (
      <Autenticado
        titulo={`Captura de comprobantes de ${empleado}`}
        guia={guia}
      >
        <div className="ibox">
          <div className="ibox-content">
            <div className="row mt2">
              <div className="col-sm-6">
                <div className="row border-bottom">
                  <div className="col-1">
                    <i
                      className="fa fa-exclamation-circle text-warning text-right"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="col-11 fsz-sm">
                    <p>
                      <b>
                        Es importante que tomes en cuenta las siguientes
                        consideraciones:
                      </b>
                    </p>
                    <p>
                      Puedes subir archivos escaneados o imágenes tomadas desde
                      la cámara de tu celular.
                    </p>
                    <p>
                      Cuando vas a tomar la foto con tu celular, tómala en
                      calidad media o baja.
                    </p>
                    <p>Las imágenes deben de estar perfectamente legibles.</p>
                    <p>Los formatos aceptados son .jpg, .png, .pdf y .gif</p>
                  </div>
                </div>
                <div className="row mt1">
                  <div className="col-1" />
                  <div className="col-11 fsz-sm">
                    <p>
                      Descarga la carta de autorización de consulta al buró de
                      crédito
                    </p>
                    <a
                      href={`${BASE_URL}/descargas/autorizacion-buro-credito/`}
                      className="btn btn-link flex-row"
                    >
                      <i className="fa fa-download" aria-hidden="true" />
                      &nbsp;&nbsp;
                      <b>Descarga</b>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-md-12">
                    <button onClick={this.showAgregarDocumento} className="btn btn-primary float-right"><i className="fa fa-plus"></i> Agregar comprobante</button>
                  </div>
                </div>

                {comprobantes.map(c => (
                  <div key={`comp${c.id}`} className="row mt1">
                    <div className="col-5 comprobanteLabel">{c.nombre}</div>
                    <div className="col-7">
                      <div key={c.id} className="custom-file">
                        <input
                          type="file"
                          className={`custom-file-input ${
                            c.error ? 'is-invalid' : ''
                          }`}
                          id={`comprobante${c.id}`}
                          name={`comprobante_${c.id}`}
                          onChange={this.handleImageChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor={`comprobante${c.id}`}
                        >
                          {c.nombreArchivo}
                        </label>
                      </div>
                      {c.error && (
                        <div
                          className="invalid-feedback"
                          style={{ display: 'inline' }}
                        >
                          {c.error}
                        </div>
                      )}
                    </div>
                    {c.documento && (
                      <div className="col-7 offset-5">
                        <a
                          href={c.documento}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fa fa-check-circle-o text-right text-success"
                            aria-hidden="true"
                          />
                          <small className="archivo"> Archivo</small>
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <a
              className="btn btn-secondary mt2"
              href={`/solicitudes/${id}/otros-creditos`}
            >
              Regresar
            </a>
          </div>
          <div className="col-6 text-right">
            <button
              className="btn btn-warning mt2"
              type="button"
              onClick={() => this.submit('incompleta')}
            >
              Dejar incompleta
            </button>
            <button
              className="btn btn-success mt2 m-l-sm"
              type="button"
              onClick={() => this.submit('completa')}
            >
              Terminar
            </button>
          </div>
        </div>

        { this.state.showAgregarDocumento &&
          <Modal visible={ true } width="28%" height="20%" effect="fadeInUp">
            <div className="modal-content">
              <div className="modal-header">
                  <h4 className="modal-title">Agregar comprobante</h4>
              </div>
              <div className="modal-body">

                <Select
                  value={this.state.agregarDocumento}
                  placeholder='Selecciona un tipo de documento'
                  onChange={this.handleChangeAgregarDocumento}
                  options={listaDocumentos}
                />

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-white" onClick={() => this.setState({showAgregarDocumento: false}) }>Cerrar</button>
                <button type="button" className="btn btn-primary" disabled={this.state.agregarDocumento === null} onClick={() => this.guardarComprobante()}>{ this.state.comprobanteGuardarDisabled ? 'Guardando...' : 'Guardar' }</button>
              </div>
            </div>
          </Modal>
        }

        <style jsx="true">{`
          .flex-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 0px !important;
            color: #25d07b !important;
          }
          .flex-row img {
            width: 20px;
            margin-right: 0.5rem;
          }
          .comprobanteLabel {
            color: #25d07b;
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
          .archivo {
            font-size: 0.8rem;
            color: #333;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default OtrosCreditos;
