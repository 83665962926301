import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Tabla from '../../components/Tabla'
import { obtenerGruposEmpresas } from '../../api/GrupoEmpresas'
import { verificarPermiso } from '../../utils/auth'

class Lista extends Component {
    state = {
        titulo: 'Grupos de empresas',
        objetos: []
    }

    async componentDidMount() {
        try {
            const res = await obtenerGruposEmpresas()
            const objetos = res.data
            this.setState({ objetos })
        } catch (error) {
            console.log(error)
        }   
    }

    buscar = async texto => {
        try {
            const params = { search: texto }
            const req = await obtenerGruposEmpresas(params)
            const objetos = req.data
            this.setState({ objetos })
        } catch (error) {
            console.log(error)
        }
        console.log(texto)
    }

    limpiar = async () => {
        try {
            const req = await obtenerGruposEmpresas()
            const objetos = req.data
            this.setState({ objetos })
        } catch (error) {
            console.log(error)
        }
    };

    render () {
        const { titulo, objetos } = this.state
        const guia = [
            { nombre: titulo, url: '', clase: 'active' }
        ]
        const urlBotonAgregar = verificarPermiso('empresas.add_grupoempresa,') ? '/grupos-de-empresas/agregar' : ''
        const urlBotonEditar = verificarPermiso('empresas.change_grupoempresa,') ? '/grupos-de-empresas/editar/' : ''
        const botones = [
            {
                etiqueta: 'Editar',
                url: urlBotonEditar
            },{
                etiqueta: 'Ver',
                url: '/grupos-de-empresas/ver/'
            }
        ]
        const columnas = [
            {
                llave: 'nombre',
                etiqueta: 'Nombre'
            }
        ]
        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <Tabla
                    objetos={ objetos }
                    columnas={ columnas }
                    botones={ botones }
                    urlBotonAgregar={urlBotonAgregar}
                    buscar
                    onFindClicked={this.buscar}
                    onCleanClicked={this.limpiar}
                />
            </Autenticado>
        )
    }
}

export default Lista
