import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { obtenerCuentaMaestra } from '../../api/CuentasMaestras';
import { dateTimeFormat } from '../../utils/filters';
// import { verificarPermiso } from '../../utils/auth';
import { Link } from 'react-router-dom';

class Form extends Component {
  state = {
    id: this.props.match.params.id,
    objeto: {
      domicilio: {}
    }
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      try {
        const resp = await obtenerCuentaMaestra(id);
        const objeto = resp.data;
        const empresas = objeto.empresas;
        empresas.map(c => (console.log(c)));
        this.setState({ objeto });
      } catch (error) {
        console.log(error);
      }
    }
  }

  formarDireccion = d => {
    if (!d) {
      return '';
    }
    return `${d.calle} #${d.numero_exterior}${
      d.numero_interior ? ` int. ${d.numero_interior} ` : ''
    } ${d.colonia} C.P. ${d.codigo_postal}, ${d.municipio_str}, ${
      d.estado_str
    }`;
  };

  renderRow = (label, value) => (
    <div className="row m-t-xs">
      <div className="col-sm-6">
        <span>{label}</span>
      </div>
      <div className="col-sm-6">
        <strong>{value || '--'}</strong>
      </div>
    </div>  
  );

  render() {
    const guia = [
      { nombre: 'Cuentas Maestras', url: '/cuentas-maestra', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
    const { objeto/* , id  */} = this.state;

    return (
      <Autenticado titulo="Ver Cuenta Maestra" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>Información general</h5>
                  </div>
                  <div className="ibox-content">
                    <h4>
                      <strong>{objeto.nombre}</strong>
                    </h4>
                    {this.renderRow(
                      'UUID',
                      objeto.uuid
                    )}
                    {this.renderRow(
                      'Tipo de Contacto',
                      objeto.tipo_contacto 
                    )}
                    {this.renderRow(
                      'Canal de Venta',
                      objeto.canal_venta
                    )}
                    {this.renderRow(
                      'Frecuecia de pago',
                      objeto.frecuencia_pago
                    )}
                    {this.renderRow(
                      'Ejecutivo CS',
                      objeto.ejecutivo_cs
                    )}
                    {this.renderRow(
                      'Vendedor',
                      objeto.vendedor
                    )}
                    {this.renderRow(
                      'Industria',
                      objeto.industria
                    )}
                    {this.renderRow(
                      'Giro',
                      objeto.giro
                    )}
                    {this.renderRow(
                      'Empleados Administrativos',
                      objeto.empleados_administrativos
                    )}
                    {this.renderRow(
                      'Empleados Operativos',
                      objeto.empleados_operativos
                    )}
                    {this.renderRow(
                      'Total de Empleados',
                      objeto.total_empleados
                    )}
                    {this.renderRow(
                      'Fecha Creación',
                      dateTimeFormat(objeto.fecha_creacion)
                    )}
                    {this.renderRow(
                      'Fecha Edición',
                      dateTimeFormat(objeto.fecha_edicion)
                    )}
                  </div>
                </div>
              </div>
            </div>
            {objeto.empresas && objeto.empresas.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Empresas</h5>
                    </div>
                    {objeto.empresas.map(empresa => (
                      <div className="ibox-content" key={`empresa${empresa.id}`}>
                        <div className="info">
                        <span>{empresa.nombre || '--'}</span>
                        <Link
                                key={`ver${empresa.id}`}
                                to={`/empresas/ver/${empresa.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-eye fa-margin" />
                                Ver
                        </Link>
                        </div>       
                      </div>
                       ))}
                      
                  </div>
                </div>
              </div>
               )}
               {objeto.empresas && objeto.empresas.length === 0 && (
                <div className="row">
                       <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Empresas</h5>
                    </div>
                    <div className="ibox-content">
                        <div className="info">
                        <span>No hay empresas registradas a esta cuenta</span>
                        </div>       
                      </div>
                  </div>
                </div>
                </div>
                )}
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .sinLogo {
            width: 100%;
            background-color: #ccc;
          }
          .sinLogo:after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
