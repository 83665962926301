import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { obtenerUsuario } from '../../api/Usuarios';
import { verificarPermiso } from '../../utils/auth'

class Form extends Component {
  state = {
    id: null,
    objeto: {}
  };

  componentWillMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({ id });
  }

  async componentDidMount() {
    const { id } = this.state;
    if (id) {
      try {
        const resp = await obtenerUsuario(id);
        const objeto = resp.data;
        console.log(objeto);
        this.setState({ objeto });
      } catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    const guia = [
      { nombre: 'Usuarios', url: '/usuarios', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
    const { objeto } = this.state;

    return (
      <Autenticado titulo="Ver usuario" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-9">
                <div className="ibox">
                  <div className="ibox-content">
                    <h3>
                      <strong>
                        {`${objeto.nombre} ${objeto.apellido_paterno} ${
                          objeto.apellido_materno
                        }`}
                      </strong>
                    </h3>
                    <div className="info">
                      <span>Correo electrónico</span>
                      <strong>{objeto.email}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pb25">
                { verificarPermiso('usuarios.change_user,') &&
                  <a
                    href={`/usuarios/editar/${objeto.id}`}
                    className="btn btn-primary btn-sm btn-block"
                  >
                    <i className="fa fa-pencil" /> Editar
                  </a>
                }
                { verificarPermiso('usuarios.delete_user,') &&
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-block"
                  >
                    <i className="fa fa-trash" /> Eliminar
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .pb25 {
            padding-bottom: 25px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
