import { ConstructorAPIAsync } from './API';
import { callError } from './Error';


async function obtenerPrestamosParaPagos(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/pagos/prestamos/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerPagos(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/pagos-prestamos/', { params: parametros });
  } catch (err) {
    callError(err);;
  }
}

async function exportarPagos() {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    return await API.get('/admin/pagos-prestamos/?export=true');
  } catch (err) {
    callError(err);;
  }
}

async function obtenerDetallePago(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/pagos-prestamos/${id}/`);
  } catch (err) {
    callError(err);;
  }
}

async function crearPago(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/admin/pagos-prestamos/', datos);
  } catch (err) {
    callError(err);
  }
}

async function verificarPago(id) {
  const API = await ConstructorAPIAsync(120000)
  try {
    return await API.post(`/admin/pagos-prestamos/${id}/verificar-pago/`, {
      verificar_pago: true
    });
  } catch (err) {
    callError(err);;
  }
}

async function cancelarPago(datos, id) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post(`/admin/pagos-prestamos/${id}/cancelar-pago/`, datos);
  } catch (err) {
    callError(err);;
  }
}

async function rechazarPago(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/pagos-prestamos/${id}/rechazar-pago/`);
  } catch (err) {
    callError(err);;
  }
}

async function listaPagosMasivos(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/pagos-masivos/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function detallePagoMasivo(id) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.get(`/admin/pagos-masivos/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function listaPagosMasivosPrestamo(id, parametros = {}) {
  // Listado de pagos masivos prestamos de un pago masivo
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.get(`/admin/pagos-masivos/${id}/pagos-masivos-prestamos/`, { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function validarArchivoPagoMasivo(datos) {
  const API = await ConstructorAPIAsync(300000);
  try {
    return await API.post('/admin/pagos-masivos/validar/v2/', datos);
  } catch (err) {
    callError(err);
  }
}

async function crearPagoMasivo(datos) {
  const API = await ConstructorAPIAsync(300000);
  try {
    return await API.post('/admin/pagos-masivos/', datos);
  } catch (err) {
    callError(err);
  }
}

async function aprobarPagoMasivo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/pagos-masivos/${id}/verificar-pago/`, {
      verificar_pago: true
    });
  } catch (err) {
    callError(err);
  }
}

async function rechazarPagoMasivo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/pagos-masivos/${id}/rechazar-pago/`);
  } catch (err) {
    callError(err);
  }
}

async function reporteCobranza(parametros = '') {
  const API = await ConstructorAPIAsync(120000)
  try {
    return await API.get(`/admin/cobranza/`, { params: parametros })
  } catch (err) {
    callError(err)
  }
}

async function exportarReporteCobranza(parametros = {}) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  parametros.export = true;
  try {
    return await API.get(`/admin/cobranza/`, { params: parametros });
    delete parametros.export;
  } catch (err) {
    callError(err)
  }
}

async function reportePagosEmergentes(parametros = '') {
  const API = await ConstructorAPIAsync(120000)
  try {
    return await API.get(`/admin/pagos-emergentes/`, { params: parametros })
  } catch (err) {
    callError(err)
  }
}

async function exportarPagosEmergentes(parametros = {}) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  parametros.export = true;
  try {
    return await API.get(`/admin/pagos-emergentes/`, { params: parametros });
    delete parametros.export;
  } catch (err) {
    callError(err)
  }
}

export {
  obtenerPagos,
  exportarPagos,
  obtenerDetallePago,
  crearPago,
  verificarPago,
  cancelarPago,
  rechazarPago,
  listaPagosMasivos,
  detallePagoMasivo,
  listaPagosMasivosPrestamo,
  validarArchivoPagoMasivo,
  crearPagoMasivo,
  aprobarPagoMasivo,
  rechazarPagoMasivo,
  reporteCobranza,
  exportarReporteCobranza,
  obtenerPrestamosParaPagos,
  reportePagosEmergentes,
  exportarPagosEmergentes
};
