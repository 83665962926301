import React, { Component } from 'react';
import { obtenerCuentasMaestrasList } from '../api/CuentasMaestras';
import Select from 'react-select';

class CuentaMaestraSelect extends Component {

  state = {
    cuentas_maestras: []
  };

  async componentDidMount() {

    try {
      const req = await obtenerCuentasMaestrasList();
      const cuentas_maestras = req.data;

      this.setState({cuentas_maestras});

    } catch (e) {
      console.log(e);
    }

  }

  render() {

    const { cuentas_maestras } = this.state;

    const { onChange } = this.props;

    const cuentas_maestras_values = cuentas_maestras.map( c => {
      return {label: c.nombre, value: c.id};
    });

    cuentas_maestras_values.unshift({label: "Selecciona una Cuenta Maestra", value: ""})

    return (
      <Select
        name="cuentaMaestra"
        placeholder="Selecciona una Cuenta Maestra"
        className="select"
        onChange={onChange}
        options={cuentas_maestras_values}
      />
    )
  }

}

export default CuentaMaestraSelect;