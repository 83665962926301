import { ConstructorAPIAsync } from './API';
import { callError } from './Error';


async function obtenerReportesBuroAdmin (parametros = '') {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/admin/buro-credito/reportes/', { params: parametros })
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerReportesBuroAdmin
}
