import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { reactivar_prestamo_domiciliacion, obtener_prestamos_bloqueados } from '../../api/Domiciliacion'
// import moment from 'moment';
import Modal from 'react-awesome-modal';
import Swal from 'sweetalert2';
// import { obtenerPrestamos, exportarPrestamos } from '../../api/Prestamos';
// import { currencyFormat } from '../../utils/filters'
import Paginacion from '../../components/Paginacion';


class HabilitacionCreditos extends Component {
  state = {
    titulo: 'Préstamos bloqueados de domiciliación',
    objetos: [],
    totalRecords: 0,
    pageLimit: 20,
    params: {},
    loading: true,
    modal_reactivar: false,
    id_reactivar: 0,
    mensaje: 'No hay préstamos bloqueados de domiciliación',
    datos: {
      search: ''
    },
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const promesas = [this.obtenerPrestamos(params)];
      const [req] = await Promise.all(promesas);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params});
    } catch (error) {
      console.log(error);
    }
  }
  
  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await this.obtenerPrestamos(params);
      const objetos = req.data.results;
      console.log(objetos);
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerPrestamos = async params => {
    try {
      const req = await obtener_prestamos_bloqueados(params);
      const loading = false;
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params, loading });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerPrestamos(params);
  };

  onChange = async(campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (campo === 'search') {
      const {
        datos: { search }
      } = this.state;
      delete params.search;
      delete params.page;
      params = Object.assign({ search }, params);
      this.obtenerPrestamos(params);
    }
    if (valor === '') {
      await this.limpiar()
    }
  };

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: ''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerPrestamos(params);
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  reactivar_prestamo(id){
    this.setState({id_reactivar: id, modal_reactivar: true})
  }

  obtener_objeto = id => {
    const { objetos } = this.state
    let objeto = objetos.filter((o) => o.id === id)
    return objeto[0]
  }

  btn_reactivar_prestamo_domiciliacion = async () => {
    try{
      this.setState({loading: true});
      const {id_reactivar, objetos} = this.state
      /*const eliminacion = */ await reactivar_prestamo_domiciliacion(id_reactivar);
      //
      var indice_objeto = objetos.indexOf(this.obtener_objeto(id_reactivar))
      objetos.splice(indice_objeto, 1);
      this.setState({objetos, modal_reactivar:false, loading:false});
      this.mostrarToast('Préstamo reactivado correctamente.');
    }catch(error){
      console.log(error)
      this.mostrarToast(error.message, 'error');
    }
  }

  cerrarAlertas = () => {
    Swal.close();
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };


  render() {
    const {
      titulo,
      loading,
      mensaje,
      objetos,
      modal_reactivar,
      totalRecords,
      pageLimit,
      datos: { search }
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">

              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    {/* <div className="row"> */}
                    <div className="col-sm-4 ml-auto p-0">
                      <div className="input-group">
                        <input
                          value={search}
                          type="text"
                          className="form-control form-control-sm"
                          onChange={e => this.onChange('search', e)}
                          onKeyDown={e => this.handleKeyDown(e)}
                          placeholder="Buscar"
                        />
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-sm btn-info"
                            onClick={() => this.limpiar()}
                          >
                            <i className="fa fa-close fa-margin"/>
                          </button>
                        </span>
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => this.buscar()}
                          >
                            <i className="fa fa-search fa-margin"/>
                            Buscar
                          </button>
                        </span>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="row">
                      <br/>
                      <div className="col-sm-12">
                        {
                          loading ?
                            <div className="spiner-example">
                              <div className="sk-spinner sk-spinner-wandering-cubes">
                                <div className="sk-cube1"></div>
                                <div className="sk-cube2"></div>
                              </div>
                            </div>
                          :
                          objetos.length > 0 ?
                            // <div className="table-responsive" style={{'height':'500px'}}>
                            <div className="table-responsive" >
                              <table className="table table-striped" style={{'overflowY':'scroll'}}>
                                <thead>
                                  <tr>
                                    <th>Folio</th>
                                    <th>Empresa</th>
                                    <th>Empleado</th>
                                    <th>Frecuencia de Pago</th>
                                    <th>Banco</th>
                                    <th>Clabe interbancaria</th>
                                    <th>Estatus</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {objetos.map(obj => {
                                    return (
                                      <tr key={obj.folio}>
                                        <td>{obj.folio}</td>
                                        <td>{obj.empresa}</td>
                                        <td>{obj.empleado}</td>
                                        <td>{obj.frecuencia_pago}</td>
                                        <td>{obj.banco}</td>
                                        <td>{obj.clabe_interbancaria}</td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-lg btn-primary"
                                            onClick={() => this.reactivar_prestamo(obj.id)}
                                          >
                                            Reactivar
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          :
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="text-center p-lg">
                                    <h2>{ mensaje }</h2>
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>

                  <Paginacion
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                  />

                </div>
              </div>
            </div>
          </div>
          <Modal visible={ modal_reactivar } width="40%" height="35%" effect="fadeInUp">
            <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                <div className="modal-header">
                    <h4 className="modal-title">Reactivar préstamo</h4>
                </div>
                <div className="modal-body" style={{'overflowY':'scroll'}}>
                    <div className="row">
                      <div className="col-sm-12">
                        <p className="text-center">¿Deseas reactivar el préstamo para que aparezca en la búsqueda al generar archivos?</p>
                      </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-white"
                      onClick={() => this.setState({modal_reactivar: false}) }
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.btn_reactivar_prestamo_domiciliacion()}
                    >
                        Reactivar
                    </button>
                </div>
            </div>
          </Modal>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default HabilitacionCreditos;
