import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import { obtenerCuentaBancaria } from '../../api/CuentasBancarias'
import { dateFormat } from '../../utils/filters'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        objeto: {}
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Ver cuenta bancaria'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            const res = await obtenerCuentaBancaria(id)
            const objeto = res.data
            this.setState({ objeto })
        } catch (error) {
            console.log(error)
        }
    }

    render () {
        const guia = [
            { nombre: 'Cuentas bancarias', url: '/cuentas-bancarias', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        const {
            titulo,
            objeto
        } = this.state

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Información de la cuenta bancaria
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Número de cuenta</label>
                                                                <label>{ objeto.no_cuenta }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Banco</label>
                                                                <label>{ objeto.banco }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>CLABE</label>
                                                                <label>{ objeto.clabe}</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Usuario que creó</label>
                                                                <label>{ objeto.usuario_creo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de creación</label>
                                                                <label>{ dateFormat(objeto.fecha_creacion) }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
