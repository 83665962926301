import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../css/bootstrap.css';
import '../font-awesome/css/font-awesome.css';
import '../css/animate.css';
import '../css/style.css';
import perfil from '../img/user-48x48.png';
import { cambiarImagenPerfil } from '../api/Auth';
import { removeToken, setImagenPerfil, verificarPermiso } from '../utils/auth';
import { cargando, mostrarToast, cerrarAlertas } from '../utils/alerts';
// import ReporteNip from '../pages/reportes/ReporteNip';

class Autenticado extends Component {
  state = {
    menuColapsado: false,
    menusColapsados: {
      id_empresas: true
    },
    profileImagePreview: null
  };

  logOut = () => {
    removeToken();
  };

  pressMenu = () => {
    let { menuColapsado } = this.state;
    if (menuColapsado === false) {
      document.body.classList.add('mini-navbar');
      menuColapsado = true;
    } else {
      document.body.classList.remove('mini-navbar');
      menuColapsado = false;
    }
    this.setState({ menuColapsado });
  };

  clickMenu = (e, idMenu) => {
    e.preventDefault();
    const { menusColapsados } = this.state;
    let menu = menusColapsados[idMenu];
    if (menu === false) {
      document.getElementById(idMenu).classList.remove('in');
      document.getElementById(`li_${idMenu}`).classList.remove('active');
      menu = true;
    } else {
      document.getElementById(idMenu).classList.add('in');
      document.getElementById(`li_${idMenu}`).classList.add('active');
      menu = false;
    }
    menusColapsados[idMenu] = menu;
    this.setState({ menusColapsados });
  };

  handleImageChange = async e => {
    e.preventDefault();
    const {
      target: { files }
    } = e;
    const file = files[0];
    if (file) {
      cargando();
      const formData = new FormData();
      formData.append('imagen_perfil', file);
      try {
        const resp = await cambiarImagenPerfil(formData);
        await setImagenPerfil(resp.data.imagen_perfil);
        cerrarAlertas();
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            profileImagePreview: reader.result
          });
        };
        reader.readAsDataURL(file);
        mostrarToast('Imagen de perfil actualizada.');
      } catch (error) {
        console.log('Error:', error);
        cerrarAlertas();
        mostrarToast('No fue posible actualizar la imagen de perfil.', 'error');
      }
    }
  };

  render() {
    const { titulo, guia, children, header } = this.props;
    const { profileImagePreview } = this.state;
    const nombre = localStorage.getItem('nombre');
    const tipo = localStorage.getItem('tipo');
    const imagenPerfil = localStorage.getItem('imagenPerfil');

    return (
      <div id="wrapper">
        <nav className="navbar-default navbar-static-side" role="navigation">
          <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu">
              <li className="nav-header">
                <div className="dropdown profile-element">
                  <div className="profileImageDiv">
                    <img
                      alt="Perfil"
                      className="rounded-circle"
                      width="48"
                      height="48"
                      src={profileImagePreview || imagenPerfil || perfil}
                    />
                    <div className="overlay">
                      <label htmlFor="fileProfileImage">Cambiar imagen</label>
                      <input
                        type="file"
                        id="fileProfileImage"
                        name="profileImage"
                        style={{ display: 'none' }}
                        onChange={e => this.handleImageChange(e)}
                      />
                    </div>
                  </div>
                  <a
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    href="#"
                  >
                    <span className="block m-t-xs font-bold">{nombre}</span>
                    <span className="text-muted text-xs block">
                      {tipo === 'null' ? '' : tipo}
                    </span>
                  </a>
                  {/* <ul className="dropdown-menu animated fadeInRight m-t-xs">
                    <li>
                      <a className="dropdown-item" href="profile.html">
                        Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="contacts.html">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="mailbox.html">
                        Mailbox
                      </a>
                    </li>
                    <li className="dropdown-divider" />
                    <li>
                      <a className="dropdown-item" href="login.html">
                        Logout
                      </a>
                    </li>
                  </ul> */}
                </div>
                {/* <div className="logo-element">IN+</div> */}
              </li>   
              <li>
                <Link to="/">
                  <i className="fa fa-home" />{' '}
                  <span className="nav-label">Inicio</span>
                </Link>
              </li>
              <li id="li_id_reportes" className={verificarPermiso('solicitudes.view_solicitudcredito,') ? '' : ' ocultar' }>
                <Link to="/dashboard-tracking">
                  <i className="fa fa-database" />{' '}
                  <span className="nav-label">Tracking solicitudes</span>{' '}
                  {/* <span className="fa arrow" /> */}
                </Link>
                {/* <ul className="nav nav-second-level collapse" id="id_reportes">
                  <li>
                    <Link to="/reportes/reporte-nip">
                      <i className="fa fa-table"></i>{' '}
                      <span className="nav-label"></span>
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li className={verificarPermiso('empresas.view_empresa,') || verificarPermiso('empresas.view_grupoempresa,') ? '' : ' ocultar' } id="li_id_empresas">
                <a href="" onClick={e => this.clickMenu(e, 'id_empresas')}>
                  <i className="fa fa-th-large" />{' '}
                  <span className="nav-label">Empresas</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_empresas">
                  <li className={verificarPermiso('empresas.view_empresa,') ? '' : ' ocultar' }>
                    <Link to="/cuentas-maestra">
                      <i className="fa fa-list-alt" />{' '}
                      <span className="nav-label">Cuentas Maestras</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('empresas.view_empresa,') ? '' : ' ocultar' }>
                    <Link to="/empresas">
                      <i className="fa fa-list-alt" />{' '}
                      <span className="nav-label">Empresas</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('empresas.view_empresa,') ? '' : ' ocultar' }>
                    <Link to="/unidades-operativas">
                      <i className="fa fa-list-alt" />{' '}
                      <span className="nav-label">Unidades Operativas</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li id="li_id_usuarios" className={verificarPermiso('usuarios.view_user,') || verificarPermiso('auth.view_group,') ? '' : ' ocultar' }>
                <a href="" onClick={e => this.clickMenu(e, 'id_usuarios')} >
                  <i className="fa fa-user-o" />{' '}
                  <span className="nav-label">Usuarios</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_usuarios">
                  <li className={verificarPermiso('usuarios.view_user,') ? '' : ' ocultar' }>
                    <Link to="/usuarios">
                      <i className="fa fa-list-alt" />{' '}
                      <span className="nav-label">Lista</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('auth.view_group,') ? '' : ' ocultar' }>
                    <Link to="/grupos">
                      <i className="fa fa-users" />{' '}
                      <span className="nav-label">Grupos</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={verificarPermiso('empleados.view_empleado,') ? '' : ' ocultar' } id="li_id_empleados">
                <a href="" onClick={e => this.clickMenu(e, 'id_empleados')}>
                  <i className="fa fa-th-large" />{' '}
                  <span className="nav-label">Empleados</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_empleados">
                  <li>
                    <Link to="/empleados">
                      <i className="fa fa-list-alt" />{' '}
                      <span className="nav-label">Lista</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/empleados/importar">
                      <i className="fa fa-cloud-upload" />{' '}
                      <span className="nav-label">Importar</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={verificarPermiso('productos.view_producto,') ? '' : ' ocultar' }>
                <Link to="/productos">
                  <i className="fa fa-cubes" />{' '}
                  <span className="nav-label">Productos</span>
                </Link>
              </li>
              <li className={verificarPermiso('solicitudes.view_solicitudcredito,') ? '' : ' ocultar' }>
                <Link to="/solicitudes">
                  <i className="fa fa-cubes" />{' '}
                  <span className="nav-label">Solicitudes</span>
                </Link>
              </li>
              <li className={verificarPermiso('disposiciones.view_disposicion,') ? '' : ' ocultar' }>
                <Link to="/disposiciones">
                  <i className="fa fa-cubes" />{' '}
                  <span className="nav-label">Disposiciones</span>
                </Link>
              </li>
              <li className={verificarPermiso('prestamos.view_prestamo,') ? '' : ' ocultar' }>
                <Link to="/prestamos">
                  <i className="fa fa-cubes" />{' '}
                  <span className="nav-label">Prestamos</span>
                </Link>
              </li>
              <li id="li_id_pagos" className={verificarPermiso('pagos.view_pagoprestamo,') || verificarPermiso('pagos.view_pagomasivo,') ? '' : ' ocultar' }>
                <a href="" onClick={e => this.clickMenu(e, 'id_pagos')}>
                  <i className="fa fa-th-large" />{' '}
                  <span className="nav-label">Pagos</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_pagos">
                  <li className={verificarPermiso('pagos.view_pagoprestamo,') ? '' : ' ocultar' }>
                    <Link to="/pagos">
                      <i className="fa fa-list-alt" />{' '}
                      <span className="nav-label">Listado de pagos</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('pagos.view_pagomasivo,') ? '' : ' ocultar' }>
                    <Link to="/pagos/masivos">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Pagos masivos</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('prestamos.view_prestamo,') ? '' : ' ocultar' }>
                    <Link to="/pagos/reporte-de-cobranza">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Reporte de cobranza</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('prestamos.view_prestamo,') ? '' : ' ocultar' }>
                    <Link to="/pagos/reporte-pagos-emergentes">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Reporte de Pagos Emergentes</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={verificarPermiso('cuentas_bancarias.view_cuenta,') ? '' : 'ocultar'}>
                <Link to="/cuentas-bancarias">
                  <i className="fa fa-cubes" />{' '}
                  <span className="nav-label">Cuentas bancarias</span>
                </Link>
              </li>

              <li id="li_id_conciliaciones" className={verificarPermiso('cuentas_bancarias.view_cuenta,') ? '' : 'ocultar'}>
                <a href="" onClick={e => this.clickMenu(e, 'id_conciliaciones')}>
                  <i className="fa fa-money" />{' '}
                  <span className="nav-label">Conciliaciones</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_conciliaciones">
                  <li className={verificarPermiso('transacciones_bancarias.view_archivobancario,') ? '' : 'ocultar'}>
                    <Link to="/conciliaciones/archivos-bancarios">
                      <i className="fa fa-book"></i>{' '}
                      <span className="nav-label">Archivos Bancarios</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li id="li_id_emida" className={verificarPermiso('pago_servicios.view_transaccion,') || verificarPermiso('pago_servicios.view_pagoservicio,') || verificarPermiso('pago_servicios.view_recarga,') ? '' : 'ocultar'}>
                <a href="" onClick={e => this.clickMenu(e, 'id_emida')}>
                  <i className="fa fa-th-large" />{' '}
                  <span className="nav-label">EMIDA</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_emida">
                  <li className={verificarPermiso('pago_servicios.view_transaccion,') ? '' : 'ocultar'}>
                    <Link to="/transacciones">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Transacciones</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('pago_servicios.view_pagoservicio,') ? '' : 'ocultar'}>
                    <Link to="/pago-de-servicios">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Pago de servicios</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('pago_servicios.view_recarga,') ? '' : 'ocultar'}>
                    <Link to="/recargas">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Recargas</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li id="li_id_domiciliacion" className={verificarPermiso('domiciliacion.generar_archivos,') || verificarPermiso('domiciliacion.generar_promesas,') || verificarPermiso('domiciliacion.leer_archivos,') ||  verificarPermiso('domiciliacion.generar_altas,') ||  verificarPermiso('domiciliacion.habilitar_creditos,') ||  verificarPermiso('domiciliacion.ver_historial,') ? '' : 'ocultar'}>
                <a href="" onClick={e => this.clickMenu(e, 'id_domiciliacion')}>
                  <i className="fa fa-th-large" />{' '}
                  <span className="nav-label">Cobranza</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_domiciliacion">
                  {/*<li>
                    <Link to="/domiciliacion/express">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Generación Express</span>
                    </Link>
                  </li>*/}
                  <li className={verificarPermiso('domiciliacion.generar_archivos,') ? '' : 'ocultar'}>
                    <Link to="/domiciliacion/generar">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Generación</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('domiciliacion.generar_promesas,') ? '' : 'ocultar'}>
                    <Link to="/domiciliacion/generar_promesas">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Promesas de Pago</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('domiciliacion.leer_archivos,') ? '' : 'ocultar'}>
                    <Link to="/domiciliacion/leer">
                      <i className="fa fa-user-o" />{' '}
                      <span className="nav-label">Respuesta</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('domiciliacion.generar_altas,') ? '' : 'ocultar'}>
                    <Link to="/domiciliacion/altas">
                      <i className="fa fa-cloud-upload" />{' '}
                      <span className="nav-label">Altas</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('domiciliacion.habilitar_creditos,') ? '' : 'ocultar'}>
                    <Link to="/domiciliacion/habilitar_creditos">
                      <i className="fa fa-cloud-upload" />{' '}
                      <span className="nav-label">Habilitación de créditos</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('domiciliacion.ver_historial,') ? '' : 'ocultar'}>
                    <Link to="/domiciliacion/historial">
                      <i className="fa fa-history" />{' '}
                      <span className="nav-label">Historial de Respuestas</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li id="li_id_reportes" className={verificarPermiso('solicitudes.view_solicitudcredito,') ? '' : 'ocultar'}>
                <a href="" onClick={e => this.clickMenu(e, 'id_reportes')}>
                  <i className="fa fa-database" />{' '}
                  <span className="nav-label">Reportes</span>{' '}
                  <span className="fa arrow" />
                </a>
                <ul className="nav nav-second-level collapse" id="id_reportes">
                  <li className={verificarPermiso('buro_credito.view_reporteburocredito,') ? '' : 'ocultar'}>
                    <Link to="/buro-credito/reportes">
                      <i className="fa fa-table"></i>{' '}
                      <span className="nav-label">Reportes Buró Crédito</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reportes/reporte-nip">
                      <i className="fa fa-table"></i>{' '}
                      <span className="nav-label">Reporte de nip</span>
                    </Link>
                  </li>
                  <li className={verificarPermiso('prestamos.add_documentoprestamo,') ? '' : 'ocultar'}>
                    <Link to="/prestamos/descarga-contratos">
                      <i className="fa fa-table"></i>{' '}
                      <span className="nav-label">Descarga de contratos</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={verificarPermiso('prestamos.change_tipo_descuento,') ? '' : ' ocultar' }>
                <Link to="/prestamos_tipo_descuento/update">
                  <i className="fa fa-cubes" />{' '}
                  <span className="nav-label">Tipo Descuento</span>
                </Link>
              </li>
              <li className={verificarPermiso('integraciones.change_integracion') ? '' : ' ocultar'}>
                  <Link to="/integraciones/fecha-expiracion-buro-credito">
                    <i className="fa fa-cubes"/>{' '}
                    <span className="nav-label">Integraciones</span>
                  </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div id="page-wrapper" className="gray-bg">
          <div className="row border-bottom">
            <nav className="navbar navbar-static-top" role="navigation">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-minimalize minimalize-styl-2 btn btn-primary "
                  // href="#top1"
                  onClick={() => this.pressMenu()}
                >
                  <i className="fa fa-bars" />{' '}
                </button>
              </div>
              <ul className="nav navbar-top-links navbar-right">
                {/* <li className="dropdown">
                  <a
                    className="dropdown-toggle count-info"
                    data-toggle="dropdown"
                    href="#top2"
                  >
                    <i className="fa fa-bell" />{' '}
                    <span className="label label-primary">8</span>
                  </a>
                  <ul className="dropdown-menu dropdown-alerts">
                    <li>
                      <a href="mailbox.html" className="dropdown-item">
                        <div>
                          <i className="fa fa-envelope fa-fw" /> You have 16
                          messages
                          <span className="float-right text-muted small">
                            4 minutes ago
                          </span>
                        </div>
                      </a>
                    </li>
                    <li className="dropdown-divider" />
                    <li>
                      <a href="profile.html" className="dropdown-item">
                        <div>
                          <i className="fa fa-twitter fa-fw" /> 3 New Followers
                          <span className="float-right text-muted small">
                            12 minutes ago
                          </span>
                        </div>
                      </a>
                    </li>
                    <li className="dropdown-divider" />
                    <li>
                      <a href="grid_options.html" className="dropdown-item">
                        <div>
                          <i className="fa fa-upload fa-fw" /> Server Rebooted
                          <span className="float-right text-muted small">
                            4 minutes ago
                          </span>
                        </div>
                      </a>
                    </li>
                    <li className="dropdown-divider" />
                    <li>
                      <div className="text-center link-block">
                        <a href="notifications.html" className="dropdown-item">
                          <strong>See All Alerts</strong>
                          <i className="fa fa-angle-right" />
                        </a>
                      </div>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <a onClick={() => this.logOut()} href="/">
                    <i className="fa fa-sign-out" /> Cerrar sesión
                  </a>
                </li>
              </ul>
            </nav>
          </div>


          {
            header && children
          }
          {
            !header &&
              <div>
                <div className="row wrapper border-bottom white-bg page-heading">
                  <div className="col-lg-10">
                    <h2>{titulo}</h2>
                    <ol className="breadcrumb">
                      {guia.map((obj, indice) => {
                        return (
                          <li className={`breadcrumb-item ${obj.clase}`} key={indice}>
                            {obj.url ? (
                              <Link to={obj.url}>{obj.nombre}</Link>
                            ) : (
                              <strong>{obj.nombre}</strong>
                            )}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                  <div className="col-lg-2" />
                </div>

                <div className="wrapper wrapper-content">{children}</div>
              </div>
          }

        </div>
        <style jsx="true">{`
          .ocultar {
              display: none !important;
          }
        `}</style>
      </div>
    );
  }
}

export default Autenticado;
