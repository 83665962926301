import React, { useEffect, useState } from 'react';
import { empresasWhirlpool } from '../../api/Empresas.js';

const EmpresasWhirlpoolSelect = ({ onChange, value = '', errors = {} }) => {

	const [empresas, setEmpresas] = useState([]);
	useEffect(() => {
		getEmpresasWhirlpool()
	}, []);

	const getEmpresasWhirlpool = async () => {
    try {
      const { data } = await empresasWhirlpool();
      setEmpresas(data);
    } catch(err) {
      console.log(err)
    }
	}

  return (
    <div
      className={errors.empresa_whirlpool ? 'form-group row has-error' : 'form-group row'}
    >
      <label className="col-sm-4 col-form-label">Empresa whirlpool</label>
      <div className="col-sm-8">
        <select
          name="empresa_whirlpool"
          id="empresa_select"
          className="form-control"
          value={value}
          onChange={onChange}
        >
          <option value={''}>
            --Selecciona--
          </option>
          {empresas.map(empresa => {
            return (
              <option key={`${empresa.id}`} value={empresa.id}>
                {empresa.nombre}
              </option>
            );
          })}
        </select>
        {errors.empresa_whirlpool && (
          <span className="form-text m-b-none text-error">
            {errors.empresa_whirlpool}
          </span>
        )}
      </div>
    </div>
  );
};

export default EmpresasWhirlpoolSelect;
