import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado';
import { generar_archivo_altas, obtener_prestamos_sin_alta } from '../../api/Domiciliacion'

class AltasDomiciliacion extends Component {
  state = {
    titulo: 'Altas de Clientes para domiciliación',
    objetos: [],
    loading: true,
    disabledCarga: true,
    mensaje: 'No hay préstamos pendientes por dar de alta',
    totales: {
      cantidad_prestamos: ''
    },
  };

  cerrarAlertas = () => {
    Swal.close();
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };

  async componentDidMount() {
    try {
      this.obtenerPrestamos();
    } catch (error) {
      console.log(error);
      this.mostrarToast(error, 'error');
    }
  }

  obtenerPrestamos = async params => {
    try {
      const {totales} = this.state
      const req = await obtener_prestamos_sin_alta(params);
      const objetos = req.data;
      totales.cantidad_prestamos = objetos.length
      const loading = false;
      const disabledCarga = false;
      this.setState({ objetos, loading, disabledCarga, totales });
    } catch (error) {
      console.log(error);
      this.mostrarToast(error, 'error');
    }
  };


  btn_generar_archivo_altas = async () => {
    try {
      const resp = await generar_archivo_altas();
      this.mostrarToast('Archivo generado correctamente. Descargando...');
      const url = window.URL.createObjectURL(new Blob([resp.data.content]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `CI008030${resp.data.numero}.ALT`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      this.mostrarToast(error, 'error');
    }

  };

  render() {
    const {
      titulo,
      loading,
      mensaje,
      objetos,
      disabledCarga,
      totales,
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-lg btn-primary float-right mr-1"
                          disabled={disabledCarga}
                          onClick={() => this.btn_generar_archivo_altas()}
                        >
                          <i className="fa fa-file-text fa-margin mr-1" />
                            Generar TXT
                        </button>
                      </div>
                      <br/>
                      {
                        totales.cantidad_prestamos !== '' &&
                          <div className="col-sm-5 ml-1">
                            <p>Cantidad de préstamos a dar de alta: { totales.cantidad_prestamos }</p>
                          </div>
                      }
                      <div className="col-sm-12">
                        {
                          loading ?
                            <div className="spiner-example">
                              <div className="sk-spinner sk-spinner-wandering-cubes">
                                <div className="sk-cube1"></div>
                                <div className="sk-cube2"></div>
                              </div>
                            </div>
                          :
                          objetos.length > 0 ?
                            <div className="table-responsive" style={{'height':'500px'}}>
                              <table className="table table-striped" style={{'overflowY':'scroll'}}>
                                <thead>
                                  <tr>
                                    <th>Folio</th>
                                    <th>Empresa</th>
                                    <th>Empleado</th>
                                    <th>Tipo de Descuento</th>
                                    <th>Frecuencia de Pago</th>
                                    <th>Banco</th>
                                    <th>Clabe interbancaria</th>
                                    <th>Información</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {objetos.map(obj => {
                                    return (
                                      <tr key={obj.folio}>
                                        <td>{obj.folio}</td>
                                        <td>{obj.empresa}</td>
                                        <td>{obj.empleado}</td>
                                        <td>{obj.tipo_descuento}</td>
                                        <td>{obj.frecuencia_pago}</td>
                                        <td>{obj.banco}</td>
                                        <td>{obj.clabe_interbancaria}</td>
                                        <td>
                                          {
                                            !obj.tiene_clabe ? '' : 'Este prestamo no puede ser enviado'
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          :
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="text-center p-lg">
                                    <h2>{ mensaje }</h2>
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default AltasDomiciliacion;
