import moment from 'moment';

const currencyFormat = (value, decimalPosition = 2, renderSimbol = true) => {
  if (value === '--') return '--' 
  let decimalValue = value;
  if (!decimalValue) {
    decimalValue = 0;
  }
  if (typeof decimalValue === 'string') {
    try {
      decimalValue = parseFloat(decimalValue);
    } catch (error) {
      decimalValue = 0;
    }
  }
  return `${renderSimbol ? '$' : ''}${decimalValue
    .toFixed(decimalPosition)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
};

const dateFormat = (value) => {
  if (moment(value).format('DD/MM/YYYY') === 'Invalid date')
    return '--'
  return moment(value).format('DD/MM/YYYY')
}

const dateTimeFormat = (value) => {
  if (value === 'N/A') return value
  if (moment(value).format('DD/MM/YYYY HH:mm') === 'Invalid date')
    return '--'
  return moment(value).format('DD/MM/YYYY HH:mm')
}

const timeFormat = (value, showSeconds=false) => {
  if (moment(value).format('HH:mm') === 'Invalid date')
    return '--'
  if(showSeconds){
    return moment(value).format('HH:mm:ss')
  }
  return moment(value).format('HH:mm')
}

export {
  currencyFormat,
  dateFormat,
  dateTimeFormat,
  timeFormat
};
