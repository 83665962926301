import React, { Component } from 'react';
import Input from './fields/Input';
import InputFile from './fields/InputFile';
import Select from './fields/Select';
import SelectMultiple from './fields/SelectMultiple';
import TextArea from './fields/TextArea';
import Checkbox from './fields/Checkbox';
import DatePicker from './fields/DatePicker';
import { validateDecimal } from '../../utils/regex';

class Form extends Component {
  constructor(props) {
    super(props);
    const {
      onSubmit,
      buttonLabel,
      data,
      fields,
      errors,
      recotizar
    } = this.props;
    this.onSubmit = onSubmit || null;
    this.buttonLabel = buttonLabel || 'Aceptar';
    this.noError = [];
    this.state = {
      formData: data || {},
      fields: fields || [],
      errors: errors || {},
      recotizar: recotizar || false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      formData: nextProps.data,
      fields: nextProps.fields,
      errors: nextProps.errors,
      recotizar: nextProps.recotizar
    });
  }

  getField(field) {
    let htmlField = '';

    switch (field.type) {
      case 'date':
        htmlField = (
          <DatePicker
            key={field.name}
            label={field.label}
            name={field.name}
            value={this.getValue(field)}
            options={field.options}
            onChange={this.handleChangeDate.bind(this, field)}
            // onChange={this.handleChangeDate}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            field={field}
          />
        );
        break;
      case 'select':
        htmlField = (
          <Select
            key={field.name}
            label={field.label}
            name={field.name}
            disabled={field.disabled}
            value={this.getValue(field)}
            options={field.options}
            onChange={this.handleChange}
            validate={this.validate.bind(this, field.name, field.validate)}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            field={field}
            className={field.className}
            labelClassName={field.labelClassName}
            controlClassName={field.controlClassName}
          />
        );
        break;
      case 'select-multiple':
        htmlField = (
          <SelectMultiple
            key={field.name}
            label={field.label}
            name={field.name}
            value={this.getValue(field)}
            options={field.options}
            onChange={this.handleChangeSelectMultiple.bind(this, field)}
            validate={this.validate.bind(this, field.name, field.validate)}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            className={field.className}
            labelClassName={field.labelClassName}
            controlClassName={field.controlClassName}
          />
        );
        break;
      case 'textarea':
        htmlField = (
          <TextArea
            key={field.name}
            label={field.label}
            name={field.name}
            value={this.getValue(field)}
            onChange={this.handleChange.bind(this, field)}
            validate={this.validate.bind(this, field.name, field.validate)}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            className={field.className}
            labelClassName={field.labelClassName}
            controlClassName={field.controlClassName}
          />
        );
        break;
      case 'file':
        htmlField = (
          <InputFile
            key={field.name}
            label={field.label}
            name={field.name}
            type={field.type}
            id={field.id}
            onChange={this.handleChange.bind(this, field)}
            validate={this.validate.bind(this, field.name, field.validate)}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            className={field.className}
            labelClassName={field.labelClassName}
            controlClassName={field.controlClassName}
          />
        );
        break;
      case 'checkbox':
        htmlField = (
          <Checkbox
            key={field.name}
            label={field.label}
            name={field.name}
            value={this.getValue(field)}
            type={field.type}
            onChange={this.handleChange.bind(this, field)}
            validate={this.validate.bind(this, field.name, field.validate)}
            onKeyPress={this.handleKeyPress.bind(
              this,
              field.keyPressValidation
            )}
            checked={this.getValue(field)}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            className={field.className}
            labelClassName={field.labelClassName}
            controlClassName={field.controlClassName}
          />
        );
        break;
      default:
        htmlField = (
          <Input
            key={field.name}
            label={field.label}
            name={field.name}
            value={this.getValue(field)}
            type={field.type}
            disabled={field.disabled}
            onChange={this.handleChange.bind(this, field)}
            validate={this.validate.bind(this, field.name, field.validate)}
            onKeyPress={this.handleKeyPress.bind(
              this,
              field.keyPressValidation
            )}
            helpText={field.helpText}
            error={this.getErrors(field.name)}
            className={field.className}
            labelClassName={field.labelClassName}
            controlClassName={field.controlClassName}
            maxLength={field.maxLength}
          />
        );
    }

    return htmlField;
  }

  handleChangeDate = (field, date) => {
    const { formData } = this.state;
    const fecha = date[0];
    formData[field.name] = fecha;
    this.setState({ formData });
  };

  handleChangeSelectMultiple = (field, value) => {
    const { formData } = this.state;
    const values = [];
    if (value){
      value.forEach(element => {
        values.push(element.value);
      });
    }
    formData[field.name] = values;
    this.setState({ formData });
  };

  handleChange = async (field, e) => {
    if (field.noChange) {
      return;
    }
    const { formData } = this.state;
    let { recotizar } = this.state;
    const {
      target: { name, checked, files, value }
    } = e;
    if (field.type === 'checkbox') {
      formData[name] = checked;
    } else if (field.type === 'file') {
      formData[name] = files[0];
    } else if (field.type === 'select-multiple') {
      const {
        target: { options }
      } = e;
      const valor = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          valor.push(options[i].value);
        }
      }
      formData[name] = valor;
    } else {
      formData[name] = value;
    }

    if (field.child !== undefined) {
      const resp = await field.child.func(value);
      const datos = resp.data;
      const { fields } = this.state;
      const indice = fields.findIndex(f => f.name === field.child.name);
      const municipios = datos.map(obj => {
        return { label: obj.nombre, value: obj.id };
      });
      municipios.unshift({
        label: 'Seleccionar...',
        value: ''
      });
      fields[indice].options = municipios;
      formData.fields = fields;
      // formData[field.child.name] = ''
    }

    if (field.showField !== undefined) {
      const { fields } = this.state;
      const indice = fields.findIndex(f => f.name === field.showField);
      formData.fields = fields;
      if (formData[name]) {
        fields[indice].view = true;
      } else {
        fields[indice].view = false;
        formData[field.showField] = false;
      }
    }

    if (field.disabledIfTrue !== undefined) {
      const { fields } = this.state;
      const indice = fields.findIndex(f => f.name === field.disabledIfTrue);
      formData.fields = fields;
      if (formData[name]) {
        fields[indice].disabled = true;
      } else {
        fields[indice].disabled = false;
      }
    }

    if (field.updateValues !== undefined) {
      const valueSelected = field.options.find(
        o => o.value === parseInt(value)
      );
      if (valueSelected) {
        formData.tasa_interes = valueSelected.tasa_interes_anual;
        // formData.frecuencia_cobro = valueSelected.frecuencia_cobro;
      }
    }

    this.setState({ formData }, () => {
      if (field.fun) field.fun();
    });

    if (field.validateOnChange)
      this.validate(field.name, field.validateOnChange);

    recotizar = true;
    this.setState({ recotizar });
  };

  handleKeyPress = (validation, e) => {
    const charCode = e.which ? e.which : e.keyCode;
    switch (validation) {
      case undefined:
        return true;
      case 'only_numbers':
        if (charCode >= 48 && charCode <= 57) return true;
        e.preventDefault();
        break;
      case 'read_only': {
        const _e = e || window.event;
        _e.preventDefault();
        break;
      }
      case 'decimal': {
        const value = `${e.target.value}${e.key}`;
        if (!validateDecimal(value)) e.preventDefault();
        break;
      }
      default:
        console.log('Validacion KeyPress no valida');
        return true;
    }
    return true;
  };

  getValue = field => {
    const { formData } = this.state;
    switch (field.type) {
      case 'select-multiple':
        return formData[field.name] || [];
      case 'checkbox':
        return formData[field.name] || false;
      default:
        return formData[field.name] || '';
    }
  };

  getErrors = key => {
    const { errors } = this.state;
    if (errors){
      if (!errors[key]) errors[key] = [];
      return errors[key] || this.noError;
    }
    return this.noError;

  };

  validate(key, validateFn) {
    if (validateFn) {
      // var error = validateFn(this.state.formData[key])
      const { errors } = this.state;

      if (!errors[key]) errors[key] = {};

      errors[key] = [];
      this.setState({ errors });
    }
  }

  render() {
    const { children } = this.props;
    const { fields, formData } = this.state;
    const _fields = fields.map(field => {
      let campo = '';
      if (field.view !== false) {
        campo = this.getField(field);
      }
      return campo;
    });

    return (
      <div>
        <div className="row">{_fields}</div>
        {this.onSubmit && (
          <div className="form-group row">
            <div className="col-sm-4 col-sm-offset-2">
              {children}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={e => this.onSubmit(e, formData)}
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Form;
