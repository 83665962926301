import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'sweetalert/dist/sweetalert.css';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion'
import { mostrarToast, cargando, cerrarAlertas } from '../../utils/alerts';
import { currencyFormat, dateFormat, dateTimeFormat } from '../../utils/filters';

import { quitarNulos } from '../../utils/functions';
import {obtenerArchivoBancario, obtenerTransaccionesBancarias, obtenerArchivoBancarioConciliacion,
        actualizarClasificacionTransaccionBancaria} from '../../api/TransaccionesBancarias';

class ArchivosBancariosDetalle extends Component {
    state = {
    loading: false,
    id: null,
    titulo: 'Ver Archivo Bancario',
    transaccionesBancarias: [],
    totalTransaccionesBancarias: 0,
    pageLimit: 100,
    params: {},
    actualizacionClasificacion: {},
    datos: {
        cuenta: '',
        search: '',
        clasificacion: '',
        tipo_transaccion: '',
        tipo: ''
    },
    archivoBancario: {
        estatus_procesamiento: null,
        total_transacciones: null,
        divisa: null,
        referencia_transaccion: null,
        identificador_cuenta: null,
        numero_estado: null,
        numero_secuencia: null,
        tipo_apertura_balance: null,
        fecha_apertura_balance: null,
        monto_apertura_balance: null,
        tipo_cierre_balance: null,
        fecha_cierre_balance: null,
        monto_cierre_balance: null,
        monto_ingresos: null,
        monto_egresos: null,
        inicio_procesamiento: null,
        fin_procesamiento: null,
        fecha_transaccion_reciente: null,
        fecha_transaccion_antigua: null,
        error: null,
        usuario_creo: null,
        fecha_creacion: null,
        fecha_edicion: null,  
    },
    conciliacion: {
        total_ingresos: 0,
        total_egresos: 0,
        monto_apertura_balance: 0,
        monto_rechazo_domiciliacion: 0,
        monto_traspaso_cuentas: 0,
        monto_domiciliacion: 0,
        monto_retencion: 0,
        monto_sin_clase: 0,
        monto_cierre_balance: 0,
    },
    transaccionesBancariasColumnas: [
        {etiqueta: 'Tipo', llave: 'tipo'},
        {etiqueta: 'Monto', llave: 'monto'},
        {etiqueta: 'Fecha Operación', llave: 'fecha_operacion'},
        // STP
        {etiqueta:'Clave Rastreo', llave: 'clave_rastreo'},
        {etiqueta:'Concepto Pago', llave: 'concepto_pago'},
        {etiqueta:'Cuenta Beneficiario', llave: 'cuenta_beneficiario'},
        {etiqueta:'Cuenta Ordenante', llave: 'cuenta_ordenante'},
        {etiqueta:'Estado', llave: 'estado'},
        // MT940
        {etiqueta: 'Divisa', llave: 'divisa'},
        {etiqueta: 'Referencia del Cliente', llave: 'referencia_cliente'},
        {etiqueta: 'Referencia Bancaria', llave: 'referencia_bancaria'},
        {etiqueta: 'Detalles Complementarios', llave: 'detalles_complementarios'},
        {etiqueta: 'Información Adicional', llave: 'informacion_adicional'},
    ],
    };

    obtenerArchivoBancario = async (id) =>{
        try{
            // Obtenemos la información  del archivo Bancario
            const archivoBancarioPeticion = await obtenerArchivoBancario(id);
            let archivoBancario = archivoBancarioPeticion.data;
            archivoBancario = quitarNulos(archivoBancario);
            this.setState({archivoBancario});
        }catch (error){
            mostrarToast("Ocurrió un error al obtener la información del archivo bancario", "error");
        }
    }

    obtenerArchivoBancarioConciliacion = async (id) => {
        try{
            // Obtenemos la conciliación del archivo bancario
            const conciliacionPeticion = await obtenerArchivoBancarioConciliacion(id)
            let conciliacion = conciliacionPeticion.data
            conciliacion = quitarNulos(conciliacion)
            this.setState({conciliacion})
        }catch (error){
            mostrarToast("Ocurrió un error al obtener la conciliación del archivo bancario", "error");
        }
    }

    obtenerTransaccionesBancarias = async (id) => {
        try{
            // Obtenemos las transacciones bancarias vinculadas al archivo bancario
            const { pageLimit } = this.state
            const params = { page_size: pageLimit, archivo_bancario: id }
            const transaccionesBancariasPeticion = await obtenerTransaccionesBancarias(params)
            const transaccionesBancarias = transaccionesBancariasPeticion.data.results
            const totalTransaccionesBancarias = transaccionesBancariasPeticion.data.count
            this.setState({transaccionesBancarias, totalTransaccionesBancarias, params})
        }catch (error){
            mostrarToast("Ocurrió un error al obtener las transacciones bancarias", "error");
        }
    }

    obtenerTransaccionesParams = async params => {
        try {
            const transaccionesBancariasPeticion = await obtenerTransaccionesBancarias(params)
            const transaccionesBancarias = transaccionesBancariasPeticion.data.results
            const totalTransaccionesBancarias = transaccionesBancariasPeticion.data.count
            this.setState({ 
                transaccionesBancarias,
                totalTransaccionesBancarias,
                params
            });
        } catch (error) {
            mostrarToast("Ocurrió un error al obtener las transacciones bancarias", "error");
        }
    };

    actualizarClasificacionTransaccionBancaria = async (id_transaccion, clasificacion) =>{
        try{
            await actualizarClasificacionTransaccionBancaria(id_transaccion, {clasificacion: clasificacion})
            mostrarToast("Se ha actualizado la clasificacion con éxito", "success");
        }catch (error){
            mostrarToast("Ocurrió un error al actualizar la clasificación", "error");
        }
    }
    
    componentWillMount() {
        const id = this.props.match.params.id;
        this.setState({ id });
    }

    async componentDidMount() {
        const { id } = this.state;
        this.setState({loading: true});
        cargando()
        await this.obtenerArchivoBancario(id)
        await this.obtenerArchivoBancarioConciliacion(id)
        await this.obtenerTransaccionesBancarias(id)
        cerrarAlertas()
        this.setState({loading: false});
    }

    actualizacionClasificacion = async (id_transaccion, e) => {
        let valor = e.target.value;
        let {id, params} = this.state;
        await this.actualizarClasificacionTransaccionBancaria(id_transaccion, valor)
        await this.obtenerTransaccionesParams(params)
        await this.obtenerArchivoBancarioConciliacion(id)
    }

    buscar = async () => {
        let { params } = this.state;
        const {datos: { search }} = this.state;
        delete params.search;
        delete params.page;
        params = Object.assign({ search }, params);
        await this.obtenerTransaccionesParams(params);
    };
    
    handleKeyDown = async(e) => {
        if (e.key === 'Enter') {
            await this.buscar()
        }
    }

    onChange = async(campo, e) => {
        const { datos } = this.state;
        let { params } = this.state;
        const valor = e.target.value;
        datos[campo] = valor;
        this.setState({ datos });
        if (campo === 'cuenta' || campo === 'tipo' || campo === 'clasificacion') {
            const { datos: { cuenta, tipo, clasificacion } } = this.state;
            delete params.tipo;
            delete params.clasificacion;
            params = Object.assign({ cuenta, tipo, clasificacion }, params);
            await this.obtenerTransaccionesParams(params);
        }
        if (valor === '') {
            await this.limpiar()
        }
    };

    limpiar = async () => {
        this.setState({
            params: {},
            datos: {
                cuenta: '',
            }
        });
        const { pageLimit, id } = this.state;
        const params = { page_size: pageLimit, archivo_bancario: id };
        await this.obtenerTransaccionesParams(params);
    };

    onPageChanged = async data => {
        const page = data.currentPage
        const { pageLimit, params, id } = this.state
        params.page = page
        params.page_size = pageLimit
        params.archivo_bancario = id
        try {
            const transaccionesBancariasPeticion = await obtenerTransaccionesBancarias(params)
            const transaccionesBancarias = transaccionesBancariasPeticion.data.results
            const totalTransaccionesBancarias = transaccionesBancariasPeticion.data.count
            this.setState({ transaccionesBancarias, totalTransaccionesBancarias, params })
        } catch (error) {
            console.log("Error: ", error)
        }
    }

    renderRow = (label, value, classcell=null, classvalue=null) => (
        <div className={`row pb5 ${classcell}`}>
            <div className="col-sm-4">
            <span>{label}</span>
            </div>
            <div className={`col-sm-8 capitalize ${classvalue}`}>
            <strong>{value}</strong>
            </div>
        </div>
    );

    render() {
        const guia = [
            { nombre: 'Archivos Bancarios', url: '/conciliaciones/archivos-bancarios', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ];

        const {
            titulo,
            archivoBancario,
            conciliacion,
            transaccionesBancarias,
            totalTransaccionesBancarias,
            pageLimit,
            datos: { tipo, clasificacion, search },
        } = this.state;

        return (
            <Autenticado titulo={titulo} guia={guia}>
            <div className="row">
                <div className="col-lg-12">
                <div className="row">
                    <div className="col-md-6">
                    <div className="ibox">
                        <div className="ibox-content">
                        <h3>
                            <strong>Información del archivo bancario</strong>
                        </h3>
                        {this.renderRow('Tipo de apertura de balance', archivoBancario.tipo_apertura_balance)}
                        {this.renderRow('Fecha de apertura de balance', dateFormat(archivoBancario.fecha_apertura_balance))}
                        {this.renderRow('Monto de apertura de balance', currencyFormat(archivoBancario.monto_apertura_balance))}
                        {this.renderRow('Estatus del procesamiento', archivoBancario.estatus_procesamiento)}
                        {this.renderRow('Cuenta', archivoBancario.cuenta)}
                        {this.renderRow('Total de transacciones', archivoBancario.total_transacciones)}
                        {this.renderRow('Referencia de Transacción', archivoBancario.referencia_transaccion)}
                        {this.renderRow('Identificador de la cuenta', archivoBancario.identificador_cuenta)}
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="ibox">
                        <div className="ibox-content">
                        <h3>
                            <strong>Información del archivo bancario</strong>
                        </h3>
                        {this.renderRow('Tipo de cierre de balance', archivoBancario.tipo_cierre_balance)}
                        {this.renderRow('Fecha de cierre de balance', dateFormat(archivoBancario.fecha_cierre_balance))}
                        {this.renderRow('Monto de cierre de balance', currencyFormat(archivoBancario.monto_cierre_balance))}
                        {this.renderRow('Monto de ingresos', currencyFormat(archivoBancario.monto_ingresos))}
                        {this.renderRow('Monto de egresos', currencyFormat(archivoBancario.monto_egresos))}
                        {this.renderRow('Fecha de transacción más reciente', dateFormat(archivoBancario.fecha_transaccion_reciente))}
                        {this.renderRow('Fecha de transacción más antigua', dateFormat(archivoBancario.fecha_transaccion_antigua))}
                        {this.renderRow('Error', archivoBancario.error)}
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                    <div className="ibox">
                        <div className="ibox-content">
                        <h3>
                            <strong>Conciliación</strong>
                        </h3>
                        {this.renderRow('Saldo inicial', currencyFormat(conciliacion.monto_apertura_balance), 'celda-gris')}
                        {/* Ingresos */}
                        {this.renderRow('Domiciliacion', currencyFormat(conciliacion.monto_domiciliacion), null, `${conciliacion.monto_domiciliacion > 0 ? 'verde' : null} `)}
                        {this.renderRow('Retención', currencyFormat(conciliacion.monto_retencion), null, `${conciliacion.monto_retencion > 0 ? 'verde' : null} `)}
                        {this.renderRow('Ingresos sin clasificación', currencyFormat(conciliacion.monto_ingresos_sin_clasificacion), null, `${conciliacion.monto_ingresos_sin_clasificacion > 0 ? 'verde' : null} `)}
                        {this.renderRow('Total de ingresos', currencyFormat(conciliacion.monto_ingresos_sin_clasificacion +
                                                                            conciliacion.monto_domiciliacion +
                                                                            conciliacion.monto_retencion), 'celda-gris')}
                        {/* Egresos */}
                        {this.renderRow('Rechazo domiciliación', currencyFormat(conciliacion.monto_rechazo_domiciliacion), null, `${conciliacion.monto_rechazo_domiciliacion < 0 ? 'rojo' : null} `)}
                        {this.renderRow('Traspaso entre cuentas', currencyFormat(conciliacion.monto_traspaso_cuentas), null, `${conciliacion.monto_traspaso_cuentas < 0 ? 'rojo' : null} `)}
                        {this.renderRow('Comisiones bancarias', currencyFormat(conciliacion.monto_comisiones_bancarias), null, `${conciliacion.monto_comisiones_bancarias < 0 ? 'rojo' : null} `)}
                        {this.renderRow('IVA comisiones', currencyFormat(conciliacion.monto_iva_comisiones), null, `${conciliacion.monto_iva_comisiones < 0 ? 'rojo' : null} `)}
                        {this.renderRow('Egresos Sin clasificación', currencyFormat(conciliacion.monto_egresos_sin_clasificacion), null, `${conciliacion.monto_egresos_sin_clasificacion < 0 ? 'rojo' : null} `)}
                        {this.renderRow('Total de Egresos', currencyFormat(conciliacion.monto_rechazo_domiciliacion +
                                                                            conciliacion.monto_traspaso_cuentas +
                                                                            conciliacion.monto_comisiones_bancarias +
                                                                            conciliacion.monto_iva_comisiones +
                                                                            conciliacion.monto_egresos_sin_clasificacion),'celda-gris')}
                        {this.renderRow('Saldo Final', currencyFormat(conciliacion.monto_cierre_balance))}
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">Lista de transacciones bancarias</div>
                        <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                            {/* Tabla de Transacciones */}
                            <div className="row">
                            <div className="col-sm-2">
                                <div className="input-group">
                                <select
                                    value={tipo}
                                    onChange={e => this.onChange('tipo', e)}
                                    className="form-control m-b"
                                >
                                    <option value="">Selecciona tipo</option>
                                    <option value="ingreso">Ingreso</option>
                                    <option value="egreso">Egreso</option>
                                </select>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="input-group">
                                <select
                                    value={clasificacion}
                                    onChange={e => this.onChange('clasificacion', e)}
                                    className="form-control m-b"
                                >
                                    <option value="">Selecciona una clasificación</option>
                                    <option value="domiciliacion">Domiciliación</option>
                                    <option value="retencion">Retención</option>
                                    <option value="rechazo domiciliacion">Rechazo domiciliación</option>
                                    <option value="traspaso entre cuentas">Traspaso entre cuentas</option>
                                    <option value="comisiones bancarias">Comisiones bancarias</option>
                                    <option value="iva comisiones">IVA comisiones</option>
                                    <option value="sin clasificacion">Sin clasificación</option>
                                </select>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="input-group">
                                <input
                                    value={search}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={e => this.onChange('search', e)}
                                    onKeyDown={e => this.handleKeyDown(e)}
                                    placeholder="Buscar"
                                />
                                <span className="input-group-append">
                                    <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    onClick={() => this.limpiar()}
                                    >
                                    <i className="fa fa-close fa-margin"/>
                                    </button>
                                </span>
                                <span className="input-group-append">
                                    <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    onClick={() => this.buscar()}
                                    >
                                    <i className="fa fa-search fa-margin"/>
                                    Buscar
                                    </button>
                                </span>
                                </div>
                            </div>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Monto</th>
                                    <th>Fecha Operación</th>

                                    {/* CAMPOS STP */}
                                    {/* <th>Clave Rastreo</th>
                                    <th>Concepto Pago</th>
                                    <th>Cuenta Beneficiario</th>
                                    <th>Cuenta Ordenante</th> */}

                                    {/* CAMPOS BX+ */}
                                    <th>Referencia del Cliente</th>
                                    <th>Referencia Bancaria</th>
                                    <th>Detalles Complementarios</th>
                                    <th>Información Adicional</th>
                                    <th>Clasificación</th>
                                    {/* <th>Tipo Transaccion</th> */}
                                </tr>
                                </thead>
                                <tbody>
                                {transaccionesBancarias.map(obj => {
                                return (
                                    <tr key={obj.id}>
                                    {obj.tipo == 'ingreso' && <td className='fa fa-caret-up verde'>{currencyFormat(obj.monto)}</td>}
                                    {obj.tipo == 'egreso' && <td className='fa fa-caret-down rojo'>{currencyFormat(obj.monto)}</td>}
                                    <td>{dateFormat(obj.fecha_operacion)}</td>
                                    {/* <td>{obj.clave_rastreo}</td>
                                    <td>{obj.concepto_pago}</td>
                                    <td>{obj.cuenta_beneficiarion}</td>
                                    <td>{obj.cuenta_ordenante}</td> */}
                                    <td>{obj.referencia_cliente}</td>
                                    <td>{obj.referencia_bancaria}</td>
                                    <td>{obj.detalles_complementarios}</td>
                                    <td>{obj.informacion_adicional}</td>
                                    <td>
                                        <select
                                            value={obj.clasificacion}
                                            onChange={e => this.actualizacionClasificacion(obj.id, e)}
                                            className="form-control m-b"
                                        >
                                            <option value="domiciliacion">Domiciliación</option>
                                            <option value="retencion">Retención</option>
                                            <option value="rechazo domiciliacion">Rechazo Domiciliacion</option>
                                            <option value="traspaso entre cuentas">Traspaso Entre Cuentas</option>
                                            <option value="comisiones bancarias">Comisiones bancarias</option>
                                            <option value="iva comisiones">IVA comisiones</option>
                                            <option value="sin clasificacion">Sin clasificación</option>
                                        </select>
                                    </td>
                                    <td>
                                        <Link
                                        key={obj.id}
                                        to={`/conciliaciones/transacciones/ver/${obj.id}/`}
                                        className="btn btn-primary btn-sm btns-space"
                                        >
                                        <i className="fa fa-eye fa-margin"/>
                                        Ver
                                        </Link>
                                    </td>
                                    </tr>
                                );
                                })}
                                {/*  sin resultados */}
                                {transaccionesBancarias.length === 0 &&
                                <tr>
                                    <td colSpan="8" className="text-center">No se encontraron resultados</td>
                                </tr>
                                }
                                </tbody>
                            </table>
                            <Paginacion
                                totalRecords={totalTransaccionesBancarias}
                                pageLimit={pageLimit}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <style jsx="true">{`
                .sol{
                    display: flex;
                    justify-content: space-between;
                }
                .ibox-content{
                    border-style: none;
                }
                .fa-margin {
                    margin-right: 5px;
                }
                .right {
                    float: right;
                }
                .celda-delgada {
                    padding: 0.3rem !important;
                }
                .rojo {
                    color: red;
                }
                .verde{
                    color: green;
                }
                .capitalize{
                    text-transform: capitalize;
                }
                .celda-gris{
                    background-color: #f2f2f2;
                }

            `}</style>
            </Autenticado>
        );
    }
}

export default ArchivosBancariosDetalle;
