import React, { Component } from 'react';
import { obtenerBancosList } from '../api/Domiciliacion';
import Select from 'react-select';

class BancosSelect extends Component {

  state = {
    bancos: []
  };

  async componentDidMount() {

    try {
      const req = await obtenerBancosList();

      const bancos = req.data;
      console.log(bancos)
      bancos.unshift({'id':0,'nombre':'Todos'})
      this.setState({bancos});

    } catch (e) {
      console.log(e);
    }

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.opciones === this.props.opciones){
        return
    }
  
    try {
      const req = await obtenerBancosList();

      const bancos = req.data;

      this.setState({bancos});

    } catch (e) {
      console.log(e);
    }

  }

  render() {

    const { bancos } = this.state;

    const { onChange } = this.props;

    // const { isMulti } = this.props;
    const { options } = this.props;

    var bancos_values = bancos.map( banco => {
      return {label: banco.nombre, value: banco.id};
    });
    if(options){
      if(options.length >= 1){
        bancos_values = options
      }else{
        bancos_values.unshift({label: "Selecciona un Banco", value: ""})
      }
    }
    


    return (
      <Select
        isMulti
        name="banco"
        placeholder="Selecciona un Banco"
        className="select"
        onChange={onChange}
        options={bancos_values}
      />
    )
  }

}

export default BancosSelect;