import React, { Component } from 'react'
import Select from 'react-select'
// import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import Paginacion from '../../components/Paginacion'
import {/*  obtenerPrestamos,  */obtenerPrestamo } from '../../api/Prestamos'
import { crearPago, obtenerPrestamosParaPagos } from '../../api/Pagos';
import { obtenerEmpresas } from '../../api/Empresas'
// import DatePicker from '../../components/form/fields/DatePicker'
import { dateFormat, dateTimeFormat, currencyFormat } from '../../utils/filters'
import { quitarNulos } from '../../utils/functions'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import '../../css/plugins/steps/jquery.steps.css'
import moment from 'moment'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { mostrarToast } from '../../utils/alerts';
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias';

class Form extends Component {
    state = {
        idPrestamo: null,
        indicePrestamo: null,
        titulo: null,
        pasoSeleccionado: 1,
        objetos: [],
        cuentas: [],
        data: {},
        errors: {},
        mostratAlerta: false,
        textoAlerta: '',
        mostratAlertaError: false,
        textoAlertaError: '',
        textoAlertaErrorMsj: '',
        prestamo: {},
        empleado: {},
        tiposDePrestamo: [
            {label:'Seleccionar...', value:''},
            {label:'Emergente', value:'emergente'},
            {label:'Tradicional', value:'tradicional'}
        ],
        TipoDePago: '',
        texto: '',
        empresa: '',
        empresas: [],


        totalRecords: 0,
        pageLimit: 50,
        params: {},
        // empresas: [],
        datos: {
          empresa: '',
          estatus: '',
          tipo: '',
          frecuenciaCobro: '',
          search: ''
        }
    }

    componentWillMount() {
        const titulo = 'Crear pago'
        this.setState({ titulo })
    }

    async componentDidMount() {
        try {
            const { pageLimit } = this.state;
            const params = { page_size: pageLimit };
            const promesas = [obtenerPrestamosParaPagos(params), obtenerEmpresas()];
            const [req, resp] = await Promise.all(promesas);
            const objetos = req.data.results;
            const totalRecords = req.data.count;
            const empresas = resp.data.results.map(obj => {
              return { label: obj.nombre, value: obj.id };
            });
            this.setState({ objetos, totalRecords, params, empresas });
        } catch (error) {
            console.log(error)
        }
    }

    onPageChanged = async data => {
        const page = data.currentPage;
        const { pageLimit, params } = this.state;
        params.page = page;
        params.page_size = pageLimit;
        try {
          const req = await obtenerPrestamosParaPagos(params);
          const objetos = req.data.results;
          const totalRecords = req.data.count;
          this.setState({ objetos, totalRecords, params });
        } catch (error) {
          console.log('Error: ', error);
        }
      };
    
      obtenerObjetos = async params => {
        try {
          const req = await obtenerPrestamosParaPagos(params);
          const objetos = req.data.results;
          const totalRecords = req.data.count;
          this.setState({ objetos, totalRecords, params });
        } catch (error) {
          console.log(error);
        }
      };
    
      buscar = async () => {
        let { params } = this.state;
        const {
          datos: { search }
        } = this.state;
        delete params.search;
        delete params.page;
        params = Object.assign({ search }, params);
        this.obtenerObjetos(params);
      };
    
      onChange = (campo, e) => {
        const { datos } = this.state;
        let { params } = this.state;
        const valor = e.target.value;
        datos[campo] = valor;
        this.setState({ datos });
        if (
          campo === 'empresa' ||
          campo === 'estatus' ||
          campo === 'tipo' ||
          campo === 'frecuenciaCobro'
        ) {
          const {
            datos: { empresa, estatus, tipo, frecuenciaCobro }
          } = this.state;
          delete params.empresa;
          delete params.estatus;
          delete params.tipo;
          delete params.frecuencia_cobro;
          delete params.page;
          params = Object.assign(
            {
              empresa,
              estatus,
              tipo,
              frecuencia_cobro: frecuenciaCobro
            },
            params
          );
          this.obtenerObjetos(params);
        }
      };
    
      limpiar = async () => {
        this.setState({
          params: {},
          datos: {
            empresa: '',
            estatus: '',
            tipo: '',
            frecuenciaCobro: '',
            search: ''
          }
        });
        const { pageLimit } = this.state;
        const params = { page_size: pageLimit };
        this.obtenerObjetos(params);
      };

    handleChange = (name, value) => {
        const valor = name.value
        const variable = value.name
        this.setState({ empresa: valor })
        const { datos } = this.state;
        let { params } = this.state;
        datos[variable] = valor;
        this.setState({ datos });
        const {
        datos: { empresa, estatus, tipo, frecuenciaCobro }
        } = this.state;
        delete params.empresa;
        delete params.estatus;
        delete params.tipo;
        delete params.frecuencia_cobro;
        delete params.page;
        params = Object.assign(
        {
            empresa,
            estatus,
            tipo,
            frecuencia_cobro: frecuenciaCobro
        },
        params
        );
        this.obtenerObjetos(params);
    }

    seleccionarPrestamo(idPrestamo, indicePrestamo){
        this.setState({ idPrestamo, indicePrestamo }, () => console.log(this.state))
    }

    anterior(){
        let { pasoSeleccionado } = this.state
        pasoSeleccionado -= 1
        this.setState({ pasoSeleccionado })
    }

    async siguiente(){
        const { idPrestamo } = this.state
        if (idPrestamo !== null){
            let { pasoSeleccionado } = this.state
            pasoSeleccionado += 1
            const res = await obtenerPrestamo(idPrestamo)
            let prestamo = res.data
            prestamo = quitarNulos(prestamo)
            let { empleado } = prestamo
            empleado = quitarNulos(empleado)
            const cuentasBancarias = await obtenerCuentasBancarias()
            let cuentas = []
            cuentasBancarias.data.forEach(obj => {
              cuentas.push({ label: obj.banco+' - '+obj.no_cuenta, value: obj.id });
            })
            cuentas.unshift({
              label:'Seleccionar...', value:''
            })
            this.setState({ pasoSeleccionado, prestamo, empleado, cuentas, empresas: [] })
        }
    }

    async crear() {
        try {
            let { data } = this.state
            const { idPrestamo } = this.state
            data = Object.assign({ prestamo: idPrestamo }, data)
            const formData = new FormData()
            Object.keys(data).forEach((key) => {
                if (key === 'fecha_pago'){
                    if (data[key]){
                        formData.append(key, moment(data[key]).format('YYYY-MM-DD'))
                    }
                } else {
                    formData.append(key, data[key])
                }
            })
            /* const resp =  */await crearPago(formData)
            this.props.history.push('/pagos')
        } catch (error) {
            const errors = error.data
            if(errors.excepcion){
              mostrarToast(errors.excepcion, 'error');
            }
            this.setState({ errors })
        }

    }

    render () {
        const guia = [
            { nombre: 'Pagos', url: '/pagos', clase: '' },
            { nombre: 'Crear', url: '', clase: 'active' }
        ]
        const {
            titulo,
            objetos,
            pasoSeleccionado,
            indicePrestamo,
            mostratAlerta,
            textoAlerta,
            mostratAlertaError,
            textoAlertaError,
            textoAlertaErrorMsj,
            idPrestamo,
            prestamo,
            empleado,
            cuentas,
            // tiposDePago,
            empresas,
            totalRecords,
            pageLimit,
            datos: { empresa, /* estatus, */ tipo, frecuenciaCobro, search },
            // tiposDePrestamo
        } = this.state

        const form = {
            fields:[{
                name:'monto',
                label:'Monto *',
                type:'text',
                helpText:'',
                keyPressValidation: 'decimal'
            },{
                name:'motivo',
                label:'Motivo *',
                type:'select',
                helpText:'',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Amortización regular', value:'amortizacion regular'},
                    {label:'Pago capital', value:'pago anticipado'},
                    {label:'Liquidación', value:'liquidacion'},
                    // {label:'Penalización', value:'penalizacion'}
                ]
            },{
                name:'tipo_pago',
                label:'Tipo de pago *',
                type:'select',
                helpText:'',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Cheque', value:'cheque'},
                    {label:'Depósito en efectivo', value:'deposito en efectivo'},
                    {label:'SPEI', value:'SPEI'},
                    {label:'Pago referenciado', value:'pago referenciado'},
                    {label:'Cargo Openpay', value:'cargo openpay'}
                ]
            },{
              name:'cuenta',
              label:'Cuenta Entrante *',
              type:'select',
              helpText:'',
              options: cuentas
            },{
                name:'comprobante',
                label:'Comprobante',
                type:'file',
                helpText:''
            },{
                name:'referencia',
                label:'Referencia',
                type:'text',
                helpText:''
            },{
                name:'comentario',
                label:'Comentario',
                type:'text',
                helpText:''
            },{
                name:'fecha_pago',
                label:'Fecha de pago *',
                type:'date',
                options: {dateFormat: 'd-m-Y', locale: Spanish, maxDate: 'today' },
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <SweetAlert
                    show={mostratAlerta}
                    title={textoAlerta}
                    type="success"
                    onConfirm={() => {
                        this.setState({ mostratAlerta: false });
                    }}
                    onEscapeKey={() => this.setState({ mostratAlerta: false })}
                    onOutsideClick={() => this.setState({ mostratAlerta: false })}
                />
                <SweetAlert
                    show={mostratAlertaError}
                    title={textoAlertaError}
                    type="error"
                    text={textoAlertaErrorMsj}
                    onConfirm={() => {
                        this.setState({ mostratAlertaError: false });
                    }}
                    onEscapeKey={() => this.setState({ mostratAlertaError: false })}
                    onOutsideClick={() => this.setState({ mostratAlertaError: false })}
                />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Crear pago</h5>
                            </div>
                            <div className="ibox-content">
                                <form id="form" action="#" className="wizard-big wizard clearfix frm">
                                    <div className="steps clearfix">
                                        <ul role="tablist">
                                            <li role="tab" className={`first ${pasoSeleccionado === 1 ? 'current' : 'done'}`} aria-disabled="false" aria-selected="true">
                                                <a id="form-t-0" href="#form-h-0" aria-controls="form-p-0">
                                                    <span className="number">1.</span> Seleccionar prestamo
                                                </a>
                                            </li>
                                            <li role="tab" className={`last ${pasoSeleccionado === 2 ? 'current' : 'done'}`} aria-disabled="false" aria-selected="false">
                                                <a id="form-t-1" href="#form-h-1" aria-controls="form-p-1">
                                                    <span className="number">2.</span> Finalizar
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    { pasoSeleccionado === 1 &&
                                        <fieldset>
                                            <h2>Seleccionar prestamo</h2>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="ibox ">
                                                    <div className="ibox-content">
                                                        <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="input-group">
                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="empresa"
                                                                    value={empresas.filter(({value}) => value === empresa)}
                                                                    onChange={this.handleChange}
                                                                    options={empresas}
                                                                />
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-sm-2">*/}
                                                        {/*    <div className="input-group">*/}
                                                        {/*    <select*/}
                                                        {/*        value={estatus}*/}
                                                        {/*        onChange={e => this.onChange('estatus', e)}*/}
                                                        {/*        className="form-control m-b"*/}
                                                        {/*    >*/}
                                                        {/*        <option value="">Selecciona estatus</option>*/}
                                                        {/*        <option value="activo">Activo</option>*/}
                                                        {/*        <option value="cancelado">Cancelado</option>*/}
                                                        {/*        <option value="liquidado">Liquidado</option>*/}
                                                        {/*        <option value="cartera vencida">Cartera vencida</option>*/}
                                                        {/*    </select>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className="col-sm-2">
                                                            <div className="input-group">
                                                            <select
                                                                value={frecuenciaCobro}
                                                                onChange={e => this.onChange('frecuenciaCobro', e)}
                                                                className="form-control m-b"
                                                            >
                                                                <option value="">Selecciona frecuencia</option>
                                                                <option value="semanal">Semanal</option>
                                                                <option value="decenal">Decenal</option>
                                                                <option value="catorcenal">Catorcenal</option>
                                                                <option value="quincenal">Quincenal</option>
                                                                <option value="mensual">Mensual</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="input-group">
                                                            <select
                                                                value={tipo}
                                                                onChange={e => this.onChange('tipo', e)}
                                                                className="form-control m-b"
                                                            >
                                                                <option value="">Selecciona tipo</option>
                                                                <option value="tradicional">Tradicional</option>
                                                                <option value="emergente">Emergente</option>
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <div className="input-group">
                                                            <input
                                                                value={search}
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                onChange={e => this.onChange('search', e)}
                                                                placeholder="Buscar"
                                                            />
                                                            <span className="input-group-append">
                                                                <button
                                                                type="button"
                                                                className="btn btn-sm btn-info"
                                                                onClick={() => this.limpiar()}
                                                                >
                                                                <i className="fa fa-close fa-margin" />
                                                                </button>
                                                            </span>
                                                            <span className="input-group-append">
                                                                <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary"
                                                                onClick={() => this.buscar()}
                                                                >
                                                                <i className="fa fa-search fa-margin" />
                                                                Buscar
                                                                </button>
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Empleado</th>
                                                                <th>Empresa</th>
                                                                <th>Folio</th>
                                                                <th className="centrado">Amortizaciones</th>
                                                                <th>Estatus</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {objetos.map((obj, indice) => {
                                                                return (
                                                                    <tr key={ obj.id } className={`${indicePrestamo === indice && 'prestamo-sel'}`}>
                                                                    <td>{obj.empleado}</td>
                                                                    <td>{obj.empresa}</td>
                                                                    <td>{obj.folio}</td>
                                                                    <td className="centrado">
                                                                    {obj.num_amortizaciones}
                                                                    </td>
                                                                    <td>{obj.estatus}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-sm btns-space"
                                                                            onClick={() => this.seleccionarPrestamo(obj.id, indice)}
                                                                        >
                                                                            Seleccionar
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <Paginacion
                                                    totalRecords={totalRecords}
                                                    pageLimit={pageLimit}
                                                    pageNeighbours={1}
                                                    onPageChanged={this.onPageChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button
                                                        type="button"
                                                        className={`btn btn-primary btn-sm right ${idPrestamo === null ? 'disabled' : ''}`}
                                                        onClick={ () => this.siguiente() }
                                                    >
                                                        Siguiente
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }
                                    { pasoSeleccionado === 2 &&
                                        <fieldset>
                                            <br/>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Datos del empleado
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Nombre</label>
                                                                    <label>{ empleado.nombre }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Correo electrónico</label>
                                                                    <label>{ empleado.email }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Puesto</label>
                                                                    <label>{ empleado.puesto }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Fecha de nacimiento</label>
                                                                    <label>{ dateFormat(empleado.fecha_nacimiento) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Fecha de contratación</label>
                                                                    <label>{ dateFormat(empleado.fecha_contratacion) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>RFC</label>
                                                                    <label>{ empleado.rfc }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>CURP</label>
                                                                    <label>{ empleado.curp }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Teléfono de casa</label>
                                                                    <label>{ empleado.telefono_casa }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Teléfono celular</label>
                                                                    <label>{ empleado.telefono_celular }</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading">
                                                            Detalles
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Empresa</label>
                                                                    <label>{ prestamo.empresa }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Estatus</label>
                                                                    <label>{ prestamo.estatus }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Fecha de creación</label>
                                                                    <label>{ dateTimeFormat(prestamo.fecha_creacion) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Fecha primer amortización</label>
                                                                    <label>{ dateFormat(prestamo.fecha_primera_amortizacion) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Folio</label>
                                                                    <label>{ prestamo.folio }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Frecuencia de cobro</label>
                                                                    <label>{ prestamo.frecuencia_cobro }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Número de amortizaciones</label>
                                                                    <label>{ prestamo.num_amortizaciones }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Amortizaciones restantes</label>
                                                                    <label>{ prestamo.amortizaciones_restantes }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Tipo</label>
                                                                    <label>{ prestamo.tipo }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Tase de interes</label>
                                                                    <label>{ prestamo.tasa_interes } %</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Próximo pago</label>
                                                                    <label>{ dateFormat(prestamo.proximo_pago) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Último pago</label>
                                                                    <label>{ dateFormat(prestamo.ultimo_pago) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Monto</label>
                                                                    <label>{ currencyFormat(prestamo.monto) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Adeudo total</label>
                                                                    <label>{ currencyFormat(prestamo.adeudo_total) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Adeudo atrasado</label>
                                                                    <label>{ currencyFormat(prestamo.adeudo_atrasado) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Capital</label>
                                                                    <label>{ currencyFormat(prestamo.capital) }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Días de retraso</label>
                                                                    <label>{ prestamo.dias_retraso }</label>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Monto pago</label>
                                                                    <label>{ currencyFormat(prestamo.monto_pago) }</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h2>Ingresar información</h2>
                                            <Formulario
                                                fields={ form.fields }
                                                data={ form.data }
                                                errors={ form.errors }
                                            />
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm right"
                                                        onClick={ () => this.crear() }
                                                    >
                                                        Crear
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm fa-margin right"
                                                        onClick={ () => this.anterior() }
                                                    >
                                                        Anterior
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                    margin{
                        margin-top: 120px
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .prestamo-sel {
                        border: solid #398058 2px;
                        background-color: #94DAD0 !important;
                    }

                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .centrado{
                        text-align: center;
                    }
                    .rojo{
                        color: red;
                    }
                    .verde{
                        color: green;
                    }
                    .frm {
                        min-height: 500px;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
