import React from 'react';
import moment from 'moment';
import { exportarReporteNip } from '../../../api/Reportes';

const DescargaCSV = ({busqueda}) => {

    const exportarOnClick = async (params = {}) => {
        const {fechaInicio, fechaFin} =  busqueda;
        // const params = params
        if (fechaInicio.trim() !== '' && fechaFin.trim() !== '') {
            const {fechaInicio, fechaFin} = busqueda;
            params = {fecha_inicio: fechaInicio, fecha_fin: fechaFin};
        }
        try {
            const resp = await exportarReporteNip(params);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            // link.setAttribute('id', 'link_descarga');
            link.setAttribute(
                'download',
                `Reporte_Nip_${moment().format('YYYYMMDD')}.csv`
            );
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            console.log(err)
        }
        delete params.export;
    }

    return (
        <button
            className="btn btn-sm btn-primary fa-margin"
            onClick={exportarOnClick}
        >
            <i className="fa fa-download fa-margin"></i>
            Exportar
        </button>
    );
}
 
export default DescargaCSV;