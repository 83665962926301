import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import Formulario from '../../components/form/Form';
import { actualizarSolicitud, cotizarSolicitud, obtenerSolicitud } from '../../api/Solicitudes';
import { obtenerProductos } from '../../api/Productos';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Spanish } from 'flatpickr/dist/l10n/es';
import TablaCotizacion from '../../components/TablaCotizacion';

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        recotizar: false,
        data: {},
        errors: {},
        productos: [],
        frecuenciasCobro: [
            {label:'Seleccionar...', value:''},
            {label:'Semanal', value:'semanal'},
            {label:'Decenal', value:'decenal'},
            {label:'Catorcenal', value:'catorcenal'},
            {label:'Quincenal', value:'quincenal'},
            {label:'Mensual', value:'mensual'}
        ],
        cotizacion: {},
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Editar solicitud'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            const res = await obtenerSolicitud(id)
            // const empresaId = res.data.empleado.empresa
            const params = { unidades_operativas: res.data.empleado.unidad_operativa, activo: true }
            const prod = await obtenerProductos(params)
            const productos = []
            let producto_actual_id = null
            prod.data.results.forEach(p => {
                if (res.data.producto && res.data.producto.id == p.id){
                    producto_actual_id = p.id
                }
                productos.push({ label: p.nombre, value: p.id, tasa_interes_anual: p.tasa_interes_anual, frecuencia_cobro: p.frecuencia_cobro });
            })
            productos.unshift({
                label:'Seleccionar...', value:'', tasa_interes_anual: '', frecuencia_cobro: ''
            })

            const data = {
                producto: producto_actual_id,
                monto: res.data.monto,
                dinero_nuevo: res.data.dinero_nuevo,
                num_amortizaciones: res.data.num_amortizaciones,
                tasa_interes: res.data.tasa_interes,
                frecuencia_cobro: res.data.frecuencia_cobro,
                dias_cobro: res.data.dias_cobro,
                clase_credito: res.data.clase_credito,
                tipo_descuento: res.data.tipo_descuento
            };

            if (res.data.fecha_primera_amortizacion){
                data.fecha_primera_amortizacion = moment(res.data.fecha_primera_amortizacion, "YYYY-MM-DD").format("DD-MM-YYYY");
            }

            const datosCotizados = Object.assign({}, data)
            const cotizacion = res.data.cotizacion;

            this.setState({ productos, data, datosCotizados, cotizacion })
        } catch (error) {
            console.log(error)
        }
    }

    onChange = async (key, e) => {
        const { dataPrestamo } = this.state;
        dataPrestamo[key] = moment(e[0]).format('YYYY-MM-DD');
        this.setState({dataPrestamo})
    }

    async cotizar() {
        const { data } = this.state
        if (data.fecha_primera_amortizacion){
            data.fecha_primera_amortizacion = moment(data.fecha_primera_amortizacion, "DD-MM-YYYY").format("YYYY-MM-DD");
        }
        const res = await cotizarSolicitud(data)
        if (data.fecha_primera_amortizacion){
            data.fecha_primera_amortizacion = moment(data.fecha_primera_amortizacion, "YYYY-MM-DD").format("DD-MM-YYYY");
        }
        const cotizacion = res.data;
        this.setState({ data, cotizacion })
    }

    render () {
        const guia = [
            { nombre: 'Solicitudes', url: '/solicitudes', clase: '' },
            { nombre: 'Editar', url: '', clase: 'active' }
        ]
        const {
            id, productos,
            frecuenciasCobro,
            titulo,
            recotizar,
            cotizacion
        } = this.state;

        const form = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    try {
                        if (values.fecha_primera_amortizacion){
                            values.fecha_primera_amortizacion = moment(values.fecha_primera_amortizacion, "DD-MM-YYYY").format("YYYY-MM-DD");
                        }
                        await actualizarSolicitud(values, id)
                        // Se actualizó la solicitud
                        Swal.fire({
                            type: 'success',
                            text: 'Se actualizó la solicitud con éxito',
                            toast: true,
                            position: 'top-end',
                            timer: 3000,
                            showConfirmButton: false
                        });

                        this.props.history.push(`/solicitudes/ver/${id}`)
                    } catch (error) {
                        const errors = error.data
                        console.log(errors)
                        if (values.fecha_primera_amortizacion){
                            values.fecha_primera_amortizacion = moment(values.fecha_primera_amortizacion, "YYYY-MM-DD").format("DD-MM-YYYY");
                        }
                        console.log('valores despues de actualizar', values);
                        this.setState({ errors })
                    }
                }
            },
            fields:[{
                name:'producto',
                label:'Producto',
                type:'select',
                helpText:'',
                options: productos,
                updateValues: true
            },{
                name:'monto',
                label:'Monto',
                type:'text',
                helpText:''
            },{
                name:'dinero_nuevo',
                label:'Dinero nuevo',
                type:'text',
                helpText:''
            },{
                name:'num_amortizaciones',
                label:'Número de amortizaciones',
                type:'text',
                helpText:''
            },{
                name:'tasa_interes',
                label:'Tasa de interes',
                type:'text',
                noChange: false,
                helpText:''
            },{
            //     name:'frecuencia_cobro',
            //     label:'Frecuencia de cobro',
            //     type:'select',
            //     disabled: false,
            //     helpText: '',
            //     options: frecuenciasCobro
            // },{
                name:'fecha_primera_amortizacion',
                label:'Fecha primera amortización',
                type:'date',
                options: {dateFormat: 'd-m-Y', locale: Spanish, minDate: moment().add(3, 'd').format('DD-MM-YYYY')},
                disabled: false,
                helpText: '',
            },
            {
                name:'dias_cobro',
                label:'Días de cobro',
                type:'text',
                helpText: 'Días de cobro separados por coma. Ej. 10,20,30',
            },
            {
                name:'clase_credito',
                label:'Clase del crédito',
                type:'select',
                helpText:'',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Nuevo', value:'nuevo'},
                    {label:'Renovación', value:'renovacion'},
                    {label:'Former', value:'former'}, 
                    {label:'Reestructura vigente', value:'reestructura-vigente'},
                    {label:'Reestructura mora', value:'reestructura-mora'},
                    {label:'Migración', value:'migracion'}
                ]
            },
            {
                name:'tipo_descuento',
                label:'Tipo de descuento',
                type:'select',
                helpText:'',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Retención', value:'retencion'},
                    {label:'Domiciliación', value:'domiciliacion'}
                ]
            }
            ],
            data: this.state.data,
            errors: this.state.errors
        };


        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <br/>
                                <Formulario
                                    buttonLabel={ form.button.label }
                                    onSubmit={ form.button.onSubmit }
                                    fields={ form.fields }
                                    data={ form.data }
                                    errors={ form.errors }
                                    recotizar={ recotizar }
                                >
                                    <button
                                    type="button"
                                    className="btn btn-primary btn-sm fa-margin"
                                    onClick={ () => this.cotizar() }
                                    >
                                        Cotizar
                                    </button>
                                </Formulario>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TablaCotizacion cotizacion={cotizacion} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                .fa-margin {
                    margin-right: 5px;
                }
                .right {
                    float: right;
                }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
