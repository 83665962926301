import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    obtenerPrestamo,
    crearRenovacion
} from '../../api/Prestamos'
import {
    cotizarSolicitud,
} from '../../api/Solicitudes'
import Modal from 'react-awesome-modal'
import moment from 'moment'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { currencyFormat, dateFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import { obtenerProductosEmpleado } from '../../api/Promotores';
import { mostrarToast } from '../../utils/alerts';

class Renovacion extends Component {
    state = {
        id: null,
        titulo: null,
        empleado: {},

        dataSolicitud: {},
        dataRenovacion: {},
        errorsRenovacion: {},
        tipo: [],
        tipoCliente:[
            {label:'Seleccionar...', value:''},
            {label:'Recurrente', value:'recurrente'},
            {label:'Former', value:'former'}
        ],
        clasificacion:[
            {label:'Seleccionar...', value:''},
            {label:'A', value:'a'},
            {label:'AA', value:'aa'},
            {label:'AAA', value:'aaa'},
            {label:'B', value:'b'},
            {label:'BB', value:'bb'}
        ],
        tipoMonto:[
            {label:'Seleccionar...', value:''},
            {label:'Mismo Monto', value:'mismo monto'},
            {label:'Mayor Monto', value:'mayor monto'},
            {label:'Menor Monto', value:'menor monto'}
        ],
        avanceDePagos: "",
        avanceDeCapital: 0,
        prestamoActual: {},
        numAmortizaciones: 0,
        numAmortizacionesRestastes: 0,

        productos: [],
        frecuenciasCobro: [
            {label:'Seleccionar...', value:''},
            {label:'Semanal', value:'semanal'},
            {label:'Decenal', value:'decenal'},
            {label:'Catorcenal', value:'catorcenal'},
            {label:'Quincenal', value:'quincenal'},
            {label:'Mensual', value:'mensual'}
        ],

        cotizado: false,
        pagos: [],
        totales: {},

        saldo_actual: '',

        mostrarTabla01: false,
        totalTabla01: '',

        disabledSave: true,

        moratorios: 116,
        condonar_moratorio: false,
        castigos: 232,
        condonar_castigo: false
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Renovación'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            const res = await obtenerPrestamo(id)
            const prestamoActual = res.data
            const numAmortizaciones = prestamoActual.num_amortizaciones
            const numAmortizacionesPagadas = prestamoActual.num_amortizaciones - prestamoActual.amortizaciones_restantes
            const avanceDePagos = numAmortizacionesPagadas+'/'+numAmortizaciones
            const avanceDeCapital = prestamoActual.avance_capital
            const empleado = quitarNulos(prestamoActual.empleado)

            const prod = await obtenerProductosEmpleado(empleado.id)
            const productos = []
            prod.data.forEach(p => {
                productos.push({ label: p.nombre, value: p.id, tasa_interes_anual: p.tasa_interes_anual, frecuencia_cobro: p.frecuencia_cobro });
            })
            productos.unshift({
                label:'Seleccionar...', value:'', tasa_interes_anual: '', frecuencia_cobro: ''
            })

            const dataSolicitud = {
                producto: res.data.producto.id,
                monto: res.data.monto,
                num_amortizaciones: res.data.num_amortizaciones,
                tasa_interes: res.data.tasa_interes,
                frecuencia_cobro: res.data.frecuencia_cobro,
                dias_cobro: res.data.dias_cobro,
                interes_ordinario: res.data.intereses_ordinarios
            };
            if (res.data.fecha_primera_amortizacion){
                dataSolicitud.fecha_primera_amortizacion = moment(res.data.fecha_primera_amortizacion, "YYYY-MM-DD").format("DD-MM-YYYY");
            }

            let tipo
            if(parseFloat(prestamoActual.adeudo_atrasado)>0){
                tipo = [
                    {label:'Seleccionar...', value:''},
                    {label:'Reestructura Mora', value:'reestructura mora'},
                    {label:'Reestructura Vigente', value:'reestructura vigente'}
                ]
            }else{
                tipo = [
                    {label:'Seleccionar...', value:''},
                    {label:'Renovación', value:'renovacion'},
                    // {label:'Reestructura Vigente', value:'reestructura vigente'}
                ]
            }

            const titulo = `Renovación de #${prestamoActual.folio}`;

            this.setState({
                empleado,
                prestamoActual,
                numAmortizaciones,
                numAmortizacionesPagadas,
                avanceDePagos,
                avanceDeCapital,
                dataSolicitud,
                productos,
                tipo,
                titulo,
            })
        } catch (error) {
            console.log(error)
        }
    }

    handleChangeCheck = (e) => {
        const {
            target: { name, checked }
        } = e;
        let {moratorios, condonar_moratorio, castigos, condonar_castigo, totalTabla01} = this.state
        if(name==='moratorio'){
            condonar_moratorio = checked
            if(condonar_moratorio) {
                totalTabla01 = totalTabla01 - moratorios
            }else {
                totalTabla01 = totalTabla01 + moratorios
            }
        }
        if(name==='castigo'){
            condonar_castigo = checked
            if(condonar_castigo) {
                totalTabla01 = totalTabla01 - castigos
            }else {
                totalTabla01 = totalTabla01 + castigos
            }
        }
        this.setState({condonar_moratorio, condonar_castigo, totalTabla01})
    }

    sumaTabla01 = () => {
        const { prestamoActual, moratorios, condonar_moratorio, castigos, condonar_castigo } = this.state
        const capital_vigente = parseFloat(prestamoActual.capital)
        const interes_vencido = parseFloat(prestamoActual.interes_vencido)
        const intereses_ordinarios = parseFloat(prestamoActual.intereses_ordinarios)
        let total = (interes_vencido + capital_vigente + intereses_ordinarios)

        if(parseFloat(prestamoActual.adeudo_atrasado)>0){
            if(condonar_moratorio===false){
                total = total + parseFloat(moratorios)
            }
            if(condonar_castigo===false) {
                total = total + parseFloat(castigos)

            }
        }
        return total
    }

    mostrarTabla01 = () => {
        const totalTabla01 = this.sumaTabla01()
        this.setState({ totalTabla01, mostrarTabla01 : true })
    }

    aplicarMonto = () => {
        const {cotizado, dataSolicitud} = this.state
        const saldo_actual = this.sumaTabla01()

        let disabledSave = true
        if(cotizado){
            disabledSave = false
        }
        dataSolicitud.monto = saldo_actual
        let pagos = []
        this.setState({ pagos, saldo_actual, dataSolicitud, disabledSave, mostrarTabla01: false})
    }

    verPrestamo = () => {
        const { id } = this.state
        this.props.history.push(`/prestamos/ver/${id}`)
    }

    async cotizar() {
        const { dataSolicitud, saldo_actual } = this.state
        if (dataSolicitud.fecha_primera_amortizacion){
            dataSolicitud.fecha_primera_amortizacion = moment(dataSolicitud.fecha_primera_amortizacion, "DD-MM-YYYY").format("YYYY-MM-DD");
        }

        const res = await cotizarSolicitud(dataSolicitud)

        const pagos = res.data.pagos;
        const totales = res.data.totales;

        if (pagos.length>0){
            dataSolicitud.fecha_primera_amortizacion = moment(pagos[0].fecha, "YYYY-MM-DD").format("DD-MM-YYYY");
        }

        let disabledSave = true
        if(saldo_actual!==''){
            disabledSave = false
        }
        this.setState({ dataSolicitud, pagos, totales, disabledSave, cotizado: true })
    }

    validarMonto = async() => {
        // const { dataSolicitud, dataRenovacion, prestamoActual } = this.state
        // let errorsRenovacion = {}
        // if( dataRenovacion.tipo === 'renovacion' && parseFloat(dataSolicitud.monto) < parseFloat(prestamoActual.monto)){
        //     errorsRenovacion.monto = ["El monto no puede ser menor al crédito actual (con interes ordinario)"]
        // }
        // if( dataRenovacion.tipo === 'reestructura mora' && parseFloat(dataSolicitud.monto) < parseFloat(prestamoActual.monto)){
        //     errorsRenovacion.monto = ["El monto no puede ser menor al crédito actual (con interes ordinario, moratorio o castigo)"]
        // }
        // this.setState({errorsRenovacion:errorsRenovacion})
    }

    guardar = async() => {

        await this.validarMonto()

        const {
            id,
            dataSolicitud,
            dataRenovacion,
            numAmortizacionesPagadas,
            avanceDeCapital,
            moratorios,
            condonar_moratorio,
            castigos,
            condonar_castigo,
            errorsRenovacion } = this.state
        try {
            if (dataSolicitud.fecha_primera_amortizacion){
                dataSolicitud.fecha_primera_amortizacion = moment(dataSolicitud.fecha_primera_amortizacion, "DD-MM-YYYY").format("YYYY-MM-DD");
            }
            dataRenovacion.avance_pagos = numAmortizacionesPagadas
            dataRenovacion.avance_capital = avanceDeCapital

            let data = {...dataRenovacion, ...dataSolicitud}
            data.moratorio = moratorios
            data.condonar_moratorio = condonar_moratorio
            data.castigo = castigos
            data.condonar_castigo = condonar_castigo
            console.log(data)

            const res = await crearRenovacion(data, id)
            const renovacion = res.data
            // Se creo un nuevo crédito
            mostrarToast('Se ha generado un nuevo Crédito.', 'success');

            this.props.history.push(`/solicitudes/ver/${renovacion.solicitud}`)
        } catch (error) {
            const err = error.data
            let errors={}
            if(errorsRenovacion.monto.length){
                errors = {...err, monto: errorsRenovacion.monto}
            }else{
                errors = err
            }

            if (dataSolicitud.fecha_primera_amortizacion){
                dataSolicitud.fecha_primera_amortizacion = moment(dataSolicitud.fecha_primera_amortizacion, "YYYY-MM-DD").format("DD-MM-YYYY");
            }
            this.setState({ errorsRenovacion:errors })
        }
    }

    render () {
        const guia = [
            { nombre: 'Prestamos', url: '/prestamos', clase: '' },
            { nombre: 'Renovación', url: '', clase: 'active' }
        ]
        const {
            titulo,
            empleado,

            dataSolicitud,
            dataRenovacion,
            errorsRenovacion,
            prestamoActual,
            tipo,
            tipoCliente,
            clasificacion,
            tipoMonto,
            avanceDeCapital,
            avanceDePagos,

            productos,
            frecuenciasCobro,

            pagos,
            totales,

            saldo_actual,

            mostrarTabla01,
            totalTabla01,

            disabledSave,

            moratorios,
            condonar_moratorio,
            castigos,
            condonar_castigo,

        } = this.state;

        const formRenovacion = {
            fields:[{
                name:'tipo',
                label:'Tipo',
                type:'select',
                helpText:'',
                options: tipo,
            },{
                name:'tipo_cliente',
                label:'Tipo Cliente',
                type:'select',
                helpText:'',
                options: tipoCliente,
            },{
                name:'clasificacion',
                label:'Clasificación',
                type:'select',
                helpText:'',
                options: clasificacion,
            },{
                name:'tipo_monto',
                label:'Tipo Monto',
                type:'select',
                helpText:'',
                options: tipoMonto,
            },
            // {
            //     name:'avance_pagos',
            //     label:'Avance de Pagos',
            //     type:'text',
            //     disabled: true,
            //     value: avanceDePagos,
            //     helpText: '',
            // },
            // {
            //     name:'avance_capital',
            //     label:'Avance de Capital',
            //     type:'text',
            //     disabled: true,
            //     helpText: '',
            // }
            ],
            data: dataRenovacion,
            errors: errorsRenovacion
        };

        const formSolicitudCredito = {
            fields:[{
                name:'producto',
                label:'Producto',
                type:'select',
                helpText:'',
                options: productos,
                updateValues: true
            },{
                name:'monto',
                label:'Monto',
                type:'text',
                helpText:''
            },{
                name:'num_amortizaciones',
                label:'Número de amortizaciones',
                type:'text',
                helpText:''
            },{
                name:'tasa_interes',
                label:'Tasa de interes',
                type:'text',
                noChange: false,
                helpText:''
            },{
                name:'frecuencia_cobro',
                label:'Frecuencia de cobro',
                type:'select',
                disabled: false,
                helpText: '',
                options: frecuenciasCobro
            },{
                name:'fecha_primera_amortizacion',
                label:'Fecha primera amortización',
                type:'date',
                options: {dateFormat: 'd-m-Y', locale: Spanish},
                disabled: false,
                helpText: '',
            },
            // {
            //     name:'dias_cobro',
            //     label:'Días de cobro',
            //     type:'text',
            //     helpText: 'Días de cobro separados por coma. Ej. 10,20,30',
            // },
            // {
            //     name:'saldo_actual',
            //     label:'Saldo Actual '+prestamoActual.folio,
            //     type:'text',
            //     disabled: true,
            //     helpText: '',
            // }
            ],
            data: dataSolicitud,
            errors: errorsRenovacion
        };


        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <Modal visible={ mostrarTabla01 } width="50%" height="27.55%" effect="fadeInUp">
                    <div className="modal-content animated bounceInRight">
                        <div className="modal-header">
                            <h4 className="modal-title">Renovación de Monto</h4>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">
                                Renovación Crédito {prestamoActual.folio}
                            </p>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Concepto</th>
                                                    <th>Monto</th>
                                                    {parseFloat(prestamoActual.adeudo_atrasado) > 0 && <th>Condonar</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key='capital_vigente'>
                                                    <td>Capital Vigente</td>
                                                    <td>{ currencyFormat(prestamoActual.capital) }</td>
                                                    {parseFloat(prestamoActual.adeudo_atrasado) > 0 && <td>NA</td>}
                                                </tr>
                                                <tr key='interes_ordinario'>
                                                    <td>Intereses Ordinarios</td>
                                                    <td>{ currencyFormat(dataSolicitud.interes_ordinario) }</td>
                                                    {parseFloat(prestamoActual.adeudo_atrasado) > 0 && <td>NA</td>}
                                                </tr>
                                                {parseFloat(prestamoActual.adeudo_atrasado) > 0 && <>
                                                    <tr key='interes_vencido'>
                                                        <td>Interes Vencido</td>
                                                        <td>{currencyFormat(prestamoActual.interes_vencido)}</td>
                                                        {parseFloat(prestamoActual.interes_vencido) > 0 && <td>NA</td>}
                                                    </tr>
                                                    <tr key='interes_moratorio'>
                                                        <td>Moratorios</td>
                                                        <td>{currencyFormat(moratorios)}</td>
                                                        <td>
                                                            <input
                                                              type="checkbox"
                                                              name='moratorio'
                                                              value={condonar_moratorio}
                                                              checked={condonar_moratorio}
                                                              onChange={(e) => {
                                                                  this.handleChangeCheck(e)
                                                              }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr key='interes_castigo'>
                                                        <td>Castigo</td>
                                                        <td>{currencyFormat(castigos)}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                name='castigo'
                                                                value={condonar_castigo}
                                                                checked={condonar_castigo}
                                                                onChange={(e) => {
                                                                    this.handleChangeCheck(e)
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </>}
                                                <tr key='totales'>
                                                    <td><b>Total</b></td>
                                                    <td>{ currencyFormat(totalTabla01) }</td>
                                                    {parseFloat(prestamoActual.adeudo_atrasado) > 0 && <td></td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white" onClick={() => this.setState({mostrarTabla01:false}) }>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.aplicarMonto()}>Aceptar</button>
                        </div>
                    </div>
                </Modal>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        Datos del empleado
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Nombre</label>
                                                <label>{ empleado.nombre }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Fecha de nacimiento</label>
                                                <label>{ dateFormat(empleado.fecha_nacimiento) }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Correo electrónico</label>
                                                <label>{ empleado.email }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Puesto</label>
                                                <label>{ empleado.puesto }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Fecha de contratación</label>
                                                <label>{ dateFormat(empleado.fecha_contratacion) }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>RFC</label>
                                                <label>{ empleado.rfc }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>CURP</label>
                                                <label>{ empleado.curp }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Teléfono de casa</label>
                                                <label>{ empleado.telefono_casa }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Teléfono celular</label>
                                                <label>{ empleado.telefono_celular }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>No. empleado</label>
                                                <label>{ empleado.no_empleado }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Empresa</label>
                                                <label>{ empleado.empresa_str }</label>
                                            </div>
                                            <div className="col-sm-12 col-md-6 sol">
                                                <label>Unidad Operativa</label>
                                                <label>{ empleado.unidad_operativa_str }</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Formulario
                                  fields={ formRenovacion.fields }
                                  data={ formRenovacion.data }
                                  errors={ formRenovacion.errors }
                                />
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 sol">
                                        <label><b>Avance de pagos</b></label>
                                        <label>{ avanceDePagos }</label>
                                    </div>
                                    <div className="col-sm-12 col-md-6 sol">
                                        <label><b>Avance de Capital</b></label>
                                        <label>{ avanceDeCapital }%</label>
                                    </div>
                                </div>
                                <hr/>
                                <Formulario
                                    fields={ formSolicitudCredito.fields }
                                    data={ formSolicitudCredito.data }
                                    errors={ formSolicitudCredito.errors }
                                />
                                <div className="col-sm-6">
                                    <div className="form-group row false">
                                        <label className="col-sm-4 col-form-label">
                                            <b>Saldo Actual {prestamoActual.folio}</b>
                                        </label>
                                        <div className="col-sm-8">
                                            <input
                                              type="text"
                                              name="saldo_actual"
                                              onClick={()=>this.mostrarTabla01()}
                                              onChange={()=>console.log(saldo_actual)}
                                              value={saldo_actual}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-between">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm fa-margin"
                                          onClick={ () => this.cotizar() }
                                        >
                                            Cotizar
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm fa-margin"
                                          onClick={ () => this.guardar() }
                                          disabled={disabledSave}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Plazo</th>
                                                    <th>Fecha</th>
                                                    <th>Capital</th>
                                                    <th>Interes</th>
                                                    <th>IVA</th>
                                                    <th>Total</th>
                                                    <th>Saldo final</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {pagos.map(pago => {
                                                    return (
                                                      <tr key={`amortizacion-${pago.num_pago}`}>
                                                          <td>{ pago.num_pago }</td>
                                                          <td>{ dateFormat(pago.fecha) }</td>
                                                          <td>{ currencyFormat(pago.capital) }</td>
                                                          <td>{ currencyFormat(pago.interes_sin_iva) }</td>
                                                          <td>{ currencyFormat(pago.iva) }</td>
                                                          <td>{ currencyFormat(pago.total_pagar) }</td>
                                                          <td>{ currencyFormat(pago.saldo_final) }</td>
                                                      </tr>
                                                    );
                                                })}
                                                {
                                                    totales &&
                                                    <tr className="font-bold">
                                                        <td></td>
                                                        <td>TOTAL</td>
                                                        <td>{ currencyFormat(totales.capital) }</td>
                                                        <td>{ currencyFormat(totales.interes) }</td>
                                                        <td>{ currencyFormat(totales.iva) }</td>
                                                        <td>{ currencyFormat(totales.total) }</td>
                                                        <td></td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 d-flex justify-content-between">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm fa-margin"
                                          onClick={ () => this.verPrestamo() }
                                        >
                                            Regresar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                .sol{
                    display: flex;
                    justify-content: space-between;
                }
                .fa-margin {
                    margin-right: 5px;
                }
                .right {
                    float: right;
                }
                `}</style>
            </Autenticado>
        )
    }
}

export default Renovacion
