import React, { Component } from 'react'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_green.css'
import Autenticado from '../../components/Autenticado'
import { obtenerPrestamo, liquidarPrestamo } from '../../api/Prestamos'
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias'
import { quitarNulos } from '../../utils/functions'
import { dateFormat, dateTimeFormat, currencyFormat } from '../../utils/filters'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        pestanaSeleccionada: 1,
        prestamo: {},
        empleado: {},
        datos: {
            monto: '',
            referencia: '',
            tipo_pago: '',
            cuenta: '',
            comentario: '',
        },
        fechaPago: moment().toDate(),
        errores: {
            monto: [],
            fecha_pago: [],
            referencia: [],
            comprobante: [],
            tipo_pago: [],
            cuenta: [],
            comentario: [],
        },
        cuentasBancarias: []
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Liquidar prestamo'
        this.setState({ id, titulo })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            let res = await obtenerPrestamo(id)
            let prestamo = res.data
            prestamo = quitarNulos(prestamo)
            let { empleado } = prestamo
            empleado = quitarNulos(empleado)
            res = await obtenerCuentasBancarias()
            const cuentasBancarias = res.data
            this.setState({
                prestamo, empleado, cuentasBancarias
            })
        } catch (error) {
            console.log(error)
        }
    }

    handleChange = async (field, e) => {
        const { datos } = this.state
        const {
          target: { name, files, value }
        } = e
        if (name === 'comprobante') {
          datos['comprobante'] = files[0]
        } else {
            datos[name] = value
        }
    
        this.setState({ datos }, () => {
          if (field.fun) field.fun();
        })
    }

    handleChangeDate = date => {
        const fechaPago = date[0]
        this.setState({ fechaPago })
    }

    liquidar = async () => {
        const { id, datos, errores, fechaPago } = this.state
        datos['fecha_pago'] = moment(fechaPago).format('YYYY-MM-DD')
        const formData = new FormData()
        Object.keys(datos).forEach((key) => {
            formData.append(key, datos[key])
        })

        try {
            const resp = await liquidarPrestamo(formData, id)
            console.log('resp', resp)
            this.props.history.push(`/prestamos/ver/${id}/`)
        } catch (error) {
            Object.keys(errores).forEach((key) => {
                errores[key] = []
            })
            Object.keys(error.data).forEach((key) => {
                errores[key] = error.data[key]
            })
            this.setState({ errores })
        }
    }

    render () {
        const guia = [
            { nombre: 'Prestamos', url: '/prestamos', clase: '' },
            { nombre: 'Liquidar', url: '', clase: 'active' }
        ]

        const {
            pestanaSeleccionada,
            titulo,
            prestamo,
            empleado,
            datos,
            errores,
            cuentasBancarias,
            fechaPago
        } = this.state

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <div className="tab-content">
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Datos del empleado
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Nombre</label>
                                                                <label>{ empleado.nombre }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Correo electrónico</label>
                                                                <label>{ empleado.email }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Correo electrónico</label>
                                                                <label>{ empleado.puesto }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de contratación</label>
                                                                <label>{ dateFormat(empleado.fecha_nacimiento) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de contratación</label>
                                                                <label>{ dateFormat(empleado.fecha_contratacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>RFC</label>
                                                                <label>{ empleado.rfc }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>CURP</label>
                                                                <label>{ empleado.curp }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Teléfono de casa</label>
                                                                <label>{ empleado.telefono_casa }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Teléfono celular</label>
                                                                <label>{ empleado.telefono_celular }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading">
                                                        Detalles
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Empresa</label>
                                                                <label>{ prestamo.empresa }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Estatus</label>
                                                                <label>{ prestamo.estatus }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha de creación</label>
                                                                <label>{ dateTimeFormat(prestamo.fecha_creacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Fecha primer amortización</label>
                                                                <label>{ dateFormat(prestamo.fecha_primera_amortizacion) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Folio</label>
                                                                <label>{ prestamo.folio }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Frecuencia de cobro</label>
                                                                <label>{ prestamo.frecuencia_cobro }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Número de amortizaciones</label>
                                                                <label>{ prestamo.num_amortizaciones }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Amortizaciones restantes</label>
                                                                <label>{ prestamo.amortizaciones_restantes }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Tipo</label>
                                                                <label>{ prestamo.tipo }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Tase de interes</label>
                                                                <label>{ prestamo.tasa_interes } %</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Próximo pago</label>
                                                                <label>{ dateFormat(prestamo.proximo_pago) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Último pago</label>
                                                                <label>{ dateFormat(prestamo.ultimo_pago) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto</label>
                                                                <label>{ currencyFormat(prestamo.monto) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Adeudo total</label>
                                                                <label>{ currencyFormat(prestamo.adeudo_total) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Adeudo atrasado</label>
                                                                <label>{ currencyFormat(prestamo.adeudo_atrasado) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Capital</label>
                                                                <label>{ currencyFormat(prestamo.capital) }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Días de retraso</label>
                                                                <label>{ prestamo.dias_retraso }</label>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 sol">
                                                                <label>Monto pago</label>
                                                                <label>{ currencyFormat(prestamo.monto_pago) }</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['monto'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Monto</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            value={ datos['monto']}
                                                            name="monto"
                                                            onChange={e => this.handleChange('monto', e)}
                                                            className="form-control"
                                                        />
                                                        { errores['monto'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['fecha_pago'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Fecha de pago</label>
                                                    <div className="col-sm-8">
                                                        <Flatpickr
                                                            className="form-control date"
                                                            value={fechaPago}
                                                            options={{ dateFormat: 'd-m-Y' }}
                                                            onChange={this.handleChangeDate}
                                                        />
                                                        { errores['fecha_pago'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['referencia'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Referencia</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            value={ datos['referencia']}
                                                            name="referencia"
                                                            onChange={e => this.handleChange('referencia', e)}
                                                            className="form-control"
                                                        />
                                                        { errores['referencia'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['comprobante'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Comprobante</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="file"
                                                            name="comprobante"
                                                            onChange={e => this.handleChange('comprobante', e)}
                                                            className="form-control"
                                                        />
                                                        { errores['comprobante'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['tipo_pago'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Tipo de pago</label>
                                                    <div className="col-sm-8">
                                                        <select
                                                            value={ datos['tipo_pago']}
                                                            name="tipo_pago"
                                                            onChange={e => this.handleChange('tipo_pago', e)}
                                                            className="form-control m-b"
                                                        >
                                                            <option value="">Seleccionar tipo de pago...</option>
                                                            <option value="cheque">Cheque</option>
                                                            <option value="depósito en efectivo">Depósito en efectivo</option>
                                                            <option value="SPEI">SPEI</option>
                                                            <option value="pago referenciado">Pago referenciado</option>
                                                        </select>
                                                        { errores['tipo_pago'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['cuenta'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Cuenta bancaria</label>
                                                    <div className="col-sm-8">
                                                        <select
                                                            value={ datos['cuenta']}
                                                            name="cuenta"
                                                            onChange={e => this.handleChange('cuenta', e)}
                                                            className="form-control m-b"
                                                        >
                                                            <option value="">Seleccionar cuenta bancaria...</option>
                                                            { cuentasBancarias.map((obj, index) => (
                                                                <option key={index} value={ obj.id }>{ obj.no_cuenta }</option>
                                                            ))}
                                                        </select>
                                                        { errores['cuenta'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className={`form-group row ${errores['comentario'].length > 0 && 'has-error'}`}>
                                                    <label className="col-sm-4 col-form-label">Comentario</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            value={ datos['comentario']}
                                                            name="comentario"
                                                            onChange={e => this.handleChange('comentario', e)}
                                                            className="form-control"
                                                        />
                                                        { errores['comentario'].map((obj, index) => (
                                                            <span className="form-text m-b-none text-error" key={index}>
                                                                {obj}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-4 col-sm-offset-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={ this.liquidar }
                                                >
                                                    Liquidar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                    .date {
                        background-color: #fff !important;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
