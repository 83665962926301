import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado';
import Formulario from '../../components/form/Form';
import {
  actualizarConfigEmergente,
  actualizarEmpleado,
  agregarEmpleado,
  agregarReferenciaEmpleado,
  editarReferenciaEmpleado,
  eliminarReferenciaEmpleado,
  guardarDomicilioEmpleado,
  guardarInfoBancariaEmpleado,
  guardarPercepcionesEmpleado,
  obtenerConfigEmergente,
  obtenerEmpleado
} from '../../api/Empleados';
import { obtenerCuentasMaestrasList } from '../../api/CuentasMaestras';
import { obtenerEmpresasList } from '../../api/Empresas';
import { obtenerUnidadesOperativasList } from '../../api/UnidadesOperativas';
import { obtenerBancos, obtenerEstados, obtenerMunicipios } from '../../api/Sistema';
import {
  obtenerEstadosSepomex,
  obtenerMunicipiosSepomex,
  obtenerEstadoMunicipiosSepomex
} from  '../../api/Sepomex';
// import ColoniasSepomexSelect from '../../components/ColoniasSepomexSelect';
import Modal from 'react-awesome-modal';
import { verificarPermiso } from '../../utils/auth';
import moment from 'moment';
import { Spanish } from 'flatpickr/dist/l10n/es';
// import Flatpickr from 'react-flatpickr';
import DatePicker from '../../components/form/fields/DatePicker';
import Checkbox from '../../components/form/fields/Checkbox';
import EmpresasWhirlpoolSelect from '../../components/empleados/EmpresasWhirlpoolSelect';
// import { quitarNulos } from '../../utils/functions';

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        pestanaSeleccionada: 1,
        data: {},
        errors: [],
        domicilio: {},
        erroresDomicilio:[],
        infoBancaria: {},
        erroresInfoBancaria:[],
        percepciones: {},
        erroresPercepciones:[],
        referencia: {},
        erroresReferencia:[],
        cuentasMaestras: [],
        empresas: [],
        unidadesOperativas: [],
        estados: [],
        municipios: [],
        bancos: [],
        ocultarTabs: true,
        mostratFormulario: false,
        mostrarConfirmacion: false,
        referencias: [],
        referenciaId: null,
        indiceReferencia: null,
        configEmergente: {},
        erroresConfigEmergente: {
            personalizado: [],
            saldo_dinamico: [],
            frecuencia_cobro: [],
            num_amortizaciones: [],
            saldo_preaprobado: [],
            tasa_interes: [],
        },
        des: null
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = id ? 'Editar empleado' : 'Agregar empleado'
        const accion = id ? 'Editar' : 'Agregar'
        this.setState({ id, titulo, accion })
    }

    async componentDidMount() {
        const { id } = this.state

        try {
            let resCM = await obtenerCuentasMaestrasList({page_size: 1000});
            let datosCM = resCM.data
            const cuentasMaestras = datosCM.map(obj =>{
                return { label: obj.nombre, value: obj.id }
            })
            cuentasMaestras.unshift({
                label:'Seleccionar...', value:'' 
            })

            let res, res_sepomex, datos;
            res = await obtenerEstados()
            res_sepomex = await obtenerEstadosSepomex()
            var estados = this.unionEstados(res.data, res_sepomex.data)
            estados.unshift({
                label:'Seleccionar...', value:'' 
            })

            res = await obtenerBancos()
            datos = res.data
            const bancos = datos.map(obj =>{
                return { label: obj.nombre, value: obj.id }
            })
            bancos.unshift({
                label:'Seleccionar...', value:'' 
            })

            this.setState({ cuentasMaestras, estados, bancos })
        } catch (error) {
            console.log(error)
        }

        if (id){
            try {
                let resp = await obtenerEmpleado(id)
                const { data } = resp
                if (data.fecha_contratacion){
                    data.fecha_contratacion = moment(data.fecha_contratacion,"YYYY-MM-DD").format("DD-MM-YYYY")
                }
                if (data.fecha_nacimiento){
                    data.fecha_nacimiento = moment(data.fecha_nacimiento,"YYYY-MM-DD").format("DD-MM-YYYY")
                }
                const domicilio = data.domicilio || {}
                const infoBancaria = data.informacion_bancaria || {}
                const percepciones = data.percepciones || {}
                const referencias = data.referencias || []
                const ocultarTabs = false

                resp = await obtenerConfigEmergente(id)
                const configEmergente =  resp.data
                let des = true
                if (!configEmergente.personalizado){
                  des = false
                }
                
                let municipios = []
                if (domicilio.estado){
                    let estado_selected = estados.filter( e => e.value_o === domicilio.estado)[0]
                    // console.log("============ ESTADO SELECTED ============")
                    // console.log(estado_selected)
                    domicilio['estado'] = estado_selected.value
                    
                    const res = await obtenerMunicipios(estado_selected.value_o);
                    const res_sepomex = await obtenerMunicipiosSepomex(estado_selected.value);
                    municipios = this.unionMunicipios(res.data, res_sepomex.data)
                    municipios.unshift({
                      label: '--Selecciona--', value: ''
                    })
                    let municipio_selected = municipios.filter( e => e.value_o === domicilio.municipio)[0]
                    // console.log("============ MUNICIPIO SELECTED ============")
                    // console.log(municipio_selected)
                    // Agregar condición aqui para evitar error si el municipio noesta seleccionado
                    if (municipio_selected){
                        domicilio['municipio'] = municipio_selected.value
                    }
                    else{
                        domicilio['municipio'] = ''
                    }
                    
                }
                // console.log("============ DOMICILIO ============")
                // console.log(domicilio)

                // let cuentasMaestras = []
                // if (data.cuenta_maestra){
                //     const res = await obtenerCuentasMaestrasList()
                //     const datos = res.data
                //     cuentasMaestras = datos.map(obj =>{
                //         return { label: obj.nombre, value: obj.id }
                //     })
                //     cuentasMaestras.unshift({
                //         label:'Seleccionar...', value:''
                //     })
                // }

                let empresas = []
                if (data.empresa){
                    const res = await obtenerEmpresasList({cuenta_maestra: data.cuenta_maestra})
                    const datos = res.data
                    empresas = datos.map(obj =>{
                        return { label: obj.nombre, value: obj.id }
                    })
                    empresas.unshift({
                        label:'Seleccionar...', value:''
                    })
                }

                let unidadesOperativas = []
                if (data.unidad_operativa){
                    const res = await obtenerUnidadesOperativasList({empresa: data.empresa})
                    const datos = res.data
                    unidadesOperativas = datos.map(obj =>{
                        return { label: obj.nombre, value: obj.id }
                    })
                    unidadesOperativas.unshift({
                        label:'Seleccionar...', value:''
                    })
                }

                this.setState({
                    data,
                    domicilio,
                    infoBancaria,
                    percepciones,
                    referencias,
                    ocultarTabs,
                    municipios,
                    configEmergente,
                    des,
                    empresas,
                    unidadesOperativas,
                })
            } catch (error) {
                console.log(error)
            }
        }

        if (!!this.state.data.cuenta_maestra) {
            let cuenta_maestra_seleccionada = document.getElementById('cm_select')
            this.setState({
                cuenta_maestra_seleccionada: cuenta_maestra_seleccionada.options[cuenta_maestra_seleccionada.selectedIndex].text.toLowerCase()
            })
        }

        if (!!this.state.data.empresa) {
            let empresa_seleccionada = document.getElementById('empresa_select')
            this.setState({
                empresa_seleccionada: empresa_seleccionada.options[empresa_seleccionada.selectedIndex].text.toLowerCase()
            })
        }

        if (!!this.state.data.unidad_operativa) {
            let uo_seleccionada = document.getElementById('unidad_operativa_select')
            this.setState({
                uo_seleccionada: uo_seleccionada.options[uo_seleccionada.selectedIndex].text.toLowerCase()
            })
        }
    }

    unionEstados = (estados, estados_sepomex) => {
      let estados_unificados = []
      // console.log("============ Estados ============")
      // console.log(estados)
      // console.log("============ Estados Sepomex ============")
      // console.log(estados_sepomex)
      estados.forEach(obj =>{
        estados_sepomex.forEach(o => {
          if(o.nombre === obj.nombre){
            estados_unificados.push({ label: o.nombre, value: o.uuid, value_o: obj.id })
          }
        })
      })
      // console.log("============ Estados Unifcados ============")
      // // estados_unificados = this.removeDuplicates(estados_unificados, "value")
      // console.log(estados_unificados)
      return estados_unificados
    }

    unionMunicipios = (municipios, municipios_sepomex) => {
      let municipios_unificados = []
      // console.log("============ Municipios ============")
      // console.log(municipios)
      // console.log("============ Municipios Sepomex ============")
      // console.log(municipios_sepomex)
      municipios.forEach(obj =>{
        municipios_sepomex.forEach(o => {
          if(o.nombre === obj.nombre){
            municipios_unificados.push({ label: o.nombre, value: o.uuid, value_o: obj.id })
          }
        })
      })

      // console.log("============ Municipios Unifcados ============")
      // // estados_unificados = this.removeDuplicates(estados_unificados, "value")
      // console.log(municipios_unificados)
      return municipios_unificados
    }

    removeDuplicates = (originalArray, prop) => {
      var newArray = [];
      var lookupObject  = {};
      for(var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }
      for(i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    }

    mostrarToast = (mensaje, tipo = 'success') => {
        Swal.fire({
            type: tipo,
            text: mensaje,
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
        })
    }

    eliminarReferencia = async() => {
        try {
            const { id, referenciaId, referencias, indiceReferencia } = this.state
            await eliminarReferenciaEmpleado(id, referenciaId)
            referencias.splice(indiceReferencia, 1)
            this.setState(
                { referencias, mostrarConfirmacion: false },
                this.mostrarToast('Referencia eliminada')
            )
        } catch (error) {
            console.log(error)
        }
    }

    editarReferencia = indice => {
        const { referencias } = this.state
        const referencia = referencias[indice]
        this.setState({
            referencia,
            mostratFormulario: true,
            indiceReferencia: referencia.id
        })
    }

    guardarReferencia = async() => {
        const values = this.state.referencia
        const { id, indiceReferencia } = this.state
        if (values.id){
            try {
                await editarReferenciaEmpleado(values, id, indiceReferencia)
                this.setState(
                    { erroresReferencia: [] },
                    this.mostrarToast('Referencia guardada')
                )
                this.cerrarModal()
            } catch (error) {
                this.setState({ erroresReferencia: error.data })
            }
        } else {
            try {
                const resp = await agregarReferenciaEmpleado(values, id)
                const referencia = resp.data
                const { referencias } = this.state
                referencias.push(referencia)
                this.setState({
                    referencia: {},
                    referencias,
                    erroresReferencia: []
                    },
                    this.mostrarToast('Referencia guardada')
                )
                this.cerrarModal()
            } catch (error) {
                this.setState({ erroresReferencia: error.data })
            }
        }
    }

    editarContacto = (indice) => {
        const { referencias } = this.state
        const referencia = referencias[indice]
        const indiceReferencia = referencia.id
        this.setState({ referencia, indiceReferencia })
        this.mostrarFormulario('Editar referencia')
    }

    mostrarConfirmacion(referenciaId, indiceReferencia) {
        this.setState({ mostrarConfirmacion : true, referenciaId, indiceReferencia })
    }

    mostrarFormulario(tituloFrm) {
        this.setState({ mostratFormulario : true, tituloFrm })
    }

    cerrarModal() {
        this.setState({
            mostratFormulario : false,
            mostrarConfirmacion : false,
            referencia: {},
            erroresReferencia: []
        });
    }

    seleccionarPestana(pestanaSeleccionada) {
        this.setState({ pestanaSeleccionada })
    }

    handleChange = async (type, e) => {
        const { configEmergente } = this.state
        let { des } = this.state
        const {
            target: { name, checked, value }
        } = e
        if (type === 'checkbox') {
            configEmergente[name] = checked
            if (name === 'personalizado'){
                if(checked){
                    des = true
                } else {
                    des = false
                }
            }
        } else {
            configEmergente[name] = value
        }
    
        this.setState({ configEmergente, des })
    }

    guardarConfigEmergente = async () => {
        const { configEmergente, erroresConfigEmergente, id } = this.state
        try {
            await actualizarConfigEmergente(configEmergente, id)
            this.setState({ 
                erroresConfigEmergente: {
                    personalizado: [],
                    saldo_dinamico: [],
                    frecuencia_cobro: [],
                    num_amortizaciones: [],
                    saldo_preaprobado: [],
                    tasa_interes: []
                }
            })
            this.mostrarToast('Configuración guardada')
        } catch (error) {
            const errorsKeys = Object.keys(error.data)
            errorsKeys.forEach(e => {
                erroresConfigEmergente[e] = error.data[e]
            });
            
            this.setState({ erroresConfigEmergente })
        }
    }

    handleChangeDomicilio = async (e) => {
      const { domicilio } = this.state;
      let { estados, municipios } = this.state
      const { target: { name, value } } = e;

      domicilio[name] = value;
      if (name === 'estado') {
        let estado_selected = estados.filter( e => e.value === value)[0]
        this.obtenerEstablecerMunicipios(estado_selected)

      } else if (name === 'codigo_postal' && value.length === 5) {
        const respEstadoMunicipiosSepomex = await obtenerEstadoMunicipiosSepomex(value);
        // console.log("============ Estado y Municipios ============")
        // console.log(respEstadoMunicipiosSepomex.data)
        if (respEstadoMunicipiosSepomex.data.length > 0) {
          let estado_sepomex = respEstadoMunicipiosSepomex.data[0].estado
          domicilio["estado"] = estado_sepomex.uuid

          let estado_selected = estados.filter( e => e.value === estado_sepomex.uuid)[0]
          this.obtenerEstablecerMunicipios(estado_selected, respEstadoMunicipiosSepomex.data[0].municipio.uuid)
        } else {

          domicilio["estado"] = ""
          domicilio["municipio"] = ""
          municipios = []
        }
        this.setState({ domicilio, municipios })
      } else {
        this.setState({ domicilio })
      }
    }

    obtenerEstablecerMunicipios = async(estado, municipio='') =>{
      const { domicilio } = this.state;
      const res = await obtenerMunicipios(estado.value_o);
      const res_sepomex = await obtenerMunicipiosSepomex(estado.value);
      let municipios = this.unionMunicipios(res.data, res_sepomex.data)
      municipios.unshift({
        label: '--Selecciona--', value: ''
      })

      if (municipio === '') {
        domicilio.municipio = '';
      } else {
        domicilio.municipio = municipio
      }
      this.setState({ domicilio, municipios })
    }

    handleChangeColonia(e){
      const { domicilio } = this.state;
      const {label/* , value */} = e
      domicilio["colonia"] = label
      this.setState({domicilio})
    }

    submitDomicilio = async() => {

      const {domicilio, id, estados, municipios} = this.state;
      // console.log("============ DOMICILIO ============")
      let estado_selected = estados.filter( e => e.value === domicilio.estado)[0]
      let municipio_selected = municipios.filter( e => e.value === domicilio.municipio)[0]
      domicilio['estado'] = estado_selected.value_o
      if (municipio_selected){
        domicilio['municipio'] = municipio_selected.value_o
      }
      else{
        domicilio['municipio'] = ''
      }
      // console.log(domicilio)
      try {
        /* const resp =  */await guardarDomicilioEmpleado(domicilio, id)
        domicilio['estado'] = estado_selected.value
        domicilio['municipio'] = municipio_selected.value
        this.setState(
          { erroresDomicilio: [] },
          () =>{ this.mostrarToast('Domicilio guardado') }
        )
      } catch (error) {
        this.setState({ erroresDomicilio: error.data })
      }

    }

    domicilioForm(){

        const { domicilio, erroresDomicilio, estados, municipios } = this.state;

        return (
          <div>
            <div className="row">
              <div className="col-sm-6">
                <div className={ erroresDomicilio.calle ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Calle *</label>
                  <div className="col-sm-8">
                    <input type="text" name="calle" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.calle || ''} />
                    { erroresDomicilio.calle && <span className="form-text m-b-none text-error">{ erroresDomicilio.calle }</span> }
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={ erroresDomicilio.numero_exterior ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Número exterior *</label>
                  <div className="col-sm-8">
                    <input type="text" name="numero_exterior" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.numero_exterior || ''} />
                    { erroresDomicilio.numero_exterior && <span className="form-text m-b-none text-error">{ erroresDomicilio.numero_exterior }</span> }
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={ erroresDomicilio.numero_interior ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Número interior</label>
                  <div className="col-sm-8">
                    <input type="text" name="numero_interior" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.numero_interior || ''} />
                    { erroresDomicilio.numero_interior && <span className="form-text m-b-none text-error">{ erroresDomicilio.numero_interior }</span> }
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={ erroresDomicilio.codigo_postal ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Código postal *</label>
                  <div className="col-sm-8">
                    <input type="text" name="codigo_postal" maxLength={5} className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.codigo_postal || ''} />
                    { erroresDomicilio.codigo_postal && <span className="form-text m-b-none text-error">{ erroresDomicilio.codigo_postal }</span> }
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={ erroresDomicilio.estado ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Estado *</label>
                  <div className="col-sm-8">
                    <select name="estado" id="estado_select" className="form-control" value={domicilio.estado} onChange={this.handleChangeDomicilio}>
                      { estados.map((estado) => {
                        return <option key={`estado-${estado.value}`} value={estado.value}>{estado.label}</option>
                      }) }
                    </select>
                    { erroresDomicilio.estado && <span className="form-text m-b-none text-error">{ erroresDomicilio.estado }</span> }
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={ erroresDomicilio.municipio ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Municipio *</label>
                  <div className="col-sm-8">
                    <select name="municipio" id="municipio_select" className="form-control" value={domicilio.municipio} onChange={this.handleChangeDomicilio}>
                      { municipios.map((municipio) => {
                        return <option key={`municipio-${municipio.value}`} value={municipio.value}>{municipio.label}</option>
                      }) }
                    </select>
                    { erroresDomicilio.municipio && <span className="form-text m-b-none text-error">{ erroresDomicilio.municipio }</span> }
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={ erroresDomicilio.colonia ? "form-group row has-error" : "form-group row" }>
                  <label className="col-sm-4 col-form-label">Colonia</label>
                  <div className="col-sm-8">
                    <input type="text" name="colonia" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.colonia || ''} />
                    { erroresDomicilio.colonia && <span className="form-text m-b-none text-error">{ erroresDomicilio.colonia }</span> }
                  </div>
                </div>
              </div>

              {/*<div className="col-sm-6">*/}
              {/*  <div className={ erroresDomicilio.colonia ? "form-group row has-error" : "form-group row" }>*/}
              {/*    <label className="col-sm-4 col-form-label">Colonia</label>*/}
              {/*    <div className="col-sm-8">*/}
              {/*      <ColoniasSepomexSelect*/}
              {/*        onChange={(e) => {this.handleChangeColonia(e)}}*/}
              {/*        codigo_postal={domicilio.codigo_postal}*/}
              {/*      />*/}
              {/*      { erroresDomicilio.colonia && <span className="form-text m-b-none text-error">{ erroresDomicilio.colonia }</span> }*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="col-sm-6">
                  <div className={ erroresDomicilio.tipo_vivienda ? "form-group row has-error" : "form-group row" }>
                      <label className="col-sm-4 col-form-label">Tipo de vivienda</label>
                      <div className="col-sm-8">
                          <select name="tipo_vivienda" id="tipo_vivienda_select" className="form-control" value={domicilio.tipo_vivienda || ''} onChange={this.handleChangeDomicilio}>
                              <option value="">Selecciona el tipo de vivienda</option>
                              <option value="propia">Propia</option>
                              <option value="propia pagando">Propia pagando</option>
                              <option value="padres">Padres</option>
                              <option value="rentada">Rentada</option>
                              <option value="prestada">Prestada</option>
                              <option value="familiar">Familiar</option>
                          </select>
                          { erroresDomicilio.municipio && <span className="form-text m-b-none text-error">{ erroresDomicilio.municipio }</span> }
                      </div>
                  </div>
              </div>

              <div className="col-sm-6">
                  <div className={ erroresDomicilio.antiguedad ? "form-group row has-error" : "form-group row" }>
                      <label className="col-sm-4 col-form-label">Antigüedad en el domicilio</label>
                      <div className="col-sm-8">
                          <input type="text" name="antiguedad" className="form-control" onChange={this.handleChangeDomicilio} value={domicilio.antiguedad || ''} />
                          { erroresDomicilio.antiguedad && <span className="form-text m-b-none text-error">{ erroresDomicilio.antiguedad }</span> }
                      </div>
                  </div>
              </div>
                
            </div>
            <div className="row">
                  <div className="col-sm-12">
                  <button className="btn btn-primary btn-sm" onClick={this.submitDomicilio}>Guardar</button>
              </div>
            </div>
          </div>
        )
    }

    handleChangeData = async (e) => {
        const { data } = this.state;
        const {target: {name, value}} = e;

        data[name] = value;

        if (name === 'cuenta_maestra') {

            let resEmp = await obtenerEmpresasList({cuenta_maestra: value});
            const empresas = [{label: '--Selecciona--', value: ''}];
            resEmp.data.forEach(m => {
                empresas.push({label: m.nombre, value: m.id});
            });
            data.empresa = '';
            this.setState({ data, empresas })

        } else if (name === 'empresa') {

            let resUO = await obtenerUnidadesOperativasList({empresa: value});
            const unidadesOperativas = [{label: '--Selecciona--', value: ''}];
            resUO.data.forEach(m => {
                unidadesOperativas.push({label: m.nombre, value: m.id});
            });
            data.unidad_operativa = '';
            this.setState({ data, unidadesOperativas })
            
            
        } else {
            this.setState({ data })
        }
        
        let cuenta_maestra_seleccionada = document.getElementById('cm_select')
        let empresa_seleccionada = document.getElementById('empresa_select')
        let uo_seleccionada = document.getElementById('unidad_operativa_select')

        this.setState({
            cuenta_maestra_seleccionada: cuenta_maestra_seleccionada.options[cuenta_maestra_seleccionada.selectedIndex].text.toLowerCase()
        })

        this.setState({
            empresa_seleccionada: empresa_seleccionada.options[empresa_seleccionada.selectedIndex].text.toLowerCase()
        })

        if (!!data.unidad_operativa) {
            this.setState({
                uo_seleccionada: uo_seleccionada.options[uo_seleccionada.selectedIndex].text.toLowerCase()
            })
        }

        if ( empresa_seleccionada.options[empresa_seleccionada.selectedIndex].text.toLowerCase() !== 'whirlpool' 
        && cuenta_maestra_seleccionada.options[cuenta_maestra_seleccionada.selectedIndex].text.toLowerCase() !== 'whirlpool' ) {
            let newData = { ...data, empresa_whirlpool: null }
            this.setState({ uo_seleccionada: undefined})
            this.setState({ data: newData })
        }

    }

    submitPersonal = async() => {

        const {data, id, ocultarTabs/* , errors */} = this.state;
        const errorsCM = {};
        if (!data.cuenta_maestra){
            errorsCM.cuenta_maestra = ["Este campo es requerido."];
        }
        if (id){
            const values = data;
            // const errorsData = errors;
            try {
                values.crear_usuario = false;
                values.enviar_email_activacion = false;
                if (values.fecha_nacimiento){
                    values.fecha_nacimiento = moment(values.fecha_nacimiento, "DD-MM-YYYY").format('YYYY-MM-DD')
                }
                if (values.fecha_contratacion){
                    values.fecha_contratacion = moment(values.fecha_contratacion, "DD-MM-YYYY").format('YYYY-MM-DD')
                }
                const resp = await actualizarEmpleado(values, id);
                const { data } = resp;
                if (data.fecha_nacimiento){
                    data.fecha_nacimiento = moment(data.fecha_nacimiento, "YYYY-MM-DD").format('DD-MM-YYYY')
                }
                if (data.fecha_contratacion){
                    data.fecha_contratacion = moment(data.fecha_contratacion, "YYYY-MM-DD").format('DD-MM-YYYY')
                }
                this.setState(
                    { data, errors: [] },
                    () => this.mostrarToast('Empleado guardado')
                )
            } catch (error) {
                const errors = error.data;
                if (data.fecha_contratacion){
                    data.fecha_contratacion = moment(data.fecha_contratacion, "YYYY-MM-DD").format('DD-MM-YYYY')
                }
                if (data.fecha_nacimiento){
                    data.fecha_nacimiento = moment(data.fecha_nacimiento, "YYYY-MM-DD").format('DD-MM-YYYY')
                }
                if(errorsCM.cuenta_maestra){errors.cuenta_maestra = errorsCM.cuenta_maestra}
                this.setState({ errors: errors })
            }
        } else {
            // const errorsData = errors;
            let hideTabs = ocultarTabs;
            try {
                const values = data;
                let idEmp = null;
                if (hideTabs){
                    if ( values.fecha_nacimiento ){
                        values.fecha_nacimiento = moment(values.fecha_nacimiento, "DD-MM-YYYY").format('YYYY-MM-DD')
                    }
                    if ( values.fecha_contratacion ){
                        values.fecha_contratacion = moment(values.fecha_contratacion, "DD-MM-YYYY").format('YYYY-MM-DD')
                    }
                    const resp = await agregarEmpleado(values)
                    const { data } = resp;
                    if ( data.fecha_nacimiento ){
                        data.fecha_nacimiento = moment(data.fecha_nacimiento, "YYYY-MM-DD").format('DD-MM-YYYY')
                    }
                    if ( data.fecha_contratacion ){

                        data.fecha_contratacion = moment(data.fecha_contratacion, "YYYY-MM-DD").format('DD-MM-YYYY')
                    }
                    idEmp = data.id;
                    hideTabs = false;
                    this.setState(
                        { id: idEmp, data, ocultarTabs: hideTabs, errors: [] },
                        () => this.mostrarToast('Empleado guardado')
                    )
                    this.props.history.push(`/empleados/editar/${idEmp}`)
                }
            } catch (error) {
                const errors = error.data
                if(errorsCM.cuenta_maestra){errors.cuenta_maestra = errorsCM.cuenta_maestra}
                this.setState({ errors: errors })
            }
        }

    }

    personalForm(){

        const {errors, cuentasMaestras, empresas, unidadesOperativas, cuenta_maestra_seleccionada } = this.state;
        let { data, uo_seleccionada, empresa_seleccionada } = this.state;
        // data = quitarNulos(data);
        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className={ errors.cuenta_maestra ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Cuenta Maestra *</label>
                            <div className="col-sm-8">
                                <select name="cuenta_maestra" id="cm_select" className="form-control" value={data.cuenta_maestra} onChange={this.handleChangeData}>
                                { cuentasMaestras.map((cuenta_maestra) => {
                                    return <option key={`cm-${cuenta_maestra.value}`} value={cuenta_maestra.value}>{cuenta_maestra.label}</option>
                                }) }
                                </select>
                                { errors.cuenta_maestra && <span className="form-text m-b-none text-error">{ errors.cuenta_maestra }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.empresa ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Empresa *</label>
                            <div className="col-sm-8">
                                <select name="empresa" id="empresa_select" className="form-control" value={data.empresa} onChange={this.handleChangeData}>
                                { empresas.map((empresa) => {
                                    return <option key={`municipio-${empresa.value}`} value={empresa.value}>{empresa.label}</option>
                                }) }
                                </select>
                                { errors.empresa && <span className="form-text m-b-none text-error">{ errors.empresa }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.unidad_operativa ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Unidad Operativa *</label>
                            <div className="col-sm-8">
                                <select name="unidad_operativa" id="unidad_operativa_select" className="form-control" value={data.unidad_operativa} onChange={this.handleChangeData}>
                                { unidadesOperativas.map((unidad_operativa) => {
                                    return <option key={`municipio-${unidad_operativa.value}`} value={unidad_operativa.value}>{unidad_operativa.label}</option>
                                }) }
                                </select>
                                { errors.unidad_operativa && <span className="form-text m-b-none text-error">{ errors.unidad_operativa }</span> }
                            </div>
                        </div>
                    </div>
                    
                    {/* empresas con las que whirlpool tiene convenio */}
                    <div className="col-sm-6">
                        { (cuenta_maestra_seleccionada === 'whirlpool' && empresa_seleccionada === 'whirlpool') && 
                        (uo_seleccionada === 'whirlpool domiciliado' || uo_seleccionada === 'whirlpool domiciliado_semanal')
                            ?
                                <EmpresasWhirlpoolSelect
                                    onChange={ this.handleChangeData }
                                    value={ data.empresa_whirlpool || '' }
                                    errors={ errors }
                                />
                            : null
                        }
                    </div>

                    <div className="col-sm-6">
                        <div className={ errors.nombre ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Nombre *</label>
                            <div className="col-sm-8">
                                <input type="text" name="nombre" className="form-control"  onChange={this.handleChangeData} value={data.nombre || ''} />
                                { errors.nombre && <span className="form-text m-b-none text-error">{ errors.nombre }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.apellido_paterno ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Apellido paterno *</label>
                            <div className="col-sm-8">
                                <input type="text" name="apellido_paterno" className="form-control" onChange={this.handleChangeData} value={data.apellido_paterno || ''} />
                                { errors.apellido_paterno && <span className="form-text m-b-none text-error">{ errors.apellido_paterno }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.apellido_materno ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Apellido materno</label>
                            <div className="col-sm-8">
                                <input type="text" name="apellido_materno" className="form-control" onChange={this.handleChangeData} value={data.apellido_materno || ''} />
                                { errors.apellido_materno && <span className="form-text m-b-none text-error">{ errors.apellido_materno }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.email ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Correo electrónico *</label>
                            <div className="col-sm-8">
                                <input type="text" name="email" className="form-control" onChange={this.handleChangeData} value={data.email || ''} />
                                { errors.email && <span className="form-text m-b-none text-error">{ errors.email }</span> }
                            </div>
                        </div>
                    </div>
                    <DatePicker
                        key={'fecha_nacimiento'}
                        label={'Fecha de nacimiento'}
                        name={'fecha_nacimiento'}
                        value={data.fecha_nacimiento}
                        options={{dateFormat: 'd-m-Y', locale: Spanish }}
                        helpText={''}
                        error={[]}
                        onChange={(v) => {
                            data.fecha_nacimiento = v[0];
                            this.setState({ data });
                        }}
                    />
                    {/* <div className="col-sm-6">
                        <div className="form-group row false">
                            <label className="col-sm-4 col-form-label">Fecha de nacimiento</label>
                            <div className="col-sm-8">
                                <Flatpickr
                                    className="form-control date"
                                    value={data.fecha_nacimiento}
                                    onChange={(v) => {
                                        data.fecha_nacimiento = v[0];
                                        this.setState({ data });
                                    }}
                                    options={{ dateFormat: 'd-m-Y', locale: Spanish }}
                                />
                                <span className="form-text m-b-none"></span>
                            </div>
                        </div>
                        <style jsx="true">{`
                            .date {
                                background-color: #fff !important;
                            }
                        `}</style>
                    </div> */}
                    {/* <div className="col-sm-6">
                        <div className={ errors.frecuencia_pago ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Frecuencia de Pago</label>
                            <div className="col-sm-8">
                                <select name="frecuencia_pago" id="frecuencia_pago_select" className="form-control" value={data.frecuencia_pago} onChange={this.handleChangeData}>
                                    <option value="">Seleccionar...</option>
                                    <option value="semanal">Semanal</option>
                                    <option value="decenal">Decenal</option>
                                    <option value="catorcenal">Catorcernal</option>
                                    <option value="quincenal">Quincenal</option>
                                    <option value="mensual">Mensual</option>
                                </select>
                                { errors.frecuencia_pago && <span className="form-text m-b-none text-error">{ errors.frecuencia_pago }</span> }
                            </div>
                        </div>
                    </div> */}
                    <div className="col-sm-6">
                        <div className={ errors.curp ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">CURP *</label>
                            <div className="col-sm-8">
                                <input type="text" name="curp" className="form-control" onChange={this.handleChangeData} value={data.curp || ''}  disabled={data.prestamo_activo}/>
                                { errors.curp && <span className="form-text m-b-none text-error">{ errors.curp }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.rfc ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">RFC</label>
                            <div className="col-sm-8">
                                <input type="text" name="rfc" className="form-control" onChange={this.handleChangeData} value={data.rfc || ''} disabled={data.prestamo_activo}/>
                                { errors.rfc && <span className="form-text m-b-none text-error">{ errors.rfc }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.telefono_casa ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Teléfono casa</label>
                            <div className="col-sm-8">
                                <input type="text" name="telefono_casa" className="form-control" onChange={this.handleChangeData} value={data.telefono_casa || ''} />
                                { errors.telefono_casa && <span className="form-text m-b-none text-error">{ errors.telefono_casa }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.telefono_celular ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Teléfono celular</label>
                            <div className="col-sm-8">
                                <input type="text" name="telefono_celular" className="form-control" onChange={this.handleChangeData} value={data.telefono_celular || ''} />
                                { errors.telefono_celular && <span className="form-text m-b-none text-error">{ errors.telefono_celular }</span> }
                            </div>
                        </div>
                    </div>
                    <DatePicker
                        key={'fecha_contratacion'}
                        label={'Fecha de contratación'}
                        name={'fecha_contratacion'}
                        value={data.fecha_contratacion}
                        options={{dateFormat: 'd-m-Y', locale: Spanish }}
                        helpText={''}
                        error={[]}
                        onChange={(v) => {
                            data.fecha_contratacion = v[0];
                            this.setState({ data });
                        }}
                    />
                    {/* <div className="col-sm-6">
                        <div className="form-group row false">
                            <label className="col-sm-4 col-form-label">Fecha de contratación</label>
                            <div className="col-sm-8">
                                <Flatpickr
                                    className="form-control date"
                                    value={data.fecha_contratacion}
                                    onChange={(v) => {
                                        data.fecha_contratacion = v;
                                        this.setState({ data });
                                    }}
                                    options={{ dateFormat: 'd-m-Y', locale: Spanish }}
                                />
                                <span className="form-text m-b-none"></span>
                            </div>
                        </div>
                        <style jsx="true">{`
                            .date {
                                background-color: #fff !important;
                            }
                        `}
                        </style>
                    </div> */}
                    <div className="col-sm-6">
                        <div className={ errors.puesto ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Puesto</label>
                            <div className="col-sm-8">
                                <input type="text" name="puesto" className="form-control" onChange={this.handleChangeData} value={data.puesto || ''} />
                                { errors.puesto && <span className="form-text m-b-none text-error">{ errors.puesto }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.genero ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Genero</label>
                            <div className="col-sm-8">
                                <select name="genero" id="genero_select" className="form-control" value={data.genero || ''} onChange={this.handleChangeData}>
                                    <option value="">Seleccionar...</option>
                                    <option value="masculino">Masculino</option>
                                    <option value="femenino">Femenino</option>
                                </select>
                                { errors.genero && <span className="form-text m-b-none text-error">{ errors.genero }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.nacionalidad ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Nacionalidad</label>
                            <div className="col-sm-8">
                                <input type="text" name="nacionalidad" className="form-control" onChange={this.handleChangeData} value={data.nacionalidad || ''} />
                                { errors.nacionalidad && <span className="form-text m-b-none text-error">{ errors.nacionalidad }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.estado_civil ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Estado civil</label>
                            <div className="col-sm-8">
                                <select name="estado_civil" id="estado_civil_select" className="form-control" value={data.estado_civil || ''} onChange={this.handleChangeData}>
                                    <option value="">Seleccionar...</option>
                                    <option value="Casado">Casado</option>
                                    <option value="Divorciado">Divorciado</option>
                                    <option value="Soltero">Soltero</option>
                                    <option value="Unión libre">Unión libre</option>
                                    <option value="Viudo">Viudo</option>
                                </select>
                                { errors.estado_civil && <span className="form-text m-b-none text-error">{ errors.estado_civil}</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.no_electoral ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">No. electoral</label>
                            <div className="col-sm-8">
                                <input type="text" name="no_electoral" className="form-control" onChange={this.handleChangeData} value={data.no_electoral || ''} />
                                { errors.no_electoral && <span className="form-text m-b-none text-error">{ errors.no_electoral }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.no_empleado ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">No. empleado</label>
                            <div className="col-sm-8">
                                <input type="text" name="no_empleado" className="form-control" onChange={this.handleChangeData} value={data.no_empleado || ''} />
                                { errors.no_empleado && <span className="form-text m-b-none text-error">{ errors.no_empleado }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                      <div className={ errors.unidad_negocio ? "form-group row has-error" : "form-group row" }>
                        <label className="col-sm-4 col-form-label">Unidad de negocio</label>
                        <div className="col-sm-8">
                          <input type="text" name="unidad_negocio" className="form-control" onChange={this.handleChangeData} value={data.unidad_negocio || ''} />
                          { errors.unidad_negocio && <span className="form-text m-b-none text-error">{ errors.unidad_negocio }</span> }
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.usuario_caja_ahorro ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Usuario caja ahorro</label>
                            <div className="col-sm-8">
                                <input type="text" name="usuario_caja_ahorro" className="form-control" onChange={this.handleChangeData} value={data.usuario_caja_ahorro || ''} />
                                { errors.usuario_caja_ahorro && <span className="form-text m-b-none text-error">{ errors.usuario_caja_ahorro }</span> }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className={ errors.canal ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Canal</label>
                            <div className="col-sm-8">
                                <input type="text" name="canal" className="form-control" onChange={this.handleChangeData} value={data.canal || ''} />
                                { errors.canal && <span className="form-text m-b-none text-error">{ errors.canal }</span> }
                            </div>
                        </div>
                    </div>
                    <Checkbox
                        key={'crear_usuario'}
                        label={'Crear usuario'}
                        name={'crear_usuario'}
                        value={data.crear_usuario || ''}
                        type={'checkbox'}
                        onChange={(e) => {
                            const { checked } = e.target
                            const { data } = this.state
                            data['crear_usuario'] = checked
                            this.setState({ data: data })
                        }}
                        //validate={this.validate.bind(this, field.name, field.validate)}
                        helpText={'Al seleccionar esta casilla, se creará un usuario del sistema el cual podrá ingresar a solicitar un crédito'}
                        error={[]}
                    />
                    {/* <div className="col-sm-6">
                        <div className={ errors.crear_usuario ? "form-group row has-error" : "form-group row" }>
                            <label className="col-sm-4 col-form-label">Crear usuario</label>
                            <div className="col-sm-8">
                                <input type="checkbox" name="crear_usuario" className="form-control" value="false" />
                                <small className="form-text m-b-none">Al seleccionar esta casilla, se creará un usuario del sistema el cual podrá ingresar a solicitar un crédito</small>
                            </div>
                        </div>
                        <style jsx="true">{`
                            .center-vertical {
                                align-items: center;
                                display: flex;
                            }
                        `}
                        </style>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-sm-4 col-sm-offset-2">
                        <button className="btn btn-primary btn-sm" onClick={this.submitPersonal}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }

    render () {
        let {
          id,
          ocultarTabs,
        //   referenciaId
        } = this.state

        const titulo = id ? 'Editar empleado' : 'Agregar empleado'
        const accion = id ? 'Editar' : 'Agregar'


        const guia = [
            { nombre: 'Empleados', url: '/empleados', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]

        const {
            // empresas,
            // estados,
            mostratFormulario,
            mostrarConfirmacion,
            // municipios,
            bancos,
            pestanaSeleccionada,
            tituloFrm,
            referencias,
            configEmergente,
            erroresConfigEmergente,
            des
        } = this.state
/*
        const form = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    if (id){
                        try {
                            values.crear_usuario = false
                            values.enviar_email_activacion = false
                            if (values.fecha_contratacion){
                                values.fecha_contratacion = moment(values.fecha_contratacion, "DD-MM-YYYY").format('YYYY-MM-DD')
                            }
                            if (values.fecha_nacimiento){
                                values.fecha_nacimiento = moment(values.fecha_nacimiento, "DD-MM-YYYY").format('YYYY-MM-DD')
                            }
                            const resp = await actualizarEmpleado(values, id)
                            const { data } = resp
                            if (data.fecha_contratacion){
                                data.fecha_contratacion = moment(data.fecha_contratacion, "YYYY-MM-DD").format('DD-MM-YYYY')
                            }
                            if (data.fecha_nacimiento){
                                data.fecha_nacimiento = moment(data.fecha_nacimiento, "YYYY-MM-DD").format('DD-MM-YYYY')
                            }
                            this.setState(
                                { data, errors: [] },
                                () => this.mostrarToast('Empleado guardado')
                            )
                        } catch (error) {
                            const errors = error.data
                            if (values.fecha_contratacion){
                                values.fecha_contratacion = moment(values.fecha_contratacion, "YYYY-MM-DD").format('DD-MM-YYYY')
                            }
                            if (values.fecha_nacimiento){
                                values.fecha_nacimiento = moment(values.fecha_nacimiento, "YYYY-MM-DD").format('DD-MM-YYYY')
                            }
                            this.setState({ errors })
                        }
                    } else {
                        try {
                            if (ocultarTabs){
                                if (values.fecha_contratacion){
                                    values.fecha_contratacion = moment(values.fecha_contratacion, "DD-MM-YYYY").format('YYYY-MM-DD')
                                }
                                if (values.fecha_nacimiento){
                                    values.fecha_nacimiento = moment(values.fecha_nacimiento, "DD-MM-YYYY").format('YYYY-MM-DD')
                                }
                                const resp = await agregarEmpleado(values)
                                const { data } = resp
                                if (data.fecha_contratacion){
                                    data.fecha_contratacion = moment(data.fecha_contratacion, "YYYY-MM-DD").format('DD-MM-YYYY')
                                }
                                if (data.fecha_nacimiento){
                                    data.fecha_nacimiento = moment(data.fecha_nacimiento, "YYYY-MM-DD").format('DD-MM-YYYY')
                                }
                                id = data.id
                                ocultarTabs = false
                                this.setState(
                                    { id, data, ocultarTabs, errors: [] },
                                    () => this.mostrarToast('Empleado guardado')
                                )
                                this.props.history.push(`/empleados/editar/${id}`)
                            }
                        } catch (error) {
                            const errors = error.data
                            this.setState({ errors })
                        }
                    }
                }
            },
            fields:[{
                name:'nombre',
                label:'Nombre *',
                type:'text',
                helpText:''
            },{
                name:'apellido_paterno',
                label:'Apellido paterno *',
                type:'text',
                helpText:''
            },{
                name:'apellido_materno',
                label:'Apellido materno',
                type:'text',
                helpText:''
            },{
                name:'empresa',
                label:'Empresa *',
                type:'select',
                options: empresas,
                helpText:''
            },{
                name:'email',
                label:'Correo electrónico *',
                type:'text',
                helpText:''
            },{
                name:'fecha_nacimiento',
                label:'Fecha de nacimiento',
                type:'date',
                options: {dateFormat: 'd-m-Y', locale: Spanish },
                helpText:''
            },{
                name:'frecuencia_pago',
                label:'Frecuencia del pago',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Semanal', value:'semanal'},
                    {label:'Decenal', value:'decenal'},
                    {label:'Catorcernal', value:'catorcenal'},
                    {label:'Quincenal', value:'quincenal'},
                    {label:'Mensual', value:'mensual'}
                ],
                helpText:''
            },{
                name:'curp',
                label:'CURP *',
                type:'text',
                helpText:''
            },{
                name:'rfc',
                label:'RFC',
                type:'text',
                helpText:''
            },{
                name:'telefono_casa',
                label:'Teléfono casa',
                type:'text',
                helpText:'',
                maxLength: 10
            },{
                name:'telefono_celular',
                label:'Teléfono celular',
                type:'text',
                helpText:'',
                maxLength: 10
            },{
                name:'fecha_contratacion',
                label:'Fecha de contratación',
                type:'date',
                options: {dateFormat: 'd-m-Y', locale: Spanish },
                helpText:''
            },{
                name:'puesto',
                label:'Puesto',
                type:'text',
                helpText:''
            },{
                name:'genero',
                label:'Género',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Masculino', value:'masculino'},
                    {label:'Femenino', value:'femenino'}
                ],
                helpText:''
            },{
                name:'nacionalidad',
                label:'Nacionalidad',
                type:'text',
                helpText:''
            },{
                name:'estado_civil',
                label:'Estado civil',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Divorciado', value:'divorciado'},
                    {label:'Casado', value:'casado'},
                    {label:'Soltero', value:'soltero'},
                    {label:'Unión libre', value:'union libre'},
                    {label:'Viudo', value:'viudo'}
                ],
                helpText:''
            },{
                name:'no_electoral',
                label:'No. electoral',
                type:'text',
                helpText:''
            },{
                name:'no_empleado',
                label:'No. empleado',
                type:'text',
                helpText:''
            },{
                name:'usuario_caja_ahorro',
                label:'Usuario caja ahorro',
                type:'text',
                helpText:''
            },{
                name:'canal',
                label:'Canal',
                type:'text',
                helpText:''
            },{
                name:'crear_usuario',
                label:'Crear usuario',
                type:'checkbox',
                helpText:'Al seleccionar esta casilla, se creará un usuario del sistema el cual podrá ingresar a solicitar un crédito',
                showField:'enviar_email_activacion'
            },{
                name:'enviar_email_activacion',
                label:'Enviar correo de activación',
                type:'checkbox',
                view: false,
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }
*/
        // if (this.props.match.params.id !== undefined){
        //     form.fields.splice(20, 1)
        //     // form.fields.splice(10, 1)
        // }
/*
        const frmDomicilio = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    try {
                        const resp = await guardarDomicilioEmpleado(values, id)
                        this.setState(
                            { erroresDomicilio: [] },
                             () =>{ this.mostrarToast('Domicilio guardado') }
                        )
                    } catch (error) {
                        this.setState({ erroresDomicilio: error.data })
                    }
                }
            },
            fields:[{
                name:'calle',
                label:'Calle *',
                type:'text',
                helpText:''
            },{
                name:'numero_exterior',
                label:'Número exterior *',
                type:'text',
                helpText:''
            },{
                name:'numero_interior',
                label:'Número interior',
                type:'text',
                helpText:''
            },{
                name:'colonia',
                label:'Colonia',
                type:'text',
                helpText:''
            },{
                name:'codigo_postal',
                label:'Código postal *',
                type:'text',
                helpText:''
            },{
                name:'estado',
                label:'Estado *',
                type:'select',
                options: estados,
                helpText:'',
                child: {
                    name:'municipio',
                    func: obtenerMunicipios
                }
            },{
                name:'municipio',
                label:'Municipio *',
                type:'select',
                options: municipios,
                helpText:''
            }],
            data: this.state.domicilio,
            errors: this.state.erroresDomicilio
        }
*/
        const frmInfoBancaria = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    try {
                        /* const resp =  */await guardarInfoBancariaEmpleado(values, this.state.id)
                        this.setState(
                            { erroresInfoBancaria: [] },
                            this.mostrarToast('Información bancaria guardada')
                        )
                    } catch (error) {
                        this.setState({ erroresInfoBancaria: error.data })
                    }
                }
            },
            fields:[{
                name:'banco',
                label:'Banco *',
                type:'select',
                options: bancos,
                helpText:''
            },{
                name:'cuenta',
                label:'Cuenta *',
                type:'text',
                maxLength: 10,
                keyPressValidation: 'only_numbers',
                helpText:''
            },{
                name:'clabe',
                label:'Clabe *',
                type:'text',
                maxLength: 18,
                keyPressValidation: 'only_numbers',
                helpText:''
            }],
            data: this.state.infoBancaria,
            errors: this.state.erroresInfoBancaria
        }

        const frmPercepciones = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    try {
                        await guardarPercepcionesEmpleado(values, this.state.id)
                        this.setState(
                            { erroresPercepciones: [] },
                            () => this.mostrarToast('Percepciones guardadas')
                        )
                    } catch (error) {
                        console.log(error.data)
                        this.setState({ erroresPercepciones: error.data })
                    }
                }
            },
            fields:[{
                name:'salario_diario',
                label:'Salario diario *',
                type:'text',
                helpText:''
            },{
                name:'salario_diario_integrado',
                label:'Salario diario integrado',
                type:'text',
                helpText:''
            },{
                name:'sueldo_mensual',
                label:'Sueldo mensual *',
                type:'text',
                helpText:''
            },{
                name:'sueldo_mensual_real',
                label:'Sueldo mensual neto *',
                type:'text',
                helpText:''
            }],
            data: this.state.percepciones,
            errors: this.state.erroresPercepciones
        }

        const frmReferencia = {
            fields:[{
                name:'nombre',
                label:'Nombre *',
                type:'text',
                helpText:''
            },{
                name:'telefono',
                label:'Celular *',
                type:'text',
                helpText:'',
                maxLength:10
            },{
                name:'parentesco',
                label:'Parentesco *',
                type:'text',
                helpText:''
            },{
                name:'domicilio',
                label:'Domicilio',
                type:'text',
                helpText:''
            }],
            data: this.state.referencia,
            errors: this.state.erroresReferencia
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tabs-container">
                            <ul className="nav nav-tabs" role="tablist">
                                <li>
                                    <a className={`nav-link ${pestanaSeleccionada === 1 && 'active show'}`} onClick={(e) => this.seleccionarPestana(1)} >General</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empleados.change_empleadodomicilio,') ? '' : ' ocultar'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 2 && 'active show'}`} onClick={(e) => this.seleccionarPestana(2)} >Domicilio</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empleados.change_empleadoinformacionbancaria,') ? '' : ' ocultar'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 3 && 'active show'}`} onClick={(e) => this.seleccionarPestana(3)} >Información bancaria</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empleados.change_empleadopercepciones,') ? '' : ' ocultar'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 4 && 'active show'}`} onClick={(e) => this.seleccionarPestana(4)} >Percepciones</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empleados.view_empleadoreferencia,') ? '' : ' ocultar'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 5 && 'active show'}`} onClick={(e) => this.seleccionarPestana(5)} >Referencias</a>
                                </li>
                                <li className={`${ocultarTabs && 'hide'}${verificarPermiso('empleados.view_empleadoreferencia,') ? '' : ' ocultar'}`}>
                                    <a className={`nav-link ${pestanaSeleccionada === 6 && 'active show'}`} onClick={(e) => this.seleccionarPestana(6)} >Configuración emergente</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                    <div className="panel-body">
                                        { this.personalForm() }
                                        {/* <Formulario
                                            buttonLabel={ form.button.label }
                                            onSubmit={ form.button.onSubmit }
                                            fields={ form.fields }
                                            data={ form.data }
                                            errors={ form.errors }
                                        /> */}
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 2 && 'active'}`}>
                                    <div className="panel-body">
                                        { this.domicilioForm() }

                                        {/*<Formulario*/}
                                        {/*    buttonLabel={ frmDomicilio.button.label }*/}
                                        {/*    onSubmit={ frmDomicilio.button.onSubmit }*/}
                                        {/*    fields={ frmDomicilio.fields }*/}
                                        {/*    data={ frmDomicilio.data }*/}
                                        {/*    errors={ frmDomicilio.errors }*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 3 && 'active'}`}>
                                    <div className="panel-body">
                                        <Formulario
                                            buttonLabel={ frmInfoBancaria.button.label }
                                            onSubmit={ frmInfoBancaria.button.onSubmit }
                                            fields={ frmInfoBancaria.fields }
                                            data={ frmInfoBancaria.data }
                                            errors={ frmInfoBancaria.errors }
                                        />
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 4 && 'active'}`}>
                                    <div className="panel-body">
                                        <Formulario
                                            buttonLabel={ frmPercepciones.button.label }
                                            onSubmit={ frmPercepciones.button.onSubmit }
                                            fields={ frmPercepciones.fields }
                                            data={ frmPercepciones.data }
                                            errors={ frmPercepciones.errors }
                                        />
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 5 && 'active'}`}>
                                    <div className="panel-body">
                                        { referencias.length > 0 ?
                                            <div>
                                                { verificarPermiso('empleados.add_empleadoreferencia,') &&
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn btn-primary"
                                                                onClick={() => this.mostrarFormulario('Agregar referencia')}
                                                            >
                                                                Agregar
                                                            </button>
                                                        </span>
                                                    </div>
                                                }
                                                <br/>
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-hover">
                                                        <tbody>
                                                            { referencias.map((obj, indice) => (
                                                                <tr key={ obj.id }>
                                                                    <td className="client-link">{ obj.nombre }</td>
                                                                    <td className="contact-type"><i className="fa fa-phone"> </i></td>
                                                                    <td>{ obj.celular }</td>
                                                                    <td className="contact-type"><i className="fa fa-phone"> </i></td>
                                                                    <td>{ obj.telefono }</td>
                                                                    <td className="contact-type"><i className="fa fa-envelope"> </i></td>
                                                                    <td>{ obj.domicilio }</td>
                                                                    <td>{ obj.parentesco }</td>
                                                                    <td>
                                                                        { verificarPermiso('empleados.change_empleadoreferencia,') &&
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-warning btn-sm btns-space"
                                                                                onClick={() => this.editarReferencia(indice)}
                                                                            >
                                                                                Editar
                                                                            </button>
                                                                        }
                                                                        { verificarPermiso('empleados.delete_empleadoreferencia,') &&
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm btns-space"
                                                                                onClick={() => this.mostrarConfirmacion(obj.id, indice) }
                                                                            >
                                                                                Eliminar
                                                                            </button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        :
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="wrapper wrapper-content">
                                                        <div className="ibox-content m-b-sm">
                                                            <div className="text-center p-lg">
                                                                <h2>Aún no tienes referencias agregadas</h2>
                                                                { verificarPermiso('empleados.add_empleadoreferencia,') &&
                                                                    <button 
                                                                        className="btn btn-primary btn-sm"
                                                                        type="button"
                                                                        onClick={() => this.mostrarFormulario('Agregar referencia')}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                        <span className="bold">Agregar</span>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <Modal visible={ mostratFormulario } width="50%" height="45%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">{ tituloFrm}</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <Formulario
                                                        fields={ frmReferencia.fields }
                                                        data={ frmReferencia.data }
                                                        errors={ frmReferencia.errors }
                                                    />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.guardarReferencia()}>Guardar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                        <Modal visible={ mostrarConfirmacion } width="50%" height="31%" effect="fadeInUp">
                                            <div className="modal-content animated bounceInRight">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">Eliminar referencia</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        ¿Estás seguro de eliminar esta referencia?
                                                    </p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.eliminarReferencia()}>Aceptar</button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 6 && 'active'}`}>
                                    <div className="panel-body">
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">Personalizado</label>
                                                        <div className="col-sm-8 center-vertical">
                                                            <input
                                                                type="checkbox"
                                                                name="personalizado"
                                                                className="form-control"
                                                                value={ configEmergente.personalizado }
                                                                onChange={ e => this.handleChange('checkbox', e) }
                                                            />
                                                            <small className="form-text m-b-none">Si este campo está activado permite editar la configuración del empleado</small>
                                                            { erroresConfigEmergente.personalizado.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">Saldo dinámico</label>
                                                        <div className="col-sm-8 center-vertical">
                                                            { des ?
                                                                <input
                                                                    type="checkbox"
                                                                    name="saldo_dinamico"
                                                                    className="form-control"
                                                                    value={ configEmergente.saldo_dinamico}
                                                                    onChange={ e => this.handleChange('checkbox', e) }
                                                                />
                                                            :
                                                                <input
                                                                    type="checkbox"
                                                                    name="saldo_dinamico"
                                                                    className="form-control"
                                                                    disabled
                                                                    value={ configEmergente.saldo_dinamico}
                                                                    onChange={ e => this.handleChange('checkbox', e) }
                                                                />
                                                            }
                                                            <small className="form-text m-b-none"></small>
                                                            { erroresConfigEmergente.saldo_dinamico.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">Frecuencia del cobro</label>
                                                        <div className="col-sm-8">
                                                            { des ?
                                                                <select
                                                                    name="frecuencia_cobro"
                                                                    className="form-control m-b"
                                                                    value={ configEmergente.frecuencia_cobro}
                                                                    onChange={ e => this.handleChange('select', e) }
                                                                >
                                                                    <option value="">Seleccionar...</option>
                                                                    <option value="semanal">Semanal</option>
                                                                    <option value="decenal">Decenal</option>
                                                                    <option value="catorcenal">Catorcernal</option>
                                                                    <option value="quincenal">Quincenal</option>
                                                                    <option value="mensual">Mensual</option>
                                                                </select>
                                                            :
                                                                <select
                                                                    name="frecuencia_cobro"
                                                                    className="form-control m-b"
                                                                    disabled
                                                                    value={ configEmergente.frecuencia_cobro}
                                                                    onChange={ e => this.handleChange('select', e) }
                                                                >
                                                                    <option value="">Seleccionar...</option>
                                                                    <option value="semanal">Semanal</option>
                                                                    <option value="decenal">Decenal</option>
                                                                    <option value="catorcenal">Catorcernal</option>
                                                                    <option value="quincenal">Quincenal</option>
                                                                    <option value="mensual">Mensual</option>
                                                                </select>
                                                            }
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.frecuencia_cobro.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">
                                                            Número de amortizaciones
                                                        </label>
                                                        <div className="col-sm-8">
                                                            {/* { des ?
                                                                <input
                                                                    type="text"
                                                                    name="num_amortizaciones"
                                                                    className="form-control"
                                                                    value={ configEmergente.num_amortizaciones}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            : */}
                                                                <input
                                                                    type="text"
                                                                    name="num_amortizaciones"
                                                                    className="form-control"
                                                                    disabled={!des}
                                                                    value={ configEmergente.num_amortizaciones || '' }
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            {/* } */}
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.num_amortizaciones.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">
                                                            Saldo preaprobado
                                                        </label>
                                                        <div className="col-sm-8">
                                                            {/* { des ?
                                                                <input
                                                                    type="text"
                                                                    name="saldo_preaprobado"
                                                                    className="form-control"
                                                                    value={ configEmergente.saldo_preaprobado}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            : */}
                                                                <input
                                                                    type="text"
                                                                    name="saldo_preaprobado"
                                                                    className="form-control"
                                                                    disabled={!des}
                                                                    value={ configEmergente.saldo_preaprobado || '' }
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            {/* } */}
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.saldo_preaprobado.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row false">
                                                        <label className="col-sm-4 col-form-label">
                                                            Tasa de interés
                                                        </label>
                                                        <div className="col-sm-8">
                                                            {/* { des ?
                                                                <input
                                                                    type="text"
                                                                    name="tasa_interes"
                                                                    className="form-control"
                                                                    value={ configEmergente.tasa_interes}
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            : */}
                                                                <input
                                                                    type="text"
                                                                    name="tasa_interes"
                                                                    className="form-control"
                                                                    disabled={!des}
                                                                    value={ configEmergente.tasa_interes || '' }
                                                                    onChange={ e => this.handleChange('text', e) }
                                                                />
                                                            {/* } */}
                                                            <span className="form-text m-b-none"></span>
                                                            { erroresConfigEmergente.tasa_interes.map((obj, index) => (
                                                                <span className="form-text m-b-none text-error" key={index}>
                                                                    {obj}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            { des &&
                                                <div className="form-group row">
                                                    <div className="col-sm-4 col-sm-offset-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={ this.guardarConfigEmergente}
                                                        >
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .center-vertical {
                        align-items: center;
                        display: flex;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
