import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
// import Tabla from '../../components/Tabla';
import Paginacion from '../../components/Paginacion';
import { obtenerPagos, exportarPagos } from '../../api/Pagos';
// import { verificarPermiso } from '../../utils/auth'
import EmpresasSelect from '../../components/EmpresasSelect';
import { currencyFormat, dateFormat } from '../../utils/filters';
import Link from 'react-router-dom/Link';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

class Lista extends Component {
  state = {
    titulo: 'Pagos',
    search: '',
    objetos: [],
    totalRecords: 0,
    pageLimit: 50,
    params: {},
    fechaInicio: '',
    fechaFin: ''
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const req = await obtenerPagos(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  }

  async getPagos() {
    try {
      let { params } = this.state;
      // params = Object.assign({ search: texto }, params);
      const req = await obtenerPagos(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerPagos(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  buscar = async () => {

    this.getPagos();
  };

  onCleanClicked = async () => {
    const search = '';
    const { params } = this.state;

    params.search = search;

    this.setState({search, params});

    this.getPagos();
  }

  searchHandleChage = (e) => {
    const search = e.target.value;
    const { params } = this.state;

    params.search = search;
    this.setState({search, params});
  }

  empresaHandleChange = (e) => {
    const { params } = this.state;
    params.prestamo__empresa = e.value;
    this.setState({params});
    this.getPagos();
  }

  estatusHandleChange = (e) => {
    const { params } = this.state;
    params.estatus = e.value;
    this.setState({params});
    this.getPagos();
  }

  handleFechaInicio = date => {
    const fechaInicio = date[0];
    const { params } = this.state;
    const fecha = moment(fechaInicio).format('YYYY-MM-DD');
    params.fecha_inicio = fecha;
    this.setState({ fechaInicio, params });
    this.getPagos();
  }

  handleFechaFin = date => {
    const fechaFin = date[0];
    const { params } = this.state;
    const fecha = moment(fechaFin).format('YYYY-MM-DD');
    params.fecha_fin = fecha;
    this.setState({ fechaFin, params });
    this.getPagos();
  }

  render() {
    const { titulo, objetos, totalRecords, pageLimit, search, fechaInicio, fechaFin } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];

    // const urlBotonAgregar = verificarPermiso('pagos.add_pagoprestamo,') ? '/pagos/crear' : '';

    const estatusValues = [
      {
        label: 'Pendiente',
        value: 'pendiente',
      },
      {
        label: 'Verificado',
        value: 'verificado',
      },
      {
        label: 'Rechazado',
        value: 'rechazado',
      },
      {
        label: 'Cancelado',
        value: 'cancelado',
      },
    ];

    return (
      <Autenticado titulo={titulo} guia={guia}>

        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">

                <div className="row m-b-sm">
                  <div className="col-sm-2">
                    {/*SE DESHABILITA BOTON PARA AGREGAR PAGO*/}
                    {/*{*/}
                    {/*  verificarPermiso('pagos.add_pagoprestamo,') &&*/}
                    {/*  <a className="btn btn-sm btn-primary fa-margin" href="/pagos/crear">*/}
                    {/*    <i className="fa fa-plus fa-margin"></i>Agregar*/}
                    {/*  </a>*/}
                    {/*}*/}

                    <button onClick={exportarPagos} type="button" className="btn btn-primary btn-sm btns-space m-l-sm"><i
                      className="fa fa-download fa-margin"></i>Exportar
                    </button>
                  </div>
                </div>

                <div className="row">

                  <div className="col-sm-2">
                    <EmpresasSelect onChange={this.empresaHandleChange} />
                  </div>

                  <div className="col-sm-2">
                    <Select
                      name="estatus"
                      placeholder="Selecciona un estatus"
                      className="select"
                      onChange={this.estatusHandleChange}
                      options={estatusValues}
                    />
                  </div>

                  <div className="col-sm-2">
                    <Flatpickr
                      className="form-control date"
                      value={fechaInicio}
                      placeholder="Fecha inicio"
                      options={{ dateFormat: 'd-m-Y' }}
                      onChange={this.handleFechaInicio}
                    />
                  </div>

                  <div className="col-sm-2">
                    <Flatpickr
                      className="form-control date"
                      value={fechaFin}
                      placeholder="Fecha fin"
                      options={{ dateFormat: 'd-m-Y' }}
                      onChange={this.handleFechaFin}
                    />
                  </div>

                  <div className="col-sm-4">
                    <div className="input-group">
                      <input
                        name="search"
                        value={search}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={e => this.searchHandleChage(e)}
                        // onKeyDown={e => this.searchHandleChage(e)}
                      />
                      <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-sm btn-info"
                            onClick={() => this.onCleanClicked()}
                          >
                            <i className="fa fa-close fa-margin" />
                          </button>
                        </span>
                      <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => this.buscar()}
                          >
                            <i className="fa fa-search fa-margin" />
                            Buscar
                          </button>
                        </span>
                    </div>
                  </div>

                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th>Folio</th>
                      <th>Préstamo</th>
                      <th>Fecha pago</th>
                      <th>Monto</th>
                      <th>Motivo</th>
                      <th>Empresa</th>
                      <th>Empleado</th>
                      <th>Estatus</th>
                      <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    { objetos && objetos.map( pago => {
                      return (
                        <tr key={`pago-${pago.id}`}>
                          <td>{ pago.folio }</td>
                          <td>{ pago.prestamo }</td>
                          <td>{ dateFormat(pago.fecha_pago) }</td>
                          <td>{ currencyFormat(pago.monto) }</td>
                          <td>{ pago.motivo }</td>
                          <td>{ pago.empresa }</td>
                          <td>{ pago.empleado}</td>
                          <td className="text-capitalize">{ pago.estatus }</td>
                          <td>
                            <Link to={`/pagos/ver/${pago.id}`}>
                              <button className="btn btn-primary">Ver</button>
                            </Link>
                          </td>
                        </tr>
                      )
                    }) }
                    {
                      objetos && objetos.length === 0 &&
                        <tr>
                          <td colSpan="9" className="text-center">No se encontraron pagos</td>
                        </tr>
                    }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>



        {/*<Tabla*/}
        {/*  objetos={objetos}*/}
        {/*  columnas={columnas}*/}
        {/*  botones={botones}*/}
        {/*  urlBotonAgregar={urlBotonAgregar}*/}
        {/*  btnExportar*/}
        {/*  fnExportar={exportarPagos}*/}
        {/*  nombreExportar="pagos"*/}
        {/*  buscar*/}
        {/*  onFindClicked={this.buscar}*/}
        {/*/>*/}
        <Paginacion
          totalRecords={totalRecords}
          pageLimit={pageLimit}
          pageNeighbours={1}
          onPageChanged={this.onPageChanged}
        />
      </Autenticado>
    );
  }
}

export default Lista;
