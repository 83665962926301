import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import { obtenerUnidadOperativa } from '../../api/UnidadesOperativas';
import { currencyFormat, dateTimeFormat } from '../../utils/filters';
import { Link } from 'react-router-dom';
// import { verificarPermiso } from '../../utils/auth';

class Form extends Component {
  state = {
    id: this.props.match.params.id,
    objeto: {
      domicilio: {}
    }
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      try {
        const resp = await obtenerUnidadOperativa(id);
        const objeto = resp.data;
        console.log(objeto)
        const empresa = objeto.empresa
        this.setState({ objeto, empresa});
      } catch (error) {
        console.log(error);
      }
    }
  }

  formarDireccion = d => {
    if (!d) {
      return '';
    }
    return `${d.calle} #${d.numero_exterior}${
      d.numero_interior ? ` int. ${d.numero_interior} ` : ''
    } ${d.colonia} C.P. ${d.codigo_postal}, ${d.municipio_str}, ${
      d.estado_str
    }`;
  };

  renderRow = (label, value) => (
    <div className="row m-t-xs">
      <div className="col-sm-6">
        <span>{label}</span>
      </div>
      <div className="col-sm-6">
        <strong>{value || '--'}</strong>
      </div>
    </div>  
  );

  render() {
    const guia = [
      { nombre: 'Unidades Operativas', url: '/unidades-operativas', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
  const { objeto, empresa/*,  id*/ } = this.state;

    return (
      <Autenticado titulo="Ver Unidades Operativas" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>Información general</h5>
                  </div>
                  <div className="ibox-content">
                    <h4>
                      <strong>{objeto.nombre}</strong>
                    </h4>
                    {objeto.empresa && (
                      <div className="row m-t-xs">
                        <div className="col-sm-6">
                          <span>Empresa</span>
                        </div>
                        <div className="col-sm-6">
                        <Link
                          key={`ver${empresa.id}`}
                          to={`/empresas/ver/${empresa.id}/`}>
                          {empresa.nombre}
                        </Link>
                        </div>
                      </div> 
                    )}
                    <div className="row m-t-xs">
                      <div className="col-sm-6">
                        <span>Cuenta Maestra</span>
                      </div>
                      <div className="col-sm-6">
                        <Link
                          key={`ver${objeto.cuenta_maestra_id}`}
                          to={`/cuentas-maestra/ver/${objeto.cuenta_maestra_id}/`}>
                          {objeto.cuenta_maestra}
                        </Link>
                      </div> 
                    </div>
                    {this.renderRow(
                      'UUID',
                      objeto.uuid
                    )}
                    {this.renderRow(
                      'Tipo de descuento',
                      objeto.tipo_descuento 
                    )}
                    {this.renderRow(
                      'Frecuecia de pago',
                      objeto.frecuencia_pago
                    )}
                    {this.renderRow(
                      'Días de gracia',
                      objeto.dias_de_gracia 
                    )}
                    {this.renderRow(
                      'Días de pago (semana)',
                      objeto.dia_pago_semana
                    )}
                    {this.renderRow(
                      'Días de pago (mes)',
                      objeto.dias_pago_mes
                    )}
                    {this.renderRow(
                      'Dias Notificación',
                      objeto.dias_notificacion 
                    )}
                    {this.renderRow(
                      'Capacidad Afectada',
                      `${objeto.capacidad_afectada}%`
                    )}
                    {this.renderRow(
                      'Tasa Pactada',
                      `${objeto.tasa_pactada}%`
                    )}
                    {this.renderRow(
                      'Plazo Mínimo',
                      objeto.plazo_minimo
                    )}
                    {this.renderRow(
                      'Plazo Máximo',
                      objeto.plazo_maximo
                    )}
                    {this.renderRow(
                      'Clabe STP',
                      objeto.clabe_stp
                    )}
                    <div className="row m-t-xs">
                      <div className="col-sm-6">
                        <span>Fecha Creación</span>
                      </div>
                      <div className="col-sm-6">
                      <strong>{dateTimeFormat(objeto.fecha_creacion, 'ddd mmm dd yyyy HH:MM:ss') || '--'}</strong>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            {objeto.productos && objeto.productos.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Productos</h5>
                    </div>
                    {objeto.productos.map(producto => (
                      <div className="ibox-content"  key={`producto${producto.id}`}>
                        <div className="row m-t-xs">
                          <div className="col-sm-6">
                            <h4>
                              <strong>{producto.nombre}</strong>
                            </h4>
                          </div>
                          <div className="col-sm-6">
                            <Link
                              key={`ver${producto.id}`}
                              to={`/productos/ver/${producto.id}/`}
                              className="btn btn-primary btn-sm">
                              <i className="fa fa-eye fa-margin" />
                              Ver Producto
                            </Link>
                          </div>
                        </div>
                        {this.renderRow(
                          'Monto Mínimo',
                          currencyFormat(producto.monto_minimo)
                        )}
                        {this.renderRow(
                          'Monto Máximo',
                          currencyFormat(producto.monto_maximo)
                        )}
                        {this.renderRow(
                          'Tasa de Interés Anual',
                          producto.tasa_interes_anual + '%' 
                        )}
                        {this.renderRow(
                          'Plazo Mínimo',
                          producto.plazo_minimo
                        )}
                        {this.renderRow(
                          'Plazo Máximo',
                          producto.plazo_maximo
                        )}
                        {this.renderRow(
                          'Doumentos',
                          producto.documentos_str
                        )}
                        {this.renderRow(
                          'Estatus',
                          producto.activo ? 'Activo' : 'Inactivo'
                        )}
                        {this.renderRow(
                          'Fecha de Creación',
                          dateTimeFormat(producto.fecha_creacion) 
                        )}
                      </div>
                       ))}
                      
                  </div>
                </div>
              </div>
               )}
               {objeto.productos && objeto.productos.length === 0 && (
                <div className="row">
                       <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Productos</h5>
                    </div>
                    <div className="ibox-content">
                        <div className="info">
                        <span>No hay productos registrados a esta unidad operativa</span>
                        </div>       
                      </div>
                  </div>
                </div>
                </div>
                )}
             {objeto.pagadora && (
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Pagadora</h5>
                    </div> 
                    <div className="ibox-content">
                      {this.renderRow(
                        'UUID',
                        objeto.pagadora.uuid
                      )}
                      {this.renderRow(
                        'Nombre',
                        objeto.pagadora.nombre
                      )}
                      {this.renderRow(
                        'Razón Social',
                        objeto.pagadora.razon_social
                      )}
                      {this.renderRow(
                        'Fecha de Creación',
                        objeto.pagadora.rfc
                      )}
                      {this.renderRow(
                        'Telefono',
                        objeto.pagadora.telefono
                      )}
                      {this.renderRow(
                        'Email',
                        objeto.pagadora.email
                      )}
                      {this.renderRow(
                        'Fecha de Creación',
                        dateTimeFormat(objeto.pagadora.fecha_creacion)
                      )}
                      {this.renderRow(
                        'Fecha de Edición',
                        dateTimeFormat(objeto.pagadora.fecha_edicion)
                      )}
                    </div>         
                  </div>
                </div>
              </div>
              )}
          </div>
        </div>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .sinLogo {
            width: 100%;
            background-color: #ccc;
          }
          .sinLogo:after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
