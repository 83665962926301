import React from 'react';
import OcrINE from './OcrINE';
import OcrPoa from './OcrPoa';
import PLD from './PLD';


const OcrData = ({idSolicitud}) => {
    

    return (
        <>
            <OcrINE idSolicitud={idSolicitud} />
            <OcrPoa idSolicitud={idSolicitud} />
            <PLD idSolicitud={idSolicitud} />
        </>
    );
}
 
export default OcrData;