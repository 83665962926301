import React, { useEffect, useState } from 'react';
// import Modal from 'react-awesome-modal';

import CamposContrato from './CamposContrato';

const GenerarContrato = ({ solicitud, children, cargarDocumentos }) => {
    const [ mostrarCampos, setMostrarCampos ] = useState( false );
    

    return (
        <>
            { solicitud.canal && solicitud.canal.toLowerCase() === 'whirlpool' &&
                <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={ () => setMostrarCampos( !mostrarCampos ) }
                >
                    <span className="bold">Generar Contrato WP</span>
                </button>
            }

            { mostrarCampos
                ? <CamposContrato
                    solicitud={ solicitud }
                    mostrar={ mostrarCampos }
                    setMostrar={ setMostrarCampos }
                    cargarDocumentos={ cargarDocumentos }
                />
                : <>{ children }</>
            }
            
        </>
    );
}
 
export default GenerarContrato;