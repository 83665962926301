import React, { Component } from 'react'
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Autenticado from '../../components/Autenticado'
// import Tabla from '../../components/Tabla'
// import Paginacion from '../../components/Paginacion'
import { exportarReporteCobranza, reporteCobranza } from '../../api/Pagos';
import { /*dateTimeFormat,*/ currencyFormat, dateFormat } from '../../utils/filters';
import { obtenerEmpresasList } from '../../api/Empresas';
import Select from 'react-select'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { mostrarToast } from '../../utils/alerts';
// import { exportarPrestamos } from '../../api/Prestamos';

class Lista extends Component {
    state = {
        titulo: 'Reporte de cobranza',
        objetos: [],
        params: {
            empresa: null,
            tipo: null,
            frecuencia_cobro: null,
            fecha: moment(new Date()).format('YYYY-MM-DD'),
        },
        empresas: [],
        loading: false,
        exportando: false,
    }

    componentDidMount = async() => {
        try {
            await this.getReporteCobranza();
            this.getEmpresas();
        } catch (error) {
            console.log(error)
        }
    }

    async getEmpresas() {
        try {
            const req = await obtenerEmpresasList();
            this.setState({empresas: req.data});
        }catch (e) {
            console.log('Ocurrio un error');
        }
    }

    getReporteCobranza = async () => {
        const {params} = this.state;
        this.setState({loading: true});
        try {
            const req = await reporteCobranza(params);
            const objetos = req.data;
            this.setState({ objetos, loading: false });
        } catch (error) {
            console.log(error);
            mostrarToast('Ocurrió un error en el reporte', 'error');
            this.setState({loading: false});
        }
    }

    handleFecha = async (date) => {
        const {params} = this.state;
        const fecha = moment(date[0]).format('YYYY-MM-DD');
        params.fecha = fecha;
        this.setState({params});
        await this.getReporteCobranza();
    }

    handleEmpresa = async (data) => {
        const {params} = this.state;
        params.empresa = data.value;
        this.setState({params});
        await this.getReporteCobranza();
    }

    handleFiltro = async (e) => {
        const {params} = this.state;
        params[e.target.name] = e.target.value;
        this.setState({params});
        await this.getReporteCobranza();
    }

    exportar = async () =>{
        const {params} = this.state;

        this.setState({exportando: true});
        try {
            const resp = await exportarReporteCobranza( params );
            console.log(resp.data);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `reporte-cobranza-${moment().format('YYYYMMDD')}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
        }catch (e) {
            console.log(e);
        }
        this.setState({exportando: false});
    }

    render () {
        const { titulo, objetos, /*fechaInicio,*/ empresas, params } = this.state
        const guia = [
            { nombre: titulo, url: '', clase: 'active' }
        ]

        let empresasOptions = [];
        empresas.forEach(empresa => {
            empresasOptions.push({label: empresa.nombre, value: empresa.id});
        });

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm m-b-sm"
                                          onClick={this.exportar}
                                          disabled={this.state.exportando}
                                        >
                                            <i className="fa fa-download fa-margin"/>
                                            { this.state.exportando ? 'Exportando...' : 'Exportar' }
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Select
                                          placeholder="Seleccionar empresa"
                                          options={empresasOptions}
                                          noOptionsMessage={()=> 'Sin opciones'}
                                          onChange={this.handleEmpresa}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <select name="tipo" id="" className="form-control form-control-lg" onChange={(e) => this.handleFiltro(e)}>
                                            <option value="">Selecciona el tipo</option>
                                            <option value="emergente">Emergente</option>
                                            <option value="tradicional">Tradicional</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <select name="frecuencia_cobro" id="" className="form-control form-control-lg" onChange={(e) => this.handleFiltro(e)}>
                                            <option value="">Selecciona la frecuencia</option>
                                            <option value="semanal">Semanal</option>
                                            <option value="decenal">Decenal</option>
                                            <option value="catorcenal">Catorcenal</option>
                                            <option value="quincenal">Quincenal</option>
                                            <option value="mensual">Mensual</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <select name="tipo_descuento" id="" className="form-control form-control-lg" onChange={(e) => this.handleFiltro(e)}>
                                            <option value="">Selecciona tipo de descuento</option>
                                            <option value="retencion">Retención</option>
                                            <option value="domiciliacion">Domiciliación</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <Flatpickr
                                            className="form-control date"
                                            value={ moment(params.fecha).format('DD-MM-YY') }
                                            name='fecha'
                                            options={{ dateFormat: 'd-m-Y', locale: Spanish }}
                                            onChange={this.handleFecha}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.loading ?
                                      <div className="spiner-example">
                                          <div className="sk-spinner sk-spinner-wandering-cubes">
                                              <div className="sk-cube1"></div>
                                              <div className="sk-cube2"></div>
                                          </div>
                                      </div>
                                    :
                                      <div className="table-responsive">
                                          <table className="table table-striped">
                                              <thead>
                                              <tr>
                                                  <th>Prestamo</th>
                                                  <th>Empleado</th>
                                                  <th>Empresa</th>
                                                  <th>Tipo</th>
                                                  <th>Frecuencia</th>
                                                  <th>Monto del préstamo</th>
                                                  <th>Fecha sig. pago</th>
                                                  <th>Días de atraso</th>
                                                  <th>Total a pagar</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              { objetos.map(obj => {
                                                  return <tr key={ obj.id }>
                                                      <td>{ obj.folio }</td>
                                                      <td>{ obj.empleado }</td>
                                                      <td>{ obj.empresa }</td>
                                                      <td>{ obj.tipo }</td>
                                                      <td className="text-capitalize">{ obj.frecuencia_cobro }</td>
                                                      <td>{ currencyFormat(obj.monto) }</td>
                                                      <td>{ dateFormat(obj.fecha_siguiente_pago) }</td>
                                                      <td>{ obj.dias_atraso }</td>
                                                      <td>{ currencyFormat(obj.adeudo_fecha) }</td>
                                                  </tr>
                                              })}
                                              </tbody>
                                          </table>
                                      </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .centrado{
                        text-align: center;
                    }
                    .rojo{
                        color: red;
                    }
                    .verde{
                        color: green;
                    }
                    .negritas{
                        font-weight: bold;
                    }
                    .clean-search{
                        position: absolute;
                    }
                    .right {
                        float: right;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Lista
