import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { obtenerUnidadesOperativas, exportarUnidadesOperativas } from '../../api/UnidadesOperativas';
import { dateTimeFormat } from '../../utils/filters';
import { obtenerEmpresasList } from '../../api/Empresas';
import EmpresasSelect from '../../components/EmpresasSelect';
import CuentaMaestraSelect from  '../../components/CuentasMaestraSelect';
import moment from 'moment';

class Lista extends Component {
  state = {
    titulo: 'Unidades Operativas',
    objetos: [],
    totalRecords: 0,
    pageLimit: 20,
    params: {},
    empresaValue : '',
    datos: {
      search: '',
      grupo: '',
      empresa: '',
      cuenta_maestra:''
    },
    ordenarPor: 'nombre',
    tipoDeOrden: '',
    empresas: [],
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const req = await obtenerUnidadesOperativas();
      console.log(req);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      const resp = await obtenerEmpresasList(params);
      console.log(resp)
      const empresas = resp.data.map(obj => {
        return { label: obj.nombre, value: obj.id };
      });
      console.log(empresas)
      this.setState({ objetos, totalRecords, params, empresas });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerUnidadesOperativas(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      console.log(objetos)
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerObjetos = async params => {
    try {
      const req = await obtenerUnidadesOperativas(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async (campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.value;
    datos[campo] = valor;
    this.setState({ datos });
    if (campo === 'cuenta_maestra') {
      const {
        datos: { cuenta_maestra }
      } = this.state;
      delete params.cuenta_maestra;
      delete params.page;
      delete params.empresa;
      params = Object.assign({ cuenta_maestra }, params);
      this.obtenerObjetos(params);
    }
    if (campo === 'empresa') {
      const {
        datos: { empresa }
      } = this.state;
      delete params.empresa;
      delete params.page;
      params = Object.assign({ empresa }, params);
      this.obtenerObjetos(params);
    }
    if (valor === '') {
      await this.limpiar();
    }
  };

  cuentaMaestraChange = async (e) => {
    this.setState({empresaValue: null});
    await this.onChange('cuenta_maestra', e);
  }

  empresaChange = async (e) => {
    this.setState({empresaValue: e});
    await this.onChange('empresa', e);
  }

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: '',
        grupo: ''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(params);
  };

  handleKeyDown = async e => {
    if (e.key === 'Enter') {
      await this.buscar();
    }
  };

  ordenar = async campo => {
    let { ordenarPor, tipoDeOrden } = this.state;
    if (campo === ordenarPor) {
      if (tipoDeOrden === '') {
        tipoDeOrden = '-';
      } else {
        tipoDeOrden = '';
      }
    } else {
      ordenarPor = campo;
      tipoDeOrden = '';
    }
    const { params } = this.state;
    params.ordering = tipoDeOrden + ordenarPor;
    try {
      const req = await obtenerUnidadesOperativas(params);
      const objetos = req.data.results;
      console.log(objetos)
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params, ordenarPor, tipoDeOrden });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  exportar = async () => {
    const { params } = this.state;

    try {
      const resp = await exportarUnidadesOperativas( params );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `yuhu_unidades_operativas_${moment().format('YYYYMMDD')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }

    delete params.export;

  };

  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      datos: { search }
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                <div className="row actions-buttons-row m-b-sm">
                  <div className="col-sm-3">
                    <button onClick={this.exportar} className="btn btn-sm btn-primary fa-margin">
                      <i className="fa fa-download fa-margin" />
                      Exportar
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <CuentaMaestraSelect 
                      onChange={this.cuentaMaestraChange} 
                    />
                  </div>
                  <div className="col-sm-3">
                    <EmpresasSelect 
                      onChange={this.empresaChange}
                      cuenta_maestra={this.state.datos.cuenta_maestra}
                      value={this.state.empresaValue}
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control"
                        onChange={e => this.onChange('search', e.target)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Buscar"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          Nombre
                        </th>
                        <th>
                          UUID
                        </th>
                        <th>
                         Cuenta Maestra
                        </th>
                        <th>
                          Empresa
                        </th>
                        <th>
                          Fecha creación
                        </th>
                        <th>
                          Productos
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {objetos.map(obj => {
                        return (
                          <tr key={obj.id}>
                            <td>{obj.nombre}</td>
                            <td>{obj.uuid}</td>
                            <td>{obj.cuenta_maestra}</td>
                            <td>{obj.empresa}</td>
                            <td>{dateTimeFormat(obj.fecha_creacion)}</td>
                            {obj.productos_count === 0 ? 
                              <td className="text-danger text-center">{obj.productos_count}</td>:
                              <td className="text-center">{obj.productos_count}</td>}
                            <td>
                              <Link
                                key={`ver${obj.id}`}
                                to={`/unidades-operativas/ver/${obj.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-eye fa-margin" />
                                Ver
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
          .fa {
            margin-left: 10px;
          }
          .manita:hover {
            cursor: pointer;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
