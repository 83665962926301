import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerEstados() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/estados/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerMunicipios(estado_id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/estados/${estado_id}/municipios/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerBancos() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/bancos/`);
  } catch (err) {
    callError(err);
  }
}

async function ObtenerMotivosRechazo() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/motivos_rechazo/`)
  } catch (err) {
    callError(err)
  }
}

export {
  obtenerEstados,
  obtenerMunicipios,
  obtenerBancos,
  ObtenerMotivosRechazo
};
