import React, { Component } from 'react'
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado'
import Formulario from '../../components/form/Form'
import {
    ConsultarFechaExpiracionBuroCredito
} from '../../api/Integraciones'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        data: {},
        errors: [],
    }

    mostrarAlerta = (mensaje, tipo = 'info') => {
        Swal.fire({
            type: tipo,
            html: mensaje,
        })
    }

    componentWillMount() {
        const titulo = 'Integraciones'
        const accion = 'Consultar Fecha Expiracion de Buró de Crédito'
        this.setState({ titulo, accion })
    }

    render () {
        const { accion, titulo } = this.state
        const guia = [
            { nombre: 'Integraciones', url: '', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]

        const form = {
            button:{
                label:'Consultar',
                onSubmit: async (event, values) => {
                    try {
                        const resp = await ConsultarFechaExpiracionBuroCredito(values);
                        const consulta = resp.data;
                        const data = consulta.data
                        const keys = Object.keys(data)
                        console.log(keys)
                        let text = ''
                        keys.forEach((key, index) => {
                            text += `<b>${key}:</b> ${data[key]}<br>`;
                        });
                        console.log(text)
                        this.mostrarAlerta(text)
                    } catch (error) {
                        const errors = error.data
                        this.setState({ errors })
                    }
                }
            },
            fields:[{
                name:'member',
                label:'Usuario *',
                type:'text',
                helpText:''
            },{
                name:'password',
                label:'Contraseña *',
                type:'text',
                helpText:''
            }],
            data: this.state.data,
            errors: this.state.errors
        }

        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <Formulario
                                    buttonLabel={ form.button.label }
                                    onSubmit={ form.button.onSubmit }
                                    fields={ form.fields }
                                    data={ form.data }
                                    errors={ form.errors }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Autenticado>
        )
    }
}

export default Form
