import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
// import Formulario from '../../components/form/Form';
import {
  actualizarProducto,
  agregarProducto,
  obtenerProducto
} from '../../api/Productos';
// import { obtenerEmpresas } from '../../api/Empresas';
import { obtenerUnidadesOperativasList } from '../../api/UnidadesOperativas';
import { obtenerDocumentos } from '../../api/Documentos';
import { obtenerPlazosFijos } from '../../api/Productos';
import SelectMultiple from '../../components/form/fields/SelectMultiple';
// import Select from 'react-select';
import { Link } from 'react-router-dom';

class Form extends Component {
  state = {
    id: null,
    titulo: null,
    data: {
      nombre: '',
      monto_minimo: '',
      monto_maximo: '',
      tasa_interes_anual: '',
      frecuencia_cobro_dinamico: false,
      frecuencia_cobro: '',
      plazos_fijos: true,
      plazo_minimo: '',
      plazo_maximo: '',
      activo: true,
      empresas: [],
      unidades_operativas: [],
      documentos: []
    },
    empresas: [{ label: 'Seleccionar...', value: '' }],
    unidades_operativas: [{label: 'Seleccionar...', value: '' }],
    documentos: [{ label: 'Seleccionar...', value: '' }],
    plazosFijos: {},
    plazosFijosProducto: [],
    errors: {},
  };

  componentWillMount = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const titulo = id ? 'Editar producto' : 'Agregar producto';
    this.setState({ id, titulo });
  };

  componentDidMount = async () => {
    const { id } = this.state;
    let data = {
      nombre: '',
      monto_minimo: '',
      monto_maximo: '',
      tasa_interes_anual: '',
      frecuencia_cobro_dinamico: false,
      frecuencia_cobro: '',
      plazos_fijos: true,
      plazo_minimo: '',
      plazo_maximo: '',
      activo: true,
      empresas: [],
      unidades_operativas: [],
      documentos: [],
      leyenda_cat: '',
    };

    if (id) {
      try {
        const resp = await obtenerProducto(id);
        data = resp.data;
      } catch (error) {
        console.log(error);
      }
    }

    const empresas = [];
    const unidades_operativas = [];
    // let empresasApi = [];
    let unidadesOperativasAPI = [];
    const documentos = [];
    let documentosApi = [];
    let plazosFijos = [];
    const promesas = [];
    // promesas.push(obtenerEmpresas({page_size: 1000}));
    promesas.push(obtenerUnidadesOperativasList({page_size: 1000}));
    promesas.push(obtenerDocumentos());
    promesas.push(obtenerPlazosFijos());
    try {
      const [/*respEmpresas,*/ respUnidadesOperativas, respDocs, respPlazosFijos] = await Promise.all(promesas);
      // empresasApi = respEmpresas.data.results;
      // empresasApi.forEach(emp => {
      //   empresas.push({ label: emp.nombre, value: emp.id });
      // });

      unidadesOperativasAPI = respUnidadesOperativas.data;
      unidadesOperativasAPI.forEach(uo => {
        unidades_operativas.push({ label: uo.nombre, value: uo.id });
      });

      documentosApi = respDocs.data;
      documentosApi.forEach(doc => {
        documentos.push({ label: doc.nombre, value: doc.id });
      });

      plazosFijos = respPlazosFijos.data;

    } catch (err) {
      console.log('Error al consumir api:', err);
    }

    this.setState({
      data,
      empresas,
      unidades_operativas,
      documentos,
      plazosFijos
    });

    this.setPlazosFijos();
  };

  handleChange = (e) => {
    const { data/* , plazosFijos */ } = this.state;

    if (e.target.type === 'checkbox'){
      if (e.target.checked === true){
        data[e.target.name] = true;
      }else {
        data[e.target.name] = false;
      }
    } else{
      data[e.target.name] = e.target.value;
    }

    this.setState({data});

    this.setPlazosFijos();
  }

  handleChangeSelectMultiple = (field, value) => {
    const { data } = this.state;
    const values = [];
    if (value){
      value.forEach(element => {
        values.push(element.value);
      });
    }
    data[field] = values;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    const { id, data } = this.state;
    const { history } = this.props;
    if (id) {
      try {
        await actualizarProducto(data, id);
        this.setState({ errors: [] });
        history.push('/productos');
      } catch (error) {
        const respErrors = error.data;
        this.setState({ errors: respErrors });
      }
    } else {
      try {
        await agregarProducto(data);
        this.setState({ errors: [] });
        history.push('/productos');
      } catch (error) {
        const respErrors = error.data;
        this.setState({ errors: respErrors });
      }
    }
  }

  setPlazosFijos(){
    const {data, plazosFijos} = this.state;
    // Definimos los plazos fijos
    let plazosFijosProducto = [];
    if (data.plazos_fijos) {
      switch (data.frecuencia_cobro) {

        case 'semanal':
          plazosFijosProducto = plazosFijos.plazos_semanales;
          break;

        case 'decenal':
          plazosFijosProducto = plazosFijos.plazos_decenales;
          break;

        case 'catorcenal':
          plazosFijosProducto = plazosFijos.plazos_catorcenales;
          break;

        case 'quincenal':
          plazosFijosProducto = plazosFijos.plazos_quincenales;
          break;

        default:
          plazosFijosProducto = plazosFijos.plazos_mensuales;
      }
    }

    this.setState({plazosFijosProducto});
  }


  getForm = () => {
    const { data, errors, /*empresas,*/ unidades_operativas, documentos, /* plazosFijos, */ plazosFijosProducto } = this.state;



    return (
      <div className="row">

        <div className="col-sm-12">
          <div className={`form-group row ${errors.nombre && errors.nombre.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-2 col-form-label">Nombre</label>
            <div className="col-sm-10">
              <input type="text" name="nombre" className="form-control" value={data.nombre || ''} onChange={(e) => this.handleChange(e)} />
              { errors.nombre && errors.nombre.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.monto_minimo && errors.monto_minimo.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Monto mínimo</label>
            <div className="col-sm-8">
              <input type="text" name="monto_minimo" className="form-control" value={data.monto_minimo || ''} onChange={(e) => this.handleChange(e)} />
              { errors.monto_minimo && errors.monto_minimo.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.monto_maximo && errors.monto_maximo.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Monto máximo</label>
            <div className="col-sm-8">
              <input type="text" name="monto_maximo" className="form-control" value={data.monto_maximo || ''} onChange={(e) => this.handleChange(e)} />
              { errors.monto_maximo && errors.monto_maximo.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.tasa_interes_anual && errors.tasa_interes_anual.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Tasa de interés anual</label>
            <div className="col-sm-8">
              <input type="text" name="tasa_interes_anual" className="form-control" value={data.tasa_interes_anual || ''} onChange={(e) => this.handleChange(e)} />
              { errors.tasa_interes_anual && errors.tasa_interes_anual.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.frecuencia_cobro_dinamico && errors.frecuencia_cobro_dinamico.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Frecuencia de cobro dinámico</label>
            <div className="col-sm-8 center-vertical">
              <input type="checkbox" name="frecuencia_cobro_dinamico" className="form-control" value={data.frecuencia_cobro_dinamico || ''} onChange={(e) => this.handleChange(e)} checked={data.frecuencia_cobro_dinamico} />
              { errors.frecuencia_cobro_dinamico && errors.frecuencia_cobro_dinamico.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.frecuencia_cobro && errors.frecuencia_cobro.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Frecuencia de cobro</label>
            <div className="col-sm-8">
              <select name="frecuencia_cobro" className="form-control" value={data.frecuencia_cobro || ''} onChange={(e) => this.handleChange(e)} >
                <option value="">Seleccionar...</option>
                <option value="semanal">Semanal</option>
                <option value="decenal">Decenal</option>
                <option value="catorcenal">Catorcenal</option>
                <option value="quincenal">Quincenal</option>
                <option value="mensual">Mensual</option>
              </select>
              { errors.frecuencia_cobro && errors.frecuencia_cobro.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.plazos_fijos && errors.plazos_fijos.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Plazos fijos</label>
            <div className="col-sm-8 center-vertical">
              <input type="checkbox" name="plazos_fijos" className="form-control" value={data.plazos_fijos || ''} onChange={(e) => this.handleChange(e)} checked={data.plazos_fijos} />
              { errors.plazos_fijos && errors.plazos_fijos.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.plazo_minimo && errors.plazo_minimo.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Plazo mínimo</label>
            <div className="col-sm-8">
              { plazosFijosProducto.length > 0 ?
                <select name="plazo_minimo" className="form-control" value={data.plazo_minimo || ''} onChange={(e) => this.handleChange(e)} >
                  <option value="">Selecciona un plazo</option>
                  { plazosFijosProducto.map((plazo, index) => {
                    return <option key={`plazomin-${plazo}`} value={plazo}>{ plazo }</option>
                  }) }
                </select>
                :
                <input type="text" name="plazo_minimo" className="form-control" value={data.plazo_minimo || ''} onChange={(e) => this.handleChange(e)} />
              }
              { errors.plazo_minimo && errors.plazo_minimo.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.plazo_maximo && errors.plazo_maximo.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Plazo máximo</label>
            <div className="col-sm-8">
              { plazosFijosProducto.length > 0 ?
                <select name="plazo_maximo" className="form-control" value={data.plazo_maximo || ''} onChange={(e) => this.handleChange(e)} >
                  <option value="">Selecciona un plazo</option>
                  { plazosFijosProducto.map((plazo, index) => {
                    return <option key={`plazomax-${plazo}`} value={plazo}>{ plazo }</option>
                  }) }
                </select>
                :
                <input type="text" name="plazo_maximo" className="form-control" value={data.plazo_maximo || ''} onChange={(e) => this.handleChange(e)} />
              }
              { errors.plazo_maximo && errors.plazo_maximo.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        {/* <SelectMultiple
          options={empresas}
          label="Empresas"
          error={errors.empresa || []}
          name="empresas"
          value={data.empresas || []}
          onChange={(e)=>this.handleChangeSelectMultiple('empresas', e)}
        /> */}

        <SelectMultiple
          options={unidades_operativas}
          label="Unidades Operativas"
          error={errors.unidades_operativas || []}
          name="unidades_operativa"
          value={data.unidades_operativas || []}
          onChange={(e)=>this.handleChangeSelectMultiple('unidades_operativas', e)}
        />

        <SelectMultiple
          options={documentos}
          label="Documentos"
          error={errors.documentos || []}
          name="documentos"
          value={data.documentos || []}
          onChange={(e)=>this.handleChangeSelectMultiple('documentos', e)}
        />

        <div className="col-sm-6">
          <div className={`form-group row ${errors.leyenda_cat && errors.leyenda_cat.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Leyenda CAT</label>
            <div className="col-sm-8">
              <input type="text" name="leyenda_cat" className="form-control" value={data.leyenda_cat || ''} onChange={(e) => this.handleChange(e)} />
              <span className="form-text m-b-none">Leyenda que se muestra en la cotización del producto</span>
              { errors.leyenda_cat && errors.leyenda_cat.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className={`form-group row ${errors.activo && errors.activo.length > 0 ? 'has-error' : ''}`}>
            <label className="col-sm-4 col-form-label">Activo</label>
            <div className="col-sm-8 center-vertical">
              <input type="checkbox" name="activo" className="form-control" value={data.activo || false} onChange={(e) => this.handleChange(e)} checked={data.activo} />
              { errors.activo && errors.activo.map( error => {
                return (<span className="form-text mb-none text-error">{ error }</span>)
              }) }
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <Link to='/productos/' className="btn btn-sm btn-default m-r-sm">Cancelar</Link>
          <button type="button" className="btn btn-primary btn-sm" onClick={(e) => this.handleSubmit(e)}>Guardar</button>
        </div>

      </div>
    )
  }

  render() {
    const {
      id,
      titulo
    } = this.state;
    // const { history } = this.props;

    const guia = [
      { nombre: 'Productos', url: '/productos', clase: '' },
      { nombre: id ? 'Editar' : 'Agregar', url: '', clase: 'active' }
    ];

    return (
      <Autenticado titulo={titulo} guia={guia}>

        <div className="row">
          <div className="col-lg-12">
            <div className="ibox">
              <div className="ibox-content">

                { this.getForm() }

              </div>
            </div>
          </div>
        </div>

      </Autenticado>
    );
  }
}

export default Form;
