import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function dashboardEmpleados(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/dashboard/empleados/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function dashboardMontoPrestado(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/dashboard/monto-prestado/', {
      params: parametros
    });
  } catch (err) {
    callError(err);
  }
}

async function dashboardMontoEmergente(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/dashboard/monto-prestado-emergente/', {
      params: parametros
    });
  } catch (err) {
    callError(err);
  }
}

async function dashboardPagadoCobrar(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/dashboard/por-cobrar/', {
      params: parametros
    });
  } catch (err) {
    callError(err);
  }
}

async function dashboardSaldo() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/dashboard/emida-saldo/');
  } catch (err) {
    callError(err);
  }
}

async function dashboardactualizarSaldo() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put('/admin/dashboard/emida-saldo/');
  } catch (err) {
    callError(err);
  }
}

async function dashboardMontos() {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/dashboard/montos/');
  } catch (err) {
    callError(err);
  }
}

export {
  dashboardEmpleados,
  dashboardMontoPrestado,
  dashboardMontoEmergente,
  dashboardPagadoCobrar,
  dashboardSaldo,
  dashboardMontos,
  dashboardactualizarSaldo
};
