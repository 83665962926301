import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import { obtenerGrupo, eliminarGrupo } from '../../api/Grupos'
import { obtenerPermisos } from '../../api/Permisos'

class Form extends Component {
    state = {
        id: null,
        datos: {},
        grupos: [],
        permisosGrupo: []
    }

    componentWillMount() {
        const id = this.props.match.params.id
        this.setState({ id })
    }

    async componentDidMount() {
        const { id } = this.state

        let resp = await obtenerPermisos()
        const permisos = resp.data
        let gruposPermisos = permisos.map((permission) => {
            return permission.content_type
        })
        gruposPermisos = [...new Set(gruposPermisos)]

        const grupos = []
        gruposPermisos.forEach((obj) => {
            const grupo = {
                name: obj,
                permissions: permisos.filter(p => p.content_type === obj)
            }
            grupos.push(grupo)
        })
        this.setState({ grupos })

        if (id){
            try {
                resp = await obtenerGrupo(id)
                const datos = resp.data
                const permisosGrupo = datos.permisos
                this.setState({ datos, permisosGrupo })
            } catch (error) {
                console.log(error)
            }
        }
    }

    renderRowBool = (label, value) => (
        <div className="row pb5">
            <div className="col-sm-8">
                <span>{label}</span>
            </div>
            <div className="col-sm-4">
                {value ? (
                    <i className="fa fa-check fa-fw" />
                ) : (
                    <i className="fa fa-times fa-fw" />
                )}
            </div>
        </div>
    )

    eliminar = async() => {
        const { id } = this.state
        try {
            await eliminarGrupo(id)
            this.props.history.push('/grupos')
        } catch (error) {
            console.log(error)
        }
    }

    buscarPermiso(id){
        const { permisosGrupo } = this.state
        const indice = permisosGrupo.findIndex(o => o === id)
        return indice !== -1
    }

    render () {
        const { datos, grupos } = this.state

        const guia = [
            { nombre: 'Grupos', url: '/grupos', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        return (
            <Autenticado titulo="Ver grupo" guia={ guia }>
                {/* <h2>{ datos.nombre }</h2> */}
                <div className="row">
                    <div className="col-lg-9">
                        <h2>{ datos.nombre }</h2>
                    </div>
                    <div className="col-lg-3">
                        <button
                            type="button"
                            className="btn btn-warning btn-sm btns-space right"
                            onClick={this.eliminar}
                        >
                            <i className="fa fa-trash fa-margin" /> Eliminar
                        </button>
                    </div>
                </div>
                <div className="row">
                    {grupos.map((grupo, indice) => {
                        return (
                            <div className="col-lg-4" key={ indice }>
                                <div className="ibox">
                                    <div>
                                        <h2>{ grupo.name }</h2>
                                        <ul className="todo-list m-t small-list">
                                            {grupo.permissions.map(permiso => {
                                                return (
                                                    <li key={ permiso.id }>
                                                        {this.renderRowBool( permiso.name, this.buscarPermiso(permiso.id) )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Autenticado>
        )
    }
}

export default Form
