import React, { Component } from 'react';
import NoAutenticado from '../components/NoAutenticado';
import { confirmarResetearContrasena } from '../api/Auth';
import { cargando, mostrarToast, cerrarAlertas } from '../utils/alerts';

export class ResetarContrasena extends Component {
  state = {
    password: '',
    confirmarPassword: '',
    id: '',
    uuid: '',
    errores: {}
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { id, uuid }
      }
    } = this.props;
    this.setState({ id, uuid });
  };

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState({ [name]: value });
  };

  validar = () => {
    let validado = true;
    const errores = {};
    const { password, confirmarPassword } = this.state;

    if (password.trim().length === 0) {
      validado = false;
      errores.password = ['Este campo es requerido.'];
    }
    if (confirmarPassword.trim().length === 0) {
      validado = false;
      errores.confirmarPassword = ['Este campo es requerido.'];
    } else if (password !== confirmarPassword) {
      validado = false;
      errores.confirmarPassword = ['No coinciden las contraseñas.'];
    }

    this.setState({ errores });
    return validado;
  };

  resetearContrasena = async () => {
    if (!this.validar()) {
      return;
    }
    cargando();
    try {
      const { id, uuid, password } = this.state;
      await confirmarResetearContrasena(id, uuid, password);
      cerrarAlertas();
      mostrarToast('Contraseña reiniciada exitosamente.');
      this.props.history.push('/iniciar-sesion');
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast(error.message, 'error');
      }
    }
  };

  render() {
    const { errores } = this.state;
    return (
      <NoAutenticado>
        <div>
          <div>
            <h3 className="logo-name">yuhu</h3>
          </div>
          <h3>Bienvenido a Yuhu Admin</h3>
          <p>Ingresa tu nueva contraseña</p>
          <div className="m-t">
            <div className="form-group">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="ingresa tu nueva contraseña"
                onChange={this.handleChange}
              />
              {errores.password &&
                errores.password.map((obj, index) => (
                  <div key={`pe${index}`} className="invalid-feedback">
                    {obj}
                  </div>
                ))}
            </div>
            <div className="form-group">
              <input
                name="confirmarPassword"
                type="password"
                className="form-control"
                placeholder="confirma tu nueva contraseña"
                onChange={this.handleChange}
              />
              {errores.confirmarPassword &&
                errores.confirmarPassword.map((obj, index) => (
                  <div key={`cpe${index}`} className="invalid-feedback">
                    {obj}
                  </div>
                ))}
            </div>
            {errores.non_field_errors &&
              errores.non_field_errors.map((obj, index) => (
                <div key={`pe${index}`} className="invalid-feedback mb1">
                  {obj}
                </div>
              ))}
            <button
              type="button"
              className="btn btn-primary block full-width m-b"
              onClick={this.resetearContrasena}
            >
              reiniciar contraseña
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .ml1 {
            margin-left: 1rem;
          }
          .mb1 {
            margin-bottom: 1rem;
          }
          .invalid-feedback {
            display: block;
            text-align: left;
          }
        `}</style>
      </NoAutenticado>
    );
  }
}

export default ResetarContrasena;
