import { ConstructorAPIAsync } from './API';
import { callError } from './Error';
import axios from 'axios';

async function SepomexAPIAsync(timeout = 10000, responseType = 'json') {
  const headers = {
    'Authorization': 'Api-Key A6OEO5r2.ULtOtRsAMyEwxJh0eTf2Mar4L8zbCuJR',
    // 'Authorization': 'Api-Key iDDFYCHd.ZL1gJai6IS0f3ebk1js6HxVqvdQ6TjYu',
    'Content-Type': 'application/json',
    Accept: '*/*'
  };

  const instance = await axios.create({
    baseURL: "https://apidev.yuhu.mx",
    // baseURL: "http://127.0.0.1:8000",
    headers,
    responseType,
    responseEncoding: 'utf8',
    timeout,
    type: 'json'
  });

  return instance;
}

async function obtenerEstadosSepomex() {
  const API = await SepomexAPIAsync();
  try {
    return await API.get('/sepomex/estados-sepomex/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerMunicipiosSepomex(estado_uuid) {
  const API = await SepomexAPIAsync();
  try {
    return await API.get(`/sepomex/estados-sepomex/${estado_uuid}/municipios-sepomex/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerEstadoMunicipiosSepomex(codigo_postal) {
  const API = await SepomexAPIAsync();
  try {
    return await API.get(`/sepomex/codigo-postal-sepomex/${codigo_postal}/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerColoniasSepomex(parametros='') {
  const API = await SepomexAPIAsync();
  try {
    return await API.get('/sepomex/colonias-sepomex/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function importarDatos(datos) {
  const API = await ConstructorAPIAsync(5000000);
  try {
    return await API.post('/sepomex/importador/', datos);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerEstadosSepomex,
  obtenerMunicipiosSepomex,
  obtenerEstadoMunicipiosSepomex,
  obtenerColoniasSepomex,
  importarDatos,
};
