import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerEmpresasPromotores(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/promotores/empresas/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerEmpleadosDeEmpresa(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/promotores/empleados/`, { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerProductosEmpleado(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`promotores/empleados/${id}/productos-disponibles/`);
  } catch (err) {
    callError(err);
  }
}

async function cotizarProductoPromotor(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`promotores/cotizar-credito/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function crearSolicitudPromotor(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/promotores/crear-solicitud/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerReferenciasEmpleado(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/promotores/solicitud-credito/${id}/referencias/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarReferenciasEmpleado(id, datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(
      `/promotores/solicitud-credito/${id}/referencias/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function editarReferenciasEmpleado(id, datos, idRef) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(
      `/promotores/solicitud-credito/${id}/referencias/${idRef}/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function eliminarReferenciasEmpleado(id, datos, idRef) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/promotores/solicitud-credito/${id}/referencias/${idRef}/`
    );
  } catch (err) {
    callError(err);
  }
}

async function obtenerInfoCrediticia(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(
      `/promotores/solicitud-credito/${id}/informacion-crediticia/`
    );
  } catch (err) {
    callError(err);
  }
}

async function agregarInfoCrediticia(id, datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(
      `/promotores/solicitud-credito/${id}/informacion-crediticia/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function obtenerComprobantes(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/promotores/solicitud-credito/${id}/comprobantes/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarComprobante(id, datos, idComprobante) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.put(
      `/promotores/solicitud-credito/${id}/comprobantes/${idComprobante}/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function terminarSolicitud(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(
      `/promotores/solicitud-credito/${id}/terminar-solicitud/`
    );
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerEmpresasPromotores,
  obtenerEmpleadosDeEmpresa,
  obtenerProductosEmpleado,
  cotizarProductoPromotor,
  crearSolicitudPromotor,
  obtenerReferenciasEmpleado,
  agregarReferenciasEmpleado,
  editarReferenciasEmpleado,
  eliminarReferenciasEmpleado,
  obtenerInfoCrediticia,
  agregarInfoCrediticia,
  obtenerComprobantes,
  agregarComprobante,
  terminarSolicitud
};
