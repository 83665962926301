import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerReporteNip(params = {}) {
    const API = await ConstructorAPIAsync();
    try {
      return await API.get('/buro-credito/solicitudes/reporte-nip/', { params });
    } catch (err) {
      callError(err);
    }
  }

  async function exportarReporteNip(params = {}) {
    params.export = true;
    const API = await ConstructorAPIAsync(120000, 'blob');
    try {
      const resp = await API.get('/buro-credito/solicitudes/reporte-nip/', { params });
      delete params.export;
      return resp;
    } catch (err) {
      delete params.export;
      callError(err);
    }
  }

  export {
      obtenerReporteNip,
      exportarReporteNip,
  }