import React, {useState, useEffect} from 'react';
import Modal from 'react-awesome-modal';
import { generarCartaBuro, enviarCartaBuroFirmamex } from '../../api/Solicitudes';

const DescargarCarta = ({linkCarta, solicitud, children}) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await enviarCartaBuroFirmamex(solicitud.id);
            setSuccess(true);
        } catch(err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            <style jsx="true">{`
                form{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .button-flex {
                    margin: 0 10px;
                }
                h3 {
                    text-align: center;
                }
            `}</style>
            <div className="modal-body">
                <h3>La carta de buró se ha creado con éxito</h3>
                {success && <p className="text-center text-success">La carta ha sido enviada</p>}
                <br />
                <form
                    onSubmit={handleSubmit}
                >
                    <a href={linkCarta} className="btn btn-info button-flex" target="_blank" rel="noopener noreferrer">Descargar Carta</a>
                    {loading ? 
                        <button disabled className="btn btn-primary button-flex" type="button">Enviando...</button> :
                        <button className="btn btn-primary button-flex" type="submit">Enviar Carta por firmamex</button>
                    }
                </form>
                
            </div>
            <div className="modal-footer">
                {children}
            </div>
        </>
    );
}


const CartaBuro = ({visible = false, title, solicitud, children}) => {

    const [dataBuro, setDataBuro] = useState({
        nombre: '',
        domicilio: '',
        curp: '',
        telefono_celular: '',
        funcionario: 'Jean Pierre Salas Gonzalez'
    });

    const [linkCartaBuro, setLinkCartaBuro] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const updateForm = (e) => {
        setDataBuro({
            ...dataBuro,
            [e.target.name]: e.target.value
        })
    }

    const {nombre, domicilio, curp, telefono_celular, funcionario} = dataBuro;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try{
            const response = await generarCartaBuro(dataBuro, solicitud.id);
            setLinkCartaBuro(response.data.url_carta_buro);
            
        } catch(err) {
            setErrors(err.data);
            setLoading(false)
            return;
        }


        setDataBuro({
            nombre: '',
            domicilio: '',
            curp: '',
            telefono_celular: '',
            funcionario: 'Jean Pierre Salas Gonzalez'
        });
        setLoading(false);
    }

    const replaceSpacesWhite = (field) => field !== '--' ? field : '';
    
    const normalizeTelephone = (tel) => {
        if (replaceSpacesWhite(tel) === '') {
            return tel
        } else if (tel.length === 12 && tel.startsWith('52')) {
            return tel.slice(2)
        }
        
        return tel
    }

    useEffect(() => {
        if (solicitud.hasOwnProperty('empleado')) {
            const {nombre, apellido_paterno, apellido_materno, curp, telefono_celular, domicilio_buro} = solicitud.empleado
            setDataBuro({
                ...dataBuro,
                nombre: `${nombre} ${apellido_paterno} ${apellido_materno}`,
                domicilio: replaceSpacesWhite(domicilio_buro),
                curp: replaceSpacesWhite(curp),
                telefono_celular: normalizeTelephone(telefono_celular)
            });
        }
    }, [solicitud])


    return (
        <Modal
            visible={ visible }
            width="60%"
            effect="fadeInUp"
        >
            <div className="modal-content">
             
                <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>  
                </div>
                {
                    linkCartaBuro ?
                        <DescargarCarta
                            linkCarta={linkCartaBuro}
                            solicitud={solicitud}
                        >
                            {children}
                        </DescargarCarta> :

                        <form
                            onSubmit={handleSubmit}
                        >

                            <style jsx="true">{`
                                .has-error {
                                    border-color: #ed5565;
                                }
                            `}</style>
                            
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row false">
                                            <label className="col-sm-4 col-form-label">Nombre</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    name="nombre"
                                                    className={`form-control ${errors.nombre ? 'has-error' : ''}`}
                                                    onChange={updateForm}
                                                    value={nombre}
                                                />
                                                
                                                {errors.nombre && errors.nombre.map((err, index) => <span key={index} className="form-text m-b-none text-error">{err}</span>)}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row false">
                                            <label className="col-sm-4 col-form-label">Domicilio</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    name="domicilio"
                                                    className={`form-control ${errors.domicilio ? 'has-error' : ''}`}
                                                    onChange={updateForm}
                                                    value={domicilio}
                                                />
                                                {errors.domicilio && errors.domicilio.map((err, index) => <span key={index} className="form-text m-b-none text-error">{err}</span>)}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row false">
                                            <label className="col-sm-4 col-form-label">CURP</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    name="curp"
                                                    className={`form-control ${errors.curp ? 'has-error' : ''}`}
                                                    onChange={updateForm}
                                                    value={curp}
                                                />
                                                {errors.curp && errors.curp.map((err, index) => <span key={index} className="form-text m-b-none text-error">{err}</span>)}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row false">
                                            <label className="col-sm-4 col-form-label">Teléfono Celular</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    name="telefono_celular"
                                                    className={`form-control ${errors.telefono_celular ? 'has-error' : ''}`}
                                                    onChange={updateForm}
                                                    value={telefono_celular}
                                                />
                                                {errors.telefono_celular && errors.telefono_celular.map((err, index) => <span key={index} className="form-text m-b-none text-error">{err}</span>)}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group row false">
                                            <label className="col-sm-4 col-form-label">Funcionario</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    name="funcionario"
                                                    className={`form-control ${errors.funcionario ? 'has-error' : ''}`}
                                                    onChange={updateForm}
                                                    value={funcionario}
                                                />
                                                {errors.funcionario && errors.funcionario.map((err, index) => <span key={index} className="form-text m-b-none text-error">{err}</span>)}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div className="modal-footer">
                                {children}
                            
                                {linkCartaBuro ?
                                    <a href={linkCartaBuro} className="btn btn-info" target="_blank" rel="noopener noreferrer">Descargar Carta</a> : 
                                    loading ?
                                        <button className="btn btn-primary" disabled>Cargando...</button> :
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                        >
                                            Generar
                                        </button>
                                }
                            </div>
                        </form>
        
                }
            </div> 
        </Modal>
    );
}


export default CartaBuro;