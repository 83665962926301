import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Paginacion from '../../components/Paginacion';
import { obtenerProductos } from '../../api/Productos';
// import { obtenerEmpresas } from '../../api/Empresas';
import { obtenerUnidadesOperativasList } from '../../api/UnidadesOperativas';
import { currencyFormat } from '../../utils/filters';
import { verificarPermiso } from '../../utils/auth'

class Lista extends Component {
  state = {
    titulo: 'Productos',
    objetos: [],
    totalRecords: 0,
    pageLimit: 50,
    params: {},
    empresas: [],
    unidadesOperativas: [],
    datos: {
      empresa: '',
      search: ''
    }
  };

  async componentDidMount() {
    try {
      const { pageLimit } = this.state;
      const params = { page_size: pageLimit };
      const promesas = [obtenerProductos(params), obtenerUnidadesOperativasList({page_size:1000})/*obtenerEmpresas({page_size: 1000})*/];
      const [req, resp] = await Promise.all(promesas);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      // const empresas = resp.data.results.map(obj => {
      //   return { label: obj.nombre, value: obj.id };
      // });
      const unidadesOperativas = resp.data.map(obj => {
        return { label: obj.nombre, value: obj.id };
      });
      this.setState({ objetos, totalRecords, params, /*empresas,*/ unidadesOperativas });
    } catch (error) {
      console.log(error);
    }
  }

  onPageChanged = async data => {
    const page = data.currentPage;
    const { pageLimit, params } = this.state;
    params.page = page;
    params.page_size = pageLimit;
    try {
      const req = await obtenerProductos(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  obtenerObjetos = async params => {
    try {
      const req = await obtenerProductos(params);
      const objetos = req.data.results;
      const totalRecords = req.data.count;
      this.setState({ objetos, totalRecords, params });
    } catch (error) {
      console.log(error);
    }
  };

  buscar = async () => {
    let { params } = this.state;
    const {
      datos: { search }
    } = this.state;
    delete params.search;
    delete params.page;
    params = Object.assign({ search }, params);
    this.obtenerObjetos(params);
  };

  onChange = async(campo, e) => {
    const { datos } = this.state;
    let { params } = this.state;
    const valor = e.target.value;
    datos[campo] = valor;
    this.setState({ datos });
    // if (campo === 'empresa') {
    //   const {
    //     datos: { empresa }
    //   } = this.state;
    //   delete params.empresas;
    //   delete params.page;
    //   params = Object.assign({ empresas: empresa }, params);
    //   this.obtenerObjetos(params);
    // }
    if (campo === 'unidad_operativa') {
      const {
        datos: { unidad_operativa }
      } = this.state;
      delete params.unidades_operativas;
      delete params.page;
      params = Object.assign({ unidades_operativas: unidad_operativa }, params);
      this.obtenerObjetos(params);
    }
    if (valor === '') {
      await this.limpiar()
    }
  };

  limpiar = async () => {
    this.setState({
      params: {},
      datos: {
        search: '',
        empresa: ''
      }
    });
    const { pageLimit } = this.state;
    const params = { page_size: pageLimit };
    this.obtenerObjetos(params);
  };

  handleKeyDown = async(e) => {
    if (e.key === 'Enter') {
      await this.buscar()
    }
  }

  render() {
    const {
      titulo,
      objetos,
      totalRecords,
      pageLimit,
      // empresas,
      unidadesOperativas,
      datos: { /*empresa,*/ unidad_operativa, search }
    } = this.state;
    const guia = [{ nombre: titulo, url: '', clase: 'active' }];
    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-content">
                { verificarPermiso('productos.add_producto,') &&
                  <div className="row mb-2">
                    <div className="col-12">
                      <Link
                        to="/productos/agregar"
                        className="btn btn-sm btn-primary fa-margin"
                      >
                        <i className="fa fa-plus fa-margin" />
                        Agregar
                      </Link>
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-sm-3">
                    <div className="input-group">
                      {/* <select
                        value={empresa}
                        onChange={e => this.onChange('empresa', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona empresa</option>
                        {empresas.map(emp => {
                          return (
                            <option key={emp.value} value={emp.value}>
                              {emp.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <select
                        value={unidad_operativa}
                        onChange={e => this.onChange('unidad_operativa', e)}
                        className="form-control m-b"
                      >
                        <option value="">Selecciona Unidad Operativa</option>
                        {unidadesOperativas.map(uo => {
                          return (
                            <option key={uo.value} value={uo.value}>
                              {uo.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">&nbsp;</div>
                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        value={search}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={e => this.onChange('search', e)}
                        onKeyDown={e => this.handleKeyDown(e)}
                        placeholder="Buscar"
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => this.limpiar()}
                        >
                          <i className="fa fa-close fa-margin" />
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => this.buscar()}
                        >
                          <i className="fa fa-search fa-margin" />
                          Buscar
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        {/* <th>Unidad Operativa</th> */}
                        <th>Monto Minimo</th>
                        <th>Monto Maximo</th>
                        <th>Tasa de Interes Anual</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {objetos.map(obj => {
                        return (
                          <tr key={obj.id}>
                            <td>{obj.nombre}</td>
                            {/* ToDo: Ver la manera de mostrar todas las unidades operativas que han sido asignadas en el producto */}
                            {/* <td>{obj.unidades_operativas}</td> */}
                            <td>{currencyFormat(obj.monto_minimo)}</td>
                            <td>{currencyFormat(obj.monto_maximo)}</td>
                            <td>{obj.tasa_interes_anual}%</td>
                            <td>
                              <Link
                                key={`ver${obj.id}`}
                                to={`/productos/ver/${obj.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-eye fa-margin" />
                                Ver
                              </Link>
                              { verificarPermiso('productos.change_producto,') &&
                                <Link
                                  key={`editar${obj.id}`}
                                  to={`/productos/editar/${obj.id}/`}
                                  className="btn btn-primary btn-sm btns-space"
                                >
                                  <i className="fa fa-pencil fa-margin" />
                                  Editar
                                </Link>
                              }
                              <Link
                                key={`cotizar${obj.id}`}
                                to={`/productos/cotizar/${obj.id}/`}
                                className="btn btn-primary btn-sm btns-space"
                              >
                                <i className="fa fa-usd fa-margin" />
                                Cotizar
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Paginacion
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
            />
          </div>
        </div>
        <style jsx="true">{`
          .fa-margin {
            margin-right: 5px;
          }
          .centrado {
            text-align: center;
          }
          .rojo {
            color: red;
          }
          .verde {
            color: green;
          }
          .negritas {
            font-weight: bold;
          }
          .clean-search {
            position: absolute;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Lista;
