import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerEmpleados(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/empleados/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function exportarEmpleados(params = {}) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    params.export = true;
    console.log(params)
    return await API.get('/empleados/', { params });
  } catch (err) {
    callError(err);
  }
}

async function exportarEmpleado(id) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    return await API.get(`/empleados/${id}/exportar/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerEmpleado(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empleados/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarEmpleado(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/empleados/', datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarEmpleado(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/empleados/${id}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarEmpleado(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(`/empleados/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function guardarDomicilioEmpleado(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empleados/${id}/domicilio/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function guardarInfoBancariaEmpleado(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empleados/${id}/informacion-bancaria/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function guardarPercepcionesEmpleado(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empleados/${id}/percepciones/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerReferenciasEmpleado(empleadoId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empleados/${empleadoId}/referencias/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarReferenciaEmpleado(datos, empleadoId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empleados/${empleadoId}/referencias/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function editarReferenciaEmpleado(datos, empleadoId, referenciaId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(
      `/empleados/${empleadoId}/referencias/${referenciaId}/`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function eliminarReferenciaEmpleado(empleadoId, referenciaId) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/empleados/${empleadoId}/referencias/${referenciaId}/`
    );
  } catch (err) {
    callError(err);
  }
}

async function importarEmpleados(datos) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post('/empleados/importador/', datos);
  } catch (err) {
    callError(err);
  }
}

async function importarEmpleado(datos) {
  const API = await ConstructorAPIAsync(120000);
  try {
    return await API.post('/empleados/importar-empleado/', datos);
  } catch (err) {
    callError(err);
  }
}

async function enviarEmailActivacion(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/empleados/${id}/enviar-email-activacion/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerConfigEmergente(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empleados/${id}/configuracion-emergente/`);
  } catch (err) {
    callError(err);
  }
}

async function actualizarConfigEmergente(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.put(`/empleados/${id}/configuracion-emergente/`, datos);
  } catch (err) {
    callError(err);
  }
}



async function obtenerPrestamoActivo(id, parametros='' ) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empleados/${id}/prestamo-activo/`,{ params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerPrestamoActivoRenovacion(id, parametros='' ) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/empleados/${id}/prestamo-activo-renovacion/`,{ params: parametros });
  } catch (err) {
    callError(err);
  }
}


async function setContrasenaUsable(id){
  const API = await ConstructorAPIAsync();
  try{
    return await  API.post(`/empleados/${id}/set-contrasena-usable/`)
  }catch (err) {
    callError(err);
  }

}

export {
  obtenerEmpleados,
  exportarEmpleados,
  obtenerEmpleado,
  agregarEmpleado,
  actualizarEmpleado,
  eliminarEmpleado,
  guardarDomicilioEmpleado,
  guardarInfoBancariaEmpleado,
  guardarPercepcionesEmpleado,
  obtenerReferenciasEmpleado,
  agregarReferenciaEmpleado,
  editarReferenciaEmpleado,
  eliminarReferenciaEmpleado,
  importarEmpleados,
  importarEmpleado,
  enviarEmailActivacion,
  obtenerConfigEmergente,
  actualizarConfigEmergente,
  exportarEmpleado,
  obtenerPrestamoActivo,
  setContrasenaUsable,
  obtenerPrestamoActivoRenovacion
};
