import React, { Component } from 'react'
import Autenticado from '../../components/Autenticado'
import { actualizarGrupo, agregarGrupo, obtenerGrupo } from '../../api/Grupos'
import { obtenerPermisos } from '../../api/Permisos'

class Form extends Component {
    state = {
        id: null,
        titulo: null,
        accion: null,
        datos: {},
        errores: {
            nombre: []
        },
        grupos: [],
        permisosGrupo: []
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = id ? 'Editar grupo' : 'Agregar grupo'
        const accion = id ? 'Editar' : 'Agregar'
        this.setState({ id, titulo, accion })
    }

    async componentDidMount() {
        const { id } = this.state

        let resp = await obtenerPermisos()
        const permisos = resp.data
        let gruposPermisos = permisos.map((permission) => {
            return permission.content_type
        })
        gruposPermisos = [...new Set(gruposPermisos)]

        const grupos = []
        gruposPermisos.forEach((obj) => {
            const grupo = {
                name: obj,
                permissions: permisos.filter(p => p.content_type === obj)
            }
            grupos.push(grupo)
        })
        this.setState({ grupos })

        if (id){
            try {
                resp = await obtenerGrupo(id)
                const datos = resp.data
                const permisosGrupo = datos.permisos
                this.setState({ datos, permisosGrupo })
            } catch (error) {
                console.log(error)
            }
        }
    }

    guardar = async() => {
        const { id, permisosGrupo } = this.state
        const { datos } = this.state
        datos.permisos = permisosGrupo
        if (id){
            try {
                
                await actualizarGrupo(datos, id)
                this.props.history.push('/grupos')
            } catch (error) {
                const errores = error.data
                this.setState({ errores })
            }
        } else {
            try {
                await agregarGrupo(datos)
                this.props.history.push('/grupos')
            } catch (error) {
                const errores = error.data
                this.setState({ errores })
            }
        }
    }

    onChangeNombre = (e) => {
        const { datos } = this.state
        const valor = e.target.value
        datos.nombre = valor
        this.setState({ datos })
    }

    onChangePermission = id => {
        const { permisosGrupo } = this.state
        const index = permisosGrupo.indexOf(id)
        if (index === -1) { permisosGrupo.push(id) } else { permisosGrupo.splice(index, 1) }
        this.setState({ permisosGrupo })
    }


    buscarPermiso(id){
        const { permisosGrupo } = this.state
        const indice = permisosGrupo.findIndex(o => o === id)
        return indice !== -1
    }

    render () {
        const { accion, titulo, grupos, datos, errores } = this.state
        const guia = [
            { nombre: 'Grupos', url: '/grupos', clase: '' },
            { nombre: accion, url: '', clase: 'active' }
        ]


        return (
            <Autenticado titulo={ titulo } guia={ guia }>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox ">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label">Nombre *</label>
                                            <div className="col-sm-9">
                                                <input
                                                    value={datos.nombre}
                                                    type="text"
                                                    className="form-control"
                                                    onChange={this.onChangeNombre}
                                                />
                                                {errores.nombre.map((obj, index) => (
                                                <span className="form-text m-b-none text-error" key={index}>
                                                    {obj}
                                                </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {grupos.map((grupo, indice) => {
                                        return (
                                            <div className="col-lg-4" key={ indice }>
                                                <div className="ibox">
                                                    <div>
                                                        <h2>{ grupo.name }</h2>
                                                        <ul className="todo-list m-t small-list">
                                                            {grupo.permissions.map(permiso => {
                                                                return (
                                                                    <li key={ permiso.id }>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={ this.buscarPermiso(permiso.id) }
                                                                            onChange={ () => this.onChangePermission(permiso.id) }
                                                                        />
                                                                        <span className="m-l-xs">{ permiso.name }</span>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-4 col-sm-offset-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={this.guardar}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Autenticado>
        )
    }
}

export default Form
