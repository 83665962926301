import React from 'react';
import DatePicker from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

const Buscador = ({fechaInicio, fechaFin, reporteNip, busqueda, setBusqueda}) => {

    const limpiarCamposOnClick = () => {
        setBusqueda({
            fechaInicio: '',
            fechaFin: ''
        });
        reporteNip();
    }

    const busquedaOnClick = (e) => {
        if (fechaInicio && fechaFin) {
            reporteNip({fecha_inicio: fechaInicio, fecha_fin: fechaFin});
        }   
    }

    return ( 
        <div className="row">
            <div className="col-sm-3">
                <div className="form-group">
                    <DatePicker
                        key={'fecha_inicio'}
                        label={'Fecha de Inicio'}
                        name={'fechaInicio'}
                        className="form-control"
                        value={fechaInicio}
                        options={{dateFormat: 'd-m-Y', locale: Spanish }}
                        // helpText={'Fecha de Inicio'}
                        placeholder={'Fecha de inicio'}
                        error={[]}
                        onChange={(e) => {
                            setBusqueda({
                                ...busqueda,
                                fechaInicio: e[0].toISOString().split('T')[0].split('-').reverse().join('-')
                            })
                        }}
                    />
                </div>
            </div>
            <div className="col-sm-3">
                <div className="form-group">
                    <DatePicker
                        key={'fecha_fin'}
                        label={'Fecha fin'}
                        name={'fecha_fin'}
                        className="form-control"
                        value={fechaFin}
                        options={{dateFormat: 'd-m-Y', locale: Spanish }}
                        // helpText={'Fecha de finalizacion'}
                        placeholder={'Fecha de inalización'}
                        error={[]}
                        onChange={(e) => {
                            setBusqueda({
                                ...busqueda,
                                fechaFin: e[0].toISOString().split('T')[0].split('-').reverse().join('-')
                            })
                            
                        }}
                    />
                </div>
            </div>
            <div className="col-sm-3">
                <div className="form-group">
                    <button
                        className="btn btn-sm btn-info"
                        onClick={limpiarCamposOnClick}
                    >
                        <i className="fa fa-close fa-margin"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={busquedaOnClick}
                    >
                        <i className="fa fa-search fa-margin"></i>
                        Buscar
                    </button>
                </div>
            </div>
        </div>
    );
}
 
export default Buscador;