import React, { Component } from 'react';
import Swal from 'sweetalert2';
import 'flatpickr/dist/themes/material_green.css';
import Autenticado from '../../components/Autenticado';
import spinner from '../../img/spinner.gif';
import { actualizarTipoDescuentoMasivoPrestamo } from '../../api/Prestamos';
// import { currencyFormat } from '../../utils/filters';

class Masivos extends Component {
  state = {
    titulo: 'Tipo Descuento',
    archivo: null,
    errores: {
      archivo: [],
    },

    procesado: false,
    prestamos_actualizados: 0,
    prestamos_no_actualizados: 0,
    prestamos_error: [],
  };

  componentDidMount = async () => {

  };

  cargando = () => {
    Swal.fire({
      text: 'Espera un momento por favor...',
      showConfirmButton: false,
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      imageUrl: spinner,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: 'Espera un momento'
    });
  };

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
      type: tipo,
      text: mensaje,
      toast: true,
      position: 'top-end',
      timer: 3000,
      showConfirmButton: false
    });
  };

  cerrarAlertas = () => {
    Swal.close();
  };

  handleImageChange = async e => {
    e.preventDefault();
    const {
      target: { name, files }
    } = e;
    const file = files[0];
    if (file) {
      this.setState({ [name]: file });
    } else {
      this.setState({ [name]: null });
    }
  };

  validarArchivo = () => {
    let validado = true;
    const errores = {
      archivo: [],
    };
    const { archivo } = this.state;

    if (!archivo) {
      validado = false;
      errores.archivo = ['Selecciona un archivo por favor.'];
    }

    this.setState({
      errores,
      procesado: false,
      prestamos_actualizados: 0,
      prestamos_no_actualizados: 0,
      prestamos_error: []
    });
    return validado;
  };

  procesar = async () => {
    if (!this.validarArchivo()) {
      return;
    }
    this.cargando();
    const { archivo } = this.state;
    const formData = new FormData();
    formData.append('archivo', archivo);
    try {
      const resp = await actualizarTipoDescuentoMasivoPrestamo(formData)
      const prestamos = resp.data;
      let prestamos_actualizados = prestamos.prestamos_actualizados
      let prestamos_no_actualizados = prestamos.prestamos_no_actualizados
      let prestamos_error = prestamos.prestamos_error
      this.setState({
        procesado: true,
        prestamos_actualizados,
        prestamos_no_actualizados,
        prestamos_error
      });
      this.cerrarAlertas();
    } catch (error) {
      console.log('Error al procesar:', error);
      this.cerrarAlertas();
      this.mostrarToast(error.message, 'error');
    }
  };

  render() {
    const {
      titulo,
      errores,

      procesado,
      prestamos_actualizados,
      prestamos_no_actualizados,
      prestamos_error
    } = this.state;
    const guia = [
      { nombre: titulo, url: '/prestamos_tipo_descuento/update', clase: 'active' },
      { nombre: 'Actualizar', url: '', clase: '' }
    ];

    const ListaPrestamosError = () => (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
          <tr>
            <th>Folio</th>
            <th>Tipo Descuento</th>
            <th>Error</th>
          </tr>
          </thead>
          <tbody>
          {prestamos_error.map((pre, index) => (
            <tr key={`pag${index}`}>
              <td>{pre.folio}</td>
              <td>{pre.tipo_descuento}</td>
              <td>{pre.motivo}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );

    return (
      <Autenticado titulo={titulo} guia={guia}>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="archivo">Archivo</label>
                          <input
                            type="file"
                            className="form-control p36"
                            id="archivo"
                            name="archivo"
                            onChange={this.handleImageChange}
                          />
                          {errores.archivo.map((obj, index) => (
                            <span
                              className="form-text m-b-none text-error"
                              key={`ea${index}`}
                            >
                              {obj}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label>&nbsp;</label>
                        <button
                          type="button"
                          className="btn btn-success btn-sm btn-block"
                          onClick={this.procesar}
                        >
                          <i className="fa fa-cloud-upload" /> Procesar archivo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            { procesado &&
              <div className="row">
                <div className="col-md-12">
                  <div className="ibox">
                    <div className="ibox-content">
                      <div className="row">
                        <div className="col-sm-4">
                          Prestamos procesados: {prestamos_actualizados + prestamos_no_actualizados}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          Actualizados: {prestamos_actualizados}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          No Actualizados: {prestamos_no_actualizados}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-12">
                          <h5>Prestamos No procesados</h5>
                        </div>
                      </div>
                      {ListaPrestamosError()}
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
        <style jsx="true">{`
          .p36 {
            padding: 3px 6px;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Masivos;
