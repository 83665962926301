import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Autenticado from '../../components/Autenticado';
import {
  eliminarEmpleado,
  enviarEmailActivacion,
  exportarEmpleado,
  obtenerConfigEmergente,
  obtenerEmpleado,
  setContrasenaUsable
} from '../../api/Empleados';
import { currencyFormat, dateFormat, dateTimeFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import { verificarPermiso } from '../../utils/auth';

// import axios from 'axios';
import Modal from "react-awesome-modal";

class Form extends Component {
  state = {
    id: null,
    objeto: {
      documentos_str: [],
      empresas_str: []
    },
    domicilio: {},
    informacionBancaria: {},
    percepciones: {},
    referencias: [],
    configEmergente: {},
    valid_rfc: {},
    valid_curp: {},
    confDatosCont: false,
    loading: false,
    hecho: false,
    resultado : ''
  };

  componentWillMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.setState({ id });
  }

  async componentDidMount() {
    const { id, confDatosCont, loading, hecho, resultado } = this.state;

    if (id) {
      try {
        let resp = await obtenerEmpleado(id);
        let objeto = resp.data;
        let { domicilio, valid_rfc, valid_curp } = objeto
        if (domicilio === null) {
          domicilio = {
            'calle': '--',
            'numero_exterior': '--',
            'numero_interior': '--',
            'colonia': '--',
            'codigo_postal': '--',
            'estado_str': '--',
            'municipio_str': '--'
          }
        }
        let informacionBancaria = objeto.informacion_bancaria
        if (informacionBancaria === null) {
          informacionBancaria = {
            'banco': '--',
            'cuenta': '--',
            'clabe': '--'
          }
        }
        let { percepciones } = objeto
        if (percepciones === null) percepciones = {}
        const { referencias } = objeto
        domicilio = quitarNulos(domicilio)
        informacionBancaria = quitarNulos(informacionBancaria)
        percepciones = quitarNulos(percepciones)
        objeto = quitarNulos(objeto)
        resp = await obtenerConfigEmergente(id)
        let configEmergente = resp.data
        configEmergente = quitarNulos(configEmergente)
        if (objeto.apellido_materno === '--') objeto.apellido_materno = ''
        this.setState({
          objeto,
          domicilio,
          informacionBancaria,
          percepciones,
          referencias,
          configEmergente,
          valid_rfc,
          valid_curp,
          confDatosCont,
          loading,
          hecho,
          resultado
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  mostrarToast = (mensaje, tipo = 'success') => {
    Swal.fire({
        type: tipo,
        text: mensaje,
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false
    })
  }

  exportar = async () => {
    const { objeto, id } = this.state;
    try {
      const resp = await exportarEmpleado(id);
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `empleado_${objeto.nombre_completo}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  confirmar_cambio_contrasena = () => {
    this.setState({confDatosCont: true})
  }
  set_contrasena_usable = async () => {
    const { objeto } = this.state
    this.setState({loading: true})

    try {
      let res = await setContrasenaUsable(objeto['id'])
      this.setState({resultado: res.data, loading: false, hecho: true})
    } catch (error) {
      this.setState({resultado: error.data, loading: false})
    }
  }

  renderRow = (label, value, valid=null) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        <strong>{value}</strong>
      </div>
      { valid !== null && label === 'CURP' &&
        <small className={`col-sm-12 right ${valid.is_valid ? 'success-rfc-curp' : 'warning-rfc-curp'}`}>{ valid.msg }</small>
      }
      { valid !== null && label === 'RFC' &&
      <small className={`col-sm-12 right ${valid.is_valid ? 'success-rfc-curp' : 'warning-rfc-curp'}`}>{ valid.msg }</small>
      }
    </div>
  );

  renderRowBool = (label, value) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        {value ? (
          <i className="fa fa-check fa-fw" />
        ) : (
          <i className="fa fa-times fa-fw" />
        )}
      </div>
    </div>
  );

  renderRowSiNo = (label, value) => (
    <div className="row pb5">
      <div className="col-sm-4">
        <span>{label}</span>
      </div>
      <div className="col-sm-8">
        {value ? (
          'Sí'
        ) : (
          'No'
        )}
      </div>
    </div>
  );

  eliminar = async() => {
    try {
      const { id } = this.state
      await eliminarEmpleado(id)
      this.props.history.push('/empleados')
    } catch (error) {
      console.log(error)
    }
  }

  enviarBienvenida = async() => {
    try {
      const { id } = this.state
      const resp = await enviarEmailActivacion(id)
      const datos = resp.data
      if (datos.success){
        this.mostrarToast(datos.data)
      } else {
        this.mostrarToast(datos.data, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const guia = [
      { nombre: 'Empleados', url: '/empleados', clase: '' },
      { nombre: 'Ver', url: '', clase: 'active' }
    ];
    const { objeto, domicilio, informacionBancaria, percepciones, referencias, configEmergente, valid_rfc, valid_curp,
        confDatosCont,loading, hecho,resultado} = this.state;
    return (
      <Autenticado titulo="Ver empleado" guia={guia}>
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ibox">
                      <div className="ibox-content">
                        <h3>
                          <strong>{objeto.nombre} {objeto.apellido_paterno} {objeto.apellido_materno}</strong>
                        </h3>
                        {this.renderRow('Empresa',objeto.empresa_str)}
                        {this.renderRow('Unidad Operativa',objeto.unidad_operativa_str)}
                        { objeto.empresa_whirlpool_str !== undefined && objeto.empresa_whirlpool_str !== '--' && this.renderRow('Empresa Whirlpool', objeto.empresa_whirlpool_str)}
                        {console.log('=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', objeto.empresa_whirlpool_str)}
                        {this.renderRow('Correo electrónico',objeto.email)}
                        {this.renderRow('Frecuencia del pago',objeto.frecuencia_pago)}
                        {this.renderRow('CURP',objeto.curp, valid_curp)}
                        {this.renderRow('RFC',objeto.rfc, valid_rfc)}
                        {this.renderRow('Teléfono casa',objeto.telefono_casa)}
                        {this.renderRow('Teléfono celular', objeto.telefono_celular)}
                        {this.renderRow('Fecha contratación', dateFormat(objeto.fecha_contratacion))}
                        {this.renderRow('Puesto', objeto.puesto)}
                        {this.renderRow('Unidad de negocio', objeto.unidad_negocio)}
                        {this.renderRow('Género', objeto.genero)}
                        {this.renderRow('Nacionalidad', objeto.nacionalidad)}
                        {this.renderRow('Estado civil', objeto.estado_civil)}
                        {this.renderRow('No. electoral', objeto.no_electoral)}
                        {this.renderRow('No. empleado', objeto.no_empleado)}
                        {this.renderRow('Usuario caja ahorro', objeto.usuario_caja_ahorro)}
                        {this.renderRow('Canal', objeto.canal)}
                        {this.renderRow('UUID', objeto.uuid)}
                        {this.renderRow('Salesforce ID', objeto.sf_empleado_id)}
                        {this.renderRow('Fecha activación', objeto.usuario_activo_fecha ? dateTimeFormat(objeto.usuario_activo_fecha) : '--')}
                        {this.renderRow('Último inicio de sesión', objeto.ultimo_inicio_sesion ? dateTimeFormat(objeto.ultimo_inicio_sesion) : '--')}
                        {this.renderRow('Fecha creación', dateTimeFormat(objeto.fecha_creacion))}
                        {this.renderRow('Fecha actualización', dateTimeFormat(objeto.fecha_edicion))}
                      </div>
                    </div>
                  </div>
                </div>
                { verificarPermiso('empleados.view_empleadodomicilio,') &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>Domicilio</h3>
                          {this.renderRow('Calle',domicilio.calle)}
                          {this.renderRow('Número exterior',domicilio.numero_exterior)}
                          {this.renderRow('Número interior',domicilio.numero_interior)}
                          {this.renderRow('Colonia',domicilio.colonia)}
                          {this.renderRow('Código postal',domicilio.codigo_postal)}
                          {this.renderRow('Estado',domicilio.estado_str)}
                          {this.renderRow('Municipio',domicilio.municipio_str)}
                          {this.renderRow('Tipo de vivienda',domicilio.tipo_vivienda)}
                          {this.renderRow('Antigüedad en el domicilio',domicilio.antiguedad)}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                { verificarPermiso('empleados.view_empleadoinformacionbancaria,') &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>Información bancaria</h3>
                          {this.renderRow('Banco',informacionBancaria.banco_str)}
                          {this.renderRow('Cuenta',informacionBancaria.cuenta)}
                          {this.renderRow('CLABE',informacionBancaria.clabe)}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                { verificarPermiso('empleados.view_empleadopercepciones,') &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>Percepciones</h3>
                          {this.renderRow('Salario diario',currencyFormat(percepciones.salario_diario))}
                          {this.renderRow('Salario diario integrado',currencyFormat(percepciones.salario_diario_integrado))}
                          {this.renderRow('Sueldo mensual',currencyFormat(percepciones.sueldo_mensual))}
                          {this.renderRow('Sueldo mensual neto ',currencyFormat(percepciones.sueldo_mensual_real))}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                { verificarPermiso('empleados.view_empleadoreferencia,') &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>Referencias</h3>
                          <div className="table-responsive">
                          { referencias.length > 0 ?
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th>Teléfono</th>
                                  <th>Domicilio</th>
                                  <th>Parentesco</th>
                                </tr>
                              </thead>
                              <tbody>
                                {referencias.map(obj => {
                                  return (
                                    <tr key={obj.id}>
                                      <td>{obj.nombre}</td>
                                      <td>{obj.telefono}</td>
                                      <td>{obj.domicilio}</td>
                                      <td>{obj.parentesco}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          :
                            <p>No hay referencias registradas</p>
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                { verificarPermiso('empleados.view_empleadoconfiguracionemergente,') &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ibox">
                        <div className="ibox-content">
                          <h3>Configuración emergente</h3>
                          {this.renderRowSiNo('Personalizado',configEmergente.personalizado)}
                          {this.renderRowSiNo('Saldo dinámico',configEmergente.saldo_dinamico)}
                          {this.renderRow('Frecuencia del cobro',configEmergente.frecuencia_cobro)}
                          {this.renderRow('Número de amortizaciones',configEmergente.num_amortizaciones) }
                          {this.renderRow('Saldo preaprobado',currencyFormat(configEmergente.saldo_preaprobado))}
                          {this.renderRow('Tasa de interés %',configEmergente.tasa_interes)}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-md-3 pb25">
                  <button
                    type="button"
                    className="btn btn-info btn-sm btn-block"
                    onClick={this.enviarBienvenida}
                  >
                    <i className="fa fa-trash" /> Enviar bienvenida
                  </button>
                  <button
                    type="button"
                    className="btn btn-info btn-sm btn-block"
                    onClick={this.exportar}
                  >
                    <i className="fa fa-download" /> Exportar
                  </button>
                { verificarPermiso('empleados.change_empleado,') &&
                  <a
                    href={`/empleados/editar/${objeto.id}`}
                    className="btn btn-primary btn-sm btn-block"
                  >
                    <i className="fa fa-pencil" /> Editar
                  </a>
                }
                { verificarPermiso('empleados.delete_empleado,') &&
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-block"
                    onClick={this.eliminar}
                  >
                    <i className="fa fa-trash" /> Eliminar
                  </button>
                }
                { verificarPermiso('empleados.set_contrasena_usable,') &&
                <button
                    onClick={this.confirmar_cambio_contrasena}
                    type="button"
                    className="btn btn-secondary btn-sm btn-block"
                >
                  <i className="fa fa-id-badge" /> Set Contraseña Usable
                </button>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal visible={ confDatosCont } width="45%" height="40%" effect="fadeInUp">
          <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
            <div className="modal-header">
              <h4 className="modal-title">Confirmación</h4>
            </div>
            <div className="modal-body" style={{'overflow-y':'scroll'}}>
              <div className="row">
                ¿Deseas reiniciar la contraseña usable del usuario?
                { resultado !== '' &&
                  <div>
                    <b><br />{resultado}</b>
                  </div>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-white" onClick={() => this.setState({confDatosCont: false}) } disabled={loading}>Cerrar</button>
              <button type="button" className="btn btn-primary" onClick={() => this.set_contrasena_usable()} disabled={loading || hecho}>{ loading ? 'Procesando...' : 'Cambiar' }</button>
            </div>
          </div>
        </Modal>
        <style jsx="true">{`
          .info {
            display: flex;
            justify-content: space-between;
          }
          .pb25 {
            padding-bottom: 25px;
          }
          .pb5 {
            padding-bottom: 5px;
          }
          .pl10 {
            padding-left: 10px;
          }
          .warning-rfc-curp {
            color: #e2943a !important;
          }
          .success-rfc-curp {
            color: green !important;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Form;
