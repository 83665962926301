import React, { useEffect, useState } from 'react';
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias';
import { crearArchivoBancario } from '../../api/TransaccionesBancarias';
import { mostrarToast, cargando, cerrarAlertas } from '../../utils/alerts';
import Autenticado from '../../components/Autenticado';


const guia = [
  { nombre: 'Archivos Bancarios', url: 'archivos-bancarios', clase: 'active' }
];

const ProcesarArchivoBancario = () => {
  const [, updateState] = React.useState();
  const [cuentasBancarias, setCuentasBancarias] = useState([]);
  const [banco, setBanco] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(undefined);

  const getCuentasBancarias = async () => {
      await obtenerCuentasBancarias()
      .then(({data}) => {
        setCuentasBancarias(data);
      })
      .catch((error) =>{
        mostrarToast('No fue posible obtener las cuentas bancarias', 'error')
      })
  };

  useEffect(() => {
    getCuentasBancarias();
  }, []);

  const enviarArchivo = async() => {

    const formData = new FormData();
    formData.append('archivo', file);
    formData.append('cuenta', banco);

    setLoading(true)
    cargando()
    
    await crearArchivoBancario(formData)
    .then( ({data}) => {
      cerrarAlertas()
      window.location.replace(`archivos-bancarios/ver/${data.id}`)
      console.log(data)
    })
    .catch(
      (error) => {
        setLoading(false)
        cerrarAlertas()
        if (error.data.hasOwnProperty('error')){
          // Errores al momento de procesar el archivo bancario
          mostrarToast(error.data.error, 'error')
        }else{
          // Errores de serializador  (formulario)
          setErrors(error.data)
        }
      }
    );
  };

  return (
    <Autenticado titulo="Procesar Archivo" guia={guia}>
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-content">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className={`${errors.hasOwnProperty('cuenta') ? 'has-error' : ''} form-group`}>
                        <label for="banco">Banco</label>
                        <select
                          onChange={e => setBanco(e.target.value)}
                          className="form-control"
                          style={{height: '40px'}}
                          id="bancoPagos"
                          name="bancoPagos"
                        >
                          <option value="">Seleccionar cuenta</option>
                          {cuentasBancarias.map(cuenta => (
                            <option value={cuenta.id} key={cuenta.id}>
                              {cuenta.banco}
                            </option>
                          ))}
                        </select>
                        { 
                          errors.hasOwnProperty('cuenta') &&
                          errors.hasOwnProperty('cuenta') ? errors.cuenta.map((error) => <p className='text-error ml-1 mt-1'>{error}</p>) : null
                        }

                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className={`${errors.hasOwnProperty('archivo') ? 'has-error' : ''} form-group`}>
                        <label for="archivo">Archivo</label>
                        <input
                          onChange={e => setFile(e.target.files[0])}
                          type="file"
                          className="form-control p36"
                          id="archivo_pagos"
                          name="archivo_pagos"
                          disabled=""
                        />
                      </div>
                      { 
                        errors.hasOwnProperty('archivo') &&
                        errors.hasOwnProperty('archivo') ?
                        errors.archivo.map((error) => <p className='text-error ml-1 mt-1'>{error}</p>) : null
                      }
                    </div>

                    <div className="col-sm-2">
                      <label>&nbsp;</label>
                      <button
                      onClick={enviarArchivo}
                        type="button"
                        className="btn btn-success btn-sm btn-block"
                      disabled = {loading}  
                      >
                        <i className="fa fa-cloud-upload" />
                        Procesar archivo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Autenticado>
  );
};

export default ProcesarArchivoBancario;
