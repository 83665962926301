import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerCuentasMaestrasList(parametros = '') {
    const API = await ConstructorAPIAsync();
    try {
      return await API.get('/cuentas-maestra-list/', { params: parametros });
    } catch (err) {
      callError(err);
    }
}

async function obtenerCuentasMaestras(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/cuentas-maestra/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function obtenerCuentaMaestra(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/cuentas-maestra/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function exportarCuentasMaestras(params = {}) {
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    params.export = true;
    return await API.get('/cuentas-maestra/', { params });
  } catch (err) {
    callError(err);
  }
}

  export {
    obtenerCuentasMaestrasList,
    obtenerCuentasMaestras,
    obtenerCuentaMaestra,
    exportarCuentasMaestras
  };